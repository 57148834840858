import React from 'react';
import styles from './Modal.module.scss';
import Box from '@mui/material/Box';
import ModalComponent from '@mui/material/Modal';
import classNames from 'classnames';

import CloseButton from '../buttons/CloseButton/CloseButton';
import { useBackButtonOverride } from '../../hooks/useBackButtonOverride';

export default function Modal({ open, onClose, children, className }) {
  useBackButtonOverride({
    shouldOverride: open,
    onBack: onClose,
    id: 'modal',
  });

  return (
    <ModalComponent open={open} onClose={onClose}>
      <Box className={classNames(styles.modal, className)}>
        <CloseButton className={styles.closeButton} onClick={onClose} />

        {children}
      </Box>
    </ModalComponent>
  );
}
