import { useEffect, useRef } from 'react';

export const useIsWindowFocused = () => {
  const isFocusedRef = useRef(true);

  const handleFocus = () => (isFocusedRef.current = true);
  const handleBlur = () => (isFocusedRef.current = false);

  const handleVisibilityChange = () => {
    if (window.document.visibilityState === 'visible') {
      handleFocus();
    } else {
      handleBlur();
    }
  };

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isFocusedRef;
};
