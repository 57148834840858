import { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLoader } from '../hooks/useLoader';
import MdtService from '../services/api/MdtService';
import { useAuthContext } from '../hooks/useAuthContext';
import { useInterval } from '../hooks/useInterval';
import { config } from '../configs/config';
import { getQueryParamsOffset } from '../utils/url';
import { logAnalyticsEvent } from '../utils/analytics';
import { AnalyticsEvent } from '../constants/analytics.constants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../utils/error';

export const MdtContext = createContext({
  groups: [],
  threads: [],
  group: { threads: [] },
  groupThreads: [],
});

export function MdtProvider({ children }) {
  const [groups, setGroups] = useState(null);
  const [group, setGroup] = useState({});
  const [groupError, setGroupError] = useState(null);
  const [groupUser, setGroupUser] = useState({});

  const [groupThreads, setGroupThreads] = useState(null);
  const [groupThreadsCount, setGroupThreadsCount] = useState(0);
  const [groupUsers, setGroupUsers] = useState(null);
  const [groupUsersCount, setGroupUsersCount] = useState(0);

  const { user } = useAuthContext();

  const navigate = useNavigate();
  const loader = useLoader();

  const params = useParams();
  const location = useLocation();

  const groupsType = location.pathname.split('/')[3];
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const allowedTypes = ['mdt', 'public', 'private'];

        if (!allowedTypes.includes(groupsType)) {
          return;
        }
        setGroups(null);
        loader.startLoading();

        const { body } = await MdtService.getGroups({ type: groupsType.toUpperCase() });

        setGroups(body);
      } catch (error) {
        console.error(error);
        toast.error(error?.message || 'Failed to fetch groups');
      } finally {
        loader.stopLoading();
      }
    };

    fetchGroups();
  }, [groupsType]);

  useEffect(() => {
    const fetchGroup = async () => {
      resetGroup();

      await getGroup({
        groupId: params.groupId,
        offset: getQueryParamsOffset(queryParams),
        userId: queryParams.get('userId'),
        status: queryParams.get('status'),
      });
    };
    params.groupId && fetchGroup();
  }, [
    params.groupId,
    queryParams.get('page'),
    queryParams.get('userId'),
    queryParams.get('status'),
    queryParams.get('title'),
  ]);

  const startGroupsPolling = useCallback((type) => {
    if (!config.httpPolling.groups.enabled) {
      return;
    }
    return useInterval(async () => {
      const { body } = await MdtService.getGroups({ type });
      setGroups(body);
    }, config.httpPolling.groups.interval);
  }, []);

  const startGroupPolling = useCallback(() => {
    if (!config.httpPolling.group.enabled) {
      return;
    }

    return useInterval(async () => {
      if (!params.groupId) return;
      try {
        const { body } = await MdtService.getGroupThreads(params.groupId, {
          offset: getQueryParamsOffset(queryParams),
          userId: queryParams.get('userId'),
          status: queryParams.get('status'),
          title: queryParams.get('title'),
        });
        setGroupThreads(body);
      } catch (error) {
        console.error(error);
      }
    }, config.httpPolling.group.interval);
  }, [params.groupId, queryParams]);

  const addGroup = async (values) => {
    const { body } = await MdtService.createGroup(values);

    setGroups([...groups, { id: body.id, ...values }]);
  };

  const deleteGroup = async (groupId) => {
    await MdtService.deleteGroup(groupId);

    setGroups(groups.filter((group) => group.id !== groupId));
  };

  const editGroup = async (groupId, values) => {
    await MdtService.updateGroup(groupId, values);

    setGroups(groups.map((group) => (group.id !== groupId ? group : { ...group, ...values })));
    setGroup({ ...group, ...values });
  };

  const getGroup = async (params) => {
    try {
      loader.startLoading();
      setGroupError(null);

      const [groupRequest, threadsRequest, groupUserRequest] = await Promise.all([
        MdtService.getGroup(params.groupId),
        MdtService.getGroupThreads(params.groupId, params),
        MdtService.getGroupUser(params.groupId, user.id),
      ]);

      setGroup(groupRequest.body);
      setGroupThreads(threadsRequest.body);
      setGroupUser(groupUserRequest.body);
      setGroupThreadsCount(threadsRequest.headers['x-total-count']);
    } catch (error) {
      const message = getErrorMessage(error, 'Failed to fetch group');
      toast.error(message);

      setGroupError(message);
    } finally {
      loader.stopLoading();
    }
  };

  const getGroupUsers = async (params) => {
    const { body, headers } = await MdtService.getGroupUsers(params.groupId, params);

    setGroupUsers(body);
    setGroupUsersCount(headers['x-total-count']);

    return { body, headers };
  };

  const resetGroup = () => {
    setGroup({});
    setGroupThreads(null);
    setGroupUser({});
    setGroupThreadsCount(0);
  };

  const addUserToGroup = async (groupId, user, values) => {
    await MdtService.addUserToGroup(groupId, user.id, values);

    const existingUsers = groupUsers ? groupUsers : [];
    setGroupUsersCount(groupUsersCount + 1);
    setGroupUsers([...existingUsers, { ...user, ...values, usersGroups: [{ groupId }] }]);
  };

  const removeUserFromGroup = async (groupId, userId) => {
    await MdtService.removeUserFromGroup(groupId, userId);

    setGroupUsersCount(groupUsersCount - 1);
    setGroupUsers(groupUsers?.filter((user) => user.id !== userId));
  };

  const editUserGroup = async (groupId, userId, values) => {
    await MdtService.updateUserGroup(groupId, userId, values);

    setGroupUsers(
      groupUsers.map((user) =>
        user.id !== userId ? user : { ...user, ...values, updatedAt: new Date().toISOString() },
      ),
    );
  };

  const addThread = async (groupId, values) => {
    const { body, headers } = await MdtService.createThread(groupId, values);

    logAnalyticsEvent(AnalyticsEvent.THREAD_CREATE, {
      group_type: groupsType,
    });

    if (group.id !== groupId) {
      return { body, headers };
    }

    setGroupThreads([
      {
        ...values,
        id: body.id,
        status: 'OPEN',
        user,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
      },
      ...groupThreads,
    ]);

    return { body, headers };
  };

  const onThreadUpdated = async (threadId, values) => {
    setGroupThreads(
      groupThreads.map((thread) =>
        thread.id !== threadId ? thread : { ...thread, ...values, updatedAt: new Date().toISOString() },
      ),
    );
  };

  const onThreadDeleted = async (threadId) => {
    setGroupThreads(groupThreads.filter((thread) => thread.id !== threadId));
    navigate(`/internal/groups/${group.type.toLowerCase()}/${group.id}`);
  };

  return (
    <MdtContext.Provider
      value={{
        groups,
        group,
        groupThreads,
        groupUser,
        addGroup,
        deleteGroup,
        editGroup,
        getGroup,
        editUserGroup,
        removeUserFromGroup,
        addUserToGroup,
        onThreadDeleted,
        setGroupThreads,
        setGroupUser,
        groupThreadsCount,
        groupUsers,
        groupUsersCount,
        getGroupUsers,
        setGroupUsers,
        setGroupThreadsCount,
        onThreadUpdated,
        addThread,
        startGroupPolling,
        startGroupsPolling,
        groupError,
      }}
    >
      {children}
    </MdtContext.Provider>
  );
}
