import TagManager from 'react-gtm-module';

import { config } from '../configs/config';

export const logAnalyticsEvent = (event, data) => {
  try {
    if (config.googleTagId) {
      TagManager.dataLayer({
        dataLayer: {
          event,
          ...data,
        },
      });
    }
  } catch (error) {
    // ignore error
  }
};
