export const getUsersSearchParams = (term: string) => {
  const properties: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
  } = {
    firstName: null,
    lastName: null,
    email: null,
  };

  const emailMatch = term.match(/\S+@\S+\.\S+/);
  if (emailMatch && emailMatch.length > 0) {
    properties.email = emailMatch[0];
    term = term.replace(emailMatch[0], '').trim();
  }

  const [firstName, lastName] = term.split(' ');
  if (firstName) {
    properties.firstName = firstName;
  }

  if (lastName) {
    properties.lastName = lastName;
  }

  return properties;
};
