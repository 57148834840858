import React from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PaidIcon from '@mui/icons-material/Paid';
import { useNavigate } from 'react-router-dom';
import { Skeleton, Tooltip, Zoom } from '@mui/material';

import styles from './ThreadsHeader.module.scss';
import Button from '../../../../components/button/Button';

import { ThreadAddTitleNameMap } from '../../../../constants/thread.constants';
import { useAppSelector } from '../../../../store/hooks';
import { selectPermissions } from '../../../../store/slices/permissions/permissionsSlice';

export default function ThreadsHeader({
  group,
  setOpenGroupMenu,
  setOpenAddMembersGroupModal,
  setOpenAddThreadModal,
  filters,
}) {
  const navigate = useNavigate();

  const handleBack = () => navigate(`/internal/groups/${group?.type?.toLowerCase()}`);
  const { permissions: tierPermissions } = useAppSelector(selectPermissions);

  const hasThreadCreationPermission = tierPermissions && tierPermissions[`threads:${group?.type?.toLowerCase()}:create`];
  const hasGroupInvitePermission = tierPermissions && tierPermissions[`groups:${group?.type?.toLowerCase()}:invite`];

  const getGroupInfo = () => {
    if (!group.name) {
      return <Skeleton variant="rounded" height={20} width={180} sx={{ mb: '4px' }} />;
    }

    return (
      <div className={styles.headerContentNavigation}>
        <IconButton onClick={handleBack} className={styles.backButton}>
          <ChevronLeftIcon />
        </IconButton>

        <Typography className={styles.headerContentNavigationName}>{group?.name}</Typography>

        <div>
          <Tooltip
            title="Settings"
            TransitionComponent={Zoom}
            enterDelay={500}
            leaveDelay={200}
            placement="bottom"
            arrow
          >
            <IconButton onClick={(event) => setOpenGroupMenu(event.target)}>
              <SettingsIcon fontSize="small" sx={{ fill: '#5d7285' }} />
            </IconButton>
          </Tooltip>

          {setOpenAddMembersGroupModal && hasGroupInvitePermission && (
            <Tooltip
              title="Add New Member"
              TransitionComponent={Zoom}
              enterDelay={500}
              leaveDelay={200}
              placement="bottom"
              arrow
            >
              <IconButton onClick={(event) => setOpenAddMembersGroupModal(event.target)}>
                <PersonAddIcon fontSize="small" sx={{ fill: '#5d7285' }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  const getFilters = () => {
    if (!group.name) {
      return (
        <div className={styles.filters}>
          <Skeleton variant="rounded" height={20} width="10%" />
          <Skeleton variant="rounded" height={20} width="20%" />
        </div>
      );
    }

    return <div className={styles.filters}>{filters}</div>;
  };

  const getButton = () => {
    if (!group.name) {
      return <Skeleton variant="rounded" height={30} width={110} />;
    }

    return (
      <Button
        rounded
        className={styles.button}
        onClick={() => {
          hasThreadCreationPermission ? setOpenAddThreadModal(true) : navigate(`/internal/profile?tab=subscriptions`);
        }}
      >
        {ThreadAddTitleNameMap[group.type]} {!hasThreadCreationPermission &&<PaidIcon sx={{ width: '21px', height: '21px', margin: '4px' }} />}
      </Button>
    );
  };

  return (
    <div className={styles.header}>
      <div className={styles.topRow}>
        {getGroupInfo()}

        {getButton()}
      </div>

      {getFilters()}
    </div>
  );
}
