import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';

import { platformSpecific } from './platform';
import S3Service from '../services/s3/S3Service';

const downloadS3FileWeb = async (url: string, fileName: string) => {
  const blob = await S3Service.downloadObject(url);

  const blobUrl = URL.createObjectURL(blob.body);
  const a = document.createElement('a');

  a.style.display = 'none';
  a.href = blobUrl;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(blobUrl);
};

const downloadS3FileNative = async (url: string, fileName: string) => {
  const { path } = await Filesystem.downloadFile({
    path: fileName,
    url,
    directory: Directory.Documents,
  });

  if (!path) {
    throw new Error('Failed to download file');
  }

  await FileOpener.openFile({
    path,
  });
};

export const downloadS3File = platformSpecific({
  web: downloadS3FileWeb,
  native: downloadS3FileNative,
});
