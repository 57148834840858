import { UserStatus } from './user.constants';

export const USER_STATUS_OPTIONS = [
  { value: UserStatus.ACTIVE, label: 'Active' },
  { value: UserStatus.INACTIVE, label: 'Inactive' },
  { value: UserStatus.PENDING, label: 'Pending' },
];

export const SPECIALITY_OPTIONS = [
  { value: 'Anaesthesia', label: 'Anaesthesia' },
  { value: 'Psychiatry', label: 'Psychiatry' },
  { value: 'GP', label: 'GP' },
  { value: 'Nurse', label: 'Nurse' },
  { value: 'Gynaecology', label: 'Gynaecology' },
  { value: 'Neurology', label: 'Neurology' },
  { value: 'Orthapaedics', label: 'Orthapaedics' },
  { value: 'Paediatrics', label: 'Paediatrics' },
  { value: 'Rheumatology', label: 'Rheumatology' },
  { value: 'Oncology', label: 'Oncology' },
  { value: 'Palliative Care', label: 'Palliative Care' },
  { value: 'Gastroenterology', label: 'Gastroenterology' },
  { value: 'Musculoskeletal', label: 'Musculoskeletal' },
  { value: 'Ophthalmology', label: 'Ophthalmology' },
  { value: 'Emergency Medicine', label: 'Emergency Medicine' },
  { value: 'Other', label: 'Other' },
];
