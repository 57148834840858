import React from 'react';
import ChipComponent from '@mui/material/Chip';
import classNames from 'classnames';
import { SxProps } from '@mui/material';

import styles from './Chip.module.scss';

interface Props {
  label: string;
  className?: string;
  size?: 'small' | 'medium';
  sx?: SxProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  onClick?: () => void;
  variant?: 'filled' | 'outlined';
  color?: 'primary' | 'secondary' | 'default' | 'error' | 'warning' | 'info' | 'success';
}

const Chip: React.FC<Props> = ({
  label,
  className,
  size,
  sx,
  icon,
  onClick,
  variant = 'outlined',
  color = 'primary',
}) => {
  return (
    <div className={styles.container}>
      <ChipComponent
        label={label}
        color={color}
        sx={sx}
        className={classNames(styles.chip, className)}
        variant={variant}
        icon={icon}
        size={size}
        onClick={onClick}
      />
    </div>
  );
};

export default Chip;
