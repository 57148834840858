import React from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Messaging, getMessaging, onMessage } from 'firebase/messaging';

import { config } from '../../configs/config';
import { useFeedback } from '../../hooks/useFeedback';

let firebaseApp: FirebaseApp | undefined = undefined;
let messaging: Messaging | undefined = undefined;

if (!config.isNative) {
  firebaseApp = initializeApp(config.firebaseConfig);
  messaging = getMessaging(firebaseApp);
}

const WebNotificationsProvider: React.FC = () => {
  const { showInfoFeedback } = useFeedback();

  if (messaging) {
    onMessage(messaging, (payload) => {
      const message = payload?.data?.message;

      if (message) showInfoFeedback(message);
    });
  }

  return null;
};

export default WebNotificationsProvider;
