import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceName } from '../../config';
import OrganisationsService from '../../../services/api/OrganisationsService';
import { OrganisationsFilters } from '../../../types/OrganisationsFilters';

export const getOrganisations = createAsyncThunk(
  `${SliceName.ORGANISATIONS}/getOrganisations`,
  async (filters?: OrganisationsFilters) => {
    const organisations = await OrganisationsService.get(filters);

    return organisations;
  },
);
