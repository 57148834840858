import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import SignUpForm from './sign-up-form/SignUpForm';
import styles from './SignUpPage.module.scss';
import { useAppDispatch } from '../../../store/hooks';
import { signUp } from '../../../store/slices/auth/authThunks';
import { SingUpDTO } from '../../../types/DTOs/SignUpDTO';

const SignUpPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSignUp = async (signUpDTO: SingUpDTO) => {
    await dispatch(signUp(signUpDTO)).unwrap();

    navigate('/');
  };

  return (
    <Container className={styles.container}>
      <Typography className={styles.title} component="h1" variant="h4">
        Sign Up
      </Typography>
      <Typography className={styles.description}>
        Sign up securely to MedSym for free – <strong>no credit card required</strong>. Following your 3-month free trial you’ll have the option to subscribe for just £7.99 per user, per month, with <strong>free cancelation</strong> any time. To successfully register you will need:
        <ul>
          <li>Photographic ID or passport</li>
          <li>Your regulatory body identifying number (e.g. GMC, NMC, GPhC)</li>
        </ul>
      </Typography>
      <Typography className={styles.requirementsInfo}>
      </Typography>

      <SignUpForm onSubmit={handleSignUp} />
    </Container>
  );
};

export default SignUpPage;
