import React from 'react';

import { UserProfileStatusMap } from '../../../constants/user.constants';
import { OnboardingStatus } from '../../../types/enums/OnboardingStatus';
import Chip from '../Chip/Chip';

interface Props {
  status: OnboardingStatus;
  onClick?: () => void;
}

const OnboardingStatusChip: React.FC<Props> = ({ status, onClick }) => {
  const { color, label, icon } = UserProfileStatusMap[status];

  return (
    <Chip
      size="small"
      label={label}
      icon={icon}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color={color as any}
      onClick={onClick}
    />
  );
};

export default OnboardingStatusChip;
