import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import FullscreenLoader from '../../../../../components/feedback/FullscreenLoader/FullscreenLoader';
import classes from './NotificationsManagement.module.scss';
import ErrorMessage from '../../../../../components/feedback/ErrorMessage/ErrorMessage';
import Switch from '../../../../../components/inputs/Switch/Switch';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectUserNotificationSettings } from '../../../../../store/slices/userNotificationSettings/userNotificationSettingsSlice';
import { getUserNotificationSettings, updateUserNotificationSettings } from '../../../../../store/slices/userNotificationSettings/userNotificationSettingsThunks';


const NotificationsManagement: React.FC = () => {
  const dispatch = useAppDispatch();

  const { userNotificationSettings, error, loading } = useAppSelector(selectUserNotificationSettings);
  const NOTIFICATION_COLUMNS = [
    {
      key: 'emailSettings',
      label: 'Email',
      width: 100,
      disabled: false
    },
  ]

  useEffect(() => {
    dispatch(getUserNotificationSettings());
  }, []);

  const handleNotificationSettingChange = (e: React.ChangeEvent<HTMLInputElement>, type: string, name: string) => {
    dispatch(updateUserNotificationSettings({
      ...userNotificationSettings!,
      [type]: { ...userNotificationSettings![type], [name]: e.target.checked }
    }));
  };


  if (loading || (!userNotificationSettings && !error)) {
    return (
      <div className={classes.loaderContainer}>
        <FullscreenLoader />
      </div>
    );
  }

  if (error || !userNotificationSettings) {
    return (
      <div className={classes.container}>
        <ErrorMessage>{error || 'An error occurred while fetching your notification settings'}</ErrorMessage>
      </div>
    );
  }
  const renderNotifictionSettingRow = (title: string, name: string) => {
    return (
      <TableRow>
        <TableCell component="th" scope="row">{title}</TableCell>

        {NOTIFICATION_COLUMNS.map(({ key, width, disabled }) =>
          <TableCell key={key} width={width} align="left">
            <Switch value={userNotificationSettings[key][name]} disabled={disabled} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNotificationSettingChange(e, key, name)}/>
          </TableCell>
        )}
      </TableRow>
    )
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.info}>
        <Typography>Notifications Management</Typography>
        <Typography className={classes.description}>Manage Your Notification Preferences</Typography>
      </Typography>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell key="name"></TableCell>
              {NOTIFICATION_COLUMNS.map(({ key, width, label }) => (<TableCell key={key} width={width}>{label}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderNotifictionSettingRow('Public Group', 'publicGroup')}
            {renderNotifictionSettingRow('Private Group', 'privateGroup')}
            {renderNotifictionSettingRow('MDT Organisation', 'mdtOrganisation')}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default NotificationsManagement;
