import React, { useState, useEffect } from 'react';

import styles from './AdminUserPage.module.scss';
import UserService from '../../../services/api/UserService';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  Avatar,
  TextField,
  ButtonGroup,
  ListItemText,
  List,
  ListItemButton,
  IconButton,
} from '@mui/material';
import { OnboardingApplicationStatusMap, UserProfileStatus, UserStatus } from '../../../constants/user.constants';
import dayjs from 'dayjs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useFormik } from 'formik';
import Chip from '../../../components/chips/Chip/Chip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Button from '../../../components/button/Button';
import UserProfileWrapper from '../../../components/user-profile/UserProfileWrapper';
import UserProfileItem from '../../../components/user-profile/UserProfileItem';
import { OnboardingApplicationStatus } from '../../../types/enums/OnboardingApplicationStatus';
import { formatCouncil } from '../../../utils/onboarding';
import { updateUserDetails } from '../../../store/slices/userDetails/userDetailsThunks';
import SelectInputField from '../../../components/select-input-field/SelectInputField';
import { PlanTier } from '../../../types/enums/PlanTier';
import { useAppDispatch } from '../../../store/hooks';
import { setUserId } from '../../../store/slices/userDetails/userDetailsSlice';
import { specialities } from '../../public/sign-up/sign-up-form/SignUpForm.constants';
import TextInputField from '../../../components/text-input-field/TextInputField';

// TODO: Refactor component
export default function AdminUserPage() {
  const [user, setUser] = useState({});
  const [open, setOpen] = React.useState(false);
  const [isPlanTierEditting, setIsPlanTierEditting] = React.useState(false);
  const [isSpecialityEditting, setIsSpecialityEditting] = React.useState(false);
  const [isMedicalRegistrationEditting, setIsMedicalRegistrationEditting] = React.useState(false);

  const navigate = useNavigate();
  
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedPlan, setSelectedPlan] = React.useState(user.tier);
  const [selectedSpeciality, setSelectedSpeciality] = React.useState(user.speciality);
  const [medicalRegistrationNumber, setmedicalRegistrationNumber] = React.useState(user?.mdtProfile?.medicalRegistrationNumber);



  const dispatch = useAppDispatch();

  const params = useParams();

  useEffect(() => {
    const fetchInitialData = async () => {
      dispatch(setUserId(params.userId));
      const { body } = await UserService.getUser(params.userId);
      setUser(body);
      setSelectedPlan(body.tier);
      setSelectedSpeciality(body.speciality);
      setmedicalRegistrationNumber(body?.mdtProfile?.medicalRegistrationNumber);
    };
    params.userId && fetchInitialData();
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const getOnboardingApplicationReviewStatus = () => {
    if (user.mdtProfile.onboardingApplicationStatus.includes(OnboardingApplicationStatus.RESTRICTED_STANDARD_PENDING)) {
      return OnboardingApplicationStatus.RESTRICTED_STANDARD;
    }

    if (user.mdtProfile.onboardingApplicationStatus.includes(OnboardingApplicationStatus.MDT_STANDARD_PENDING)) {
      return OnboardingApplicationStatus.MDT_STANDARD;
    }
  }

  const onOnboardingDocumentClick = async (url) => {
    const { body } = await UserService.getOnboardingDocumentUrl({ url });

    window.open(body.url);
  };

  const options = [
    { name: UserProfileStatus.CHANGES_REQUESTED, title: 'Request Changes' },
    { name: UserProfileStatus.APPROVED, title: 'Approve' },
    { name: UserProfileStatus.REJECTED, title: 'Reject' },
  ];

  const formik = useFormik({
    initialValues: { onboardingReviewComment: '', status: '' },
    onSubmit: async (values, { resetForm }) => {
      const mdtProfile = {
        onboardingReviewComment: values.onboardingReviewComment || undefined,
        status: options[selectedIndex].name,
        onboardingApplicationStatus: options[selectedIndex].name === UserProfileStatus.APPROVED ? getOnboardingApplicationReviewStatus() : undefined
      };

      await UserService.updateProfile(user.id, { mdtProfile });

      setUser({
        ...user,
        status: mdtProfile?.status === UserProfileStatus.APPROVED ? UserStatus.ACTIVE : undefined,
        mdtProfile: { ...user.mdtProfile, ...mdtProfile },
      });

      resetForm();
    },
  });

  const handleStatusUpdate = async (status) => {
    await UserService.updateProfile(user.id, { status });

    setUser({ ...user, status });
  };


  const handleSubcriptionPlanUpdate = async () => {
    if (!selectedPlan) return;

    setIsPlanTierEditting(false);

    await dispatch(updateUserDetails({ tier: selectedPlan })).unwrap();
    setUser({ ...user, tier: selectedPlan });
  }

  const handleSpecialityUpdate = async () => {
    if (!selectedSpeciality) return;

    setIsSpecialityEditting(false);

    await dispatch(updateUserDetails({ speciality: selectedSpeciality })).unwrap();
    setUser({ ...user, speciality: selectedSpeciality });
  }

  const handleMedicalRegistrationUpdate = async () => {
    if (!medicalRegistrationNumber) return

    setIsMedicalRegistrationEditting(false);
      
    await dispatch(updateUserDetails({ mdtProfile: { medicalRegistrationNumber } })).unwrap();
    setUser({ ...user, mdtProfile: { ...user.mdtProfile, medicalRegistrationNumber } });
  }


  const displayMdtProfileActions = () => {
    if (user?.mdtProfile?.status !== UserProfileStatus.IN_REVIEW) {
      return;
    }

    return (
      <form onSubmit={formik.handleSubmit}>
        <Typography className={styles.cardItem} variant="body2">
          <TextField
            className={styles.reviewComment}
            id="outlined-multiline-static"
            name="onboardingReviewComment"
            value={formik.values.onboardingReviewComment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            multiline
            rows={2}
            placeholder="Onboarding Review Comment (optional)"
          />
        </Typography>
        <ButtonGroup className={styles.buttonGroup} variant="contained" aria-label="split button">
          <Button
            loading={formik.isSubmitting}
            variant="contained"
            type="submit"
            className={styles.actionButton}
            refAnchor={anchorRef}
          >
            {options[selectedIndex].title}
          </Button>
          <Button
            className={styles.optionsButton}
            type="none"
            disabled={formik.isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              setOpen((prevOpen) => !prevOpen);
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popper
          sx={{ zIndex: 1, width: 'max-content' }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
                width: anchorRef.current ? anchorRef.current.offsetWidth : null,
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.name}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </form>
    );
  };

  const displayUploadedFiles = () => {
    const files = [
      user.mdtProfile?.addressDocument,
      user.mdtProfile?.identityDocument,
      user.mdtProfile?.criminalBackgroundDocument,
      user.mdtProfile?.qualityCredentialDocument,
      user.mdtProfile?.indemnityInsuranceDocument,
    ]
      .filter((file) => file)
      .map((url) => {
        const [type, name] = url.split('/').slice(-2);

        return { type, name, url };
      });
    const typeMap = {
      address: 'Address',
      identity: 'Identity',
      criminalBackground: 'Criminals Background',
      qualityCredential: 'Quality',
      indemnityInsurance: 'Indemnity Insurance',
    };

    if (!files.length) {
      return (
        <List>
          <ListItemButton
            disabled={true}
            rel="noopener noreferrer"
            variant="outlined"
            color="primary"
            className={styles.documentButton}
          >
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <ListItemText primary={'There are no uploaded documents yet'} />
            </Typography>
          </ListItemButton>
        </List>
      );
    }

    return (
      <List>
        {files.map((file, index) => (
          <ListItemButton
            key={index}
            rel="noopener noreferrer"
            variant="outlined"
            color="primary"
            className={styles.documentButton}
            onClick={() => onOnboardingDocumentClick(file.url)}
          >
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <FileDownloadIcon fontSize="large" />
              <ListItemText primary={file.name} />
            </Typography>
            <Chip color="default" size="small" label={typeMap[file.type]} />
          </ListItemButton>
        ))}
      </List>
    );
  };

  const renderPlanTier = () => {
    if (isPlanTierEditting) {
      return (
        <SelectInputField
          name="tier"
          className={styles.selectInput}
          value={selectedPlan}
          defaultValue={selectedPlan}
          onChange={(e) => setSelectedPlan(e.target.value)}
        >
          {[PlanTier.FREE, PlanTier.STANDARD, PlanTier.PRO].map((tier) => (<MenuItem key={tier} value={tier}>{tier.toLocaleLowerCase()}</MenuItem>))}
        </SelectInputField>
      )
    }

    return (
      <Chip size="small" color="primary" label={user.tier?.toLowerCase() || 'N/A'} />
    )
  }

  const renderSpeciality = () => {
    if (isSpecialityEditting) {
      return (
        <SelectInputField
          name="speciality"
          className={styles.selectInput}
          value={selectedSpeciality}
          defaultValue={selectedSpeciality}
          onChange={(e) => setSelectedSpeciality(e.target.value)}
        >
          {specialities.map((speciality) => (<MenuItem key={speciality} value={speciality.toLocaleLowerCase()}>{speciality}</MenuItem>))}
        </SelectInputField>
      )
    }

    return (
      <div className={styles.item}>{<Chip size="small" color="primary" label={user.speciality || 'N/A'} />}</div>
    )
  }

  const renderMedicalRegistrationNumber = () => {
    if (isMedicalRegistrationEditting) {
      return <TextInputField
        label="Medical Registration Number"
        size="small"
        name="medicalRegistrationNumber"
        value={medicalRegistrationNumber}
        onChange={(e) => setmedicalRegistrationNumber(e.target.value)}
      />;
    }

    return <div className={styles.item}>{formatCouncil(user?.mdtProfile?.medicalRegistrationNumberType, user?.mdtProfile?.medicalRegistrationNumber)}</div>
  }

  const displayActionButtons = () => (
    <>
      {user.status === UserStatus.ACTIVE && (
        <Button type="" className={styles.disableButton} onClick={() => handleStatusUpdate(UserStatus.INACTIVE)}>
          Deactivate
        </Button>
      )}
      {user.status === UserStatus.INACTIVE && user?.mdtProfile?.status !== UserProfileStatus.IN_REVIEW && (
        <Button type="" className={styles.enableButton} onClick={() => handleStatusUpdate(UserStatus.ACTIVE)}>
          Activate
        </Button>
      )}
    </>
  );

  return (
    <div className={styles.wrapper}>
      <UserProfileWrapper
        title={
          <div className={styles.header}>
            <IconButton onClick={() => navigate(`/internal/admin/users`)}>
              <ChevronLeftIcon />
            </IconButton>{' '}
            User Profile
          </div>
        }
      >
        <div className={styles.contentWrapper}>
          <UserProfileItem title="Name">
            <div className={styles.profileWrapper}>
              <div className={styles.profile}>
                <Avatar>
                  {user?.firstName && user?.firstName[0]}
                  {user?.lastName && user?.lastName[0]}
                </Avatar>
                <Typography>
                  {' '}
                  {user.firstName} {user.lastName}
                </Typography>
              </div>

              {displayActionButtons()}
            </div>
          </UserProfileItem>

          <UserProfileItem title="Email address">
            <div className={styles.item}>{user.email}</div>
          </UserProfileItem>
          <UserProfileItem title="Phone number">
            <div className={styles.item}>{user.phoneNumber}</div>
          </UserProfileItem>

          <UserProfileItem title="Place of Registration/Medical Practise">
            <div className={styles.item}>
              {user.address
                ? [
                    user?.address?.addressLine2,
                    user?.address?.addressLine1,
                    user?.address?.city,
                    user?.address?.zipCode,
                    user?.address?.country,
                  ]
                    .filter((item) => item)
                    .join(', ')
                : ''}
            </div>
          </UserProfileItem>

          <UserProfileItem
            title="Speciality"
            showActions={true}
            onEdit={() => setIsSpecialityEditting(true)}
            onClose={() => setIsSpecialityEditting(false)}
            onSave={handleSpecialityUpdate}
          >
            <div className={styles.item}>{renderSpeciality()}</div>
          </UserProfileItem>

          <UserProfileItem title="Application status">
            <div className={styles.item}>
              {Array.isArray(user?.mdtProfile?.onboardingApplicationStatus)
                ? user?.mdtProfile?.onboardingApplicationStatus?.map(status => <Chip key={status} size='small' label={OnboardingApplicationStatusMap[status].label} icon={OnboardingApplicationStatusMap[status].icon} color={OnboardingApplicationStatusMap[status].color} />)
                : <Chip key={user?.mdtProfile?.onboardingApplicationStatus} size='small' label={OnboardingApplicationStatusMap[user?.mdtProfile?.onboardingApplicationStatus]?.label} icon={OnboardingApplicationStatusMap[user?.mdtProfile?.onboardingApplicationStatus]?.icon} color={OnboardingApplicationStatusMap[user?.mdtProfile?.onboardingApplicationStatus]?.color} />
              }
            </div>
          </UserProfileItem>

          <UserProfileItem
            title="Medical registration number"
            showActions={true}
            onEdit={() => setIsMedicalRegistrationEditting(true)}
            onClose={() => setIsMedicalRegistrationEditting(false)}
            onSave={handleMedicalRegistrationUpdate}
          >
            <div className={styles.item}>{renderMedicalRegistrationNumber()}</div>
          </UserProfileItem>

          <UserProfileItem title="Latest appraisal date">
            <div className={styles.item}>
              {user?.mdtProfile?.latestAppraisalDate
                ? dayjs(user.mdtProfile?.latestAppraisalDate).format('MMM DD YYYY')
                : 'N/A'}
            </div>
          </UserProfileItem>

          <UserProfileItem title="Created at">
            <div className={styles.item}>{dayjs(user?.createdAt).format('MMM DD YYYY H:mm A') || 'N/A'}</div>
          </UserProfileItem>

          <UserProfileItem
            title="Subscription plan"
            showActions={true}
            onEdit={() => setIsPlanTierEditting(true)}
            onClose={() => setIsPlanTierEditting(false)}
            onSave={handleSubcriptionPlanUpdate}
          >
            <div className={styles.item}>{renderPlanTier()}</div>
          </UserProfileItem>

          <UserProfileItem title="Application status">
            <div className={styles.item}>
              {Array.isArray(user?.mdtProfile?.onboardingApplicationStatus)
                ? user?.mdtProfile?.onboardingApplicationStatus?.map(status => <Chip key={status} size='small' label={OnboardingApplicationStatusMap[status].label} icon={OnboardingApplicationStatusMap[status].icon} color={OnboardingApplicationStatusMap[status].color} />)
                : <Chip key={user?.mdtProfile?.onboardingApplicationStatus} size='small' label={OnboardingApplicationStatusMap[user?.mdtProfile?.onboardingApplicationStatus]?.label} icon={OnboardingApplicationStatusMap[user?.mdtProfile?.onboardingApplicationStatus]?.icon} color={OnboardingApplicationStatusMap[user?.mdtProfile?.onboardingApplicationStatus]?.color} />
              }
            </div>
          </UserProfileItem>

          <UserProfileItem title="Latest onboarding review comment">
            <div className={styles.item}>{user.mdtProfile?.onboardingReviewComment || 'N/A'}</div>
          </UserProfileItem>

          <UserProfileItem title="Uploaded documents" className={styles.documentWrapper}>
            <div className={styles.itemDocument}>{displayUploadedFiles()}</div>
          </UserProfileItem>

          {displayMdtProfileActions()}
        </div>
      </UserProfileWrapper>
    </div>
  );
}
