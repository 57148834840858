import React from 'react';
import { useParams } from 'react-router-dom';

import { useUserDetailsEffects } from '../../../../hooks/effects/useUserDetailsEffects';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectUserDetails } from '../../../../store/slices/userDetails/userDetailsSlice';
import classes from './OrganisationsUserPage.module.scss';
import FullscreenLoader from '../../../../components/feedback/FullscreenLoader/FullscreenLoader';
import ErrorMessage from '../../../../components/feedback/ErrorMessage/ErrorMessage';
import UserDetailsView from '../../../../components/users/UserDetailsView/UserDetailsView';
import { getUserDocument, reviewUserOnboarding } from '../../../../store/slices/userDetails/userDetailsThunks';
import { OnboardingStatus } from '../../../../types/enums/OnboardingStatus';
import ReviewUserProfileForm from '../../../../components/users/ReviewUserProfileForm/ReviewUserProfileForm';
import { OnboardingReviewDTO } from '../../../../types/DTOs/OnboardingReviewDTO';

const OrganisationsUserPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { organisationId } = useParams();

  const { userDetails, isLoading, error } = useAppSelector(selectUserDetails);

  useUserDetailsEffects();

  const handleGetUserDocument = async (url: string) => {
    if (!organisationId) return;

    await dispatch(
      getUserDocument({
        s3Url: url,
        organisationId,
      }),
    ).unwrap();
  };

  const handleReviewOnboarding = async (onboardingReviewDTO: OnboardingReviewDTO) => {
    if (!organisationId) return;

    await dispatch(reviewUserOnboarding({ organisationId, review: onboardingReviewDTO })).unwrap();
  };

  if (isLoading)
    return (
      <div className={classes.loaderContainer}>
        <FullscreenLoader />
      </div>
    );

  if (error || !userDetails)
    return (
      <div className={classes.container}>
        <ErrorMessage>{error || 'User details not found'}</ErrorMessage>
      </div>
    );

  return (
    <div className={classes.container}>
      <UserDetailsView userDetails={userDetails} onDownloadFile={handleGetUserDocument} />

      {userDetails.mdtProfile.status === OnboardingStatus.IN_REVIEW && (
        <ReviewUserProfileForm onSubmit={handleReviewOnboarding} />
      )}
    </div>
  );
};

export default OrganisationsUserPage;
