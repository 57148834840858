import { EditorState } from 'draft-js';
import { MessageType } from '../constants/message.constants';
import { useAppDispatch } from '../store/hooks';
import { addThreadMessage, addThreadMessageAttachments, addThreadReview, editThreadMessage, updateThreadMessageAttachments } from '../store/slices/thread/threadThunks';
import { Thread, ThreadMessage } from '../types/Thread';
import { FormikProps, FormikValues } from 'formik';
import { convertEditorStateToHtml } from '../utils/editor';
import { ThreadReviewStatus } from '../types/enums/ThreadReviewStatus';

export const useMessageSubmission = ({ formik, thread, editMessageId, messagesEndRef, setEditMessageId }: {
  formik: FormikProps<FormikValues>
  thread: Thread
  editMessageId: string;
  messagesEndRef: React.MutableRefObject<HTMLInputElement>;
  setEditMessageId: (value: string|null) => void;
}) => {
  const dispatch = useAppDispatch();

  async function onSubmit(values: ThreadMessage & { text: EditorState }) {
    try {
      if (!values.text.getCurrentContent().hasText() && !values.attachments.length) return;
      const markup = convertEditorStateToHtml(values.text || '');
      await handleMessageSubmit(values, markup);
    } finally {
      resetFormFields();
    }
  }

  async function handleMessageSubmit(values: ThreadMessage, markup: string) {
    if (editMessageId) {
      await handleEditMessage(values, markup);
    } else if (!values.type || values.type === MessageType.DEFAULT) {
      await handleDefaultMessageSubmit(values, markup);
    } else {
      await handleReviewMessageSubmit(values, markup);
    }
  }

  async function handleEditMessage(values: ThreadMessage, markup: string) {
    const { messageId } = await dispatch(
      editThreadMessage({ threadId: thread.id, messageId: editMessageId, text: markup }),
    ).unwrap();

    await dispatch(updateThreadMessageAttachments({ messageId, threadId: thread.id, attachments: values.attachments! }));
    setEditMessageId(null);
  }
  
  async function handleDefaultMessageSubmit(values: ThreadMessage, markup: string) {
    const { message } = await dispatch(
      addThreadMessage({ threadId: thread.id, text: markup }),
    ).unwrap();
  
    await dispatch(addThreadMessageAttachments({ messageId: message.id, threadId: thread.id, attachments: values.attachments }));
    scrollToMessageEnd();
  }

  async function handleReviewMessageSubmit(values: ThreadMessage, markup: string) {
    const { message } = await dispatch(
      addThreadReview({ threadId: thread.id, text: markup, reviewStatus: values.type as ThreadReviewStatus }),
    ).unwrap();

    await dispatch(addThreadMessageAttachments({ messageId: message.id, threadId: thread.id, attachments: values.attachments }));
    scrollToMessageEnd();
  }

  function scrollToMessageEnd() {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  function resetFormFields() {
    formik.setFieldValue('text', EditorState.createEmpty());
    formik.setFieldValue('attachments', []);
  }

  return {
    onSubmit,
  };
};
