import React, { createContext, useEffect } from 'react';
import UserService from '../services/api/UserService';
import AuthService from '../services/api/AuthService';
import { UserScope, UserStatus } from '../constants/user.constants';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getProfile } from '../store/slices/profile/profileThunks';
import { selectProfile } from '../store/slices/profile/profileSlice';

export const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  const dispach = useAppDispatch();

  const { profile } = useAppSelector(selectProfile);
  const user = profile;

  useEffect(() => {
    const accessToken = AuthService.getLocalAccessToken();

    if (accessToken) {
      dispach(getProfile());
    }
  }, []);

  const getScopes = () => {
    if (user?.scopes) return user?.scopes;

    const localUser = UserService.getLocalUser();
    return localUser?.scopes || [];
  };

  const isAuthenticated = () => {
    return !!profile || !!AuthService.getLocalAccessToken();
  };

  const isAdmin = () => {
    const scopes = getScopes();

    return scopes.includes(UserScope.ADMIN);
  };

  const isBasic = () => {
    const scopes = getScopes();

    return scopes.includes(UserScope.BASIC);
  };

  const isBasicRestricted = (strictly = false) => {
    const scopes = getScopes();

    if (strictly) return scopes.length === 1 && scopes.includes(UserScope.BASIC_RESTRICTED);

    return scopes.includes(UserScope.BASIC_RESTRICTED);
  };

  const isActive = () => {
    const status = user?.status || UserService.getLocalUser()?.status;

    return status === UserStatus.ACTIVE || isAdmin();
  };

  const isAccountOnboarding = user && !user.scopes.length;
  const isMdtOnboarding = user && !isBasic();
  const isFullyOnboarded = user && isBasic()

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, isAdmin, isBasic, isBasicRestricted, isActive, isAccountOnboarding, isMdtOnboarding, isFullyOnboarded }}>
      {children}
    </AuthContext.Provider>
  );
};
