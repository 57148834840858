import React, { useState } from 'react';

import { ThreadStatus } from '../../../../constants/thread.constants';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterSelect from '../../../../components/filter-select/FilterSelect';

export default function MdtThreadStatusFilter() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [status, setStatus] = useState(queryParams.get('status') || '');

  const handleChange = async (event) => {
    const newStatus = event.target.value || '';
    setStatus(newStatus);
    if (newStatus) {
      queryParams.set('status', newStatus);
    } else {
      queryParams.delete('status');
    }

    navigate({ search: queryParams.toString() });
  };

  return (
    <div>
      <FilterSelect
        onChange={handleChange}
        label="Status"
        name="status"
        value={status}
        options={[
          { name: ThreadStatus.OPEN, label: 'Open' },
          { name: ThreadStatus.APPROVED, label: 'Approved' },
          { name: ThreadStatus.CHANGES_REQUESTED, label: 'Changes Requested' },
          { name: ThreadStatus.REJECTED, label: 'Rejected' },
        ]}
      />
    </div>
  );
}
