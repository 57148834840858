import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextInputField from '../../../../../components/text-input-field/TextInputField';
import ModalTitle from '../../../../../components/modal-title/ModalTitle';
import ModalButton from '../../../../../components/modal-button/ModalButton';
import Modal from '../../../../../components/modal/Modal';
import styles from './AdminMembershipPlanCreateModal.module.scss';
import SelectInputField from '../../../../../components/select-input-field/SelectInputField';
import MenuItem from '@mui/material/MenuItem';

import { PlanRecurringInterval } from '../../../../../types/enums/PlanRecurringInterval';
import { createPlan } from '../../../../../store/slices/membershipPlan/membershipPlanThunks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectPlan } from '../../../../../store/slices/membershipPlan/membershipPlanSlice';

const validationSchema = yup.object({
  name: yup.string().min(2).max(100, 'Max plan name 10 characters').required('Name field is required'),
  description: yup.string().max(1000, 'Max plan description 1000 characters').required('Description field is required'),
  amount: yup.number().min(0, 'Amount must be greater than or equal to 1').max(1000, 'amount must be less than or equal to 1000').required('Amount field is required'),
});

const AdminMembershipPlanCreateModal: React.FC<{ onClose: () => void, open: boolean }> = ({ onClose, open }) => {
  const dispatch = useAppDispatch();
  const plan = useAppSelector(selectPlan)


  const formik = useFormik({
    initialValues: { name: '', description: '', amount: 0, currency: 'gbp', recurringInterval: PlanRecurringInterval.MONTH },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(createPlan({
        name: values.name,
        description: values.description,
        currency: values.currency,
        recurringInterval: values.recurringInterval,
        amount: values.amount * 100,
      }));

      onClose()
    },
  });

  return (
    <div>
      <Modal open={open} className={styles.createModal} onClose={() => {
        onClose();
        formik.resetForm();
      }}>
        <form onSubmit={formik.handleSubmit}>
          <ModalTitle>Create Membership Plan</ModalTitle>
          <TextInputField
            label="Name"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            errorHelperText={formik.errors.name}
          />

          <TextInputField
            label="Description"
            type="description"
            id="description"
            name="description"
            multiline
            rows={4}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.description && Boolean(formik.errors.description)}
            errorHelperText={formik.touched.description && formik.errors.description}
            helperText="Use a comma to create a new list item"
          />


          <TextInputField
            label="Amount"
            type="amount"
            id="amount"
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            errorHelperText={formik.touched.amount && formik.errors.amount}
          />
          <SelectInputField
            name="currency"
            label="Currency"
            value={formik.values.currency}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            error={formik.touched.currency && formik.errors.currency}
          >
            <MenuItem value="gbp">GBP</MenuItem>

          </SelectInputField>
          <SelectInputField
            name="recurringInterval"
            label="Interval"
            value={formik.values.recurringInterval}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            error={formik.touched.recurringInterval && formik.errors.recurringInterval}
          >
            <MenuItem value={PlanRecurringInterval.MONTH}>Month</MenuItem>
            <MenuItem value={PlanRecurringInterval.YEAR}>Year</MenuItem>
          </SelectInputField>

          <ModalButton loading={plan.loading} type="submit">
            Create
          </ModalButton>
        </form>
      </Modal>
    </div>
  );
}

export default AdminMembershipPlanCreateModal;
