import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { sanitize } from 'dompurify';

import styles from './ThreadMessage.module.scss';
import ThreadMessageMetadata from './ThreadMessageMetadata';
import { convertHtmlToEditorState } from '../../../../../utils/editor';
import { hasPermission } from '../../../../../utils/permissions';
import { useAuthContext } from '../../../../../hooks/useAuthContext';
import { MdtPermissions } from '../../../../../constants/user.constants';
import { isOlderThan } from '../../../../../utils/date';
import Skeleton from '@mui/material/Skeleton';

import { MessageType } from '../../../../../constants/message.constants';
import Chip from '../../../../../components/chips/Chip/Chip';
import { ThreadStatusMap } from '../../../../../constants/thread.constants';
import ConfirmDialog from '../../../../../components/confirm-dialog/ConfirmDialog';
import classNames from 'classnames';
import { useAppDispatch } from '../../../../../store/hooks';
import { deleteThreadMessage } from '../../../../../store/slices/thread/threadThunks';
import MessageAttachedFile from './components/MessageAttachedFile';

export default function ThreadMessage({ message, formik, setEditMessageId, editPeriod, permissions }) {
  const dispatch = useAppDispatch();
  const { user } = useAuthContext();

  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

  if (message === null) {
    return (
      <div className={styles.threadMessageWrapper}>
        <ThreadMessageMetadata type="message" />
        <div className={styles.message}>
          <Typography>{new Array(5).fill(<Skeleton variant="rounded" sx={{ mb: '4px' }} height={12} />)}</Typography>
        </div>
        <div className={styles.messageButtons}></div>
      </div>
    );
  }

  const onEdit = async (message) => {
    formik.setFieldValue('text', convertHtmlToEditorState(message.text));
    formik.setFieldValue('attachments', message.attachments);
    setEditMessageId(message.id);
  };

  const onDelete = async () => {
    setDeleteConfirmDialog(true);
  };

  const onDeleteConfirm = async () => {
    try {
      setDeleteConfirmDialog(false);
      await dispatch(
        deleteThreadMessage({
          threadId: message.threadId,
          messageId: message.id,
        }),
      );
    } catch {}
  };

  const hasOwnerPermission = (message) => {
    return message.user.id === user.id && !isOlderThan(new Date(message.createdAt), editPeriod);
  };

  const isReviewMessage = (message) => message.type === MessageType.REVIEW;

  const displayAttachedFiles = () => {
    return (
      <div className={styles.attachedFiles}>
        {message?.attachments?.map((attachment) => (
          <MessageAttachedFile attachment={attachment} key={attachment.id} messageId={message.id} />
        ))}
      </div>
    );
  };

  if (isReviewMessage(message)) {
    return (
      <div
        className={classNames(styles.threadMessageWrapper, {
          [styles.threadMessageHighlightedWrapper]: message.user.id === user.id,
        })}
      >
        <div className={styles.messageHeader}>
          <ThreadMessageMetadata
            user={message.user}
            createdAt={message.createdAt}
            updatedAt={message.updatedAt}
            type="message"
          />

          <Chip
            size="small"
            variant="filled"
            label={ThreadStatusMap[message.reviewStatus].label}
            color={ThreadStatusMap[message.reviewStatus].color}
          />
        </div>

        <div className={styles.message}>
          <Typography dangerouslySetInnerHTML={{ __html: sanitize(message.text) }}></Typography>
        </div>

        {displayAttachedFiles()}
      </div>
    );
  }

  return (
    <div
      key={message.id}
      className={classNames(styles.threadMessageWrapper, {
        [styles.threadMessageHighlightedWrapper]: message.user.id === user.id,
      })}
    >
      <ThreadMessageMetadata
        user={message.user}
        createdAt={message.createdAt}
        updatedAt={message.updatedAt}
        type="message"
      />
      <div className={styles.message}>
        <Typography dangerouslySetInnerHTML={{ __html: sanitize(message.text) }}></Typography>
      </div>

      {displayAttachedFiles()}

      <div className={styles.messageButtons}>
        {hasOwnerPermission(message) && (
          <Button size="small" onClick={() => onEdit(message)}>
            Edit
          </Button>
        )}
        {(hasPermission(permissions, MdtPermissions.DELETE_MESSAGE) || hasOwnerPermission(message)) && (
          <Button size="small" onClick={onDelete}>
            Remove
          </Button>
        )}
      </div>

      {deleteConfirmDialog && (
        <ConfirmDialog
          open={deleteConfirmDialog}
          text="Are you sure you want to remove the message?"
          onClose={() => setDeleteConfirmDialog(false)}
          onConfirm={onDeleteConfirm}
        />
      )}
    </div>
  );
}
