export const GroupType = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  MDT: 'MDT',
};

export const GROUP_TYPE_NAV_ITEMS = [
  {
    label: 'Public',
    value: GroupType.PUBLIC,
    to: '/internal/groups/public',
  },
  {
    label: 'Private',
    value: GroupType.PRIVATE,
    to: '/internal/groups/private',
  },
  {
    label: 'MDT',
    value: GroupType.MDT,
    to: '/internal/groups/mdt',
  },
];
