import { useAppDispatch } from '../store/hooks';
import { showFeedback } from '../store/slices/feedback/feedbackSlice';
import { Feedback } from '../types/Feedback';
import { FeedbackStatus } from '../types/enums/FeedbackStatus';

export const useFeedback = () => {
  const dispatch = useAppDispatch();

  const showErrorFeedback = (message: string, options?: Feedback['options']) =>
    dispatch(
      showFeedback({
        status: FeedbackStatus.ERROR,
        message,
        options,
      }),
    );

  const showSuccessFeedback = (message: string, options?: Feedback['options']) =>
    dispatch(
      showFeedback({
        status: FeedbackStatus.SUCCESS,
        message,
        options,
      }),
    );

  const showWarningFeedback = (message: string, options?: Feedback['options']) =>
    dispatch(
      showFeedback({
        status: FeedbackStatus.WARNING,
        message,
        options,
      }),
    );

  const showInfoFeedback = (message: string, options?: Feedback['options']) =>
    dispatch(
      showFeedback({
        status: FeedbackStatus.INFO,
        message,
        options,
      }),
    );

  return {
    showErrorFeedback,
    showSuccessFeedback,
    showWarningFeedback,
    showInfoFeedback,
  };
};
