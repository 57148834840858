import React from 'react';
import Button from '@mui/material/Button';
import { Paper, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './HomeWithAppPreview.module.scss';

interface Props {
  id?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
  image?: React.ReactNode;
}

const Home: React.FC<Props> = ({ id = 'home', title, description, className, image }) => {
  return (
    <section id={id} className={classNames(styles.section, className)}>
      <div className={styles.info}>
        <Typography className={styles.infoTitle}>{title}</Typography>

        <div className={styles.descriptionContainer}>
          <Typography className={styles.description}>{description}</Typography>
        </div>

        <Button variant="contained" to="/book-demo" size="large" className={styles.joinButton} component={NavLink}>
          Join Us
        </Button>
      </div>

      <Paper className={styles.imageContainer} elevation={5}>
        {image || (
          <>
            <img src="/assets/app-screen-shot.webp" alt="App screen shot" className={styles.img} />
            <img src="/assets/app-screen-shot-mobile.webp" alt="App screen shot" className={styles.imgMobile} />
          </>
        )}
      </Paper>

      <Button
        variant="contained"
        to="/book-demo"
        size="large"
        className={classNames(styles.joinButton, styles.joinButtonMobile)}
        component={NavLink}
      >
        Join Us
      </Button>
    </section>
  );
};

export default Home;
