import { useEffect } from 'react';

export const useBackButtonOverride = ({ onBack, id = 'over', shouldOverride }) => {
  useEffect(() => {
    if (shouldOverride) {
      window.history.pushState('forward', '', `#${id}`);
      window.addEventListener('popstate', onBack);

      return () => {
        window.removeEventListener('popstate', onBack);
      };
    } else {
      if (window.location.hash.includes(id)) {
        window.history.replaceState('', document.title, window.location.pathname);
      }
    }
  }, [shouldOverride]);
};
