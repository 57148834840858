import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, CircularProgress, IconButton, Skeleton, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectUserDetails } from '../../../store/slices/userDetails/userDetailsSlice';
import classes from './OrganisationUserHeader.module.scss';
import { removeUserFromOrganisation } from '../../../store/slices/userDetails/userDetailsThunks';
import { UserStatus } from '../../../types/enums/UserStatus';

const OrganisationUserHeader: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { organisationId, userId } = useParams();
  const dispatch = useAppDispatch();

  const { isLoading, userDetails } = useAppSelector(selectUserDetails);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleGoBack = () => navigate(-1);

  const handleRemoveUserFromOrganisation = async () => {
    try {
      if (!organisationId || !userId) return;

      setIsDeleting(true);

      await dispatch(removeUserFromOrganisation({ organisationId, userId })).unwrap();

      setIsDeleting(false);

      navigate(pathname.replace(`/${userId}`, ''), { replace: true });
    } catch (error) {
      setIsDeleting(false);
    }
  };

  return (
    <header className={classes.container}>
      <IconButton onClick={handleGoBack}>
        <ChevronLeftIcon />
      </IconButton>

      {isLoading ? (
        <>
          <Skeleton variant="rectangular" width={180} height={36} />

          <div className={classes.controls}>
            <Skeleton variant="rectangular" width={140} height={24} />
          </div>
        </>
      ) : (
        <>
          <Typography variant="h6">
            {userDetails?.firstName} {userDetails?.lastName}
          </Typography>

          <div className={classes.controls}>
            {userDetails?.status !== UserStatus.PENDING && (
              <Button color="error" size="small" disabled={isDeleting} onClick={handleRemoveUserFromOrganisation}>
                {isDeleting ? <CircularProgress size={24} /> : 'Exclude from organisation'}
              </Button>
            )}
          </div>
        </>
      )}
    </header>
  );
};

export default OrganisationUserHeader;
