import { useEffect, useState } from 'react';

export const useEffectAfterInitialLoad = (
  onAfterInitialLoad: () => void | (() => void),
  dependencies: React.DependencyList,
  onInitialLoad?: () => void,
) => {
  const [isInitialyLoaded, setIsInitialyLoaded] = useState(false);

  useEffect(() => {
    onInitialLoad?.();
    setIsInitialyLoaded(true);
  }, []);

  useEffect(() => {
    if (isInitialyLoaded) {
      onAfterInitialLoad();
    }
  }, [...dependencies, isInitialyLoaded]);

  return isInitialyLoaded;
};
