import Menu from '@mui/material/Menu';
import styles from './SidebarMenu.module.scss';

export default function SidebarMenu({ menuAnchorEl, setMenuAnchorEl, children }) {
  return (
    <Menu
      className={styles.menu}
      id="basic-menu"
      anchorEl={menuAnchorEl}
      open={!!menuAnchorEl}
      onClose={() => setMenuAnchorEl(null)}
      classes={{ paper: styles.MuiDrawerPaper }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </Menu>
  );
}
