import React from 'react';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';

import MdtOnboardingStepWrapper from './components/MdtOnboardingStepWrapper';
import MdtOnboardingDocumentUploadField, { onboardingDocumentValidation, uploadOnboardingDocument } from './components/MdtOnboardingDocumentUploadField';
import MdtOnboardingHelperText from './components/MdtOnboardingHelperText';

const validationSchema = yup.object().shape({
  document: onboardingDocumentValidation
});

export default function CriminalBackgroundCheckStep({ handleBack, profile, handleNext, setProfile }) {
  const formik = useFormik({
    initialValues: { ...profile.criminalBackground },
    validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      await uploadOnboardingDocument(formik.values.document, 'criminalBackground');

      setProfile({ ...profile, criminalBackground: values });

      handleNext();
    },
  });

  return (
    <MdtOnboardingStepWrapper
      name="Verify Criminal Background"
      formik={formik}
      onSubmit={formik.handleSubmit}
      handleBack={handleBack}
    >
      <Grid item xs={12} sm={12}>
        <MdtOnboardingDocumentUploadField
          description="Please upload criminal record check or police clearance certificate"
          buttonLabel="Attach Document"
          name="document"
          onBlur={formik.handleBlur}
          uploaded={profile.criminalBackground.document}
          error={formik.touched.document && Boolean(formik.errors.document)}
          errorHelperText={formik.errors.document}
          setFieldValue={formik.setFieldValue}
          disabled={formik.isSubmitting}
        />
      </Grid>

      <MdtOnboardingHelperText />
    </MdtOnboardingStepWrapper>
  );
}
