import { MultifactorAuthDTO } from '../../types/DTOs/MultifactorAuthDTO';
import { SignInDTO } from '../../types/DTOs/SignInDTO';
import { SingUpDTO } from '../../types/DTOs/SignUpDTO';
import { MultifactorAuthSettings } from '../../types/MultifactorAuth';
import ApiClient from './ApiClient';
import { ApiRoute } from './ApiRoute';
import { getResponseData } from './adapterUtils';

class AuthService {
  async signUp(payload: SingUpDTO): Promise<void> {
    await ApiClient.post('/auth/sign-up', {
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      password: payload.password,
      phoneNumber: payload.phoneNumber,
      speciality: payload.speciality,
      country: payload.country,
    });
  }

  async signIn(signInDTO: SignInDTO) {
    const response = await ApiClient.post<
      | {
          accessToken: string;
          idToken: string;
        }
      | {
          session: string;
        }
    >('/auth/token', signInDTO);

    getResponseData(response);

    return getResponseData(response);
  }

  async signOut(accessToken: string) {
    await ApiClient.post('/auth/logout', {
      accessToken,
    });
  }

  async passwordReset(payload: { email: string }) {
    const { status, data } = await ApiClient.post('/auth/forgot-password', {
      email: payload.email,
    });

    return { body: data, statusCode: status };
  }

  async confirmPasswordReset(payload: { email: string; password: string; code: string }) {
    const { status, data } = await ApiClient.post('/auth/confirm-forgot-password', {
      email: payload.email,
      password: payload.password,
      code: payload.code,
    });

    return { body: data, statusCode: status };
  }

  async updateTokensRequest() {
    const { status, data } = await ApiClient.post('/auth/token/refresh');

    return { body: data, statusCode: status };
  }

  async changePassword(payload: { oldPassword: string; newPassword: string }) {
    const { status, data } = await ApiClient.post('/auth/change-password', {
      oldPassword: payload.oldPassword,
      newPassword: payload.newPassword,
    });

    return { body: data, statusCode: status };
  }

  async getMultifactorAuthSettings() {
    const response = await ApiClient.get<MultifactorAuthSettings>(ApiRoute.MFA_SETTINGS);

    return getResponseData(response);
  }

  async enableMultifactorAuth() {
    const response = await ApiClient.post<{
      secret: string;
    }>(ApiRoute.MFA_ASSOCIATE);

    return getResponseData(response).secret;
  }

  async verifyMultifactorAuth(code: string) {
    await ApiClient.post(`${ApiRoute.MFA_ASSOCIATE}/verify`, {
      code,
    });
  }

  async disableMultifactorAuth() {
    await ApiClient.post(ApiRoute.MFA_DISASSOCIATE);
  }

  async confirmMultifactorAuth(multifactorAuthDTO: MultifactorAuthDTO) {
    const response = await ApiClient.post<{
      accessToken: string;
      idToken: string;
    }>(ApiRoute.MFA, multifactorAuthDTO);

    return getResponseData(response);
  }

  setLocalTokens({ accessToken, idToken }: { accessToken: string; idToken: string }) {
    localStorage.setItem('tokens', JSON.stringify({ accessToken, idToken }));
  }

  getLocalAccessToken() {
    const tokens = JSON.parse(localStorage.getItem('tokens') || '{}');

    return tokens?.accessToken;
  }

  removeLocalTokens() {
    localStorage.removeItem('tokens');
  }

  async subscribeToNotifications(token: string) {
    await ApiClient.post('/notifications/subscribe', {
      token,
    });
  }
}

export default new AuthService();
