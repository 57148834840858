import { Typography } from '@mui/material';

import styles from './Pricing.module.scss';
import SubscriptionPurchase from '../../../private/profile/components/SubscriptionsManagement/components/SubscriptionPurchase';


export default function Pricing({ id }) {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.title}>
        <Typography component="h1" variant="h4">
          Pricing
        </Typography>

        <div className={styles.description}>
          Join us now to get a 3-month free trial access
        </div>
      </div>
      <div className={styles.sectionItem}>
        <SubscriptionPurchase isPublic={true} />
      </div>
    </section>
  );
}
