import { createContext, useState } from 'react';

export const LoadingContext = createContext({ loading: false, setLoading: null });

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const loadingQueue = [];

  const startLoading = () => {
    if (!loadingQueue.length) {
      setLoading(true);
    }

    loadingQueue.push(true);
  };

  const stopLoading = () => {
    loadingQueue.pop();

    if (!loadingQueue.length) {
      setLoading(false);
    }
  };

  const isLoading = () => loading;

  return (
    <LoadingContext.Provider value={{ loading, startLoading, stopLoading, isLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}
