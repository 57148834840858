import ReactPDF from '@react-pdf/renderer';

export const PdfInterFamilyFont = {
  family: 'Inter',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf' },
    {
      src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZg.ttf',
      fontWeight: 100,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZg.ttf',
      fontWeight: 200,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZg.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZg.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZg.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZg.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZg.ttf',
      fontWeight: 800,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZg.ttf',
      fontWeight: 900,
    },
    { src: 'https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xP.ttf', fontWeight: 400, fontStyle: 'italic' },
  ],
};

const baseTextStyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
};

export const PdfBasicStyles: ReactPDF.Styles = {
  page: {
    ...baseTextStyle,
    backgroundColor: '#fff',
    padding: '12px 24px',
    fontSize: 12,
  },
  logo: {
    width: '180px',
    height: '47px',
    alignSelf: 'center',
  },
  h2: {
    ...baseTextStyle,
    fontWeight: 700,
    fontSize: '16px',
    margin: '6px 0px',
  },
  section: {
    margin: '12px 0px',
  },
  italic: {
    ...baseTextStyle,
    fontStyle: 'italic',
  },
  light: {
    ...baseTextStyle,
    fontWeight: 300,
  },
  bold: {
    ...baseTextStyle,
    fontWeight: 600,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
};
