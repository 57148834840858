export const displayCents = (cents: number): string => {
  const amount = cents / 100;
  const formatter = new Intl.NumberFormat('en-US', {
  });
  return formatter.format(amount);
}

export const displayCentsWithCurrency = (cents: number, currency: string = 'GBP'): string => {
  const amount = cents / 100;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });
  return formatter.format(amount);
}
