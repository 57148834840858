import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import classNames from 'classnames';

import classes from './Select.module.scss';

type Props<T extends string | number> = SelectProps<T> & {
  options: { value: T; label: string }[];
  label?: string;
  fullWidth?: boolean;
  helperText?: string;
  error?: string | boolean | undefined;
  className?: string;
  size?: 'small' | 'medium';
  showHelperText?: boolean;
};

const Select = <T extends string | number>({
  label,
  fullWidth,
  helperText,
  options,
  className,
  size,
  showHelperText = true,
  ...props
}: Props<T>) => {
  return (
    <FormControl
      fullWidth={fullWidth}
      error={props.error}
      className={classNames(classes.container, className)}
      size={size}
    >
      {label && <InputLabel id={props.id}>{label}</InputLabel>}

      <MuiSelect fullWidth={fullWidth} label={label} {...props}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>

      {showHelperText && <FormHelperText className={classes.helperText}>{props.error || helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
