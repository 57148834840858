import { createBrowserRouter, redirect } from 'react-router-dom';
import { RouterProvider } from 'react-router-dom';

import ProtectedLayout from '../layouts/ProtectedLayout';
import PublicLayout from '../layouts/PublicLayout';
import ProfilePage from '../pages/private/profile/ProfilePage';
import SignInPage from '../pages/public/sign-in/SignInPage';
import SignUpPage from '../pages/public/sign-up/SignUpPage';
import PasswordResetPage from '../pages/public/password-reset/PasswordResetPage';
import PrivacyPolicyPage from '../pages/common/privacy-policy/PrivacyPolicyPage';
import TermsOfUsePage from '../pages/common/terms-of-use/TermsOfUsePage';

import ROUTER from './Router.constants';
import MdtOnboardingPage from '../pages/private/onboarding/MdtOnboardingPage';
import DashboardPage from '../pages/private/dashboard/DashboardPage';
import ProtectedGroupsLayout from '../layouts/protected/ProtectedGroupsLayout';
import PublicGroups from '../pages/private/groups/public/PublicGroups';
import PublicThread from '../pages/private/groups/public/PublicThread';
import PrivateGroups from '../pages/private/groups/private/PrivateGroups';
import PrivateThread from '../pages/private/groups/private/PrivateThread';
import MdtGroups from '../pages/private/groups/mdt/MdtGroups';
import MdtThread from '../pages/private/groups/mdt/MdtThread';
import AdminUsersPage from '../pages/private/admin/AdminUsersPage';
import AdminPendingApplicationsPage from '../pages/private/admin/AdminPendingApplicationsPage';
import AdminUserPage from '../pages/private/admin/AdminUserPage';
import ProtectedAdminLayout from '../layouts/protected/ProtectedAdminLayout';
import UsersPage from '../pages/private/users/UsersPage';
import UserPage from '../pages/private/users/UserPage';
import BookDemoPage from '../pages/public/book-demo/BookDemoPage';
import AdminDemosPage from '../pages/private/admin/AdminDemosPage';
import AdminDemoPage from '../pages/private/admin/AdminDemoPage';
import AdminContactUsRequestsPage from '../pages/private/admin/AdminContactUsRequestsPage';
import AdminContactUsRequestPage from '../pages/private/admin/AdminContactUsRequestPage';
import ProtectedPrintLayout from '../layouts/protected/ProtectedPrintLayout';
import MdtApprovedThreadPrintPage from '../pages/private/print/MdtApprovedThreadPrintPage';
import ThreadAuthenticityValidationPage from '../pages/common/thread-authenticity-validation/ThreadAuthenticityValidationPage';
import LandingPage from '../pages/public/landing/LandingPage';
import MedicalCPage from '../pages/public/medical-c/MedicalCPage';
import OnboardingLayout from '../layouts/OnboardingLayout/OnboardingLayout';
import HRTPage from '../pages/public/hrt/HRTPage';
import RegenerativeMedicinePage from '../pages/public/regenerative-medicine/RegenerativeMedicinePage';
import PharmacyPage from '../pages/public/pharmacy/PharmacyPage';
import PsychedelicsPage from '../pages/public/psychedelics/PsychedelicsPage';
import OrganisationsLayout from '../layouts/protected/OrganisationsLayout/OrganisationsLayout';
import NotFoundPage from '../pages/common/404/404';
import OrganisationsUsersPage from '../pages/private/organisations/OrganisationsUsersPage/OrganisationsUsersPage';
import OrganisationsUserPage from '../pages/private/organisations/OrganisationsUserPage/OrganisationsUserPage';
import OrganisationsPendingApplicationsPage from '../pages/private/organisations/OrganisationsPendingApplicationsPage/OrganisationsPendingApplicationsPage';
import { withPlatformSpecific } from '../components/HOCs/withPlatformSpecific/withPlatformSpecific';
import AccountOnboardingPage from '../pages/private/onboarding/AccountOnboardingPage';
import { OnboardingApplicationStatus } from '../types/enums/OnboardingApplicationStatus';
import AdminMembershipLayout from '../pages/private/admin/AdminMembership/AdminMembershipLayout';
import AdminMembershipPlansPage from '../pages/private/admin/AdminMembership/AdminMembershipPlansPage';
import AdminMembershipPlanPage from '../pages/private/admin/AdminMembership/AdminMembershipPlanPage';
import PaymentSuccessPage from '../pages/private/payment/PaymentSuccessPage';
import PaymentFailurePage from '../pages/private/payment/PaymentFailurePage';

const routes = createBrowserRouter([
  {
    path: ROUTER.PUBLIC.INDEX.PATH,
    element: <PublicLayout />,
    errorElement: <PublicLayout />,
    id: ROUTER.PUBLIC.INDEX.ID,
    children: [
      { index: true, element: <LandingPage /> },
      { path: ROUTER.PUBLIC.SIGN_IN.PATH, element: <SignInPage /> },
      { path: ROUTER.PUBLIC.SIGN_UP.PATH, element: <SignUpPage /> },
      { path: ROUTER.PUBLIC.PASSWORD_RESET.PATH, element: <PasswordResetPage /> },
      { path: ROUTER.PUBLIC.PRIVACY_POLICY.PATH, element: <PrivacyPolicyPage /> },
      { path: ROUTER.PUBLIC.TERMS_OF_USE.PATH, element: <TermsOfUsePage /> },
      { path: 'book-demo', element: <BookDemoPage /> },
      { path: 'thread-authenticity-validation', element: <ThreadAuthenticityValidationPage /> },
      { path: 'medical-c', element: <MedicalCPage /> },
      { path: 'hrt', element: <HRTPage /> },
      { path: 'regenerative-medicine', element: <RegenerativeMedicinePage /> },
      { path: 'pharmacy', element: <PharmacyPage /> },
      { path: 'psychedelics', element: <PsychedelicsPage /> },
      { path: '*', element: <LandingPage /> },
    ],
  },
  {
    path: ROUTER.PROTECTED.INDEX.PATH,
    element: <ProtectedLayout />,
    errorElement: <ProtectedLayout />,
    id: ROUTER.PROTECTED.INDEX.ID,
    children: [
      {
        index: true,
        loader: async () => redirect(ROUTER.PROTECTED.HOME.PATH),
      },
      { path: ROUTER.PROTECTED.PROFILE.PATH, element: <ProfilePage /> },
      { path: ROUTER.PROTECTED.PRIVACY_POLICY.PATH, element: <PrivacyPolicyPage /> },
      { path: ROUTER.PROTECTED.TERMS_OF_USE.PATH, element: <TermsOfUsePage /> },
      {
        path: 'onboarding',
        element: <OnboardingLayout />,
        children: [
          {
            path: 'mdt-application',
            element: <MdtOnboardingPage />,
          },
          {
            index: true,
            element: <AccountOnboardingPage />,
          },
        ],
      },
      { path: 'home', element: <DashboardPage /> },
      {
        path: 'groups',
        element: <ProtectedGroupsLayout />,
        children: [
          { path: 'private', element: <PrivateGroups />, children: [{ path: ':groupId' }] },
          { path: 'public', element: <PublicGroups />, children: [{ path: ':groupId' }] },
          { path: 'mdt', element: <MdtGroups />, children: [{ path: ':groupId' }] },
          { path: 'public/:groupId/threads/:threadId', element: <PublicThread /> },
          { path: 'private/:groupId/threads/:threadId', element: <PrivateThread /> },
          { path: 'mdt/:groupId/threads/:threadId', element: <MdtThread /> },
          {
            index: true,
            loader: async () => redirect('public'),
          },
        ],
      },
      {
        path: 'users',
        children: [
          { index: true, element: <UsersPage /> },
          {
            path: ':userId',
            element: <UserPage />,
          },
        ],
      },
      {
        path: 'admin',
        element: <ProtectedAdminLayout />,
        children: [
          {
            path: 'users/:userId',
            element: <AdminUserPage />,
          },
          {
            path: 'users',
            element: <AdminUsersPage />,
            children: [{ element: <AdminUserPage />, path: ':userId' }],
          },
          { path: 'pending-verification-applications', element: <AdminPendingApplicationsPage onboardingApplicationStatus={OnboardingApplicationStatus.RESTRICTED_STANDARD_PENDING} /> },
          { path: 'pending-mdt-applications', element: <AdminPendingApplicationsPage onboardingApplicationStatus={OnboardingApplicationStatus.MDT_STANDARD_PENDING} /> },
          {
            path: 'demos/:demoId',
            element: <AdminDemoPage />,
          },
          {
            path: 'demos',
            element: <AdminDemosPage />,
            children: [{ element: <AdminDemoPage />, path: ':demoId' }],
          },

          {
            path: 'contact-us-requests/:contactUsRequestId',
            element: <AdminContactUsRequestPage />,
          },
          {
            path: 'contact-us-requests',
            element: <AdminContactUsRequestsPage />,
            children: [{ element: <AdminContactUsRequestPage />, path: ':contactUsRequestId' }],
          },
          {
            path: 'membership',
            element: <AdminMembershipLayout />,
            children: [
              {
                element: <AdminMembershipPlansPage />,
                path: 'plans',
              }
            ],
          },
          {
            path: 'membership/plans/:planId',
            element: <AdminMembershipPlanPage />,
          },
        ],
      },
      {
        path: 'organisations/:organisationId',
        element: <OrganisationsLayout />,
        children: [
          {
            path: 'users',
            element: <OrganisationsUsersPage />,
          },
          { element: <OrganisationsUserPage />, path: 'users/:userId' },
          { path: 'pending-applications', element: <OrganisationsPendingApplicationsPage /> },
        ],
      },
      { path: ROUTER.PROTECTED.PROFILE.PATH, element: <ProfilePage /> },
      { path: 'payment-success', element: <PaymentSuccessPage /> },
      { path: 'payment-failure', element: <PaymentFailurePage /> },
      { path: '404', element: <NotFoundPage /> },
      // TODO: catch everything else with 404 page
      { path: '*', element: <ProfilePage /> },
    ],
  },
  {
    path: 'print',
    element: <ProtectedPrintLayout />,
    errorElement: <ProtectedPrintLayout />,
    children: [{ path: 'groups/mdt/:groupId/threads/:threadId', element: <MdtApprovedThreadPrintPage /> }],
  },
]);

const nativeRoutes = createBrowserRouter([
  {
    path: ROUTER.PUBLIC.INDEX.PATH,
    element: <PublicLayout />,
    errorElement: <PublicLayout />,
    id: ROUTER.PUBLIC.INDEX.ID,
    children: [
      { index: true, element: <SignInPage /> },
      { path: ROUTER.PUBLIC.SIGN_IN.PATH, element: <SignInPage /> },
      { path: ROUTER.PUBLIC.SIGN_UP.PATH, element: <SignUpPage /> },
      { path: ROUTER.PUBLIC.PASSWORD_RESET.PATH, element: <PasswordResetPage /> },
      { path: ROUTER.PUBLIC.PRIVACY_POLICY.PATH, element: <PrivacyPolicyPage /> },
      { path: ROUTER.PUBLIC.TERMS_OF_USE.PATH, element: <TermsOfUsePage /> },
      { path: 'thread-authenticity-validation', element: <ThreadAuthenticityValidationPage /> },
      { path: '*', element: <SignInPage /> },
    ],
  },
  {
    path: ROUTER.PROTECTED.INDEX.PATH,
    element: <ProtectedLayout />,
    errorElement: <ProtectedLayout />,
    id: ROUTER.PROTECTED.INDEX.ID,
    children: [
      {
        index: true,
        loader: async () => redirect(ROUTER.PROTECTED.HOME.PATH),
      },
      { path: ROUTER.PROTECTED.PROFILE.PATH, element: <ProfilePage /> },
      { path: ROUTER.PROTECTED.PRIVACY_POLICY.PATH, element: <PrivacyPolicyPage /> },
      { path: ROUTER.PROTECTED.TERMS_OF_USE.PATH, element: <TermsOfUsePage /> },
      {
        path: 'onboarding',
        element: <OnboardingLayout />,
        children: [
          {
            path: 'mdt-application',
            element: <MdtOnboardingPage />,
          },
          {
            index: true,
            element: <AccountOnboardingPage />,
          },
        ],
      },
      { path: 'home', element: <DashboardPage /> },
      {
        path: 'groups',
        element: <ProtectedGroupsLayout />,
        children: [
          { path: 'private', element: <PrivateGroups />, children: [{ path: ':groupId' }] },
          { path: 'public', element: <PublicGroups />, children: [{ path: ':groupId' }] },
          { path: 'mdt', element: <MdtGroups />, children: [{ path: ':groupId' }] },
          { path: 'public/:groupId/threads/:threadId', element: <PublicThread /> },
          { path: 'private/:groupId/threads/:threadId', element: <PrivateThread /> },
          { path: 'mdt/:groupId/threads/:threadId', element: <MdtThread /> },
          {
            index: true,
            loader: async () => redirect('public'),
          },
        ],
      },
      {
        path: 'users',
        children: [
          { index: true, element: <UsersPage /> },
          {
            path: ':userId',
            element: <UserPage />,
          },
        ],
      },
      {
        path: 'admin',
        element: <ProtectedAdminLayout />,
        children: [
          {
            path: 'users/:userId',
            element: <AdminUserPage />,
          },
          {
            path: 'users',
            element: <AdminUsersPage />,
            children: [{ element: <AdminUserPage />, path: ':userId' }],
          },
          { path: 'pending-verification-applications', element: <AdminPendingApplicationsPage onboardingApplicationStatus={OnboardingApplicationStatus.RESTRICTED_STANDARD_PENDING} /> },
          { path: 'pending-mdt-applications', element: <AdminPendingApplicationsPage onboardingApplicationStatus={OnboardingApplicationStatus.MDT_STANDARD_PENDING} /> },
          {
            path: 'demos/:demoId',
            element: <AdminDemoPage />,
          },
          {
            path: 'demos',
            element: <AdminDemosPage />,
            children: [{ element: <AdminDemoPage />, path: ':demoId' }],
          },

          {
            path: 'contact-us-requests/:contactUsRequestId',
            element: <AdminContactUsRequestPage />,
          },
          {
            path: 'contact-us-requests',
            element: <AdminContactUsRequestsPage />,
            children: [{ element: <AdminContactUsRequestPage />, path: ':contactUsRequestId' }],
          },
        ],
      },
      {
        path: 'organisations/:organisationId',
        element: <OrganisationsLayout />,
        children: [
          {
            path: 'users',
            element: <OrganisationsUsersPage />,
          },
          { element: <OrganisationsUserPage />, path: 'users/:userId' },
          { path: 'pending-applications', element: <OrganisationsPendingApplicationsPage /> },
        ],
      },
      { path: ROUTER.PROTECTED.PROFILE.PATH, element: <ProfilePage /> },
      { path: 'payment-success', element: <PaymentSuccessPage /> },
      { path: 'payment-failure', element: <PaymentFailurePage /> },
      { path: '404', element: <NotFoundPage /> },
      // TODO: catch everything else with 404 page
      { path: '*', element: <ProfilePage /> },
    ],
  },
  {
    path: 'print',
    element: <ProtectedPrintLayout />,
    errorElement: <ProtectedPrintLayout />,
    children: [{ path: 'groups/mdt/:groupId/threads/:threadId', element: <MdtApprovedThreadPrintPage /> }],
  },
]);

const Router = () => <RouterProvider router={routes} />;

const NativeRouter = () => <RouterProvider router={nativeRoutes} />;

export default withPlatformSpecific({ WebComponent: Router, NativeComponent: NativeRouter });
