import React from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import styles from './Home.module.scss';

interface Props {
  id?: string;
}

const Home: React.FC<Props> = ({ id }) => {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.info}>
        <Typography className={styles.infoTitle}>
          <div>
            Exclusive <span className={styles.infoTitlePrescribing}>Network</span> of{' '}
            <span className={styles.infoTitleToday}>HRT Prescribers</span>
          </div>
        </Typography>

        <Typography className={styles.description}>
          M3 plus provides a secure environment for HRT specialists to consult, share insights, and stay updated on the
          latest in hormone replacement therapy.
        </Typography>

        <Button variant="contained" to="/book-demo" size="large" className={styles.joinButton} component={NavLink}>
          Join Us
        </Button>
      </div>

      <div className={styles.imageContainer}>
        <div className={styles.image} />
      </div>
    </section>
  );
};

export default Home;
