import React from 'react';
import { sanitize } from 'dompurify';

import styles from './ThreadsItem.module.scss';

import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import dayjs from 'dayjs';
import Skeleton from '@mui/material/Skeleton';

import { NavLink } from 'react-router-dom';
import Chip from '../../../../components/chips/Chip/Chip';
import { ThreadStatusMap } from '../../../../constants/thread.constants';

export default function ThreadsItem({ group, thread, displayStatus }) {
  if (thread === null) {
    return (
      <ListItem disablePadding>
        <ListItemButton className={styles.itemButton}>
          <div className={styles.itemInfo}>
            <div className={styles.date}>
              <Skeleton variant="rounded" height={15} width={80} />
            </div>
            <div className={styles.title}>
              <Skeleton variant="rounded" height={32} width="80%" />
              {displayStatus && <Skeleton variant="rounded" height={25} width={60} />}
            </div>
            <div className={styles.createdBy}>
              <Skeleton variant="rounded" height={15} width={120} />
            </div>
            <div className={styles.text}>
              <Skeleton variant="rounded" height={12} sx={{ mb: '2px' }} />
              <Skeleton variant="rounded" height={12} sx={{ mb: '2px' }} />
            </div>

            <div className={styles.stats} sx>
              <Typography sx={{ display: 'flex' }}>
                <Skeleton variant="rounded" height={15} width={110} />
                <Skeleton variant="rounded" height={15} width={80} />
              </Typography>
            </div>
          </div>
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ListItem key={thread.id} className={styles.wrapper} disablePadding>
      <ListItemButton
        className={styles.itemButton}
        component={NavLink}
        to={`/internal/groups/${group?.type?.toLowerCase()}/${group.id}/threads/${thread.id}`}
      >
        <div className={styles.itemInfo}>
          <div className={styles.date}>
            <Typography>{dayjs(thread.createdAt).format('MMM DD, YYYY')}</Typography>
          </div>
          <div className={styles.title}>
            <Typography className={styles.titleText}>{thread.title}</Typography>
            {displayStatus && (
              <Chip label={ThreadStatusMap[thread.status].label} color={ThreadStatusMap[thread.status].color} />
            )}
          </div>
          <div className={styles.createdBy}>
            <Typography>
              By {thread?.user?.firstName} {thread?.user?.lastName}
            </Typography>
          </div>

          <div className={styles.text}>
            <Typography className={styles.ellipsis} dangerouslySetInnerHTML={{ __html: sanitize(thread.text) }} />
          </div>

          <div className={styles.stats}>
            <Typography>
              <span>{thread.messagesUserCount || 0} active members</span>
              <span>{thread.messagesCount || 0} comments</span>
            </Typography>
          </div>
        </div>
      </ListItemButton>
    </ListItem>
  );
}
