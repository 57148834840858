import React from 'react';
import Typography from '@mui/material/Typography';
import MedicalServicesRoundedIcon from '@mui/icons-material/MedicalServicesRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import classNames from 'classnames';

import styles from './Services.module.scss';

const CardItem = ({ icon, title, description, className }) => {
  return (
    <div className={classNames(styles.cardItem, className)}>
      <div className={styles.cover}>
        <div className={styles.cardItemIcon}>{icon}</div>
        <Typography className={styles.cardItemTitle}>{title}</Typography>

        <div className={styles.cardBack}>
          <Typography className={styles.cardItemDescription}>{description}</Typography>
        </div>
      </div>
    </div>
  );
};

export default function Services({ id, className, cardClassName = '' }) {
  return (
    <section id={id} className={classNames(styles.section, className)}>
      <div className={styles.title}>
        <Typography component="h1" variant="h4">
          Our Services
        </Typography>
        <div>
          By joining you&apos;ll unlock a suite of features tailored for healthcare professionals. Our platform enables
          secure communication, promotes the exchange of knowledge and provides powerful collaboration tools, reshaping
          your approach to connectivity within the healthcare sphere
        </div>
      </div>

      <div className={styles.cards}>
        <CardItem
          icon={<PublicRoundedIcon fontSize="large" />}
          title="Public Discussions"
          description="Join global conversations on a range of healthcare topics with peers, stay updated on the latest developments, and get advice from the healthcare community."
          className={classNames(styles.public, cardClassName)}
        />

        <CardItem
          icon={<LockRoundedIcon fontSize="large" />}
          title="Private Discussions"
          description="Have conversations in a secure, auditable and exportable environment, allowing your team to share and discuss specific patient cases."
          className={classNames(styles.private, cardClassName)}
        />

        <CardItem
          icon={<MedicalServicesRoundedIcon fontSize="large" />}
          title="Multi-Disciplinary Team"
          description="Conduct and manage MDT cases quickly and securely whilst remaining compliant with current regulations."
          className={classNames(styles.mdt, cardClassName)}
        />
      </div>
    </section>
  );
}
