export enum ApiRoute {
  PROFILE = 'users/me',
  USERS = 'users',
  USERS_NOTIFICATION_SETTINGS = 'users/notification-settings',
  ONBOARDING_PROFILE = 'users/onboarding',
  PUSH_NOTIFICATIONS = 'users/push-notifications',
  ORGANISATIONS = 'mdt/organisations',
  GROUPS = 'mdt/groups',
  THREADS = 'mdt/threads',
  MESSAGES = 'mdt/messages',
  PERMISSIONS = 'mdt/permissions',
  MFA = 'auth/mfa',
  MFA_SETTINGS = 'auth/mfa/settings',
  MFA_ASSOCIATE = 'auth/mfa/associate',
  MFA_DISASSOCIATE = 'auth/mfa/disassociate',
  PLANS = 'membership/plans',
  SUBSCRIPTIONS = 'membership/subscriptions',
  PAYMENT_GATEWAY_SESSION_SECRET = 'membership/session-secret',
  PAYMENT_GATEWAY_CHECKOUT_SESSION = 'membership/checkout',
}
