export const medicalRegistrationCuontryToTypes = {
  'Afghanistan': 'Medical Council',
  'Albania': 'National Council of Order of Doctors',
  'Algeria': 'Conseil National de l\'Ordre des Médecins Algeria',
  'Andorra': 'Col·legi de Metges dAndorra',
  'Angola': 'Ordem dos Médicos de Angola',
  'Antigua and Barbuda': 'Medical Board of Antigua and Barbuda',
  'Argentina': 'Federación Médica de la Provincia de Buenos Aires',
  'Armenia': 'Ministry of Health of the Republic of Armenia',
  'Australia': 'Medical Board of Australia',
  'Austria': 'Austrian Medical Chamber',
  'Azerbaijan': 'Azerbaijan Medical Association',
  'Bahamas': 'Bahamas Medical Council',
  'Bahrain': 'National Health Regulatory Authority',
  'Bangladesh': 'Bangladesh Medical and Dental Council',
  'Barbados': 'Barbados Medical Council',
  'Belarus': 'Belarusian Medical Academy',
  'Belgium': 'Order of Physicians',
  'Belize': 'Belize Medical Council',
  'Benin': 'Ordre National des Médecins du Benin',
  'Bhutan': 'Bhutan Medical and Health Council',
  'Bolivia': 'Colegio Médico de Bolivia',
  'Bosnia and Herzegovina': 'Medical Chamber of Bosnia and Herzegovina',
  'Botswana': 'Botswana Health Professions Council',
  'Brazil': 'Conselho Federal de Medicina',
  'Brunei': 'Brunei Medical Board',
  'Bulgaria': 'Bulgarian Medical Association',
  'Burkina Faso': 'Ordre National des Médecins du Burkina Faso',
  'Burundi': 'Ordre des Médecins du Burundi',
  'Cabo Verde': 'National Council of Medicine',
  'Cambodia': 'Cambodian Medical Council',
  'Cameroon': 'National Medical Council of Cameroon',
  'Canada': 'College of Physicians and Surgeons (each province and territory)',
  'Central African Republic': 'Ordre des Médecins de Centrafrique',
  'Chad': 'Conseil National de l\'Ordre des Médecins du Tchad',
  'Chile': 'Colegio Médico de Chile',
  'China': 'Chinese Medical Doctor Association',
  'Colombia': 'Colegio Médico Colombiano',
  'Comoros': 'Ordre National des Médecins Comores',
  'Congo (Republic)': 'Conseil de l\'Ordre des Médecins du Congo',
  'Congo (Democratic Republic)': 'Conseil de l\'Ordre des Médecins du Congo Démocratique',
  'Costa Rica': 'Colegio de Médicos y Cirujanos de Costa Rica',
  'Croatia': 'Croatian Medical Chamber',
  'Cuba': 'Ministry of Public Health (regulates directly)',
  'Cyprus': 'Cyprus Medical Council',
  'Czech Republic': 'Czech Medical Chamber',
  'Denmark': 'Danish Health and Medicines Authority',
  'Djibouti': 'Ministry of Health (regulates directly)',
  'Dominica': 'Medical Board of Dominica',
  'Dominican Republic': 'Colegio Médico Dominicano',
  'Ecuador': 'Federación Médica Ecuatoriana',
  'Egypt': 'Egyptian Medical Syndicate',
  'El Salvador': 'Junta de Vigilancia de la Profesión Médica',
  'Equatorial Guinea': 'Colegio Médico de Guinea Ecuatorial',
  'Eritrea': 'Eritrean Medical Association',
  'Estonia': 'Health Board (Terviseamet)',
  'Eswatini (Swaziland)': 'Medical and Dental Council of Eswatini',
  'Ethiopia': 'Ethiopian Medical Board',
  'Fiji': 'Fiji Medical Council',
  'Finland': 'Valvira (National Supervisory Authority for Welfare and Health)',
  'France': 'Conseil National de l\'Ordre des Médecins',
  'Gabon': 'Ordre National des Médecins du Gabon',
  'Gambia': 'Medical and Dental Council of The Gambia',
  'Georgia': 'Georgian National Medical Council',
  'Germany': 'Bundesärztekammer (Federal Chamber of Physicians)',
  'Ghana': 'Medical and Dental Council, Ghana',
  'Greece': 'Hellenic Medical Council',
  'Grenada': 'Grenada Medical and Dental Council',
  'Guatemala': 'Colegio de Médicos y Cirujanos de Guatemala',
  'Guinea': 'Ordre National des Médecins de Guinée',
  'Guinea-Bissau': 'Ordem dos Médicos da Guiné-Bissau',
  'Guyana': 'Medical Council of Guyana',
  'Haiti': 'Ordre des Médecins Haïtiens',
  'Honduras': 'Colegio Médico de Honduras',
  'Hungary': 'Hungarian Medical Chamber',
  'Iceland': 'Directorate of Health (oversees licensing and regulations)',
  'India': 'National Medical Commission',
  'Indonesia': 'Indonesian Medical Council',
  'Iran': 'Iranian Medical Council',
  'Iraq': 'Iraqi Medical Association',
  'Ireland': 'Medical Council of Ireland',
  'Israel': 'Israeli Medical Association',
  'Italy': 'Federazione Nazionale degli Ordini dei Medici Chirurghi e degli Odontoiatri',
  'Jamaica': 'Medical Council of Jamaica',
  'Japan': 'Japan Medical Association',
  'Jordan': 'Jordan Medical Association',
  'Kazakhstan': 'Ministry of Health of the Republic of Kazakhstan',
  'Kenya': 'Medical Practitioners and Dentists Board',
  'Kiribati': 'Ministry of Health and Medical Services (direct oversight)',
  'Korea, North': 'Ministry of Public Health (direct oversight)',
  'Korea, South': 'Korean Medical Association',
  'Kosovo': 'Kosovo Medical Council',
  'Kuwait': 'Kuwait Medical Association',
  'Kyrgyzstan': 'Ministry of Health Kyrgyzstan',
  'Laos': 'Ministry of Health Laos',
  'Latvia': 'Latvian Medical Association',
  'Lebanon': 'Order of Physicians of Beirut',
  'Lesotho': 'Medical, Dental and Pharmacy Council of Lesotho',
  'Liberia': 'Liberia Medical and Dental Council',
  'Libya': 'Libyan Medical Council',
  'Liechtenstein': 'Office of Public Health',
  'Lithuania': 'Lithuanian Medical Association',
  'Luxembourg': 'Collège Médical',
  'Madagascar': 'Order of Physicians of Madagascar',
  'Malawi': 'Medical Council of Malawi',
  'Malaysia': 'Malaysian Medical Council',
  'Maldives': 'Maldives Medical and Dental Council',
  'Mali': 'Ordre National des Médecins du Mali',
  'Malta': 'Medical Council of Malta',
  'Marshall Islands': 'Ministry of Health and Human Services (direct oversight)',
  'Mauritania': 'Ordre National des Médecins de Mauritanie',
  'Mauritius': 'Medical Council of Mauritius',
  'Mexico': 'Consejo Mexicano para la Acreditación de la Educación Médica',
  'Micronesia': 'Federated States of Micronesia Department of Health',
  'Moldova': 'Council for the Evaluation and Accreditation of Medical and Pharmaceutical Education',
  'Monaco': 'Department of Health Affairs',
  'Mongolia': 'Mongolian National Medical Council',
  'Montenegro': 'Medical Chamber of Montenegro',
  'Morocco': 'National Council of the Order of Physicians of Morocco',
  'Mozambique': 'Ordem dos Médicos de Moçambique',
  'Myanmar': 'Myanmar Medical Council',
  'Namibia': 'Health Professions Councils of Namibia',
  'Nauru': 'Nauru Health Department Nauru',
  'Nepal': 'Nepal Medical Council',
  'Netherlands': 'Royal Dutch Medical Association',
  'New Zealand': 'Medical Council of New Zealand',
  'Nicaragua': 'Consejo Superior de Universidades',
  'Niger': 'Ordre National des Médecins du Niger',
  'Nigeria': 'Medical and Dental Council of Nigeria',
  'North Macedonia': 'Macedonian Medical Chamber',
  'Norway': 'Norwegian Medical Association',
  'Oman': 'Oman Medical Specialty Board',
  'Pakistan': 'Pakistan Medical Commission',
  'Palau': 'Palau Ministry of Health Palau',
  'Palestine': 'Palestinian Medical Council',
  'Panama': 'Consejo Técnico de Salud',
  'Papua New Guinea': 'Papua New Guinea Medical Board',
  'Paraguay': 'Colegio Médico del Paraguay',
  'Peru': 'Colegio Médico del Perú',
  'Philippines': 'Philippine Medical Association',
  'Poland': 'Polish Chamber of Physicians and Dentists',
  'Portugal': 'Ordem dos Médicos',
  'Qatar': 'Qatar Council for Healthcare Practitioners',
  'Romania': 'Romanian College of Physicians',
  'Russia': 'Ministry of Health of the Russian Federation',
  'Rwanda': 'Rwanda Medical and Dental Council',
  'Saint Kitts and Nevis': 'St. Kitts and Nevis Medical Board',
  'Saint Lucia': 'Medical and Dental Council of Saint Lucia',
  'Saint Vincent and the Grenadines': 'Medical and Dental Council of Saint Vincent and the Grenadines',
  'Samoa': 'Samoa Medical Council',
  'San Marino': 'Institute for Social Security',
  'Sao Tome and Principe': 'Ministry of Health Sao Tome and Principe',
  'Saudi Arabia': 'Saudi Commission for Health Specialties',
  'Senegal': 'Ordre National des Médecins du Sénégal',
  'Serbia': 'Serbian Medical Chamber',
  'Seychelles': 'Seychelles Medical and Dental Council',
  'Sierra Leone': 'Medical and Dental Council of Sierra Leone',
  'Singapore': 'Singapore Medical Council',
  'Slovakia': 'Slovak Medical Chamber',
  'Slovenia': 'Medical Chamber of Slovenia',
  'Solomon Islands': 'Solomon Islands Medical Council',
  'Somalia': 'Somali Medical Association',
  'South Africa': 'Health Professions Council of South Africa',
  'South Sudan': 'South Sudan Medical Council',
  'Spain': 'General Council of Official Medical Associations',
  'Sri Lanka': 'Sri Lanka Medical Council',
  'Sudan': 'Sudan Medical Council',
  'Suriname': 'Suriname Medical Council',
  'Sweden': 'Swedish Medical Association',
  'Switzerland': 'Swiss Medical Association (FMH)',
  'Syria': 'Syrian Medical Association',
  'Taiwan': 'Taiwan Medical Association',
  'Tajikistan': 'Ministry of Health Tajikistan',
  'Tanzania': 'Medical Council of Tanganyika',
  'Thailand': 'Medical Council of Thailand',
  'Timor-Leste': 'Timor-Leste Medical Council',
  'Togo': 'Ordre National des Médecins du Togo',
  'Tonga': 'Ministry of Health Tonga',
  'Trinidad and Tobago': 'Trinidad and Tobago Medical Board',
  'Tunisia': 'Conseil National de l\'Ordre des Médecins de Tunisie',
  'Turkey': 'Turkish Medical Association',
  'Turkmenistan': 'Ministry of Health and Medical Industry',
  'Tuvalu': 'Tuvalu Ministry of Health Tuvalu',
  'Uganda': 'Uganda Medical and Dental Practitioners Council',
  'Ukraine': 'Ukrainian Medical Council',
  'United Arab Emirates': 'Ministry of Health and Prevention',
  'United Kingdom': 'General Medical Council',
  'United States': 'Individual State Medical Boards',
  'Uruguay': 'Colegio Médico del Uruguay',
  'Uzbekistan': 'Ministry of Health Uzbekistan',
  'Vanuatu': 'Vanuatu Medical and Dental Council',
  'Vatican City': 'Directorate of Health and Hygiene',
  'Venezuela': 'Venezuelan Medical Federation',
  'Vietnam': 'Vietnam Medical Association',
  'Yemen': 'Yemen Medical Council',
  'Zambia': 'Health Professions Council of Zambia',
  'Zimbabwe': 'Medical and Dental Practitioners Council of Zimbabwe'
}