export const getErrorMessage = (error, defaultMessage = 'Unknown error') => {
  if (typeof error === 'string') return error;

  if (Array.isArray(error?.response?.data?.details)) {
    const detailsMessage = error.response.data.details
      .flatMap((detail) => (typeof detail?.message === 'string' ? [detail.message] : []))
      .join(', ');

    if (detailsMessage) return detailsMessage;
  }

  return error?.response?.data?.message || error?.message || defaultMessage;
};
