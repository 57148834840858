import React from 'react';
import Chip from '@mui/material/Chip';

import { UserStatus } from '../../../types/enums/UserStatus';
import { UserStatusMap } from '../../../constants/user.constants';
import classes from './UserStatusChip.module.scss';

interface Props {
  status: UserStatus;
  onClick?: () => void;
}

const UserStatusChip: React.FC<Props> = ({ status, onClick }) => {
  return (
    <Chip
      color={UserStatusMap[status].color as 'warning' | 'success' | 'error'}
      label={UserStatusMap[status].label}
      onClick={onClick}
      className={classes.chip}
    />
  );
};

export default UserStatusChip;
