import classNames from 'classnames';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import styles from './SelectAutocompleteInputField.module.scss';

export default function SelectAutocompleteInputField(props) {
  const helperText = (
    <FormHelperText className={styles.helperText}>
      {props.error ? props.errorHelperText : props.helperText}
    </FormHelperText>
  );

  return (
    <FormControl fullWidth error={props.error}>
      <Autocomplete
        freeSolo
        options={props.options || []}
        onChange={(_, newValue) => props.onChange({ target: { name: props.name, value: newValue } })}
        onInputChange={(_, newInputValue) => props.onChange({ target: { name: props.name, value: newInputValue } })}
        name={props.name}
        value={props.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            name={props.name}
            size={props.size || 'normal'}
            className={classNames(styles.selectField, props.className)}
            onBlur={props.onBlur}
            value={props.value}
            defaultValue={props.defaultValue || ''}
          />
        )}
      />
      {helperText}
    </FormControl>
  );
}
