import React from 'react';
import Typography from '@mui/material/Typography';

import styles from './UserProfileWrapper.module.scss';

export default function UserProfileWrapper({ children, title, description }) {
  return (
    <>
      <div className={styles.title}>
        <Typography variant="h4">{title}</Typography>
        <Typography className={styles.description}>{description}</Typography>
      </div>

      <div>{children}</div>
    </>
  );
}
