import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import styles from './SubscriptionsManagement.module.scss';
import MembershipService from '../../../../../services/api/MembershipService';
import { displayCentsWithCurrency } from '../../../../../utils/money';
import { Subscription } from '../../../../../types/Subscription';
import { Button, FormHelperText } from '@mui/material';
import { formatDate } from '../../../../../utils/date';
import { SubscriptionStatus } from '../../../../../types/enums/SubscriptionStatus';
import Chip from '../../../../../components/chips/Chip/Chip';
import ConfirmDialog from '../../../../../components/confirm-dialog/ConfirmDialog';
import SubscriptionPurchaseModal from './components/SubscriptionPurchaseModal';

const SubscriptionsManagement: React.FC = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [cancelConfirmDialog, setCancelConfirmDialog] = useState(false);
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [subscriptionIdToCancel, setSubscriptionIdToCancel] = useState<string | null>(null);


  useEffect(() => {
    const fetchSubscriptions = async () => {
      const result = await MembershipService.getSubscriptions();

      setSubscriptions(result);
    }
    
    fetchSubscriptions().catch((error) => console.error(error));
  }, []);


  const displaySubscription = (subscription: Subscription) => {
    return <>
      <TableRow>
        <TableCell width={200} className={styles.tableCell}>
          {subscription.plan.name}
        </TableCell>
        <TableCell width={100} className={styles.tableCell}>
          {displayCentsWithCurrency(subscription.plan.amount!, subscription.plan.currency)}
        </TableCell>
        <TableCell width={100} className={styles.tableCell}>
          {subscription.plan.recurringInterval}
        </TableCell>
        <TableCell width={100} className={styles.tableCell}>
          {<Chip size='small' variant='filled' label={subscription.status} />}
        </TableCell>
        <TableCell width={100} className={styles.tableCell}>
          {subscription.cancelPeriodEndAt ? `Be cancelled at ${formatDate(subscription.cancelPeriodEndAt)}` : `${formatDate(subscription.periodStartAt)} - ${formatDate(subscription.periodEndAt)}`}
        </TableCell>

        <TableCell width={100} className={styles.tableCell} align='right'>
          {([SubscriptionStatus.ACTIVE, SubscriptionStatus.TRAILING].includes(subscription.status) && !subscription.cancelPeriodEndAt)  && <Button color='error' className={styles.button} onClick={() => {
            setCancelConfirmDialog(true)
            setSubscriptionIdToCancel(subscription.id)
          }}>Cancel</Button>}
        </TableCell>
      </TableRow>
    </>
  }


  const displaySubscriptions = () => {
    return <>
      {subscriptions.map((subscription) => displaySubscription(subscription))}
      <ConfirmDialog
        open={cancelConfirmDialog}
        text="Are you sure you want to cancel the subscription? By confirming, you will no longer be charged for this subscription. Please note: your tier level will change at the end of your current paid subscription period or upon purchasing a new subscription."
        onClose={() => {
          setCancelConfirmDialog(false);
          setSubscriptionIdToCancel(null);
        }}
        onConfirm={() => subscriptionIdToCancel && confirmSubsctipitonCancel(subscriptionIdToCancel)}
        maxWidth="md"
        confirmName="Confirm"
      />
    </>
  }

  const confirmSubsctipitonCancel = async (id: string) => {
    setCancelConfirmDialog(false);

    await MembershipService.deleteSubscription(id);

    setSubscriptions(subscriptions.map(subscription => subscription.id === id ? { ...subscription, cancelPeriodEndAt: subscription.periodEndAt } : subscription));
  }

  const hasActiveSubsctiptions = () => {
    return subscriptions.some(subscription => [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRAILING].includes(subscription.status) && !subscription.cancelPeriodEndAt);
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.info}>
        <Typography>Subscriptions Management</Typography>
        <Typography className={styles.description}>Manage Your Subscription Preferences</Typography>
      </Typography>

      <TableContainer className={styles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHeadCell} width={200}>Name</TableCell>
              <TableCell className={styles.tableHeadCell} width={100}>Amount</TableCell>
              <TableCell className={styles.tableHeadCell} width={100}>Interval</TableCell>
              <TableCell className={styles.tableHeadCell} width={100}>Status</TableCell>
              <TableCell className={styles.tableHeadCell} width={100}>Period</TableCell>

              <TableCell className={styles.tableHeadCell} align='right' width={100}>
                <Button variant='outlined' disabled={hasActiveSubsctiptions()} className={styles.button} onClick={() => setPurchaseModalOpen(true)}>Create</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displaySubscriptions()}
          </TableBody>
        </Table>
      </TableContainer>

      <SubscriptionPurchaseModal open={purchaseModalOpen} setOpen={setPurchaseModalOpen} />
    </div>
  );
};

export default SubscriptionsManagement;
