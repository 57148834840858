import { Typography } from '@mui/material';

import styles from './Faq.module.scss';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const AccordionItem = ({ title, description }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={styles.sectionItemTitle}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {description}
      </AccordionDetails>
    </Accordion>
  )
}

export default function Faq({ id }) {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.title}>
        <Typography component="h1" variant="h4">
          FAQ&apos;s
        </Typography>
      </div>
      <div className={styles.sectionItem}>
        <AccordionItem
          title="Why not just use Teams/Zoom or meet in person?"
          description="Medsym is built for MDTs, offering features like audit trails, secure case tracking, and decision-making tools that Teams or Zoom don’t have. It can work alongside these platforms but focuses on making MDT meetings faster and more organised, improving patient care."
        />

        <AccordionItem
          title="Is it difficult to integrate MedSym with our existing systems?"
          description="Not at all. Medsym easily integrates with your current systems, including Clinic Management Systems, without disrupting your workflow. It’s designed to be user-friendly and adaptable to your needs."
        />

        <AccordionItem
          title="How can we ensure patient data remains secure with MedSym?"
          description="Medsym is fully GDPR-compliant and uses strong encryption to keep patient data safe. Audit trails are created for transparency, and all communications are secure."
        />

        <AccordionItem
          title="What if our team is resistant to adopting new technology?"
          description="Medsym is easy to use, and we provide full support during onboarding. The time and cost savings, along with better patient outcomes, usually help teams adjust quickly."
        />
      </div>
    </section>
  );
}
