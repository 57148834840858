import React from 'react';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

import classes from './UserDetailsItem.module.scss';
import { isString } from '../../../utils/typeGuards';

interface Props {
  title: string;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

const UserDetailsItem: React.FC<Props> = ({ title, children, className, contentClassName }) => {
  return (
    <div className={classNames(classes.container, className)}>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>

      {isString(children) ? (
        <Typography className={contentClassName}>{children}</Typography>
      ) : (
        <div className={contentClassName}>{children}</div>
      )}
    </div>
  );
};

export default UserDetailsItem;
