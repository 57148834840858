export const debounce = (fn, wait) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      clearTimeout(timeout);
      fn(...args);
    }, wait);
  };
};
