import styles from './Footer.module.scss';
import Link from '@mui/material/Link';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Typography } from '@mui/material';
import { getCurrentLanding } from '../../utils/string';
import Logo from '../ui/Logo/Logo';

export default function Footer() {
  const { pathname } = useLocation();

  const landing = getCurrentLanding(pathname);

  return (
    <div className={classNames(styles.footer, landing === 'hrt' && styles.footerHrt)}>
      <div className={styles.companyInfo}>
        <Logo className={styles.logo} />
        <Typography className={styles.address}>
          12-18 Hoxton Street, London United Kingdom, N1 6NG +44-20-7097-4918
        </Typography>
      </div>
      <div className={styles.footerSection}>
        <Typography className={styles.title}>Products</Typography>
        <Typography className={styles.items}>
          <a href="https://group.medisonal.co.uk/" target="_blank" rel="noreferrer">
            Medisonal Group
          </a>
          <a href="https://clinic.medisonal.co.uk/" target="_blank" rel="noreferrer">
            Medisonal Clinic
          </a>
          <a href="https://mie.medisonalm3.com/" target="_blank" rel="noreferrer">
            Medisonal Institute of Education
          </a>
        </Typography>
      </div>

      <div className={styles.footerSection}>
        <Typography className={styles.title}>Legal Information</Typography>
        <Typography className={styles.items}>
          <Link color="#fff" href="terms-of-use" target="_blank" underline="none">
            Terms of Use
          </Link>
          <Link color="#fff" href="privacy-policy" target="_blank" underline="none">
            Privacy Policy
          </Link>
        </Typography>
      </div>

      <div className={styles.footerSection}>
        <Typography className={styles.title}>Get In Touch</Typography>

        <div className={styles.socialMediaLinks}>
          <a href="https://www.instagram.com/medisonaluk/" target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
          <a href="https://www.facebook.com/Medisonal" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
          <a href="https://www.linkedin.com/company/medisonal/" target="_blank" rel="noreferrer">
            <LinkedInIcon />
          </a>
          <a href="https://twitter.com/MedisonalUK" target="_blank" rel="noreferrer">
            <TwitterIcon />
          </a>
        </div>
      </div>

      <Typography className={styles.companySign}>© 2024 Medisonal LTD. All rights reserved.</Typography>
    </div>
  );
}
