import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { getUserDetails, removeUser, reviewUserOnboarding } from './userDetailsThunks';
import { UserDetails } from '../../../types/User';
import { UserStatus } from '../../../types/enums/UserStatus';

interface UserDetailsState {
  userId: string | null;
  userDetails: UserDetails | null;
  error: string | null | undefined;
  isLoading: boolean;
}

const initialState: UserDetailsState = {
  userId: null,
  userDetails: null,
  isLoading: true,
  error: null,
};

const userDetailsSlice = createSlice({
  name: SliceName.USER_DETAILS,
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    clearUserDetails: (state) => {
      state.userId = null;
      state.userDetails = null;
      state.isLoading = true;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userDetails = action.payload;
    });

    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(reviewUserOnboarding.fulfilled, (state, { payload }) => {
      if (state.userDetails?.id === payload.id) {
        state.userDetails.mdtProfile.onboardingReviewComment = payload.review.onboardingReviewComment;
        state.userDetails.mdtProfile.status = payload.review.status;
      }
    });

    builder.addCase(removeUser.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(removeUser.fulfilled, (state, action) => {
      state.isLoading = false;
      
      if (state.userDetails?.status ) {
        state.userDetails.status = UserStatus.DELETED;
      }
    });
  },
});

export const { clearUserDetails, setUserId } = userDetailsSlice.actions;

export const selectUserDetails = (state: RootState) => state.userDetails;

export default userDetailsSlice.reducer;
