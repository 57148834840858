import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import styles from './HowItWorks.module.scss';

const GuidelineItem = ({ number, title, description }) => {
  return (
    <div className={styles.guidelineItem}>
      <div className={styles.guidelineItemName}>
        <Typography className={styles.guidelineItemNumber}>{number}</Typography>
        <Typography className={styles.guidelineItemTitle}>{title}</Typography>
      </div>

      <Typography className={styles.guidelineItemDescription}>{description}</Typography>
    </div>
  );
};

export default function HowItWorks({ id }) {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.title}>
        <Typography component="h2" variant="h4">
          How It Works?
        </Typography>

        <div className={styles.guidlineScreenshot} />

        <Typography className={styles.description}>
          A simple process from registration to the receipt of expert feedback and treatment solutions.
          Be part of the Medsym community. To share, learn and help treat unmet medical needs.
        </Typography>
      </div>

      <div className={styles.guidelineContainer}>
        <GuidelineItem
          number="1"
          title="Registration"
          description={
            <>
              Create your personal account by submitting simple{' '}
              <Link to="/sign-up" component={RouterLink} underline="none">
                sign-up form
              </Link>{' '}
              on our platform
            </>
          }
        />

        <GuidelineItem
          number="2"
          title="Complete Onboarding"
          description="Complete our comprehensive onboarding process. Provide the necessary information, submit it, and our diligent team will promptly review your details to ensure you receive the most suitable service"
        />

        <GuidelineItem
          number="3"
          title="Create MDT Case"
          description="Construct a private MDT case. This case will be assessed by team of experts to determine the most effective solution tailored for your specific needs"
        />

        <GuidelineItem
          number="4"
          title="Receive Expert Feedback"
          description=" Receive insightful advice from our dedicated MDT team of experts. Our committed professionals rigorously review your case and provide you with customised advice, empowering you to successfully navigate through your unique situation"
        />
      </div>
    </section>
  );
}
