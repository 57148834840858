import React, { useState, useEffect } from 'react';
import Modal from '../../../../../components/modal/Modal';
import ModalTitle from '../../../../../components/modal-title/ModalTitle';
import styles from './GroupManageMembersModal.module.scss';
import Search from '../../../../../components/search/Search';
import { useMdtContext } from '../../../../../hooks/useMdtContext';
import List from '@mui/material/List';
import { Avatar, IconButton, ListItem, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import { getUsersSearchParams } from '../../../../../utils/userSearch';
import { MdtPermissions, UserGroupRole } from '../../../../../constants/user.constants';
import GroupAddMemberModal from './GroupAddMemberModal/GroupAddMemberModal';
import { debounce } from '../../../../../utils/debounce';
import { hasPermission } from '../../../../../utils/permissions';
import FilterSelect from '../../../../../components/filter-select/FilterSelect';
import { countries, specialities } from '../../../../public/sign-up/sign-up-form/SignUpForm.constants';
import Chip from '../../../../../components/chips/Chip/Chip';
import Button from '../../../../../components/button/Button';
import Skeleton from '@mui/material/Skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import PaidIcon from '@mui/icons-material/Paid';

import { useAuthContext } from '../../../../../hooks/useAuthContext';
import { NavLink, useNavigate } from 'react-router-dom';
import { getOffset } from '../../../../../utils/url';
import { config } from '../../../../../configs/config';
import { useAppSelector } from '../../../../../store/hooks';
import { selectPermissions } from '../../../../../store/slices/permissions/permissionsSlice';

export default function GroupManageMembersModal({ permissions, open, setOpen }) {
  const { group, getGroupUsers, groupUsersCount, editUserGroup, removeUserFromGroup } = useMdtContext();
  const auth = useAuthContext();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [specialitySelect, setSpecialitySelect] = useState('');
  const [countrySelect, setCountrySelect] = useState('');
  const [addMemberModal, setAddMemberModal] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState(null);

  const { permissions: tierPermissions } = useAppSelector(selectPermissions);
  const hasGroupInvitePermission = tierPermissions && tierPermissions[`groups:${group?.type?.toLowerCase()}:invite`];


  useEffect(() => {
    if (open) fetchGroupUsers(1).catch((error) => console.error(error));
    else resetFilters();

    return () => resetInfiniteScroll();
  }, [open, search, specialitySelect, countrySelect, addMemberModal]);

  const resetInfiniteScroll = () => {
    setUsers(null);
    setPage(1);
    setHasMore(true);
  };

  const resetFilters = () => {
    setSpecialitySelect('');
    setCountrySelect('');
    setSearch('');
  };

  const fetchGroupUsers = async (pageNum) => {
    const { headers, body } = await getGroupUsers({
      ...getUsersSearchParams(search),
      groupId: group.id,
      limit: config.pagination.usersPageSize,
      offset: getOffset(pageNum),
      speciality: specialitySelect || undefined,
      country: countrySelect || undefined,
    });

    const usersLength = users?.length || 0;

    if (usersLength + body?.length >= headers['x-total-count']) {
      setHasMore(false);
    } else {
      setPage((prevPage) => prevPage + 1);
    }

    setUsers((prevUsers) => {
      if (prevUsers === null) return body;

      return prevUsers.concat(body);
    });
  };

  const onSearchChange = async (event) => {
    const term = event.target.value;

    setSearch(term);
    setUsers([]);
    setPage(1);
  };

  const onSpecialitySelectChange = async (event) => {
    setUsers([]);
    setPage(1);
    setSpecialitySelect(event.target.value);
  };

  const onCountrySelectChange = async (event) => {
    setUsers([]);
    setPage(1);
    setCountrySelect(event.target.value);
  };

  const debouncedSearcChange = debounce(onSearchChange, 1000);

  const onRoleChange = async (event, user) => {
    await editUserGroup(group.id, user.id, { role: event.target.value });

    const userIdx = users.findIndex(({ id }) => user.id === id);
    if (userIdx > -1) {
      users[userIdx] = { ...users[userIdx], role: event.target.value, updatedAt: new Date().toISOString() };

      setUsers(users);
    }
  };

  const onMemberRemove = async (groupId, userId) => {
    await removeUserFromGroup(groupId, userId);

    setUsers(users.filter((user) => user.id !== userId));
  };

  const displayMemberActions = (user) => {
    const disabled = !hasPermission(permissions, MdtPermissions.EDIT_GROUP_MEMBER) || user.id === auth.user.id;
    return (
      <div>
        <FilterSelect
          label="Role"
          name="role"
          disabled={disabled}
          defaultOption={false}
          className={styles.roleSelect}
          value={user.role}
          onChange={(event) => onRoleChange(event, user)}
          options={[
            { name: UserGroupRole.DEFAULT, label: 'Member' },
            { name: UserGroupRole.MANAGER, label: 'Reviewer' },
            { name: UserGroupRole.OWNER, label: 'Admin' },
          ]}
        />
        <IconButton
          className={styles.delete}
          disabled={disabled}
          onClick={() => onMemberRemove(group.id, user.id)}
          edge="end"
          aria-label="delete"
        >
          <CloseIcon sx={{ fill: disabled ? '' : '#667a8a' }} fontSize="small" />
        </IconButton>
      </div>
    );
  };

  const addMemberAction = (
    <Button
      type="default"
      variant="contained"
      rounded
      className={classNames(styles.listItemAddMember)}
      onClick={(e) => {
        hasGroupInvitePermission ? setAddMemberModal(true) : navigate('internal/settings?tab=subscriptions');
        e.preventDefault();
      }}
    >
      Add Member {!hasGroupInvitePermission && <PaidIcon />}
    </Button>
  );

  const displayMember = (user) => {
    if (!user) {
      return (
        <ListItem className={classNames(styles.listItem)}>
          <div>
            <Skeleton variant="circular" height={24} width={25} className={styles.avatarSkeleton} />
            <Typography className={styles.info}>
              <span>
                <Skeleton variant="rounded" height={15} width={120} />
              </span>
            </Typography>
            <span className={styles.sceletonMobile}>
              <Skeleton variant="rounded" height={15} width={140} />
            </span>
            <Skeleton variant="rounded" height={15} width={60} />
          </div>
          <Skeleton variant="rounded" height={15} width={120} />
        </ListItem>
      );
    }

    const userPageLink = `/internal/users/${user.id}`;
    return (
      <ListItem className={classNames(styles.listItem)} key={user.id}>
        <div>
          <NavLink to={userPageLink} target="_blank" rel="noopener noreferrer">
            <Avatar className={styles.avatar}>
              {user.firstName[0]}
              {user.lastName[0]}
            </Avatar>
          </NavLink>
          <Typography className={styles.info}>
            <NavLink to={userPageLink} target="_blank" rel="noopener noreferrer">
              <span>
                {user.firstName} {user.lastName}
              </span>
            </NavLink>
            <span className={styles.email}>{user.email}</span>
          </Typography>
          <Chip size="small" label={user.speciality} />
        </div>

        {hasPermission(permissions, MdtPermissions.EDIT_GROUP_MEMBER) && displayMemberActions(user)}
      </ListItem>
    );
  };

  const displayMembers = () => {
    if (users === null) {
      return <List className={styles.list}>{new Array(10).fill().map(() => displayMember())}</List>;
    }
    return <List className={styles.list}>{users.map((user) => displayMember(user))}</List>;
  };

  return (
    <div>
      <Modal open={open} className={styles.modal} onClose={() => setOpen(false)}>
        <form component="form" fullWidth>
          <ModalTitle>Members</ModalTitle>
          <div className={styles.search}>
            <Search
              placeholder="Search for a member"
              value={search}
              onChange={(event) => debouncedSearcChange(event)}
            />
            <div className={styles.filters}>
              <div>
                <FilterSelect
                  onChange={onSpecialitySelectChange}
                  label="Speciality"
                  name="speciality"
                  value={specialitySelect}
                  options={specialities.map((speciality) => ({
                    name: speciality.toLocaleLowerCase(),
                    label: speciality,
                  }))}
                />

                <FilterSelect
                  onChange={onCountrySelectChange}
                  label="Country"
                  name="country"
                  value={countrySelect}
                  options={countries.map((speciality) => ({ name: speciality, label: speciality }))}
                />
              </div>

              {hasPermission(permissions, MdtPermissions.CREATE_GROUP_MEMBER) && addMemberAction}
            </div>

            <Typography className={styles.listItemsCount}>Total Amount of Members: {groupUsersCount}</Typography>
          </div>
          <InfiniteScroll
            className={styles.infiniteScrollWrapper}
            dataLength={users?.length || 0}
            height={400}
            next={() => fetchGroupUsers(page)}
            hasMore={hasMore}
            loader={
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box>
            }
          >
            {displayMembers()}
          </InfiniteScroll>
        </form>
      </Modal>

      <GroupAddMemberModal permissions={permissions} open={addMemberModal} setOpen={setAddMemberModal} group={group} />
    </div>
  );
}
