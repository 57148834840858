import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { UserNotificationSettings } from '../../../types/UserNotificationSettings';
import {
  getUserNotificationSettings,
  updateUserNotificationSettings
} from './userNotificationSettingsThunks';

interface userNotificationSettingsState {
  userNotificationSettings: UserNotificationSettings | null;
  error: string | null | undefined;
  loading: boolean;
}

const initialState: userNotificationSettingsState = {
  userNotificationSettings: null,
  loading: true,
  error: null,
};

const userNotificationSettingsSlice = createSlice({
  name: SliceName.USER_NOTIFICATION_SETTINGS,
  initialState,
  reducers: {
    cancelUserNotificationSettings: (state) => {
      state.error = null;
      state.userNotificationSettings = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserNotificationSettings.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getUserNotificationSettings.fulfilled, (state, action) => {
      state.userNotificationSettings = action.payload;
      state.loading = false;
    });

    builder.addCase(getUserNotificationSettings.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(updateUserNotificationSettings.fulfilled, (state, action) => {
      state.userNotificationSettings = action.payload;
    });

    builder.addCase(updateUserNotificationSettings.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export const { cancelUserNotificationSettings } = userNotificationSettingsSlice.actions;

export const selectUserNotificationSettings = (state: RootState) => state.userNotificationSettings;

export default userNotificationSettingsSlice.reducer;
