import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  clearState,
  selectOrganisationUsers,
  setFilters,
} from '../../../../store/slices/organisationUsers/organisationUsersSlice';
import { useEffectAfterInitialLoad } from '../../../../hooks/useEffectAfterInitialLoad';
import { getOrganisationUsers } from '../../../../store/slices/organisationUsers/organisationUsersThunks';
import { UserStatus } from '../../../../types/enums/UserStatus';

export const useOrganisationPendingApplicationsEffects = () => {
  const dispatch = useAppDispatch();
  const { organisationId } = useParams();

  const { filters, pagination } = useAppSelector(selectOrganisationUsers);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (organisationId) {
      dispatch(setFilters({ organisationId, status: UserStatus.PENDING }));
    }
  }, [organisationId]);

  useEffectAfterInitialLoad(() => {
    dispatch(getOrganisationUsers());
  }, [filters, pagination.offset]);
};
