import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from 'dayjs';

import styles from './DatePickerInputField.module.scss';

export default function DatePickerInputField(props) {
  const onChange = (value) => {
    props.setFieldValue(props.name, dayjs(value));
  };

  const helperText = props.error ? props.errorHelperText : props.helperText;

  return (
    <FormControl fullWidth error={props.error}>
      <LocalizationProvider dateAdapter={AdapterDayjs} error={props.error}>
        <DatePicker
          name={props.name}
          label={props.label}
          value={dayjs.isDayjs(props.value) ? props.value : dayjs(props.value)}
          onChange={onChange}
          onBlur={props.onBlur}
          className={styles.input}
          required={props.required}
          maxDate={props.maxDate && dayjs(props.maxDate)}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: 'outlined',
              error: !!props.error,
            },
          }}
        />
      </LocalizationProvider>
      <FormHelperText className={styles.helperText}>{helperText}</FormHelperText>
    </FormControl>
  );
}
