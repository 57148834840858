import React, { useState, useEffect } from 'react';

import styles from './AdminContactUsRequestPage.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Avatar, ButtonGroup, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useFormik } from 'formik';
import Chip from '../../../components/chips/Chip/Chip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { toast } from 'react-toastify';

import Button from '../../../components/button/Button';
import ContactUsService from '../../../services/api/ContactUsService';
import { ContactUsStatus, ContactUsStatusMap } from '../../../constants/contact-us-request.constants';
import UserProfileWrapper from '../../../components/user-profile/UserProfileWrapper';
import UserProfileItem from '../../../components/user-profile/UserProfileItem';

export default function AdminContactUsRequestPage() {
  const [contactUsRequest, setContactUsRequest] = useState({});
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const params = useParams();

  useEffect(() => {
    const fetchInitialData = async () => {
      const { body } = await ContactUsService.getRequest(params.contactUsRequestId);
      setContactUsRequest(body);
    };
    params.contactUsRequestId && fetchInitialData();
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const options = [
    { name: ContactUsStatus.COMPLETED, label: 'Complete' },
    { name: ContactUsStatus.CANCELLED, label: 'Cancell' },
  ];

  const formik = useFormik({
    initialValues: { notes: '', status: '' },
    onSubmit: async (values, { resetForm }) => {
      await handleStatusUpdate({
        notes: values.notes || undefined,
        status: options[selectedIndex].name,
      });

      toast.success('Request was successfully updated');

      resetForm();
    },
  });

  const handleStatusUpdate = async (updates) => {
    await ContactUsService.updateRequest(contactUsRequest.id, updates);

    setContactUsRequest({ ...contactUsRequest, status: updates.status });
  };

  const handleDelete = async () => {
    await ContactUsService.deleteRequest(contactUsRequest.id);
    toast.success('Request was successfully deleted');

    navigate('/internal/admin/contact-us-requests');
  };

  const displayContactUsRequestActions = () => {
    return (
      <form className={styles.formWrapper} onSubmit={formik.handleSubmit}>
        <Button
          type=""
          className={styles.deleteButton}
          onClick={(e) => {
            e.preventDefault();
            handleDelete();
          }}
        >
          Delete
        </Button>
        <ButtonGroup className={styles.buttonGroup} variant="contained" aria-label="split button">
          <Button
            loading={formik.isSubmitting}
            variant="contained"
            type="submit"
            className={styles.actionButton}
            refAnchor={anchorRef}
          >
            {options[selectedIndex].label}
          </Button>
          <Button
            className={styles.optionsButton}
            type="none"
            disabled={formik.isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              setOpen((prevOpen) => !prevOpen);
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popper
          sx={{ zIndex: 1, width: 'max-content' }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
                width: anchorRef.current ? anchorRef.current.offsetWidth : null,
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.name}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </form>
    );
  };

  const contactUsRequestStatus = ContactUsStatusMap[contactUsRequest.status];

  return (
    <Box component="main" className={styles.wrapper}>
      <UserProfileWrapper
        title={
          <div className={styles.header}>
            <IconButton onClick={() => navigate(`/internal/admin/contact-us-requests`)}>
              <ChevronLeftIcon />
            </IconButton>{' '}
            Contact Us Request
          </div>
        }
      >
        <div className={styles.contentWrapper}>
          <UserProfileItem title="Name">
            <div className={styles.profileWrapper}>
              <div className={styles.profile}>
                <Avatar>
                  {contactUsRequest?.firstName && contactUsRequest?.firstName[0]}
                  {contactUsRequest?.lastName && contactUsRequest?.lastName[0]}
                </Avatar>
                <Typography>
                  {contactUsRequest.firstName} {contactUsRequest.lastName}
                </Typography>
              </div>
              {displayContactUsRequestActions()}
            </div>
          </UserProfileItem>

          <UserProfileItem title="Status">
            <div className={styles.item}>
              {contactUsRequestStatus && (
                <Chip
                  sx={{ textAlign: 'left' }}
                  color={contactUsRequestStatus.color}
                  label={contactUsRequestStatus.label}
                />
              )}
            </div>
          </UserProfileItem>

          <UserProfileItem title="Created at">
            <div className={styles.item}>
              {dayjs(contactUsRequest?.createdAt).format('MMM DD YYYY H:mm A') || 'N/A'}
            </div>
          </UserProfileItem>

          <UserProfileItem title="Email address">
            <div className={styles.item}>{contactUsRequest.email}</div>
          </UserProfileItem>
          <UserProfileItem title="Message">
            <div className={styles.item}>{contactUsRequest.message}</div>
          </UserProfileItem>
        </div>
      </UserProfileWrapper>
    </Box>
  );
}
