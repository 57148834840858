import * as yup from 'yup';
import React from 'react';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';

import MdtOnboardingStepWrapper from './components/MdtOnboardingStepWrapper';
import TextInputField from '../../../../../components/text-input-field/TextInputField';
import { useAuthContext } from '../../../../../hooks/useAuthContext';
import UserService from '../../../../../services/api/UserService';
import { objectDiff } from '../../../../../utils/objectDiff';
import MdtOnboardingHelperText from './components/MdtOnboardingHelperText';
import SelectInputField from '../../../../../components/select-input-field/SelectInputField';
import { MenuItem } from '@mui/material';
import { countries, specialities } from '../../../../public/sign-up/sign-up-form/SignUpForm.constants';
import { toast } from 'react-toastify';
import parsePhoneNumber from 'libphonenumber-js'
import countriesI18n from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'; // Import the language file
import SelectAutocompleteInputField from '../../../../../components/select-autocomplete-input-field /SelectAutocompleteInputField';

countriesI18n.registerLocale(enLocale);

const validationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .min(8, `Invalid Phone Number length`)
    .matches(/\d+/, 'Please start your phone number with your country code')
    .required('Phone Number field is required'),
  speciality: yup.string().required(`Invalid Speciality field`),
  country: yup.string().required(`Invalid Country field`),
});

const getInitialValues = (profile) => {
  return {
    phoneNumber: profile?.phoneNumber || '',
    speciality: profile?.speciality || '',
    country: profile?.address.country || '',
  };
};

export default function PersonalInformationStep({ profile, setProfile, handleNext }) {
  const { user } = useAuthContext();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(profile),
    validationSchema,
    onSubmit: async (values) => {
      try {
        const countryCode = countriesI18n.getAlpha2Code(values.country, 'en') || 'GB'
        values.phoneNumber = parsePhoneNumber(values.phoneNumber, countryCode)?.number || values.phoneNumber;
  
        const changes = objectDiff(formik.initialValues, values);

        if (Object.keys(changes).length) {
          await UserService.updateProfile(user.id, { ...changes, ...(changes.country ? { address: { country: changes.country }} : {}) });
        }

        setProfile({ ...profile, ...values, address: { ...profile.address, country: values.country } });

        handleNext();
      } catch(error) {
        toast.error(` ${error?.message || 'Failed to changed password.'}`)
      }
    },
  });

  return (
    <MdtOnboardingStepWrapper name="Contact & Speciality Information" formik={formik} backDisabled>
      <Grid item xs={12}>
        <SelectAutocompleteInputField
          label="Speciality"
          name="speciality"
          value={formik.values.speciality}
          defaultValue={formik.values.speciality}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.speciality && Boolean(formik.errors.speciality)}
          errorHelperText={formik.errors.speciality}
          options={specialities.map((specialty) => specialty)}
        />
      </Grid>

      <Grid item xs={12}>
          <SelectInputField
            label="Country"
            name="country"
            value={formik.values.country}
            defaultValue={formik.values.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.country && Boolean(formik.errors.country)}
            errorHelperText={formik.errors.country}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </SelectInputField>
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            label="Phone Number"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            errorHelperText={formik.errors.phoneNumber}
          />
        </Grid>


      <MdtOnboardingHelperText />
    </MdtOnboardingStepWrapper>
  );
}
