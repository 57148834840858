import ModeIcon from '@mui/icons-material/Mode';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import { OnboardingApplicationStatus } from '../types/enums/OnboardingApplicationStatus';

export const UserProfileStatus = {
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  BLOCKED: 'BLOCKED',
};

export const FormattedUserProfileStatus = {
  [UserProfileStatus.DRAFT]: 'Draft',
  [UserProfileStatus.IN_REVIEW]: 'In Review',
  [UserProfileStatus.APPROVED]: 'Approved',
  [UserProfileStatus.REJECTED]: 'Rejected',
  [UserProfileStatus.CHANGES_REQUESTED]: 'Changes Requested',
  [UserProfileStatus.BLOCKED]: 'Blocked',
};

export const UserProfileStatusMap = {
  [UserProfileStatus.IN_REVIEW]: {
    label: 'In Review',
    icon: <PendingIcon />,
    color: 'warning',
  },
  [UserProfileStatus.DRAFT]: {
    label: 'Draft',
    icon: <ModeIcon fontSize="small" />,
    color: 'default',
  },
  [UserProfileStatus.CHANGES_REQUESTED]: {
    label: 'Changes Requested',
    icon: <ModeIcon fontSize="small" />,
    color: 'info',
  },
  [UserProfileStatus.APPROVED]: {
    label: 'Approved',
    icon: <CheckCircleIcon />,
    color: 'success',
  },
  [UserProfileStatus.REJECTED]: {
    label: 'Rejected',
    icon: <CancelIcon />,
    color: 'error',
  },
};


export const OnboardingApplicationStatusMap = {
  [OnboardingApplicationStatus.MDT_STANDARD]: {
    label: 'MDT',
    icon: <CheckCircleIcon />,
    color: 'success',
  },
  [OnboardingApplicationStatus.MDT_STANDARD_PENDING]: {
    label: 'MDT Pending',
    icon: <PendingIcon />,
    color: 'warning',
  },
  [OnboardingApplicationStatus.RESTRICTED_STANDARD]: {
    label: 'Basic Onboarding',
    icon: <CheckCircleIcon />,
    color: 'success',
  },
  [OnboardingApplicationStatus.RESTRICTED_STANDARD_PENDING]: {
    label: 'Basic Onboarding Pending',
    icon: <PendingIcon />,
    color: 'warning',
  },
};


export const UserGroupRole = {
  OWNER: 'OWNER',
  MANAGER: 'MANAGER',
  DEFAULT: 'DEFAULT',
};

export const UserScope = {
  ADMIN: 'admin',
  BASIC: 'basic',
  BASIC_RESTRICTED: 'basic-restricted'
};

export const UserRole = {
  ADMIN: 'ADMIN',
  DEFAULT: 'DEFAULT',
};

export const UserStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
  PENDING: 'PENDING',
};

export const UserStatusMap = {
  [UserStatus.PENDING]: {
    label: 'Pending',
    color: 'warning',
  },
  [UserStatus.INACTIVE]: {
    label: 'Inactive',
    color: 'error',
  },
  [UserStatus.DELETED]: {
    label: 'Deleted',
    color: 'error',
  },
  [UserStatus.ACTIVE]: {
    label: 'Active',
    color: 'success',
  },
};

export const FormattedUserStatus = {
  INACTIVE: 'Inactive',
  PENDING: 'Pending',
  ACTIVE: 'Active',
};

export const MdtPermissions = {
  CREATE_GROUP: 'create:group',
  READ_GROUP: 'read:group',
  DELETE_GROUP: 'delete:group',
  EDIT_GROUP: 'edit:group',
  LEAVE_GROUP: 'leave:group',

  CREATE_GROUP_MEMBER: 'create:group-member',
  DELETE_GROUP_MEMBER: 'delete:group-member',
  EDIT_GROUP_MEMBER: 'edit:group-member',
  READ_GROUP_MEMBER: 'read:group-member',

  READ_THREAD: 'create:thread',
  CREATE_THREAD: 'create:thread',
  EDIT_THREAD: 'edit:thread',
  DELETE_THREAD: 'delete:thread',
  PRINT_THREAD: 'print:thread',
  DOWNLOAD_THREAD: 'download:thread',

  CREATE_THREAD_ASSIGNMENT: 'create:thread-assignment',
  DELETE_THREAD_ASSIGNMENT: 'delete:thread-assignment',

  CREATE_MESSAGE: 'create:message',
  CREATE_REVIEW: 'create:review',
  EDIT_MESSAGE: 'edit:message',
  DELETE_MESSAGE: 'delete:message',
};
