import React from 'react';

import List from '@mui/material/List';
import styles from './GroupsMenuList.module.scss';
import GroupsMenuListItem from './GroupsMenuListItem';
import { GroupType } from '../../../types/enums/GroupType';
import { partition } from '../../../utils/array';
import { OrganisationCategory } from '../../../types/enums/OrganisationCategory';
import { Divider, ListSubheader } from '@mui/material';

export default function GroupsMenuList({ groups, filteredGroups, type }) {
  const getListItems = () => {
    if (!groups) {
      return new Array(4).fill(<GroupsMenuListItem group={null} />);
    }

    if (!filteredGroups) {
      return null;
    }

    if (type === GroupType.MDT.toLowerCase()) {
      const [sharedMDTs, privateMDTs] = partition(
        filteredGroups,
        ({ category }) => category === OrganisationCategory.SHARED,
      );

      return (
        <>
          {!!privateMDTs?.length && (
            <>
              <Divider />
              <ListSubheader className={styles.subheader}>Private</ListSubheader>
              <Divider />
            </>
          )}
          {privateMDTs?.map((group) => (
            <GroupsMenuListItem key={group.id} group={group} type={type} />
          ))}

          {!!sharedMDTs?.length && (
            <>
              <ListSubheader className={styles.subheader}>Shared</ListSubheader>
              <Divider />
            </>
          )}
          {sharedMDTs?.map((group) => (
            <GroupsMenuListItem key={group.id} group={group} type={type} />
          ))}
        </>
      );
    }

    return filteredGroups?.map((group) => <GroupsMenuListItem key={group.id} group={group} type={type} />);
  };

  return <List className={styles.list}>{getListItems()}</List>;
}
