import React from 'react';
import VerificationInput from 'react-verification-input';

import classes from './VerificationCodeInput.module.scss';

interface Props {
  value: string;
  onChange: (value: string) => void;
  onComplete?: (value: string) => void;
  autoFocus?: boolean;
  className?: string;
}

const VerificationCodeInput: React.FC<Props> = (props) => {
  return (
    <VerificationInput
      validChars="0-9"
      classNames={{
        container: props.className,
        character: classes.character,
        characterSelected: classes.characterSelected,
        characterInactive: classes.characterInactive,
      }}
      {...props}
    />
  );
};

export default VerificationCodeInput;
