import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceName } from '../../config';
import PermissionsService from '../../../services/api/PermissionsService';

export const getPermissions = createAsyncThunk(
  `${SliceName.PERMISSIONS}/getPermissions`,
  async () => {
    const permissions = await PermissionsService.getPermissions();

    return permissions;
  },
);

