import React, { useEffect, useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import { Avatar, BottomNavigationAction, Button, SwipeableDrawer } from '@mui/material';
import { NavLink, useLocation, useMatch, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { MoreVertRounded } from '@mui/icons-material';

import styles from './MobileNavigation.module.scss';
import SidebarItem from '../sidebar/components/SidebarItem';
import SidebarSignoutIcon from '../sidebar/components/icons/SidebarSignoutIcon';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectProfile } from '../../../store/slices/profile/profileSlice';
import { signOut } from '../../../store/slices/auth/authThunks';
import Logo from '../../ui/Logo/Logo';

const NavigationLink = React.forwardRef((props, ref) => {
  const match = useMatch({ path: props.to, exact: true, end: false });

  if (match?.pattern?.exact) {
    return (
      <div ref={ref} className={classNames({ [styles.activeLinkClass]: match?.pattern?.exact })}>
        <NavLink {...props} />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <NavLink {...props} />
    </div>
  );
});

export default function MobileNavigation({ children, navItems }) {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { profile } = useAppSelector(selectProfile);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [signOutLoading, setSignOutLoading] = useState(false);

  const handleOpenDrawer = () => setIsDrawerOpen(true);
  const handleCloseDrawer = () => setIsDrawerOpen(false);

  useEffect(() => {
    handleCloseDrawer();
  }, [pathname]);

  const handleSignOut = async () => {
    try {
      setSignOutLoading(true);
      await dispatch(signOut()).unwrap();
      navigate('/');
      setSignOutLoading(false);
    } catch (error) {
      // ignore error
    }
  };

  const renderedNavItems = navItems.length > 4 ? navItems.slice(0, 4) : navItems;
  const drawerNavItems = navItems.length > 4 ? navItems.slice(4) : [];

  return (
    <div className={styles.wrapper}>
      <BottomNavigation className={styles.bottomNavigation} showLabels>
        {renderedNavItems.map((item) => (
          <BottomNavigationAction
            component={item.to && NavigationLink}
            className={styles.navAction}
            to={item.to}
            onClick={item.onClick}
            label={item.text}
            icon={item.icon}
            key={item.to || item.text}
          />
        ))}

        {navItems.length > 4 ? (
          <BottomNavigationAction
            icon={<MoreVertRounded />}
            onClick={handleOpenDrawer}
            className={styles.navAction}
            label="More"
          />
        ) : (
          <BottomNavigationAction
            component={NavigationLink}
            className={styles.navAction}
            to="/internal/profile"
            label="Settings"
            icon={<SettingsRoundedIcon viewBox="2 2 20 20" sx={{ fill: '#5D7285' }} />}
          />
        )}
      </BottomNavigation>

      {children}

      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        className={styles.drawer}
        classes={{
          paper: styles.drawerContent,
        }}
      >
        <div>
          <Logo className={styles.logo} />

          <SidebarItem
            open={open}
            to="/internal/profile"
            text={
              <span className={styles.profile}>
                {profile?.firstName} {profile?.lastName}
              </span>
            }
            icon={
              <Avatar className={styles.avatar}>
                {profile?.firstName?.slice(0, 1)}
                {profile?.lastName?.slice(0, 1)}
              </Avatar>
            }
          />

          {drawerNavItems.map(({ component: Component, to }) => (
            <Component key={to} open />
          ))}

          <SidebarItem
            open={open}
            text="Logout"
            loading={signOutLoading}
            className={styles.signout}
            icon={<SidebarSignoutIcon />}
            onClick={handleSignOut}
          />
        </div>

        <Button onClick={handleCloseDrawer} fullWidth>
          Close
        </Button>
      </SwipeableDrawer>
    </div>
  );
}
