import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { Profile } from '../../../types/Profile';
import { getProfile } from './profileThunks';

interface ProfileState {
  profile: Profile | null;
  error: string | null | undefined;
  loading: boolean;
}

const initialState: ProfileState = {
  profile: null,
  loading: false,
  error: null,
};

const profileSlice = createSlice({
  name: SliceName.PROFILE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
    });

    builder.addCase(getProfile.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
