import React from 'react';
import styles from './GroupEditMenuItem.module.scss';
import classNames from 'classnames';

import { ListItem, ListItemButton, ListItemText } from '@mui/material';

export default function GroupEditMenuItem({ title, onClick, type, disabled }) {
  return (
    <ListItem className={styles.listItem} sx={{ display: 'block' }}>
      <ListItemButton
        disabled={disabled}
        className={classNames(styles.button, { [styles.leaveButton]: type === 'leave' })}
        onClick={onClick}
      >
        <ListItemText className={classNames(styles.text, { [styles.leaveText]: type === 'leave' })} primary={title} />
      </ListItemButton>
    </ListItem>
  );
}
