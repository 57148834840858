import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { FormHelperText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styles from './UsersPage.module.scss';
import UserService from '../../../services/api/UserService';
import Search from '../../../components/search/Search';
import { debounce } from '../../../utils/debounce';
import { getUsersSearchParams } from '../../../utils/userSearch';
import { config } from '../../../configs/config';
import FilterSelect from '../../../components/filter-select/FilterSelect';
import { countries, specialities } from '../../public/sign-up/sign-up-form/SignUpForm.constants';
import Pagination from '../../../components/pagination/Pagination';
import { UserStatus } from '../../../constants/user.constants';
import { useNavigate } from 'react-router-dom';
import Chip from '../../../components/chips/Chip/Chip';
import { useLoader } from '../../../hooks/useLoader';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';

import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';

export default function UsersPage() {
  const [users, setUsers] = useState(null);
  const [filterValues, setFilterValues] = useState({
    speciality: '',
    country: '',
    firstName: '',
    lastName: '',
    email: '',
  });
  const [page, setPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const loader = useLoader();

  const getUsers = async (values = filterValues) => {
    const { body, headers } = await UserService.getUsers({
      ...values,
      embed: ['address'].join(','),
      status: UserStatus.ACTIVE,
      limit: config.pagination.usersPageSize,
      offset: (page - 1) * config.pagination.usersPageSize,
    });

    setUsers(body);
    setTotalUsers(headers['x-total-count']);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      loader.startLoading();
      await getUsers();
      loader.stopLoading();
    };

    fetchInitialData();
  }, [page, filterValues]);

  const onSearchChange = async (event) => {
    const term = event.target.value;
    const values = { ...filterValues, ...getUsersSearchParams(term) };

    setPage(1);
    setSearch(term);
    setFilterValues(values);

    await getUsers(values);
  };

  const handleFilterChange = async (event) => {
    const values = { ...filterValues, [event.target.name]: event.target.value };

    setPage(1);
    setFilterValues(values);

    await getUsers(values);
  };

  const handleSpecialityFilterChange = (value) => {
    const target = { name: 'speciality', value };
    handleFilterChange({ target });
  };

  const displayUser = (user) => {
    if (user == null) {
      return (
        <TableRow>
          <TableCell width={300} className={styles.tableCell}>
            <Skeleton variant="rounded" height="1rem" width="7rem" />
          </TableCell>
          <TableCell width={300} className={styles.tableCell}>
            <Skeleton variant="rounded" height="1rem" width="8rem" />
          </TableCell>
          <TableCell width={300} className={classNames(styles.tableCell, styles.desktopOnly)}>
            <Skeleton variant="rounded" height="1rem" width="10rem" />
          </TableCell>
          <TableCell width={200} className={classNames(styles.tableCell, styles.desktopOnly)}>
            <Skeleton variant="rounded" height="1rem" width="8rem" />
          </TableCell>
          <TableCell width={200} className={styles.tableCell}>
            <Skeleton variant="rounded" height="1.5rem" width="3rem" />
          </TableCell>
          <TableCell width={100} className={styles.tableCell}></TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow>
        <TableCell width={300} className={styles.tableCell}>
          {user.firstName}
        </TableCell>
        <TableCell width={300} className={styles.tableCell}>
          {user.lastName}
        </TableCell>
        <TableCell width={300} className={classNames(styles.tableCell, styles.desktopOnly)}>
          {user.email}
        </TableCell>
        <TableCell width={200} className={classNames(styles.tableCell, styles.desktopOnly)}>
          {user?.address?.country}
        </TableCell>
        <TableCell width={200} sx={{ p: '0rem 1rem' }} className={styles.tableCell}>
          <Chip label={user.speciality} onClick={() => handleSpecialityFilterChange(user.speciality)} />
        </TableCell>

        <TableCell width={100} sx={{ p: '0rem 1rem' }} className={styles.tableCell}>
          <IconButton onClick={() => navigate(`/internal/users/${user.id}`)}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const displayUsers = () => {
    if (users === null) {
      return new Array(10).fill().map(() => displayUser(null));
    }

    return users.map((user) => displayUser(user));
  };

  const debouncedOnSearchChange = debounce(onSearchChange, 1000);

  return (
    <Box component="main" className={styles.wrapper}>
      <div>
        <Box className={styles.actionsWrapper}>
          <div className={styles.actions}>
            <div className={styles.search}>
              <Search
                placeholder="Search for a user"
                value={search}
                onChange={(event) => debouncedOnSearchChange(event)}
              />
            </div>
            <div className={styles.filters}>
              <FilterSelect
                onChange={handleFilterChange}
                label="Speciality"
                name="speciality"
                value={filterValues.speciality}
                className={styles.specialityFilter}
                options={specialities.map((speciality) => ({
                  name: speciality.toLocaleLowerCase(),
                  label: speciality,
                }))}
              />
              <FilterSelect
                onChange={handleFilterChange}
                label="Country"
                name="country"
                value={filterValues.country}
                className={filterValues.countryFilter}
                options={countries.map((speciality) => ({ name: speciality, label: speciality }))}
              />
            </div>
          </div>
          <FormHelperText className={styles.listItemsCount}>Total Amount of Users: {totalUsers}</FormHelperText>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell className={classNames(styles.desktopOnly)}>Email</TableCell>
                <TableCell className={classNames(styles.desktopOnly)}>Country</TableCell>
                <TableCell>Speciality</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{displayUsers()}</TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className={styles.pagination}>
        <Pagination
          count={Math.ceil(totalUsers / config.pagination.usersPageSize)}
          page={parseInt(page)}
          onChange={(_, value) => setPage(value)}
        />
      </div>
    </Box>
  );
}
