import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import React from 'react';

import styles from './WysiwygEditor.module.scss';
import classNames from 'classnames';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';

export default function WysiwygEditor(props) {
  const getHelperEditorText = () => {
    if (props.error) return props.errorHelperText;

    return `${
      typeof props.editorState === 'string' ? 0 : props.editorState.getCurrentContent().getPlainText().length
    }/3000`;
  };

  return (
    <FormControl fullWidth error={props.error}>
      <Editor
        id={props.id}
        name={props.name}
        editorState={props.editorState}
        onEditorStateChange={props.onEditorStateChange}
        wrapperClassName={classNames(styles.editorWrapper, { [styles.error]: props.error })}
        editorClassName={styles.editor}
        toolbarClassName={styles.toolbar}
        stripPastedStyles={true}
        spellCheck={true}
        placeholder="Write a text here..."
        toolbar={{
          options: ['inline', 'emoji'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
        }}
      />
      <FormHelperText className={styles.helperText}>{getHelperEditorText()}</FormHelperText>
    </FormControl>
  );
}
