import dayjs from 'dayjs';

export const isOlderThan = (date: Date, editPeriod = 3600) => {
  const now = new Date();
  const oneHourInMs = editPeriod * 1000;

  return now.getTime() - date.getTime() > oneHourInMs;
};

export const formatDateLong = (date: string) => (date ? dayjs(date).format('ddd, MMM DD YYYY H:mm A') : '');

export const formatDate = (date: string) => (date ? dayjs(date).format('MMM DD YYYY') : '');
