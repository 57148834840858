import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import {
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import styles from './AdminUsersPage.module.scss';
import UserService from '../../../services/api/UserService';
import Search from '../../../components/search/Search';
import { debounce } from '../../../utils/debounce';
import { getUsersSearchParams } from '../../../utils/userSearch';
import { config } from '../../../configs/config';
import FilterSelect from '../../../components/filter-select/FilterSelect';
import { countries, specialities } from '../../public/sign-up/sign-up-form/SignUpForm.constants';
import Pagination from '../../../components/pagination/Pagination';
import { UserStatusMap } from '../../../constants/user.constants';
import { useNavigate } from 'react-router-dom';
import Chip from '../../../components/chips/Chip/Chip';
import { useLoader } from '../../../hooks/useLoader';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';

export default function AdminUsersPage() {
  const [users, setUsers] = useState([]);
  const [filterValues, setFilterValues] = useState({
    speciality: '',
    country: '',
    firstName: '',
    lastName: '',
    email: '',
    status: '',
  });
  const [page, setPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const loader = useLoader();

  const getUsers = async (values = filterValues) => {
    const { body, headers } = await UserService.getUsers({
      ...values,
      embed: ['address'].join(','),
      limit: config.pagination.usersPageSize,
      offset: (page - 1) * config.pagination.usersPageSize,
    });

    setUsers(body);
    setTotalUsers(headers['x-total-count']);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      loader.startLoading();
      await getUsers();

      loader.stopLoading();
    };

    fetchInitialData();
  }, [page, filterValues]);

  const onSearchChange = async (event) => {
    const term = event.target.value;
    const values = { ...filterValues, ...getUsersSearchParams(term) };

    setPage(1);
    setSearch(term);
    setFilterValues(values);

    await getUsers(values);
  };

  const debouncedOnSearchChange = debounce(onSearchChange, 1000);

  const handleFilterChange = async (event) => {
    const values = { ...filterValues, [event.target.name]: event.target.value };

    setPage(1);
    setFilterValues(values);

    await getUsers(values);
  };

  const handleButtonFilterChange = ({ name, value }) => {
    const target = { name, value };
    handleFilterChange({ target });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box component="main" className={styles.wrapper}>
      <div>
        <Box className={styles.actionsWrapper}>
          <div className={styles.actions}>
            <div className={styles.search}>
              <Search
                placeholder="Search for a user"
                value={search}
                onChange={(event) => debouncedOnSearchChange(event)}
              />
            </div>
            <div className={styles.filters}>
              <FilterSelect
                onChange={handleFilterChange}
                label="Status"
                name="status"
                value={filterValues.status}
                options={[
                  { name: 'ACTIVE', label: 'Active' },
                  { name: 'INACTIVE', label: 'Inactive' },
                  { name: 'PENDING', label: 'Pending' },
                ]}
              />
              <FilterSelect
                onChange={handleFilterChange}
                label="Speciality"
                name="speciality"
                value={filterValues.speciality}
                options={specialities.map((speciality) => ({
                  name: speciality.toLocaleLowerCase(),
                  label: speciality,
                }))}
              />
              <FilterSelect
                onChange={handleFilterChange}
                label="Country"
                name="country"
                value={filterValues.country}
                options={countries.map((speciality) => ({ name: speciality, label: speciality }))}
              />
            </div>
          </div>
          <FormHelperText className={styles.listItemsCount}>Total Amount of Users: {totalUsers}</FormHelperText>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Speciality</TableCell>
                <TableCell>Country</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell width={350} className={styles.tableCell}>
                    {user.id}
                  </TableCell>
                  <TableCell width={200} className={styles.tableCell}>
                    {user.firstName}
                  </TableCell>
                  <TableCell width={200} className={styles.tableCell}>
                    {user.lastName}
                  </TableCell>
                  <TableCell width={250} className={styles.tableCell}>
                    {user.email}
                  </TableCell>
                  <TableCell align="left" className={styles.tableCell}>
                    {UserStatusMap[user.status] && (
                      <Typography sx={{ display: 'flex', justifyContent: 'left' }}>
                        <Chip
                          sx={{ textAlign: 'left', width: '92px' }}
                          color={UserStatusMap[user.status].color}
                          label={UserStatusMap[user.status].label}
                          onClick={() => handleButtonFilterChange({ name: 'status', value: user.status })}
                        />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell sx={{ p: '0px 16px' }} className={styles.tableCell}>
                    <Chip
                      label={user.speciality}
                      onClick={() => handleButtonFilterChange({ name: 'speciality', value: user.speciality })}
                    />
                  </TableCell>
                  <TableCell className={styles.tableCell}>{user?.address?.country}</TableCell>
                  <TableCell width={100} sx={{ p: '0px 16px' }} className={styles.tableCell}>
                    <IconButton onClick={() => navigate(`/internal/admin/users/${user.id}`)}>
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className={styles.pagination}>
        <Pagination
          count={Math.ceil(totalUsers / config.pagination.usersPageSize)}
          page={parseInt(page)}
          onChange={(event, value) => handlePageChange(event, value)}
        />
      </div>
    </Box>
  );
}
