import Box from '@mui/material/Box';

import styles from './DashboardPage.module.scss';
import DasboardPersonalThreads from './components/DasboardPersonalThreads';
import DasboardStats from './components/DasboardStats';
import DasboardUsage from './components/DasboardUsage';

export default function DashboardPage() {
  return (
    <Box component="main" className={styles.wrapper}>
      <div className={styles.dashboardContainer}>
        <DasboardStats />
        <DasboardUsage />
      </div>

      <DasboardPersonalThreads />
    </Box>
  );
}
