import SvgIcon from '@mui/material/SvgIcon';

export default function SidebarHomeIcon() {
  return (
    <SvgIcon>
      <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.00521 22.9296V19.2C9.00521 18.2479 9.78265 17.4761 10.7417 17.4761H14.2474C14.7079 17.4761 15.1496 17.6578 15.4753 17.981C15.8009 18.3043 15.9839 18.7428 15.9839 19.2V22.9296C15.9809 23.3254 16.1373 23.706 16.4182 23.9869C16.6991 24.2678 17.0813 24.4258 17.4801 24.4258H19.8718C20.9888 24.4286 22.0611 23.9901 22.852 23.207C23.6429 22.4239 24.0874 21.3606 24.0874 20.2516V9.62666C24.0874 8.73089 23.6874 7.88121 22.9953 7.30651L14.859 0.855625C13.4436 -0.275436 11.4158 -0.238917 10.0427 0.942359L2.09209 7.30651C1.36724 7.86427 0.934007 8.71647 0.912598 9.62666V20.2408C0.912598 22.5521 2.79998 24.4258 5.12818 24.4258H7.46532C8.29343 24.4258 8.96644 23.7625 8.97244 22.9404L9.00521 22.9296Z"
          fill="#5D7285"
        />
      </svg>
    </SvgIcon>
  );
}
