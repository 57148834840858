export const MessageType = {
  DEFAULT: 'DEFAULT',
  REVIEW: 'REVIEW',
};

export const MessageStatus = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
};

export const FormattedMessageStatus = {
  [MessageStatus.APPROVED]: 'Approved',
  [MessageStatus.REJECTED]: 'Rejected',
  [MessageStatus.CHANGES_REQUESTED]: 'Changes Requested',
};
