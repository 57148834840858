import * as yup from 'yup';
import React from 'react';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';

import MdtOnboardingStepWrapper from './components/MdtOnboardingStepWrapper';
import TextInputField from '../../../../../components/text-input-field/TextInputField';
import { useAuthContext } from '../../../../../hooks/useAuthContext';
import MdtOnboardingDocumentUploadField, {
  onboardingDocumentValidation,
  uploadOnboardingDocument,
} from './components/MdtOnboardingDocumentUploadField';
import UserService from '../../../../../services/api/UserService';
import { objectDiff } from '../../../../../utils/objectDiff';
import MdtOnboardingHelperText from './components/MdtOnboardingHelperText';

const validationSchema = yup.object().shape({
  addressLine1: yup
    .string()
    .max(100, 'Address cannot be longer than 100 characters')
    .min(5, 'Address must be at least 5 characters')
    .required('Address Line field is required'),
  city: yup
    .string()
    .max(50, 'City cannot be longer than 50 characters')
    .min(2, 'City should have at least 2 characters')
    .required('City field is required'),
  region: yup
    .string()
    .max(50, 'Region cannot be longer than 50 characters')
    .min(2, 'Region should have at least 2 characters')
    .nullable(),
  country: yup
    .string()
    .max(50, 'Country cannot be longer than 50 characters')
    .min(2, 'Country should have at least 2 characters')
    .required('Country field is required'),
  zipCode: yup
    .string()
    .max(10, 'Postal / Zip Code cannot be longer than 10 digits')
    .min(5, 'Postal / Zip Code should have at least 5 digits')
    .required('Postal / Zip Code field is required'),
  document: onboardingDocumentValidation,
});

const getInitialValues = (profile) => {
  return {
    addressLine1: profile?.address.addressLine1 || '',
    addressLine2: profile?.address.addressLine2 || '',
    city: profile?.address.city || '',
    region: profile?.address.region || '',
    country: profile?.address.country || '',
    zipCode: profile?.address.zipCode || '',
    document: profile?.address.document || null,
  };
};

export default function AddressStep({ profile, setProfile, handleNext, handleBack }) {
  const { user } = useAuthContext();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(profile),
    validationSchema,
    onSubmit: async (values) => {
      await uploadOnboardingDocument(formik.values.document, 'address');

      const changes = objectDiff(formik.initialValues, values, ['document']);
      if (Object.keys(changes).length) {
        await UserService.updateProfile(user.id, { address: changes });
      }

      setProfile({ ...profile, address: { ...values } });
      handleNext();
    },
  });

  return (
    <MdtOnboardingStepWrapper name="Verify Place of Registration/Medical Practise" formik={formik} handleBack={handleBack}>
      <Grid item xs={12} sm={12}>
        <TextInputField
          label="Address Line 1"
          name="addressLine1"
          value={formik.values.addressLine1}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
          errorHelperText={formik.errors.addressLine1}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInputField
          label="Address Line 2"
          name="addressLine2"
          value={formik.values.addressLine2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
          errorHelperText={formik.errors.addressLine2}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInputField
          label="City"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.city && Boolean(formik.errors.city)}
          errorHelperText={formik.errors.city}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInputField
          label="Region / State"
          name="region"
          value={formik.values.region}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.region && Boolean(formik.errors.region)}
          errorHelperText={formik.errors.region}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInputField
          label="Postal / Zip Code"
          name="zipCode"
          value={formik.values.zipCode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
          errorHelperText={formik.errors.zipCode}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInputField
          label="Country"
          name="country"
          disabled
          value={formik.values.country}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.country && Boolean(formik.errors.country)}
          errorHelperText={formik.errors.country}
          required
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <MdtOnboardingDocumentUploadField
          description="To verify your address, please upload either a utility bill or a bank statement that has been issued within the last three months"
          buttonLabel="Attach Document"
          name="document"
          onBlur={formik.handleBlur}
          uploaded={profile.address.document}
          error={formik.touched.document && Boolean(formik.errors.document)}
          errorHelperText={formik.errors.document}
          setFieldValue={formik.setFieldValue}
          disabled={formik.isSubmitting}
        />
      </Grid>

      <MdtOnboardingHelperText />
    </MdtOnboardingStepWrapper>
  );
}
