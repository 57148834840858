import React, { useEffect } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { SplashScreen } from '@capacitor/splash-screen';
import * as LiveUpdates from '@capacitor/live-updates';

import { withPlatformSpecific } from '../HOCs/withPlatformSpecific/withPlatformSpecific';
import { config } from '../../configs/config';
import './NativeProvider.scss';

interface Props {
  children: React.ReactNode;
}

const initializeApp = async () => {
  const result = await LiveUpdates.sync();

  if (result.activeApplicationPathChanged) {
    await LiveUpdates.reload();
  } else {
    await SplashScreen.hide();
  }
};

if (config.isNative) {
  initializeApp();

  CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (!canGoBack) {
      CapacitorApp.exitApp();
    } else {
      window.history.back();
    }
  });
}

const NativeProvider: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`);
      }
    });
  }, []);

  return (
    <>
      {children}

      <div className="native-top-inset" />
      <div className="native-bottom-inset" />
    </>
  );
};

export default withPlatformSpecific({ NativeComponent: NativeProvider });
