import React from 'react';
import MuiButton from '@mui/material/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';

import classes from './ConfirmMultifactorAuthForm.module.scss';
import VerificationCodeInput from '../../inputs/VerificationCodeInput/VerificationCodeInput';
import Button from '../../button/Button';

const validationSchema = yup.object({
  code: yup
    .string()
    .length(6, 'Code should be exactly 6 characters')
    .matches(/^\d+$/, 'Code should contain only numbers')
    .required('Code is required'),
});

interface Props {
  onSubmit: (code: string) => Promise<void>;
  onCancel?: () => void;
}

const ConfirmMultifactorAuthForm: React.FC<Props> = ({ onSubmit, onCancel }) => {
  const { setFieldValue, values, handleSubmit, isSubmitting, isValid } = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: async (values) => {
      await onSubmit(values.code);
    },
    validationSchema,
    isInitialValid: false,
  });

  const handleCodeChange = (code: string) => {
    setFieldValue('code', code);
  };

  const handleComplete = async (code: string) => {
    await setFieldValue('code', code);

    handleSubmit();
  };

  return (
    <form onSubmit={handleSubmit} className={classes.container}>
      <VerificationCodeInput
        onChange={handleCodeChange}
        value={values.code}
        autoFocus
        onComplete={handleComplete}
        className={classes.input}
      />

      <Button type="submit" disabled={!isValid} loading={isSubmitting} fullWidth className={classes.button}>
        Submit
      </Button>

      {onCancel && (
        <MuiButton onClick={onCancel} fullWidth variant="text" className={classes.button}>
          Cancel
        </MuiButton>
      )}
    </form>
  );
};

export default ConfirmMultifactorAuthForm;
