import { useFormik } from 'formik';

import MdtOnboardingStepWrapper from './components/MdtOnboardingStepWrapper';
import MdtOnboardingHelperText from './components/MdtOnboardingHelperText';
import { SubscriptionStatus } from '../../../../../types/enums/SubscriptionStatus';
import ChosenMembershipPlan from '../../../../../components/chosen-membership-plan/ChosenMembershipPlan';
import SubscriptionPurchase from '../../../profile/components/SubscriptionsManagement/components/SubscriptionPurchase';

export default function MembershipPlanStep({ profile, handleNext }) {
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      handleNext();
    },
  });

  const subscription = profile?.subscriptions.findLast(subscription => [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRAILING].includes(subscription.status));

  return (
    <MdtOnboardingStepWrapper name="Choose Membership Plan" formik={formik}>
      {subscription ? <ChosenMembershipPlan plan={subscription.plan} /> : <SubscriptionPurchase />}
      <MdtOnboardingHelperText />
    </MdtOnboardingStepWrapper>
  );
}
