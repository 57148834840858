import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import TextInputField from '../text-input-field/TextInputField';

export default function PasswordInputField(props) {
  const [showPassword, setShowPassword] = useState(props.showPassword || false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextInputField
      type={showPassword ? 'text' : 'password'}
      label={props.label || 'Password'}
      id={props.id || 'password'}
      name={props.name || 'password'}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      error={props.error}
      errorHelperText={props.errorHelperText}
      helperText={props.helperText}
      className={props.className}
      inputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
