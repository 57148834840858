import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { getOrganisationUsers } from './organisationUsersThunks';
import { UserExtended } from '../../../types/User';
import { OrganisationUsersFilters } from '../../../types/OrganisationUsersFilters';
import { Pagination } from '../../../types/Pagination';

interface OrganisationUsersState {
  users: UserExtended[];
  filters: OrganisationUsersFilters;
  pagination: Pagination;
  error: string | null | undefined;
  isLoading: boolean;
}

const initialState: OrganisationUsersState = {
  users: [],
  filters: {
    organisationId: null,
    search: '',
    speciality: null,
    status: null,
  },
  pagination: {
    offset: 0,
    limit: 15,
    totalCount: null,
  },
  isLoading: true,
  error: null,
};

const organisationUsersSlice = createSlice({
  name: SliceName.ORGANISATION_USERS,
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<Partial<OrganisationUsersFilters>>) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
      state.pagination.offset = 0;
      state.pagination.totalCount = null;
    },
    setOffset(state, action: PayloadAction<number>) {
      state.pagination.offset = action.payload;
    },
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganisationUsers.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(getOrganisationUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload.items;
      state.pagination.totalCount = action.payload.totalCount;
    });

    builder.addCase(getOrganisationUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { setFilters, setOffset, clearState } = organisationUsersSlice.actions;

export const selectOrganisationUsers = (state: RootState) => state.organisationUsers;

export default organisationUsersSlice.reducer;
