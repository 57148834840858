import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import styles from './HowItWorks.module.scss';

const GuidelineItem = ({ number, title, description }) => {
  return (
    <div className={styles.guidelineItem}>
      <div className={styles.guidelineItemName}>
        <Typography className={styles.guidelineItemNumber}>{number}</Typography>
        <Typography className={styles.guidelineItemTitle}>{title}</Typography>
      </div>

      <Typography className={styles.guidelineItemDescription}>{description}</Typography>
    </div>
  );
};

export default function HowItWorks({ id }) {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.title}>
        <Typography component="h1" variant="h4">
          How It Works?
        </Typography>
        <div>
          A simple process from registration to the receipt of expert feedback and treatment solutions.
          Be part of the Medsym community. To share, learn and help treat unmet medical needs
        </div>
      </div>
      <div className={styles.guidelineContainer}>
        <div className={styles.guidlineLeft}>
          <GuidelineItem
            number="1"
            title="Registration"
            description={
              <>
                Fill out an easy <Link to="/sign-up" component={RouterLink} underline="none"> sign-up form</Link>
              </>
            }
          />

          <GuidelineItem
            number="2"
            title="Verification"
            description="Submit details proving you are a healthcare professional"
          />
        </div>
        <div className={styles.guidlineScreenshotContainer}>
          <div className={styles.guidlineScreenshot}></div>
        </div>
        <div className={styles.guidlineRight}>
          <GuidelineItem
            number="3"
            title="Start Chatting"
            description="Once verified you can begin and respond to conversations and trust that everyone you speak to is also verified"
          />
        </div>
      </div>
    </section>
  );
}
