import React from 'react';
import { Avatar, Typography } from '@mui/material';

import { UserDetails } from '../../../types/User';
import UserDetailsItem from '../UserDetailsItem/UserDetailsItem';
import classes from './UserDetailsView.module.scss';
import { getDisplayAddress } from '../../../utils/string';
import Chip from '../../chips/Chip/Chip';
import { formatDate, formatDateLong } from '../../../utils/date';
import OnboardingStatusChip from '../../chips/OnboardingStatusChip/OnboardingStatusChip';
import FileList from '../../ui/FileList/FileList';
import { formatCouncil } from '../../../utils/onboarding';

interface Props {
  userDetails: UserDetails;
  onDownloadFile: (url: string) => Promise<void>;
}

const UserDetailsView: React.FC<Props> = ({
  userDetails: {
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    speciality,
    mdtProfile: {
      medicalRegistrationNumber,
      medicalRegistrationNumberType,
      latestAppraisalDate,
      onboardingReviewComment,
      status,
      addressDocument,
      identityDocument,
      criminalBackgroundDocument,
      qualityCredentialDocument,
    },
    createdAt,
  },
  onDownloadFile,
}) => {
  const files = [addressDocument, identityDocument, criminalBackgroundDocument, qualityCredentialDocument].filter(
    Boolean,
  ) as string[];

  return (
    <div>
      <UserDetailsItem title="Name" contentClassName={classes.horizontal}>
        <Avatar className={classes.avatar}>
          {firstName[0]}
          {lastName[0]}
        </Avatar>

        <Typography>
          {firstName} {lastName}
        </Typography>
      </UserDetailsItem>

      <UserDetailsItem title="Email address">{email}</UserDetailsItem>

      <UserDetailsItem title="Phone number">{phoneNumber}</UserDetailsItem>

      <UserDetailsItem title="Place of Registration/Medical Practise">{getDisplayAddress(address)}</UserDetailsItem>

      <UserDetailsItem title="Speciality">
        <Chip label={speciality} />
      </UserDetailsItem>

      <UserDetailsItem title="Medical registration number">{formatCouncil(medicalRegistrationNumberType, medicalRegistrationNumber)}</UserDetailsItem>

      <UserDetailsItem title="Latest appraisal date">
        {latestAppraisalDate ? formatDate(latestAppraisalDate) : 'N/A'}
      </UserDetailsItem>

      <UserDetailsItem title="Created at">{formatDateLong(createdAt)}</UserDetailsItem>

      <UserDetailsItem title="Application status">
        <OnboardingStatusChip status={status} />
      </UserDetailsItem>

      <UserDetailsItem title="Latest onboarding review comment">{onboardingReviewComment || 'N/A'}</UserDetailsItem>

      <UserDetailsItem title="Uploaded documents">
        <FileList files={files} onFileClick={onDownloadFile} />
      </UserDetailsItem>
    </div>
  );
};

export default UserDetailsView;
