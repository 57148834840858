import { Plan } from '../../types/Plan';
import { Subscription } from '../../types/Subscription';
import ApiClient from './ApiClient';
import { ApiRoute } from './ApiRoute';
import { getResponseData } from './adapterUtils';

class MembershipService {
  async getPlans() {
    const response = await ApiClient.get<Plan[]>(ApiRoute.PLANS);
    return getResponseData(response);
  }

  async getPlan(id: string) {
    const response = await ApiClient.get<Plan>(`${ApiRoute.PLANS}/${id}`);
    return getResponseData(response);
  }

  async createPlan(payload: Partial<Plan>) {
    const response = await ApiClient.post<{ id: string }>(`${ApiRoute.PLANS}`, { ...payload });
    return getResponseData(response);
  }

  async updatePlan(id: string, payload: Partial<Plan>) {
    const response = await ApiClient.patch<{ id: string }>(`${ApiRoute.PLANS}/${id}`, { ...payload });
    return getResponseData(response);
  }

  async deletePlan(id: string) {
    const response = await ApiClient.delete<void>(`${ApiRoute.PLANS}/${id}`);
    return getResponseData(response);
  }

  async getSessionUserSecret() {
    const response = await ApiClient.get<{ sessionSecret: string }>(`${ApiRoute.PAYMENT_GATEWAY_SESSION_SECRET}`);
    return getResponseData(response);
  }

  async createUserCheckoutSession(planId: string) {
    const response = await ApiClient.post<{ url: string }>(`${ApiRoute.PAYMENT_GATEWAY_CHECKOUT_SESSION}`, { planId });
    return getResponseData(response);
  }

  async getSubscriptions(params?: { userId?: string; status?: string, limit?: number, offset?: number }) {
    const response = await ApiClient.get<Subscription[]>(ApiRoute.SUBSCRIPTIONS, { params});
    return getResponseData(response);
  }

  async getSubscription(id: string) {
    const response = await ApiClient.get<Subscription>(`${ApiRoute.SUBSCRIPTIONS}/${id}`);
    return getResponseData(response);
  }

  async deleteSubscription(id: string) {
    const response = await ApiClient.delete<Subscription>(`${ApiRoute.SUBSCRIPTIONS}/${id}`);
    return getResponseData(response);
  }
}

export default new MembershipService();
