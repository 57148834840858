import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import styles from './DasboardUsage.module.scss';
import MdtService from '../../../../services/api/MdtService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { GroupType } from '../../../../constants/group.constants';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { User } from '../../../../types/User';
import Chip from '../../../../components/chips/Chip/Chip';

const fetchDashboard = async (setMonthlyUsage: React.Dispatch<React.SetStateAction<any>>) => {
  try {
    const monthlyUsageResponse = await MdtService.getUsage('month');
    setMonthlyUsage(monthlyUsageResponse.body);
  } catch (error) {
    console.error(error);
  }
};

const getCellValue = (value: number = 0, valueMax: number = 0, color: 'success' | 'warning' | 'error' | 'info'): any => {
  if (valueMax === undefined || valueMax > 100) {
    return <Chip size='small' color={color} className={styles.chipValue} label={`${value}/∞`} />;
  }

  if (valueMax === 0) {
    return<Chip size='small' color={color} className={styles.chipValue} label='N/A' />;
  }

  return <Chip size='small' color={color} className={styles.chipValue} label={`${value}/${valueMax}`} />;
};

const UsageTableRow: React.FC<{ label: string, property: string, monthlyUsage: any }> = ({ label, property, monthlyUsage }) => (
  <TableRow>
    <TableCell>{label}</TableCell>
    <TableCell>{getCellValue(monthlyUsage[property][GroupType.PUBLIC]?.value, monthlyUsage[property][GroupType.PUBLIC]?.maxValue, 'success')}</TableCell>
    <TableCell>{getCellValue(monthlyUsage[property][GroupType.PRIVATE]?.value, monthlyUsage[property][GroupType.PRIVATE]?.maxValue, 'warning')}</TableCell>
    <TableCell>{getCellValue(monthlyUsage[property][GroupType.MDT]?.value, monthlyUsage[property][GroupType.MDT]?.maxValue, 'info')}</TableCell>
  </TableRow>
);

const UsageTable: React.FC<{ monthlyUsage: any }> = ({ monthlyUsage }) => (
  <Table size="small" aria-label="a dense table">
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Public</TableCell>
        <TableCell>Private</TableCell>
        <TableCell>MDT</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <UsageTableRow label='Threads/Cases Created' property='threadsCreated' monthlyUsage={monthlyUsage} />
      <UsageTableRow label='Groups Created' property='groupsCreated' monthlyUsage={monthlyUsage} />
      <UsageTableRow label='Invites Sent' property='invitedGroups' monthlyUsage={monthlyUsage} />
    </TableBody>
  </Table>
);

const DasboardUsage: React.FC = () => {
  const [monthlyUsage, setMonthlyUsage] = useState<any>({
    threadsCreated: {},
    groupsCreated: {},
    groupsJoined: {},
    invitedGroups: {},
  });

  const auth = useAuthContext() as { user: User };

  useEffect(() => {
    fetchDashboard(setMonthlyUsage);
  }, []);

  return (
    <Grid className={styles.container}>
      <div className={styles.containerItemLabel}>
        <div>Monthly Plan Usage {auth.user.tier && <Chip label={`${auth.user.tier[0]}${auth.user.tier.slice(1).toLocaleLowerCase()}`} />}</div>
      </div>
      <div className={styles.containerItemValue}>
        <UsageTable monthlyUsage={monthlyUsage} />
      </div>
    </Grid>
  );
};

export default DasboardUsage;