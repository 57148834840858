import React from 'react';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';

import MdtOnboardingStepWrapper from './components/MdtOnboardingStepWrapper';
import TextInputField from '../../../../../components/text-input-field/TextInputField';
import MdtOnboardingDocumentUploadField, { onboardingDocumentValidation, uploadOnboardingDocument } from './components/MdtOnboardingDocumentUploadField';
import MdtOnboardingHelperText from './components/MdtOnboardingHelperText';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'First Name should have at least 2 characters')
    .max(50, 'First Name cannot be longer than 50 characters')
    .required('First Name field is required'),
  lastName: yup
    .string()
    .min(2, 'Last Name should have at least 2 characters')
    .max(50, 'Last Name cannot be longer than 50 characters')
    .required('Last Name field is required'),
  document: onboardingDocumentValidation
});

export default function IdentityStep({ profile, setProfile, handleNext, handleBack }) {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...profile.identity },
    validationSchema,
    onSubmit: async (values) => {
      await uploadOnboardingDocument(formik.values.document, 'identity');

      setProfile({ ...profile, identity: values });
      handleNext();
    },
  });

  return (
    <MdtOnboardingStepWrapper
      name="Verify Identity"
      formik={formik}
      handleBack={handleBack}
      onSubmit={formik.handleSubmit}
    >
      <Grid item xs={12} sm={12}>
        <TextInputField
          label="First Name"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={true}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          errorHelperText={formik.errors.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInputField
          label="Last Name"
          name="lastName"
          disabled={true}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          errorHelperText={formik.errors.lastName}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <MdtOnboardingDocumentUploadField
          description="Please upload a clear scan of the biographic data page of your Passport or the front of your Driver's License"
          buttonLabel="Attach Document"
          name="document"
          onBlur={formik.handleBlur}
          uploaded={profile.identity.document}
          error={formik.touched.document && Boolean(formik.errors.document)}
          errorHelperText={formik.errors.document}
          setFieldValue={formik.setFieldValue}
          disabled={formik.isSubmitting}
        />
      </Grid>

      <MdtOnboardingHelperText />
    </MdtOnboardingStepWrapper>
  );
}
