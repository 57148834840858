import React, { useEffect } from 'react';
import { sanitize } from 'dompurify';
import dayjs from 'dayjs';
import Divider from '@mui/material/Divider';
import { Box, FormHelperText, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import styles from './MdtApprovedThreadPrintPage.module.scss';
import { FormattedThreadStatus } from '../../../constants/thread.constants';
import { MessageStatus, MessageType } from '../../../constants/message.constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getThread } from '../../../store/slices/thread/threadThunks';
import { clearThread, selectThread } from '../../../store/slices/thread/threadSlice';
import Logo from '../../../components/ui/Logo/Logo';

// TODO: Clarify confirmation behavior with domain experts
export default function MdtApprovedThreadPrintPage() {
  const dispatch = useAppDispatch();
  const params = useParams();

  const { thread } = useAppSelector(selectThread);

  useEffect(() => {
    dispatch(getThread({ id: params.threadId }));

    return () => dispatch(clearThread());
  }, [params.threadId]);

  useEffect(() => {
    if (thread) {
      window.print();
    }
  }, [thread]);

  if (!thread) return null;

  const review = thread.messages.findLast(
    (message) => message.type === MessageType.REVIEW && message.reviewStatus === MessageStatus.APPROVED,
  );

  return (
    <div className={styles.threadWrapper}>
      <div className={styles.logoWrapper}>
        <Logo className={styles.logo} />
      </div>

      <Box>
        <Typography>
          <strong>Created By:</strong>{' '}
          <span className={styles.textValue}>
            {thread?.user?.firstName} {thread?.user?.lastName} <em>&lt;{thread?.user?.email}&gt;</em>
          </span>
        </Typography>
        <Typography>
          <strong>Created At:</strong>{' '}
          <span className={styles.textValue}>{dayjs(thread?.createdAt).format('MMM DD YYYY H:mm A')}</span>
        </Typography>
        {/*
        <Typography>
          <strong>Confirmation Number:</strong> <span className={styles.textValue}>{thread.id.toUpperCase()}</span>
        </Typography>
*/}
      </Box>
      <br />

      <Box>
        <Typography>
          <strong>Reviewed By:</strong>{' '}
          <span className={styles.textValue}>
            {review?.user?.firstName} {review?.user?.lastName} <em>&lt;{review?.user?.email}&gt;</em>
          </span>
        </Typography>
        <Typography>
          <strong>Reviewed At:</strong>{' '}
          <span className={styles.textValue}>{dayjs(review?.createdAt).format('MMM DD YYYY H:mm A')}</span>
        </Typography>
        <Typography>
          <strong>Review Status:</strong>{' '}
          <span className={styles.textValue}>{FormattedThreadStatus[thread?.status]}</span>
        </Typography>
        <Typography>
          <strong>Review Message:</strong>{' '}
          <span className={styles.textValue} dangerouslySetInnerHTML={{ __html: sanitize(review?.text) }}></span>
        </Typography>
      </Box>
      <br />

      <Box>
        <Typography>
          <strong>Case Members:</strong>
          <dl className={styles.list}>
            {thread.members.map((member) => (
              <li className={styles.textValue} key={member?.email}>
                {member?.firstName} {member?.lastName} <em>&lt;{member?.email}&gt;</em> – {member.role.toLowerCase()}
              </li>
            ))}
          </dl>
        </Typography>
      </Box>
      <Divider sx={{ mt: '12px', mb: '12px' }} />
      <Box>
        <Typography className={styles.title}>
          <span>{thread?.title}</span>
        </Typography>
        <Typography className={styles.text}>
          <span dangerouslySetInnerHTML={{ __html: sanitize(thread.text) }}></span>
        </Typography>
      </Box>

      <Divider sx={{ mt: '12px', mb: '12px' }} />

      <div className={styles.contactInfo}>
        <FormHelperText>12-18 Hoxton Street, London, United Kingdom, N1 6NG</FormHelperText>
        <FormHelperText>
          <u>info@medsym.io</u> <u>+44-20-7097-4918</u>
        </FormHelperText>
      </div>
    </div>
  );
}
