import React from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './DisclaimerDialog.module.scss';
import Button from '../button/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DisclaimerDialog({ open, onClose, onConfirm, text }) {
  const handleConfirm = async () => {
    await onConfirm();
  };

  return (
    <div className={styles.dialogContainer}>
      <Dialog
        fullWidth
        maxWidth="lg"
        TransitionComponent={Transition}
        PaperProps={{ style: { borderRadius: 15 } }}
        scroll="paper"
        open={open}
        onClose={onClose}
        classes={{ paper: styles.dialog }}
      >
        <DialogTitle className={styles.title}>Disclaimer</DialogTitle>
        <DialogContent className={styles.content}>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions className={styles.actionButtons}>
          <Button variant="outlined" onClick={onClose} className={styles.cancel}>
            Close
          </Button>
          <Button onClick={handleConfirm} className={styles.confirm}>
            Agree & Publish
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
