import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@mui/material/Box';
import { List } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import UserService from '../../../../../../services/api/UserService';
import { useMdtContext } from '../../../../../../hooks/useMdtContext';
import ModalTitle from '../../../../../../components/modal-title/ModalTitle';
import Modal from '../../../../../../components/modal/Modal';
import Search from '../../../../../../components/search/Search';
import { debounce } from '../../../../../../utils/debounce';
import styles from './GroupAddMemberModal.module.scss';
import { getUsersSearchParams } from '../../../../../../utils/userSearch';
import { config } from '../../../../../../configs/config';
import FilterSelect from '../../../../../../components/filter-select/FilterSelect';
import { countries, specialities } from '../../../../../public/sign-up/sign-up-form/SignUpForm.constants';
import { hasPermission } from '../../../../../../utils/permissions';
import { MdtPermissions, UserGroupRole, UserStatus } from '../../../../../../constants/user.constants';
import { useAuthContext } from '../../../../../../hooks/useAuthContext';
import { getOffset } from '../../../../../../utils/url';
import ModalSkeletonMemberItem from '../components/ModalSkeletonMemberItem';
import ModalMemberItem from '../components/ModalMemberItem';

export default function GroupAddMemberModal({ permissions, group, open, setOpen }) {
  const [users, setUsers] = useState(null);
  const { addUserToGroup, removeUserFromGroup } = useMdtContext();
  const auth = useAuthContext();

  const [search, setSearch] = useState('');
  const [specialitySelect, setSpecialitySelect] = useState('');
  const [countrySelect, setCountrySelect] = useState('');

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const disabled = !hasPermission(permissions, MdtPermissions.CREATE_GROUP_MEMBER);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchUsers = async (pageNum) => {
    const { body, headers } = await UserService.getUsers({
      ...getUsersSearchParams(search),
      groupId: group.id,
      status: UserStatus.ACTIVE,
      limit: config.pagination.usersPageSize,
      offset: getOffset(pageNum),
      speciality: specialitySelect || undefined,
      country: countrySelect || undefined,
    });

    const usersLength = users?.length || 0;
    if (usersLength + body.length >= headers['x-total-count']) {
      setHasMore(false);
    }

    setPage((prevPage) => prevPage + 1);
    setUsers((prevUsers) => {
      if (pageNum === 1) return body;

      return prevUsers ? prevUsers.concat(body) : body;
    });
  };

  useEffect(() => {
    if (open) fetchUsers(1).catch((error) => console.error(error));
    else resetFilters();

    return () => resetInfiniteScroll();
  }, [open, search, specialitySelect, countrySelect]);

  const resetInfiniteScroll = () => {
    setUsers(null);
    setPage(1);
    setHasMore(true);
  };

  const resetFilters = () => {
    setSpecialitySelect('');
    setCountrySelect('');
    setSearch('');
  };

  const handleAddUserToGroup = async (user) => {
    await addUserToGroup(group.id, user, { role: UserGroupRole.DEFAULT });
    setUsers((currentUsers) =>
      currentUsers.map((u) => {
        if (u.id == user.id) {
          u.usersGroups = [{ groupId: group.id }];
          u.role = UserGroupRole.DEFAULT;
        }
        return u;
      }),
    );
  };

  const handleRemoveUserFromGroup = async (userId) => {
    await removeUserFromGroup(group.id, userId);

    setUsers((currentUsers) =>
      currentUsers.map((user) => {
        if (user.id === userId)
          return {
            ...user,
            usersGroups: [],
          };

        return user;
      }),
    );
  };

  const onSpecialitySelectChange = async (event) => {
    const selectValue = event.target.value ? event.target.value : undefined;

    setUsers([]);
    setPage(1);
    setSpecialitySelect(selectValue);
  };

  const onCountrySelectChange = async (event) => {
    const selectValue = event.target.value ? event.target.value : undefined;

    setUsers([]);
    setPage(1);
    setCountrySelect(selectValue);
  };

  const onSearchChange = async (event) => {
    const term = event.target.value;

    setUsers([]);
    setPage(1);
    setSearch(term);
  };

  const debouncedOnSearchChange = debounce(onSearchChange, 1000);

  const displayUsers = () => {
    if (!users) {
      return (
        <List className={styles.list}>
          {new Array(4).fill().map((_, index) => (
            <ModalSkeletonMemberItem key={index} className={styles.listItem} />
          ))}
        </List>
      );
    }

    return (
      <List className={styles.list}>
        {users.map((user) => (
          <ModalMemberItem
            key={user.id}
            disabled={disabled || user.id === auth?.user?.id}
            className={styles.listItem}
            onAdd={() => handleAddUserToGroup(user)}
            onRemove={() => handleRemoveUserFromGroup(user.id)}
            user={user}
          />
        ))}
      </List>
    );
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} className={styles.modal}>
        <form onSubmit={(e) => e.preventDefault()}>
          <ModalTitle>Add a New Member</ModalTitle>
          <div className={styles.search}>
            <Search
              placeholder="Search for a user"
              value={search}
              onChange={(event) => debouncedOnSearchChange(event)}
            />
            <div className={styles.filters}>
              <FilterSelect
                onChange={onSpecialitySelectChange}
                label="Speciality"
                name="speciality"
                value={specialitySelect}
                options={specialities.map((speciality) => ({
                  name: speciality.toLocaleLowerCase(),
                  label: speciality,
                }))}
              />

              <FilterSelect
                onChange={onCountrySelectChange}
                label="Country"
                name="country"
                value={countrySelect}
                options={countries.map((speciality) => ({ name: speciality, label: speciality }))}
              />
            </div>
          </div>

          <InfiniteScroll
            className={styles.infiniteScrollWrapper}
            dataLength={users?.length || 0}
            height={300}
            next={() => fetchUsers(page)}
            hasMore={hasMore}
            loader={
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box>
            }
          >
            {displayUsers()}
          </InfiniteScroll>
        </form>
      </Modal>
    </div>
  );
}
