export const specialities = [
  "Allergy",
  "Anaesthetics",
  "Cardiology",
  "Cardiothoracic Surgery",
  "Chemical Pathology",
  "Clinical Genetics",
  "Clinical Neurophysiology",
  "Clinical Oncology",
  "Clinical Pharmacology and Therapeutics",
  "Dermatology",
  "Diagnostic Neuropathology",
  "Emergency Medicine",
  "Endocrinology and Diabetes Mellitus",
  "Forensic Histopathology",
  "Forensic Psychiatry",
  "Gastroenterology",
  "General (Internal) Medicine",
  "General Practice",
  "General Psychiatry",
  "General Surgery",
  "Genitourinary Medicine",
  "Geriatric Medicine",
  "Haematology",
  "Histopathology",
  "Immunology",
  "Infectious Diseases",
  "Intensive Care Medicine",
  "Medical Microbiology",
  "Medical Oncology",
  "Medical Ophthalmology",
  "Medical Virology",
  "Neurology",
  "Neurosurgery",
  "Nuclear Medicine",
  "Obstetrics and Gynaecology",
  "Occupational Medicine",
  "Ophthalmology",
  "Oral and Maxillofacial Surgery",
  "Otolaryngology",
  "Paediatric Cardiology",
  "Paediatric Surgery",
  "Paediatrics",
  "Palliative Medicine",
  "Pharmaceutical Medicine",
  "Plastic Surgery",
  "Rehabilitation Medicine",
  "Renal Medicine",
  "Respiratory Medicine",
  "Rheumatology",
  "Sport and Exercise Medicine",
  "Trauma and Orthopaedic Surgery",
  "Urology",
  "Vascular Surgery",
  "Medisonal Team",
  "Neuro-specialist",
  "Researcher",
  "Pharmacist",
  "Internal Medicine",
  "Genito-Urinary Medicine",
  "Junior Doctor",
  "Regenerative Medicine",
  "Oral & Maxillofacial Surgeon"
];

export const countries = [
  'Albania',
  'Algeria',
  'Angola',
  'Antigua & Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bolivia',
  'Bosnia & Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Bulgaria',
  'Burkina Faso',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo - Brazzaville',
  'Congo - Kinshasa',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  'Côte d’Ivoire',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Eritrea',
  'Estonia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Samoa',
  'San Marino',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'St. Kitts & Nevis',
  'St. Lucia',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad & Tobago',
  'Tunisia',
  'Turkmenistan',
  'Turks & Caicos Islands',
  'Türkiye',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Rest of World',
];
