import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceName } from '../../config';
import { Plan } from '../../../types/Plan';
import MembershipService from '../../../services/api/MembershipService';


export const getPlan = createAsyncThunk(
  `${SliceName.MEMBERSHIP_PLAN}/getPlan`,
  async (id: string) => {
    const plan = await MembershipService.getPlan(id);

    return plan;
  },
);

export const createPlan = createAsyncThunk(
  `${SliceName.MEMBERSHIP_PLAN}/createPlan`,
  async (plan: Partial<Plan>) => {
    await MembershipService.createPlan(plan);

    return plan
  },
);

export const getPlans = createAsyncThunk(
  `${SliceName.MEMBERSHIP_PLAN}/getPlans`,
  async () => {
    const plans = await MembershipService.getPlans();

    return plans;
  },
);


export const deletePlan = createAsyncThunk(
  `${SliceName.MEMBERSHIP_PLAN}/deletePlan`,
  async (id: string) => {
    await MembershipService.deletePlan(id);

    return id;
  },
);

export const updatePlan = createAsyncThunk(
  `${SliceName.MEMBERSHIP_PLAN}/updatePlan`,
  async (plan: { id: string; } & Partial<Plan>) => {
    await MembershipService.updatePlan(plan.id, plan);

    return plan;
  },
);
