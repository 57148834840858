import { confirmMultifactorAuth, signIn, signOut, signUp } from '../auth/authThunks';
import { getOrganisations } from '../organisations/organisationsThunks';
import {
  disableMultifactorAuth,
  enableMultifactorAuth,
  getMultifactorAuthSettings,
  verifyMultifactorAuth,
} from '../multifactorAuthSettings/multifactorAuthSettingsThunks';
import { getProfile } from '../profile/profileThunks';
import {
  addThreadMessage,
  addThreadReview,
  addUserToThread,
  deleteThread,
  deleteThreadMessage,
  editThreadMessage,
  getThread,
  removeUserFromThread,
  updateThread,
} from '../thread/threadThunks';
import { getOnboardingProfile, updateOnboardingProfile } from '../onboardingProfile/onboardingProfileThunks';
import { getOrganisationUsers } from '../organisationUsers/organisationUsersThunks';
import {
  getUserDetails,
  getUserDocument,
  removeUserFromOrganisation,
  reviewUserOnboarding,
} from '../userDetails/userDetailsThunks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyAction = any;

type FeedbackAction = {
  action: AnyAction;
  showError?: boolean;
  defaultErrorMessage?: string;
  errorMessage?: string;
  showSuccess?: boolean;
  successMessage?: string;
  showLoader?: boolean;
  duration?: number;
};

export const FEEDBACK_ACTIONS: FeedbackAction[] = [
  {
    action: getProfile,
    showError: true,
    defaultErrorMessage: 'Failed to get profile',
    showLoader: true,
  },
  {
    action: signIn,
    showError: true,
  },
  { action: confirmMultifactorAuth, showError: true },
  {
    action: signOut,
    showError: true,
  },
  {
    action: getThread,
    showError: true,
  },
  {
    action: updateThread,
    showError: true,
    successMessage: 'Thread was successfully updated',
  },
  {
    action: deleteThread,
    showError: true,
    successMessage: 'Thread was successfully deleted',
  },
  {
    action: addUserToThread,
    showError: true,
    successMessage: 'User added to thread',
  },
  {
    action: removeUserFromThread,
    showError: true,
    successMessage: 'User removed from thread',
  },
  {
    action: addThreadReview,
    showError: true,
    successMessage: 'Review added',
  },
  { action: addThreadMessage, showError: true, successMessage: 'Message added' },
  { action: editThreadMessage, showError: true, successMessage: 'Message updated' },
  { action: deleteThreadMessage, showError: true, successMessage: 'Message deleted' },
  { action: getMultifactorAuthSettings, showError: true },
  { action: enableMultifactorAuth, showError: true },
  { action: verifyMultifactorAuth, showError: true },
  { action: disableMultifactorAuth, showError: true },
  {
    action: getOrganisations,
    showError: true,
  },
  {
    action: getOnboardingProfile,
    showError: true,
  },
  {
    action: updateOnboardingProfile,
    showError: true,
  },
  { action: getOrganisationUsers, showError: true },
  { action: getUserDetails, showError: true },
  {
    action: getUserDocument,
    showError: true,
  },
  {
    action: reviewUserOnboarding,
    showError: true,
    successMessage: 'User onboarding review was successfully updated',
  },
  {
    action: removeUserFromOrganisation,
    showError: true,
    successMessage: 'User was successfully removed from organisation',
  },
  {
    action: signUp,
    showError: true,
    duration: 20000,
    successMessage: "Registration successful! Please verify your email. Remember to check your spam folder",
  },
];
