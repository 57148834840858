import * as React from 'react';
import PaginationComponent from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import styles from './Pagination.module.scss';

export default function Pagination(props) {
  return (
    <Stack spacing={5}>
      <PaginationComponent
        count={props.count}
        page={props.page}
        disabled={props.disabled}
        spacing={5}
        size="small"
        // shape="rounded"
        className={styles.paginationComponent}
        // sx={{
        //   '& .MuiPaginationItem-root': {
        //     margin: '0 10px', // Adjust spacing here
        //   },
        // }}
        onChange={props.onChange}
      />
    </Stack>
  );
}
