import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMdtContext } from '../../../../hooks/useMdtContext';
import GroupsMenu from '../../../../components/groups-menu/GroupsMenu';

import styles from './PublicGroups.module.scss';
import { useLocation } from 'react-router-dom';

import { config } from '../../../../configs/config';
import ToggleButtonField from '../../../../components/toggle-button-field/ToggleButtonField';
import UserService from '../../../../services/api/UserService';
import GroupEmptyState from '../components/GroupEmptyState';
import ThreadsPagination from '../components/ThreadsPagination';
import ThreadsHeader from '../components/ThreadsHeader';
import { getUserRole } from '../../../../utils/permissions';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { MdtPermissions, UserGroupRole, UserRole } from '../../../../constants/user.constants';
import GroupEditMenuItem from '../components/menus/GroupEditMenuItem';
import GroupEditMenu from '../components/menus/GroupEditMenu';
import GroupEditModal from '../components/modals/GroupEditModal';
import ThreadAddModal from '../components/modals/ThreadAddModal';
import Threads from '../components/Threads';
import Search from '../../../../components/search/Search';
import { debounce } from '../../../../utils/debounce';
import { GroupType } from '../../../../constants/group.constants';
import ErrorMessage from '../../../../components/feedback/ErrorMessage/ErrorMessage';

export default function PublicGroups() {
  const { groups, startGroupsPolling, group, groupThreads, groupUser, startGroupPolling, groupThreadsCount, groupError } =
    useMdtContext();
  const { user } = useAuthContext();
  const type = 'PUBLIC';
  const title = 'Public Groups';
  const params = useParams();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [openGroupMenu, setOpenGroupMenu] = useState(null);
  const [openEditGroupModal, setOpenEditGroupModal] = useState(false);
  const [openAddThreadModal, setOpenAddThreadModal] = useState(false);
  const [search, setSearch] = useState(query.get('title'));

  startGroupPolling();
  startGroupsPolling(type);

  const canEdit = groupUser?.role === UserGroupRole.OWNER;
  const rolePermissions = {
    [UserRole.ADMIN]: [
      MdtPermissions.CREATE_GROUP,
      MdtPermissions.EDIT_GROUP,
      MdtPermissions.DELETE_GROUP,
      MdtPermissions.READ_GROUP,
      MdtPermissions.CREATE_THREAD,
    ],
    [UserRole.DEFAULT]: [
      MdtPermissions.READ_GROUP,
      MdtPermissions.CREATE_THREAD,
      MdtPermissions.CREATE_GROUP,
      canEdit && MdtPermissions.EDIT_GROUP,
      canEdit && MdtPermissions.DELETE_GROUP,
    ],
  };
  const permissions = rolePermissions[getUserRole(user.scopes)] || [];

  const debouncedOnSearchChange = useCallback(
    debounce(async (term) => {
      if (term) {
        query.set('title', term);
      } else {
        query.delete('title');
      }

      navigate({ search: query.toString() });
    }, 2000),
    [],
  );

  const filters = (
    <>
      <ToggleButtonField
        text="My Threads"
        className={styles.toggleButton}
        onChange={(value) => {
          if (value) {
            query.set('userId', UserService.getLocalUser().id);
          } else {
            query.delete('userId');
          }

          navigate({ search: query.toString() });
        }}
      />
      <Search
        placeholder="Search for a thread"
        className={styles.search}
        search={search}
        onChange={(event) => {
          const term = event.target?.value;
          setSearch(term);
          debouncedOnSearchChange(term);
        }}
      />
    </>
  );

  if (!params.groupId) {
    return (
      <>
        <GroupsMenu permissions={permissions} title={title} type="Public" groups={groups} />
        <GroupEmptyState />
      </>
    );
  }

  return (
    <>
      <GroupsMenu permissions={permissions} title={title} type="Public" groups={groups} hideOnMobile />
      <div className={styles.threadsWrapper}>
        {groupError ? (
          <ErrorMessage className={styles.error}>{groupError}</ErrorMessage>
        ) : (
          <>
            <ThreadsHeader
              group={group}
              setOpenGroupMenu={setOpenGroupMenu}
              setOpenAddThreadModal={setOpenAddThreadModal}
              filters={filters}
            />
            <Threads threads={groupThreads} group={group} />
            <ThreadsPagination
              queryParams={query}
              totalCount={groupThreadsCount}
              pageSize={config.pagination.threadsPageSize}
            />

            <GroupEditMenu openGroupMenu={openGroupMenu} setOpenGroupMenu={setOpenGroupMenu}>
              <GroupEditMenuItem
                title="Settings"
                type="public"
                onClick={() => {
                  setOpenEditGroupModal(true);
                  setOpenGroupMenu(null);
                }}
              />
            </GroupEditMenu>

            <ThreadAddModal
              open={openAddThreadModal}
              setOpen={setOpenAddThreadModal}
              group={group}
              type={GroupType.PUBLIC}
            />
            <GroupEditModal
              permissions={permissions}
              open={openEditGroupModal}
              setOpen={setOpenEditGroupModal}
              group={group}
            />
          </>
        )}
      </div>
    </>
  );
}
