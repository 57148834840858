import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { UserExtended } from '../../../types/User';
import { TableColumn } from '../../../types/TableColumn';
import classes from './UsersTable.module.scss';
import { renderColumnCell } from '../../../utils/table';
import UserStatusChip from '../../chips/UserStatusChip/UserStatusChip';
import Chip from '../../chips/Chip/Chip';
import EmptyMessage from '../../feedback/EmptyMessage/EmptyMessage';

interface Props {
  users: UserExtended[];
  columns?: TableColumn<UserExtended>[];
  onRowClick?: (user: UserExtended) => void;
}

const DEFAULT_COLUMNS: TableColumn<UserExtended>[] = [
  {
    key: 'firstName',
    label: 'First Name',
    width: 200,
  },
  {
    key: 'lastName',
    label: 'Last Name',
    width: 200,
  },
  {
    key: 'email',
    label: 'Email',
    width: 250,
  },
  {
    key: 'status',
    label: 'Status',
    width: 200,
    renderCell: ({ status }) => <UserStatusChip status={status} />,
  },
  {
    key: 'speciality',
    label: 'Speciality',
    width: 200,
    renderCell: ({ speciality }) => <Chip label={speciality} />,
  },
  {
    key: 'country',
    label: 'Country',
    width: 200,
    renderCell: ({ address }) => address?.country,
  },
];

const UsersTable: React.FC<Props> = ({ users, columns = DEFAULT_COLUMNS, onRowClick }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.key} width={column.width}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {users.map((user) => (
            <TableRow
              key={user.id}
              onClick={onRowClick ? () => onRowClick(user) : undefined}
              className={onRowClick && classes.clickable}
            >
              {columns.map((column) => (
                <TableCell key={column.key} width={column.width}>
                  {renderColumnCell(column, user)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!users.length && <EmptyMessage>No users found</EmptyMessage>}
    </TableContainer>
  );
};

export default UsersTable;
