import LinearProgress from '@mui/material/LinearProgress';

import styles from './FullscreenLoader.module.scss';

const FullscreenLoader: React.FC = () => {
  return (
    <div className={styles.loaderContainer}>
      <LinearProgress />
    </div>
  );
};

export default FullscreenLoader;
