import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Outlet } from 'react-router-dom';

import styles from './AdminMembershipLayout.module.scss';

const AdminMembershipLayout: React.FC = () => {
  const [value, setValue] = useState('1');

  const handleChange = (_: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setValue(newValue);    
  };

  return (
    <div className={styles.container}>
      <TabContext value={value}>
        <TabList className={styles.tabList} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          <Tab className={styles.tabItem} label="Plan" value="1" />
        </TabList>

        <TabPanel className={styles.tabPanel} value="1">
          <Outlet />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default AdminMembershipLayout;
