import React from 'react';
import Skeleton from '@mui/material/Skeleton';

import styles from './GroupsMenuSearch.module.scss';
import Search from '../../search/Search';

export default function GroupsMenuSearch({ groups, search, onSearch }) {
  const getSearch = () => {
    if (!groups) {
      return <Skeleton variant="rounded" width={'85%'} height={'30px'} />;
    }

    return <Search className={styles.search} placeholder="Search for a group" value={search} onChange={onSearch} />;
  };

  return <div className={styles.listSearch}>{getSearch()}</div>;
}
