import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import styles from './GroupsMenuListItem.module.scss';
import classNames from 'classnames';
import Skeleton from '@mui/material/Skeleton';

const GroupMenuNavigationLink = React.forwardRef((props, ref) => {
  const match = useMatch({ path: props.to, exact: true, end: false });

  if (match?.pattern?.exact) {
    return (
      <div ref={ref} className={classNames({ [styles.activeLinkClass]: match?.pattern?.exact })}>
        <NavLink {...props} />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <NavLink {...props} />
    </div>
  );
});

export default function GroupsMenuListItem({ type, group }) {
  if (group === null) {
    return (
      <ListItem disablePadding className={styles.item}>
        <ListItemButton className={styles.button}>
          <Skeleton variant="rounded" height={20} width={'100%'} />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ListItem key={group.name} disablePadding className={styles.item}>
      <ListItemButton
        className={styles.button}
        component={GroupMenuNavigationLink}
        to={`/internal/groups/${type}/${group.id}`}
      >
        <ListItemText className={styles.text} primary={group.name} />
      </ListItemButton>
    </ListItem>
  );
}
