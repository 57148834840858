import React from 'react';
import Grid from '@mui/material/Grid';

import { useFormik } from 'formik';
import * as yup from 'yup';

import MdtOnboardingStepWrapper from './components/MdtOnboardingStepWrapper';
import MdtOnboardingHelperText from './components/MdtOnboardingHelperText';
import UserService from '../../../../../services/api/UserService';
import DatePickerInputField from '../../../../../components/date-picker/DatePickerInputField';
import { useAuthContext } from '../../../../../hooks/useAuthContext';

const validationSchema = yup.object().shape({
  latestAppraisalDate: yup.date().required('Latest Annual Appraisal Field is required'),
});

export default function RecentAnnualAppraisalStep(props) {
  const auth = useAuthContext();
  const user = auth.user || {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...props.profile.annualSalary },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (props.profile?.annualSalary?.latestAppraisalDate !== values.latestAppraisalDate) {
        const mdtProfile = { latestAppraisalDate: values.latestAppraisalDate.format('YYYY-MM-DDTHH:mm:ss') };

        await UserService.updateProfile(user.id, { mdtProfile });
      }

      props.setProfile({ ...props.profile, annualSalary: values });

      setSubmitting(false);
      props.handleNext();
    },
  });

  return (
    <MdtOnboardingStepWrapper
      name="Specify The Latest Annual Appraisal"
      formik={formik}
      onSubmit={formik.handleSubmit}
      handleBack={props.handleBack}
    >
      <Grid item xs={12} sm={12}>
        <DatePickerInputField
          label="Latest Appraisal Date"
          name="latestAppraisalDate"
          maxDate={new Date()}
          value={formik.values.latestAppraisalDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          setFieldValue={formik.setFieldValue}
          error={formik.touched.latestAppraisalDate && Boolean(formik.errors.latestAppraisalDate)}
          errorHelperText={formik.errors.latestAppraisalDate}
        />
      </Grid>

      <MdtOnboardingHelperText />
    </MdtOnboardingStepWrapper>
  );
}
