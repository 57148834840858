import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Avatar, Checkbox, CircularProgress, ListItem, Typography } from '@mui/material';
import { DisabledByDefaultRounded } from '@mui/icons-material';

import Chip from '../../../../../../components/chips/Chip/Chip';
import styles from './ModalMemberItem.module.scss';

const ModalMemberItem = ({ user, disabled, onAdd, className, onRemove, checked }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const userPageLink = `/internal/users/${user.id}`;
  const isChecked = checked || !!user.usersGroups?.length;

  const handleCheckboxChange = async (event) => {
    setIsLoading(true);

    try {
      if (event.target.checked) await onAdd();
      else await onRemove();
    } catch (error) {
      // ignore error
    }

    setIsLoading(false);
  };

  const handleControlMouseEnter = () => setIsHovered(true);
  const handleControlMouseLeave = () => setIsHovered(false);

  return (
    <ListItem className={className}>
      <div>
        <NavLink to={userPageLink} target="_blank" rel="noopener noreferrer">
          <Avatar className={styles.avatar}>
            {user.firstName[0]}
            {user.lastName[0]}
          </Avatar>
        </NavLink>
        <Typography className={styles.info}>
          <NavLink to={userPageLink} target="_blank" rel="noopener noreferrer">
            <span>
              {user.firstName} {user.lastName}
            </span>
          </NavLink>
          <span className={styles.email}>{user.email}</span>
        </Typography>
        <Chip size="small" label={user.speciality} />
      </div>

      <div
        className={styles.controlContainer}
        onMouseEnter={handleControlMouseEnter}
        onMouseLeave={handleControlMouseLeave}
      >
        {isLoading ? (
          <CircularProgress
            style={{
              height: 16,
              width: 16,
            }}
          />
        ) : (
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            disabled={disabled}
            indeterminate={isChecked && isHovered}
            indeterminateIcon={<DisabledByDefaultRounded />}
          />
        )}
      </div>
    </ListItem>
  );
};

export default ModalMemberItem;
