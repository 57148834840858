import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import styles from './AdminMembershipPlansPage.module.scss';
import MembershipService from '../../../../services/api/MembershipService';
import { Plan } from '../../../../types/Plan';
import IconButton from '../../../../components/buttons/IconButton/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/Button';
import AdminMembershipPlanCreateModal from './components/AdminMembershipPlanCreateModal';
import { displayCentsWithCurrency } from '../../../../utils/money';

const AdminMembershipPlansPage: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [openAddPlanModal, setOpenAddPlanModal] = useState(false);

  const navigate = useNavigate();

  const fetchMembershipPlans = () => {
    return MembershipService.getPlans()
      .then((plans) => setPlans(plans))
  }

  useEffect(() => {
    fetchMembershipPlans()
  }, []);

  const displayPlan = (plan: Plan) => {
    return <TableRow>
      <TableCell width={500} className={styles.tableCell}>
        {plan.name}
      </TableCell>
      <TableCell width={300} className={styles.tableCell}>
        {displayCentsWithCurrency(plan.amount, plan.currency)}
      </TableCell>
      <TableCell width={200} className={styles.tableCell}>
        {plan.recurringInterval}
      </TableCell>

      <TableCell width={100} align='right'>
        <IconButton icon={<KeyboardArrowRightIcon />} onClick={async () => { navigate(`/internal/admin/membership/plans/${plan.id}`)}} />
      </TableCell>
    </TableRow>
  }

  const displayPlans = () => {
    return plans.map((plan) => displayPlan(plan));
  }

  return (
    <TableContainer>
      <Table  size="small">
        <TableHead>
          <TableRow>
            <TableCell width={500}>Name</TableCell>
            <TableCell width={300}>Amount</TableCell>
            <TableCell width={200} >Interval</TableCell>
            <TableCell align='right' width={100}>
              <Button rounded className={styles.button} onClick={() => setOpenAddPlanModal(true)}>Add Plan</Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{displayPlans()}</TableBody>
      </Table>
      <AdminMembershipPlanCreateModal open={openAddPlanModal} onClose={() => {
        fetchMembershipPlans();
        setOpenAddPlanModal(false)
      }}/>
    </TableContainer>
  );
};

export default AdminMembershipPlansPage;
