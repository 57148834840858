import ApiClient from './ApiClient';

class ContactUsService {
  async createRequest(payload) {
    const { status, data } = await ApiClient.post(`contact-us-requests`, { ...payload });

    return { body: data, statusCode: status };
  }

  async deleteRequest(id) {
    const { status, data } = await ApiClient.delete(`contact-us-requests/${id}`);

    return { body: data, statusCode: status };
  }

  async updateRequest(id, payload) {
    const { status, data } = await ApiClient.patch(`contact-us-requests/${id}`, { ...payload });

    return { body: data, statusCode: status };
  }

  async getRequest(id) {
    const { status, data } = await ApiClient.get(`contact-us-requests/${id}`);

    return { body: data, statusCode: status };
  }

  async getRequests(params) {
    const { status, data, headers } = await ApiClient.get(`contact-us-requests`, { params });

    return { body: data, statusCode: status, headers };
  }
}

export default new ContactUsService();
