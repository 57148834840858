import { Profile } from '../types/Profile';
import { UserScope } from '../types/enums/UserScope';

export const getIsAdmin = (profile: Profile | null) => {
  if (!profile) return false;

  return profile.scopes?.includes(UserScope.ADMIN);
};

export const getIsOrganisationAdmin = (profile: Profile | null) => !!profile?.managedOrganisationIds?.length;
