import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { Typography } from '@mui/material';

import StepLayout from '../components/StepLayout/StepLayout';
import { config } from '../../../../../../configs/config';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { selectOnboardingProfile } from '../../../../../../store/slices/onboardingProfile/onboardingProfileSlice';
import { selectOrganisations } from '../../../../../../store/slices/organisations/organisationsSlice';
import FullscreenLoader from '../../../../../../components/feedback/FullscreenLoader/FullscreenLoader';
import classes from './OrganisationAffiliationStep.module.scss';
import Select from '../../../../../../components/inputs/Select/Select';
import { updateOnboardingProfile } from '../../../../../../store/slices/onboardingProfile/onboardingProfileThunks';

interface Props {
  onBack: () => void;
  onNext: () => void;
}

const OrganisationAffiliationStep: React.FC<Props> = ({ onBack, onNext }) => {
  const dispatch = useAppDispatch();

  const { onboardingProfile } = useAppSelector(selectOnboardingProfile);
  const { organisations, isLoading } = useAppSelector(selectOrganisations);

  const handleUpdateProfile = async (onboardingOrganisationId: string | null) => {
    await dispatch(
      updateOnboardingProfile({
        mdtProfile: {
          onboardingOrganisationId,
        },
      }),
    ).unwrap();
  };

  const { values, handleChange, handleBlur, submitForm, isSubmitting } = useFormik({
    initialValues: { onboardingOrganisationId: onboardingProfile?.onboardingOrganisation?.id || '' },
    enableReinitialize: true,
    onSubmit: async (values) => {
      await handleUpdateProfile(values.onboardingOrganisationId || null);

      onNext();
    },
  });

  const options = useMemo(() => {
    const organisationOptions = organisations.map((organisation) => ({
      label: organisation.name,
      value: organisation.id,
    }));

    return [{ label: 'Select your organisation', value: '' }, ...organisationOptions];
  }, [organisations]);

  if (isLoading) {
    return <FullscreenLoader />;
  }

  return (
    <StepLayout
      title="Organisation Affiliation"
      onNext={submitForm}
      onBack={onBack}
      nextLabel={onboardingProfile?.onboardingOrganisation?.id || values.onboardingOrganisationId ? undefined : 'Skip'}
      isNextDisabled={isSubmitting}
      isLoading={isSubmitting}
    >
      <div className={classes.content}>
        <Typography className={classes.info}>
          If you are a member of an organisation represented within {config.appName}, please select it from the list.
          This ensures that your application will be reviewed by an administrator from your respective organisation.
          After the review, you will be granted access to the organisation&apos;s MDTs.
        </Typography>

        <Typography className={classes.info}>
          Regardless of selected organisation, you will have access to shared {config.appName} MDTs.
        </Typography>

        <Select
          value={values.onboardingOrganisationId}
          onChange={handleChange}
          onBlur={handleBlur}
          name="onboardingOrganisationId"
          label="Select your organisation"
          options={options}
        />
      </div>
    </StepLayout>
  );
};

export default OrganisationAffiliationStep;
