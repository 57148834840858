export const ContactUsStatus = {
  REQUESTED: 'REQUESTED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const ContactUsStatusMap = {
  [ContactUsStatus.REQUESTED]: {
    label: 'Requested',
    color: 'info',
  },
  [ContactUsStatus.COMPLETED]: {
    label: 'Completed',
    color: 'success',
  },
  [ContactUsStatus.CANCELLED]: {
    label: 'Cancelled',
    color: 'error',
  },
};
