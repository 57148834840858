import React, { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectProfile } from '../../../store/slices/profile/profileSlice';
import { getIsOrganisationAdmin } from '../../../utils/roles';
import classes from './OrganisationsLayout.module.scss';
import { getOrganisations } from '../../../store/slices/organisations/organisationsThunks';
import { OrganisationView } from '../../../types/enums/OrganisationView';
import { OrganisationCategory } from '../../../types/enums/OrganisationCategory';
import { OrganisationRole } from '../../../types/enums/OrganisationRole';
import OrganisationManagementHeader from '../../../components/organisations/OrganisationManagementHeader/OrganisationManagementHeader';
import OrganisationUserHeader from '../../../components/organisations/OrganisationUserHeader/OrganisationUserHeader';

const OrganisationsLayout: React.FC = () => {
  const dispatch = useAppDispatch();

  const { organisationId, userId } = useParams();

  const { profile } = useAppSelector(selectProfile);

  useEffect(() => {
    dispatch(
      getOrganisations({
        view: OrganisationView.ADDED,
        category: OrganisationCategory.PRIVATE,
        role: OrganisationRole.OWNER,
      }),
    );
  }, []);

  if (!getIsOrganisationAdmin(profile) || !organisationId || !profile?.managedOrganisationIds?.includes(organisationId))
    return <Navigate to="/internal/404" />;

  return (
    <main className={classes.container}>
      {userId ? <OrganisationUserHeader /> : <OrganisationManagementHeader />}

      <Outlet />
    </main>
  );
};

export default OrganisationsLayout;
