import ApiClient from './ApiClient';
import { ApiRoute } from './ApiRoute';
import { getResponseData } from './adapterUtils';
import { Thread } from '../../types/Thread';
import { ThreadReviewStatus } from '../../types/enums/ThreadReviewStatus';

class ThreadsService {
  async getById(id: string) {
    const response = await ApiClient.get<Thread>(`${ApiRoute.THREADS}/${id}`);

    return getResponseData(response);
  }

  async updateThread(id: string, title: string, text: string) {
    const response = await ApiClient.patch<{
      id: string;
    }>(`${ApiRoute.THREADS}/${id}`, {
      title,
      text,
    });

    return getResponseData(response);
  }

  async deleteThread(id: string) {
    await ApiClient.delete<void>(`${ApiRoute.THREADS}/${id}`);
  }

  async addUserToThread(threadId: string, userId: string) {
    await ApiClient.post<void>(`${ApiRoute.THREADS}/${threadId}/users/${userId}`);
  }

  async removeUserFromThread(threadId: string, userId: string) {
    await ApiClient.delete<void>(`${ApiRoute.THREADS}/${threadId}/users/${userId}`);
  }

  async addReview(threadId: string, payload: { text: string; reviewStatus: ThreadReviewStatus }) {
    const response = await ApiClient.post<{
      id: string;
    }>(`${ApiRoute.THREADS}/${threadId}/review`, payload);

    return getResponseData(response);
  }

  async addMessage(threadId: string, text: string) {
    const response = await ApiClient.post<{
      id: string;
    }>(`${ApiRoute.THREADS}/${threadId}/messages`, {
      text,
    });

    return getResponseData(response);
  }

  async updateMessage(messageId: string, text: string) {
    const response = await ApiClient.patch<{
      id: string;
    }>(`${ApiRoute.MESSAGES}/${messageId}`, {
      text,
    });

    return getResponseData(response);
  }

  async deleteMessage(messageId: string) {
    await ApiClient.delete<void>(`${ApiRoute.MESSAGES}/${messageId}`);
  }
}

export default new ThreadsService();
