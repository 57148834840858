import { Grid, Typography } from '@mui/material';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import ContactUsService from '../../../../services/api/ContactUsService';
import TextInputField from '../../../../components/text-input-field/TextInputField';
import Button from '../../../../components/button/Button';
import styles from './ContactUs.module.scss';
import { logAnalyticsEvent } from '../../../../utils/analytics';
import { AnalyticsEvent } from '../../../../constants/analytics.constants';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .min(2, 'Invalid First Name')
    .max(255, 'Invalid First Name')
    .required('First Name is required'),
  lastName: yup.string().min(2, 'Invalid Last Name').max(255, 'Invalid Last Name').required('Last Name is required'),
  email: yup.string().max(255, 'Invalid Email').email('Invalid Email').required('Email is required'),
  message: yup
    .string()
    .min(5, 'Message must be at least 5 characters long')
    .max(2500, 'Message must be at most 2500 characters long')
    .required('Message is required'),
});

export default function ContactUs({ id, className = '' }) {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await ContactUsService.createRequest(values);

      logAnalyticsEvent(AnalyticsEvent.CONTACT_US);

      resetForm();
      navigate('/');
      toast.success('You successfully sent contact request');
    },
  });
  return (
    <section id={id} className={classNames(styles.section, className)}>
      <div className={styles.infoWrapper}>
        <Typography className={styles.title} component="h1" variant="h4">
          Contact Us
        </Typography>
        <Typography className={styles.text}>
          Have a question, need more information, or want to give some feedback?
        </Typography>
        <Typography className={styles.text}>
          You may directly call us on <a href="tel:4402070974918">+44-20-7097-4918</a>, or send an email to{' '}
          <a href="mailto:tech@medsym.io">tech@medsym.io</a> as per your preference.
        </Typography>
        <Typography className={styles.text}>We look forward to hearing from you!</Typography>
      </div>
      <div className={styles.formWrapper}>
        <div className={styles.contactUsImage}></div>
        <form component="form" className={styles.contactUsForm} onSubmit={formik.handleSubmit}>
          <Typography className={styles.contactUsFormTitle}>Send Request</Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextInputField
                label="First Name"
                id="firstName"
                name="firstName"
                size="small"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                errorHelperText={formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextInputField
                label="Last Name"
                id="lastName"
                name="lastName"
                size="small"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                errorHelperText={formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextInputField
                label="Email"
                id="email"
                name="email"
                size="small"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                errorHelperText={formik.errors.email}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextInputField
                label="Message"
                id="message"
                name="message"
                multiline
                rows={4}
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.message && Boolean(formik.errors.message)}
                errorHelperText={formik.errors.message}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button sx={{ mt: '4px' }} fullWidth loading={formik.isSubmitting}>
                Send
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </section>
  );
}
