import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#0c7fda',
      contrastText: '#fff',
    },
    secondary: {
      main: '#818285',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
