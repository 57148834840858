import React from 'react';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';

import TextInputField from '../../../../../components/text-input-field/TextInputField';
import MdtOnboardingStepWrapper from './components/MdtOnboardingStepWrapper';
import MdtOnboardingDocumentUploadField, { uploadOnboardingDocument } from './components/MdtOnboardingDocumentUploadField';
import UserService from '../../../../../services/api/UserService';
import { useAuthContext } from '../../../../../hooks/useAuthContext';
import MdtOnboardingHelperText from './components/MdtOnboardingHelperText';
import SelectInputField from '../../../../../components/select-input-field/SelectInputField';
import { medicalRegistrationCuontryToTypes } from './constants/CredentialCertificatesStep.constants';
import { MenuItem } from '@mui/material';

const validationSchema = yup.object().shape({
  medicalRegistrationNumberType: yup.string().required('The field is required'),
  medicalRegistrationNumber: yup.string().required('The field is required'),
  document: yup.mixed().nullable()
    .test('fileSize', 'Files size is too large, it should be less than or equal to 3 MB', (value) => value === null || !value.size || value.size <= 3_000_000)
    .test('type', 'Unsupported Format', (value) => value === null || !value.size || ['application/pdf', 'image/jpeg', 'image/png'].includes(value.type))
});

export default function CredentialCertificatesStep({ profile, setProfile, handleNext, handleBack }) {
  const { user } = useAuthContext();


  const getMedicalRegistrationNumberCountry = (medicalRegistrationNumberType) => {
    for (const [country, type] of Object.entries(medicalRegistrationCuontryToTypes)) {
      if (type === medicalRegistrationNumberType) {
        return country;
      }
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...profile.qualityCredential, medicalRegistrationNumberType: profile.qualityCredential.medicalRegistrationNumberType || medicalRegistrationCuontryToTypes[profile?.address?.country], country: getMedicalRegistrationNumberCountry(profile.qualityCredential.medicalRegistrationNumberType) || profile?.address?.country },
    validationSchema,
    onSubmit: async (values) => {
      await uploadOnboardingDocument(formik.values.document, 'qualityCredential');

      if (profile.qualityCredential.medicalRegistrationNumber !== values.medicalRegistrationNumber || profile.qualityCredential.medicalRegistrationNumberType !== values.medicalRegistrationNumberType) {
        const mdtProfile = { medicalRegistrationNumber: values.medicalRegistrationNumber, medicalRegistrationNumberType: values.medicalRegistrationNumberType };
        await UserService.updateProfile(user.id, { mdtProfile });
      }

      setProfile({ ...profile, qualityCredential: values });
      handleNext();
    },
  });

  return (
    <MdtOnboardingStepWrapper
      name="Verify Credential Certificates"
      formik={formik}
      handleBack={handleBack}
      onSubmit={formik.handleSubmit}
    >
      <Grid item xs={12}>
        <SelectInputField
          label="Medical Registration Number Country"
          name="country"
          value={formik.values.country}
          defaultValue={formik.values.country}
          onChange={(event) => {
            const newCountry = event.target.value;
            formik.setFieldValue("country", newCountry);
            formik.setFieldValue("medicalRegistrationNumberType", medicalRegistrationCuontryToTypes[newCountry]);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.country && Boolean(formik.errors.country)}
          errorHelperText={formik.errors.country}
        >
          {Object.keys(medicalRegistrationCuontryToTypes).map((country) => (
            <MenuItem key={country} value={country}>{country}</MenuItem>
          ))}
        </SelectInputField>
      </Grid>

      <Grid item xs={12}>
        <SelectInputField
          label="Medical Registration Number Council/Type"
          name="medicalRegistrationNumberType"
          value={formik.values.medicalRegistrationNumberType}
          defaultValue={formik.values.medicalRegistrationNumberType}
          onChange={(event) => {
            const type = event.target.value;
            formik.setFieldValue("country", getMedicalRegistrationNumberCountry(type));
            formik.setFieldValue("medicalRegistrationNumberType", type);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.medicalRegistrationNumberType && Boolean(formik.errors.medicalRegistrationNumberType)}
          errorHelperText={formik.errors.medicalRegistrationNumberType}
        >
          {Object.values(medicalRegistrationCuontryToTypes).map((medicalRegistrationNumberType) => (
            <MenuItem key={medicalRegistrationNumberType} value={medicalRegistrationNumberType}>{medicalRegistrationNumberType}</MenuItem>
          ))}
        </SelectInputField>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInputField
          label="Medical Registration Number"
          name="medicalRegistrationNumber"
          value={formik.values.medicalRegistrationNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.medicalRegistrationNumber && Boolean(formik.errors.medicalRegistrationNumber)}
          errorHelperText={formik.errors.medicalRegistrationNumber}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <MdtOnboardingDocumentUploadField
          description="Please upload the document or certificate confirming your qualification (optional)"
          buttonLabel="Attach Document"
          name="document"
          onBlur={formik.handleBlur}
          uploaded={profile.qualityCredential.document}
          error={formik.touched.document && Boolean(formik.errors.document)}
          errorHelperText={formik.errors.document}
          disabled={formik.isSubmitting}
          setFieldValue={formik.setFieldValue}
        />
      </Grid>

      <MdtOnboardingHelperText />
    </MdtOnboardingStepWrapper>
  );
}
