import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import styles from './ToggleButtonField.module.scss';
import classNames from 'classnames';

export default function ToggleButtonField(props) {
  const [selected, setSelected] = React.useState(false);

  return (
    <ToggleButton
      className={classNames(styles.toggleButtonWrapper, props.className, { [styles.toggleButtonSelected]: selected })}
      value="check"
      color="primary"
      selected={selected}
      onChange={async () => {
        await props.onChange(!selected);
        setSelected(!selected);
      }}
    >
      {props.icon}
      {props.text}
    </ToggleButton>
  );
}
