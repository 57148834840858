import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import BookDemoForm from './book-demo-form/BookDemoForm';
import styles from './BookDemoPage.module.scss';

export default function BookDemoPage() {
  return (
    <Container className={styles.container}>
      <Typography className={styles.title} component="h1" variant="h4">
        Book a Demo
      </Typography>
      <BookDemoForm />
    </Container>
  );
}
