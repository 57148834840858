import SvgIcon from '@mui/material/SvgIcon';

export default function SidebarGroups() {
  return (
    <SvgIcon>
      <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.1904 0.59067C19.945 0.481008 21.6752 1.09024 22.979 2.28434C24.1731 3.58809 24.7823 5.31831 24.6848 7.08509V18.4655C24.7945 20.2323 24.1731 21.9625 22.9912 23.2663C21.6874 24.4604 19.945 25.0696 18.1904 24.96H6.80981C5.04302 25.0696 3.31279 24.4604 2.00902 23.2663C0.814915 21.9625 0.205678 20.2323 0.315341 18.4655V7.08509C0.205678 5.31831 0.814915 3.58809 2.00902 2.28434C3.31279 1.09024 5.04302 0.481008 6.80981 0.59067H18.1904ZM17.8736 6.15905C17.1181 5.40361 15.8996 5.40361 15.1442 6.15905L14.3278 6.98761C14.206 7.10946 14.206 7.3166 14.3278 7.43844C14.3278 7.43844 14.3517 7.46221 14.3943 7.50453L14.694 7.8026C14.8674 7.97509 15.0838 8.19051 15.3011 8.40712L16.0379 9.14359C16.1916 9.29803 16.2936 9.40152 16.3017 9.41236C16.4358 9.55857 16.5211 9.75353 16.5211 9.97285C16.5211 10.4115 16.1677 10.777 15.7169 10.777C15.5097 10.777 15.3148 10.6917 15.1807 10.5577L13.1459 8.53506C13.0484 8.43758 12.8778 8.43758 12.7803 8.53506L6.96821 14.3471C6.56611 14.7492 6.33461 15.2854 6.32242 15.858L6.24931 18.7458C6.24931 18.9042 6.29805 19.0504 6.40771 19.1601C6.51738 19.2697 6.66359 19.3307 6.82199 19.3307H9.68541C10.2703 19.3307 10.8308 19.0992 11.2572 18.6849L19.4576 10.4602C20.2008 9.70479 20.2008 8.48632 19.4576 7.74306L17.8736 6.15905Z"
          fill="#5D7285"
        />
      </svg>
    </SvgIcon>
  );
}
