import React from 'react';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';

import MdtOnboardingStepWrapper from './components/MdtOnboardingStepWrapper';
import MdtOnboardingHelperText from './components/MdtOnboardingHelperText';
import { Typography } from '@mui/material';


export default function OnboardingIntroductionStep({ handleNext }) {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async () => {
      handleNext();
    },
  });

  return (
    <MdtOnboardingStepWrapper name="Introduction to Onboarding Process" formik={formik} backDisabled>
      <Grid item xs={12} sm={12}>
        <Typography>
          <p>Welcome to Medsym!</p>
          <p>This onboarding process is designed to verify your credentials and get you access to the Multi-Disciplinary Team functionality.</p>

          <p>You will need the following to complete your onboarding process:</p>
          <ul>
            <li>Proof of address (e.g. Utility bill or bank statement issues within the last 3 months)</li>
            <li>Date of most recent annual appraisal</li>
            <li>Criminal record check or police clearance certificate (e.g. DBS check)</li>
            <li>A copy of your personal indemnity insurance document</li>
          </ul>
          <p>Let&apos;s get started!</p>
        </Typography>
      </Grid>

      <MdtOnboardingHelperText />
    </MdtOnboardingStepWrapper>
  );
}
