import React from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import AppleStoreLogo from './logos/apple-store.svg';
import GoogleStoreLogo from './logos/google-store.svg';


import styles from './Home.module.scss';

export default function Home({ id }) {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.info}>
        <Typography className={styles.descriptionTitle}>
          Innovative Solutions for Multidisciplinary Team Workflow
        </Typography>

        <Typography className={styles.infoTitle}>
          <div>
            <span className={styles.infoTitlePrescribing}>Secure,</span> Auditable, and Effortless {' '}
            <span className={styles.infoTitleToday}>Virtual MDTs</span>
          </div>
        </Typography>

        <Typography className={styles.description}>
          Medsym enables healthcare professionals to securely collaborate on Multidisciplinary Team (MDT) cases virtually, saving both time and money.
        </Typography>

        <div className={styles.links}>
          <Button variant="outlined" to="/sign-up" size="large" className={styles.joinButton} component={NavLink}>
            Register For Your 3 Month Free Trial
          </Button>

          <div className={styles.storeLinks}>
            <NavLink to="https://apps.apple.com/gb/app/medsym/id6599708612" target="_blank" rel="noopener noreferrer">
              <img src={AppleStoreLogo} />
            </NavLink>
            <NavLink to="https://play.google.com/store/apps/details?id=com.medsym.app&amp;hl=en_GB&amp;pli=1" target="_blank" rel="noopener noreferrer">
              <img src={GoogleStoreLogo} />
            </NavLink>
          </div>
        </div>
      </div>

      <div className={styles.imageContainer}>
        <div className={styles.image} />
      </div>
    </section>
  );
}
