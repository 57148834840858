import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';

import styles from './GroupMobileNav.module.scss';
import { GROUP_TYPE_NAV_ITEMS } from '../../../constants/group.constants';

const GroupMobileNav = ({ path }) => {
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <nav className={styles.nav}>
      <Tabs value={path} onChange={handleChange} aria-label="basic tabs example">
        {GROUP_TYPE_NAV_ITEMS.map((item) => (
          <Tab key={item.value} label={item.label} value={item.to} className={styles.tab} />
        ))}
      </Tabs>
    </nav>
  );
};

export default GroupMobileNav;
