import { Typography } from '@mui/material';
import styles from './Home.module.scss';
import { NavLink } from 'react-router-dom';

import Button from '@mui/material/Button';

export default function Home({ id }) {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.info}>
        <Typography className={styles.infoTitle}>
          <div>
            Start <span className={styles.infoTitlePrescribing}>Prescribing</span>
          </div>
          <div>Medical Cannabis</div>

          <span className={styles.infoTitleToday}>Today</span>
        </Typography>

        <Typography className={styles.description}>
          Our bespoke platform created by doctors with the goal of streamlining the prescribing process for medical
          professionals. The platform serves as a centralised hub for all medical needs, offering online management of
          prescriptions from start to finish. Users can access a dedicated admin team for assistance through a help
          line. Consultants can utilise the platform to securely communicate, share knowledge, and host
          multidisciplinary team meetings.
        </Typography>

        <Button variant="outlined" to="/sign-up" size="large" className={styles.joinButton} component={NavLink}>
          Join Us
        </Button>
      </div>

      <div className={styles.images}>
        <div className={styles.image1}></div>
        <div className={styles.imagesChild}>
          <div className={styles.image2}></div>
          <div className={styles.image3}></div>
        </div>
      </div>
    </section>
  );
}
