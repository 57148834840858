import React, { useEffect, useRef, useState } from 'react';
import styles from './MdtOnboardingPage.module.scss';
import AddressStep from './components/steps/AddressStep';
import IdentityStep from './components/steps/IdentityStep';
import CredentialCertificatesStep from './components/steps/CredentialCertificatesStep';
import RecentAnnualAppraisalStep from './components/steps/RecentAnnualAppraisalStep';
import CriminalBackgroundCheckStep from './components/steps/CriminalBackgroundCheckStep';
import ApplicationReviewStep from './components/steps/ApplicationReviewStep';
import { UserProfileStatus } from '../../../constants/user.constants';
import OrganisationAffiliationStep from './components/steps/OrganisationAffiliationStep/OrganisationAffiliationStep';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getOrganisations } from '../../../store/slices/organisations/organisationsThunks';
import { OrganisationView } from '../../../types/enums/OrganisationView';
import { getOnboardingProfile } from '../../../store/slices/onboardingProfile/onboardingProfileThunks';
import {
  selectOnboardingProfile,
  syncOnboardingProfile,
} from '../../../store/slices/onboardingProfile/onboardingProfileSlice';
import ErrorMessage from '../../../components/feedback/ErrorMessage/ErrorMessage';
import FullscreenLoader from '../../../components/feedback/FullscreenLoader/FullscreenLoader';
import { OrganisationCategory } from '../../../types/enums/OrganisationCategory';
import MdtOnboardingStepper from './components/MdtOnboardingStepper';
import OnboardingIntroductionStep from './components/steps/OnboardingIntroductionStep';
import { OnboardingApplicationStatus } from '../../../types/enums/OnboardingApplicationStatus';
import IndemnityInsuranceStep from './components/steps/IndemnityInsuranceStep';

export default function MdtOnboardingPage() {
  const dispatch = useAppDispatch();

  const { onboardingProfile, loading, error } = useAppSelector(selectOnboardingProfile);

  const [activeStep, setActiveStep] = useState(0);
  const hasUpdatedActiveStep = useRef(false);

  const isAddressCompleted = onboardingProfile?.address?.city && onboardingProfile?.address?.document;
  const isIdentityCompleted = onboardingProfile?.identity?.document;
  const isCredentialCertificateCompleted = onboardingProfile?.qualityCredential?.medicalRegistrationNumber;
  const isLatestAppraisalDateCompleted = onboardingProfile?.annualSalary?.latestAppraisalDate;
  const isCriminalBackgroundCompleted = onboardingProfile?.criminalBackground?.document;
  const isIndemnityInsuranceCompleted = onboardingProfile?.indemnityInsurance?.document;
  const isOnboardingOrganisationCompleted = onboardingProfile?.onboardingOrganisation?.id;
  const isApplicationReviewCompleted = UserProfileStatus.IN_REVIEW === onboardingProfile?.status;

  const steps = [
    { label: 'Introduction', completed: true },
    { label: 'Place of Registration/Medical Practise', completed: isAddressCompleted },
    { label: 'Identity', completed: isIdentityCompleted },
    { label: 'Credential Certificates', completed: isCredentialCertificateCompleted },
    { label: 'Recent Annual Appraisal', completed: isLatestAppraisalDateCompleted },
    { label: 'Criminal Background Check', completed: isCriminalBackgroundCompleted },
    { label: 'Indemnity Insurance', completed: isIndemnityInsuranceCompleted },
    { label: 'Organisation Affiliation', completed: isOnboardingOrganisationCompleted },
    { label: 'Application Review', completed: isApplicationReviewCompleted },
  ];

  const componentStepMap = {
    0: OnboardingIntroductionStep,
    1: AddressStep,
    2: IdentityStep,
    3: CredentialCertificatesStep,
    4: RecentAnnualAppraisalStep,
    5: CriminalBackgroundCheckStep,
    6: IndemnityInsuranceStep,
    8: ApplicationReviewStep,
  };
  const ActiveStepComponent = componentStepMap[activeStep];

  const isStepperDisabled = [
    UserProfileStatus.IN_REVIEW,
    UserProfileStatus.APPROVED,
    UserProfileStatus.REJECTED,
  ].includes(onboardingProfile?.status);

  useEffect(() => {
    dispatch(getOrganisations({ view: OrganisationView.ALL, category: OrganisationCategory.PRIVATE }));
    dispatch(getOnboardingProfile());
  }, []);

  useEffect(() => {
    if (onboardingProfile && !hasUpdatedActiveStep.current) {
      updateActiveStep(onboardingProfile);
      hasUpdatedActiveStep.current = true;
    }
  }, [onboardingProfile]);

  const handleSyncOnboardingProfile = (profile) => dispatch(syncOnboardingProfile(profile));

  const handleBack = () => setActiveStep((activeStep) => activeStep - 1);
  const handleNext = () => {
    setActiveStep((activeStep) => {
      const nextStep = activeStep + 1;

      // This is done because both update profile effect and handleNext change steps. Should be fixed after each step updates profile correctly
      return nextStep > 8 ? 8 : nextStep;
    });
  };

  const renderStep = (step) => {
    switch (step) {
      case 7:
        return <OrganisationAffiliationStep onBack={handleBack} onNext={handleNext} />;

      default:
        return (
          <ActiveStepComponent
            steps={steps}
            profile={onboardingProfile}
            setProfile={handleSyncOnboardingProfile}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            onboardingApplicationStatus={OnboardingApplicationStatus.MDT_STANDARD_PENDING}
          />
        );
    }
  };

  const updateActiveStep = (profile) => {
    if (
      [
        UserProfileStatus.IN_REVIEW,
        UserProfileStatus.CHANGES_REQUESTED,
        UserProfileStatus.APPROVED,
        UserProfileStatus.REJECTED,
      ].includes(profile.status)
    ) {
      setActiveStep(8);
    } else if (!isAddressCompleted) {
      setActiveStep(0);
    } else if (!isIdentityCompleted) {
      setActiveStep(2);
    } else if (!isCredentialCertificateCompleted) {
      setActiveStep(3);
    } else if (!isLatestAppraisalDateCompleted) {
      setActiveStep(4);
    } else if (!isCriminalBackgroundCompleted) {
      setActiveStep(5);
    } else if (!isIdentityCompleted) {
      setActiveStep(6);
    } else if (!isOnboardingOrganisationCompleted) {
      setActiveStep(7);
    } else setActiveStep(8);
  };

  const handleStepChange = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  if (loading)
    return (
      <div className={styles.container}>
        <FullscreenLoader />
      </div>
    );

  if (!onboardingProfile || error)
    return (
      <div className={styles.container}>
        <ErrorMessage>{error || 'Failed to load onboarding profile'}</ErrorMessage>
      </div>
    );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>Onboarding</h2>
      </div>
      <div className={styles.stepperContainer}>
        <MdtOnboardingStepper
          steps={steps}
          activeStep={activeStep}
          onStepChange={handleStepChange}
          disabled={isStepperDisabled}
        />

        <div className={styles.activeStepComponentContainer}>{renderStep(activeStep)}</div>
      </div>
    </div>
  );
}
