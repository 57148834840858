import React, { useCallback, useRef } from 'react';

import styles from './ThreadAttachFile.module.scss'
import classNames from 'classnames';

import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import IconButton from '@mui/material/IconButton';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { ThreadAttachmentOperation } from '../../../../../../constants/thread.constants';


export default function ThreadAttachFile(props) {
  const fileUploadRef = useRef();

  const onDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const onDragEnter = useCallback(() => {
    fileUploadRef.current.classList.add(styles.fileUploadActive);
  }, []);

  const onDragLeave = useCallback(() => {
    fileUploadRef.current.classList.remove(styles.fileUploadActive);
  }, []);

  const onDrop = useCallback((event) => {
    event.preventDefault();
    fileUploadRef.current.classList.remove(styles.fileUploadActive);

    const attachments = []
    for (const file of  event.dataTransfer.files) {
      attachments.push({ url: file.name, operation: ThreadAttachmentOperation.ADD, file });
    }

    props.onChange([...props.attachments, ...attachments]);
  }, []);


  const onChange = (event) => {
    const attachments = []
    for (const file of event.target.files) {
      attachments.push({ url: file.name, operation: ThreadAttachmentOperation.ADD, file });
    }

    props.onChange([...props.attachments, ...attachments]);
  }

  const onDelete = (deletedAttachment) => {
    if (deletedAttachment.operation === ThreadAttachmentOperation.ADD) {
      props.onChange(props.attachments.filter((attachment) => attachment.url !== deletedAttachment.url));
    } else {
      props.onChange(props.attachments.map((attachment) => attachment.url === deletedAttachment.url ? { ...attachment, operation: ThreadAttachmentOperation.REMOVE } : attachment));
    }
  }

  const displayAttachments = () => {
    return <div className={styles.fileAttachments}>
      {props.attachments.map((attachment, idx) =>
        attachment.operation !== ThreadAttachmentOperation.REMOVE && <div key={idx} className={styles.fileAttachment}>
          <AttachmentIcon />{attachment.url?.split('/')?.at(-1)}<IconButton onClick={() => onDelete(attachment)}><DeleteRoundedIcon /></IconButton>
        </div>
      )}
    </div>
  }

  const displayError = () => {
    return props.error && <div className={styles.fileAttachments}>{props.error}</div>
  }

  return (
    <div className={classNames(styles.container, { [styles.error]: props.error })}>
      <label
        htmlFor={props.id}
        className={styles.fileUpload}
        ref={fileUploadRef}
        onDragOver={onDragOver} 
        onDragEnter={onDragEnter} 
        onDragLeave={onDragLeave} 
        onDrop={onDrop} 
      >
        <FileUploadRoundedIcon />Click or drag file to this area to upload
        <input id={props.id} className={styles.uploadInput} name={props.name} type="file" multiple accept="image/jpeg,image/png,application/pdf" onChange={onChange} onBlur={props.onBlur} />
      </label>
      {displayAttachments()}
      {displayError()}
    </div>
  );
}
