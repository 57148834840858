import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Link from '@mui/material/Link';

import classes from './SignInForm.module.scss';
import TextInputField from '../../../../components/text-input-field/TextInputField';
import Button from '../../../../components/button/Button';
import PasswordInputField from '../../../../components/password-input-field/PasswordInputField';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().min(6, 'Password should be of minimum 6 characters length').required('Password is required'),
});

interface Props {
  onSubmit: (credentials: { email: string; password: string }) => Promise<void>;
}

const SignInForm: React.FC<Props> = ({ onSubmit }) => {
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <form className={classes.loginForm} onSubmit={formik.handleSubmit}>
      <TextInputField
        label="Email"
        id="email"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        errorHelperText={formik.errors.email}
        containerClassName={classes.input}
      />

      <PasswordInputField
        type="password"
        label="Password"
        id="password"
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && Boolean(formik.errors.password)}
        errorHelperText={formik.touched.password && formik.errors.password}
        helperText={
          <Link to="/password-reset" component={RouterLink} underline="none">
            Forgot your password?
          </Link>
        }
        className={classes.input}
      />

      <Button className={classes.button} fullWidth loading={formik.isSubmitting} type="submit">
        Sign In
      </Button>

      <div className={classes.signUpContainer}>
        <span>Don&apos;t have an account?</span>
        <Link to="/sign-up" component={RouterLink} underline="none">
          Sign Up
        </Link>
      </div>
    </form>
  );
};

export default SignInForm;
