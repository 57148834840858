import ApiClient from './ApiClient';
import { ApiRoute } from './ApiRoute';
import { getResponseData } from './adapterUtils';
import { ThreadReviewStatus } from '../../types/enums/ThreadReviewStatus';

class MessagesService {
  async addReview(threadId: string, payload: { text: string; reviewStatus: ThreadReviewStatus }) {
    const response = await ApiClient.post<{
      id: string;
    }>(`${ApiRoute.THREADS}/${threadId}/review`, payload);

    return getResponseData(response);
  }

  async addMessage(threadId: string, text: string) {
    const response = await ApiClient.post<{
      id: string;
    }>(`${ApiRoute.THREADS}/${threadId}/messages`, {
      text,
    });

    return getResponseData(response);
  }

  async updateMessage(messageId: string, text: string) {
    const response = await ApiClient.patch<{
      id: string;
    }>(`${ApiRoute.MESSAGES}/${messageId}`, {
      text,
    });

    return getResponseData(response);
  }

  async deleteMessage(messageId: string) {
    await ApiClient.delete<void>(`${ApiRoute.MESSAGES}/${messageId}`);
  }

  async getMessageAttachmentUploadSignature(messageId: string, params: { name: string }) {
    const response = await ApiClient.get<{ url: string }>(`${ApiRoute.MESSAGES}/${messageId}/attachments/signature`, { params });

    return getResponseData(response);
  }

  async getMessageAttachmentSignature(messageId: string, params: { url: string }) {
    const response = await ApiClient.get<{ url: string }>(`${ApiRoute.MESSAGES}/${messageId}/attachments`, { params });

    return getResponseData(response);
  }

  async deleteMessageAttachment(messageId: string, params: { url: string }) {
    await ApiClient.delete<void>(`${ApiRoute.MESSAGES}/${messageId}/attachments`, { params });
  }
}

export default new MessagesService();
