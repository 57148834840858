import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import Content from '../../components/content/Content';

export default function ProtectedPrintLayout() {
  const auth = useAuthContext();

  if (!auth.isAuthenticated()) {
    return <Navigate to="/" />;
  }

  return <Content>{auth.user && <Outlet />}</Content>;
}
