import React from 'react';

import styles from './ChosenMembershipPlan.module.scss';

import { Plan } from '../../types/Plan';
import { displayCentsWithCurrency } from '../../utils/money';
import classNames from 'classnames';

const ChosenMembershipPlan: React.FC<{ plan: Plan, className?: string }> = ({ plan, className }) => {
  return (
    <div className={classNames(styles.planContainer, className)}>
      <div className={styles.planCard}>
        <div className={styles.name}>{plan.name}</div>
        <ul>{plan?.description.split(',').map((item, index) => <li key={index}>{item}</li>)}</ul>
        <div className={styles.amountWrapper}>
          <div className={styles.amount}>{displayCentsWithCurrency(plan.amount, plan.currency)}</div>
          <div className={styles.interval}>per {plan.recurringInterval}</div>
        </div>
      </div>
    </div>
  )
}

export default ChosenMembershipPlan;
