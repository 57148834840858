import { useEffect, useRef } from 'react';

import { useIsWindowFocused } from './useIsWindowFocused';

type UsePollingProps = {
  callback: () => void;
  interval: number;
  skip?: boolean;
};

export const usePolling = ({ callback, interval, skip = false }: UsePollingProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const intervalId = useRef<any | null>(null);

  const isFocused = useIsWindowFocused();

  useEffect(() => {
    const executeCallback = () => {
      if (!skip && isFocused.current) {
        callback();
      }
    };

    intervalId.current = setInterval(executeCallback, interval);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [callback, interval, skip]);

  return { stop: () => clearInterval(intervalId.current!) };
};
