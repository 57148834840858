import React, { useState } from 'react';
import { Directory, Filesystem } from '@capacitor/filesystem';
import ReactPDF, { pdf } from '@react-pdf/renderer';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { useFeedback } from './useFeedback';

export const useDowloadPDFNative = ({
  content,
  fileName,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: React.ReactElement<ReactPDF.DocumentProps, string | React.JSXElementConstructor<any>> | undefined;
  fileName: string;
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { showErrorFeedback } = useFeedback();

  const handleDownload = async () => {
    try {
      setIsGenerating(true);

      const blob = await pdf(content).toBlob();

      const reader = new FileReader();

      setIsLoading(true);
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        try {
          const base64data = reader.result;
          const result = await Filesystem.writeFile({
            data: base64data as string,
            path: `${fileName}.pdf`,
            directory: Directory.Documents,
          });

          await FileOpener.openFile({
            path: result.uri,
          });
        } catch (error) {
          showErrorFeedback('Failed to load file');
        } finally {
          setIsLoading(false);
        }
      };

      reader.onerror = () => {
        showErrorFeedback('Failed to read file');

        setIsLoading(false);
      };
    } catch (error) {
      showErrorFeedback('Failed to generate PDF');
    } finally {
      setIsGenerating(false);
    }
  };

  return { handleDownload, isLoading: isLoading || isGenerating };
};
