import styles from './ThreadAuthenticityValidationPage.module.scss';
import Button from '../../../components/button/Button';
import { Grid, Typography } from '@mui/material';
import TextInputField from '../../../components/text-input-field/TextInputField';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MdtService from '../../../services/api/MdtService';
import { useState } from 'react';
import Alert from '@mui/material/Alert';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .min(2, `Invalid First Name field`)
    .max(255, `Invalid First Name field`)
    .required('First Name field is required'),
  lastName: yup
    .string()
    .min(2, `Invalid Last Name field`)
    .max(255, `Invalid Last Name field`)
    .required('Last Name field is required'),
  confirmationNumber: yup.string().required('Confirmation Number field is required'),
});

export default function ThreadAuthenticityValidationPage() {
  const [success, setSuccess] = useState();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      confirmationNumber: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const { body } = await MdtService.validateThreadAuthenticity(values);

        setSuccess(body.success);
        setSubmitting(false);
      } catch (error) {
        setSuccess(false);
      }
    },
  });

  const displayValidationResult = () => {
    if (typeof success === 'undefined') {
      return;
    }

    if (success) {
      return (
        <Alert sx={{ fontWeight: 600 }} severity="success">
          Validation Successful! The case is authentic.
        </Alert>
      );
    }
    return (
      <Alert sx={{ fontWeight: 600 }} severity="error">
        Validation Failed! The thread could not be verified as authentic.
      </Alert>
    );
  };
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography className={styles.title} component="h1" variant="h4">
          Thread Authenticity Validation
        </Typography>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.contactForm}>
          <form component="form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInputField
                  label="First Name"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  errorHelperText={formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField
                  label="Last Name"
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  errorHelperText={formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInputField
                  label="Confirmation Number"
                  id="confirmationNumber"
                  name="confirmationNumber"
                  value={formik.values.confirmationNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.confirmationNumber && Boolean(formik.errors.confirmationNumber)}
                  errorHelperText={formik.errors.confirmationNumber}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button loading={formik.isSubmitting} sx={{ mt: '4px' }} fullWidth>
                  Validate
                </Button>
              </Grid>

              <Grid item xs={12} sm={12}>
                {displayValidationResult()}
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
}
