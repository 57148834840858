import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import MedicalServicesRoundedIcon from '@mui/icons-material/MedicalServicesRounded';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';

import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import SidebarItem from './sidebar/components/SidebarItem';
import SidebarHomeIcon from './sidebar/components/icons/SidebarHomeIcon';
import MobileNavigation from './mobile-navigation/MobileNavigation';
import DesktopSidebar from './sidebar/Sidebar';
import SidebarExpandableItem from './sidebar/components/SidebarExpandableItem/SidebarExpandableItem';
import ThreadAddModal from '../../pages/private/groups/components/modals/ThreadAddModal';
import { GroupType } from '../../constants/group.constants';
import SidebarGroups from './sidebar/components/icons/SidebarGroups';
import { useAppSelector } from '../../store/hooks';
import { selectProfile } from '../../store/slices/profile/profileSlice';
import { getIsAdmin, getIsOrganisationAdmin } from '../../utils/roles';
import { useAuthContext } from '../../hooks/useAuthContext';
import LockIcon from '@mui/icons-material/Lock';
import { selectPermissions } from '../../store/slices/permissions/permissionsSlice';
import PaidIcon from '@mui/icons-material/Paid';
import { PlanTier } from '../../types/enums/PlanTier';

const getCurrentOrganisationId = (organisationIdFromParams, managedOrganisationIds) => {
  if (organisationIdFromParams && managedOrganisationIds?.includes(organisationIdFromParams)) {
    return organisationIdFromParams;
  }

  return managedOrganisationIds?.[0];
};

const Devices = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
};

const isRenderedOnDevice = (item, device) => item?.devices?.has(device);
const filterForDevice = (items, device) => items.filter((item) => isRenderedOnDevice(item, device));

export default function PrivateNavigation({ children }) {
  const navigate = useNavigate();
  const { profile } = useAppSelector(selectProfile);
  const { isMdtOnboarding, isAccountOnboarding } = useAuthContext();
  const { organisationId: organisationIdFromParams } = useParams();
  const { permissions: tierPermissions } = useAppSelector(selectPermissions);
  const hasThreadCreatePermission = tierPermissions && tierPermissions[`threads:${GroupType.MDT.toLocaleLowerCase()}:create`];

  const organisationId = getCurrentOrganisationId(organisationIdFromParams, profile?.managedOrganisationIds);

  const [openAddThreadModal, setOpenAddThreadModal] = useState(false);

  const inactiveNavItems = [
    {
      text: 'Onboarding',
      to: '/internal/onboarding',
      icon: <PlayCircleFilledWhiteIcon viewBox="2 2 20 20" sx={{ fill: '#5D7285' }} />,
      component: SidebarItem,
      devices: new Set([Devices.DESKTOP, Devices.MOBILE]),
    },
  ];

  const getMdtCreationIcon = () => {
    if (isMdtOnboarding) {
      return <LockIcon sx={{ fill: '#5d7285', width: '21px', height: '21px', margin: '4px' }} />;
    }

    if (!hasThreadCreatePermission) {
      return <PaidIcon sx={{ fill: '#5d7285', width: '21px', height: '21px', margin: '4px' }} />;
    }
  }

  const getMdtCreationStatusText = () => {
    if (isMdtOnboarding && profile.tier === PlanTier.PRO) {
      return 'pending';
    }
  }

  const basicNavItems = [
    {
      text: 'Home',
      to: '/internal/home',
      icon: <SidebarHomeIcon />,
      component: SidebarItem,
      devices: new Set([Devices.DESKTOP, Devices.MOBILE]),
    },
    {
      text: 'Groups',
      to: '/internal/groups',
      icon: <SidebarGroups />,
      component: ({ open }) => (
        <SidebarExpandableItem
          open={open}
          label="Groups"
          to="/internal/groups"
          icon={<SidebarGroups />}
          items={[
            { label: 'Public', to: '/internal/groups/public' },
            { label: 'Private', to: '/internal/groups/private' },
            { label: 'MDT', to: '/internal/groups/mdt', statusIcon: isMdtOnboarding && <LockIcon sx={{ fill: '#5d7285', width: '21px', height: '21px', margin: '4px' }} />},
          ]}
        />
      ),
      devices: new Set([Devices.DESKTOP, Devices.MOBILE]),
    },
    {
      text: 'Create MDT',
      component: SidebarItem,
      icon: <MedicalServicesRoundedIcon viewBox="2 2 22 22" sx={{ fill: '#5D7285' }} />,
      statusIcon: getMdtCreationIcon(),
      devices: new Set([Devices.MOBILE]),
      onClick: (event) => {
        event.preventDefault();
        
        if (isMdtOnboarding) {
          return navigate('/internal/onboarding/mdt-application');
        }

        if (!hasThreadCreatePermission) {
          return navigate('/internal/profile?tab=subscriptions');
        }

        setOpenAddThreadModal(true);
      },
    },
    {
      text: 'Specialists',
      to: '/internal/users',
      icon: <ArticleRoundedIcon viewBox="2 2 20 20" sx={{ fill: '#5D7285' }} />,
      component: SidebarItem,
      devices: new Set([Devices.DESKTOP, Devices.MOBILE]),
    },

    {
      text: 'Create MDT',
      component: SidebarItem,
      icon: <MedicalServicesRoundedIcon viewBox="2 2 21 21" sx={{ fill: '#5D7285' }} />,
      statusIcon: getMdtCreationIcon(),
      statusText: getMdtCreationStatusText(),
      devices: new Set([Devices.DESKTOP]),
      onClick: (event) => {
        event.preventDefault();

        if (isMdtOnboarding) {
          return navigate('/internal/onboarding/mdt-application');
        }

        if (!hasThreadCreatePermission) {
          return navigate('/internal/profile?tab=subscriptions');
        }

        setOpenAddThreadModal(true);
      },
    },
  ];

  const adminNavItems = [
    {
      text: 'Admin',
      to: '/internal/admin',
      icon: <AdminPanelSettingsIcon viewBox="2 2 20 20" sx={{ fill: '#5D7285' }} />,
      component: ({ open }) => (
        <SidebarExpandableItem
          open={open}
          label="Admin"
          to="/internal/admin"
          icon={<AdminPanelSettingsIcon viewBox="2 2 20 20" sx={{ fill: '#5D7285' }} />}
          items={[
            { label: 'Users', to: '/internal/admin/users' },
            { label: 'Pending Verification Users', to: '/internal/admin/pending-verification-applications' },
            { label: 'Pending MDT Users', to: '/internal/admin/pending-mdt-applications' },
            { label: 'Membership', to: '/internal/admin/membership/plans' },
            { label: 'Demo Requests', to: '/internal/admin/demos' },
            { label: 'Contact Us Requests', to: '/internal/admin/contact-us-requests' },
          ]}
        />
      ),
      devices: new Set([Devices.DESKTOP]),
    },
  ];

  const orgatisationNavItems = [
    {
      text: 'Organisations',
      to: '/internal/organisation',
      icon: <Diversity3RoundedIcon />,
      component: ({ open }) => (
        <SidebarExpandableItem
          open={open}
          label="Organisations"
          to="/internal/organisations"
          icon={<Diversity3RoundedIcon />}
          items={[
            { label: 'Users', to: `/internal/organisations/${organisationId}/users` },
            {
              label: 'Pending Applications',
              to: `/internal/organisations/${organisationId}/pending-applications`,
            },
          ]}
        />
      ),
      devices: new Set([Devices.DESKTOP, Devices.MOBILE]),
    },
  ];

  const navItems = useMemo(() => {
    if (isAccountOnboarding) return inactiveNavItems;

    const items = basicNavItems;

    const isAdmin = getIsAdmin(profile);
    const isOrganisationAdmin = getIsOrganisationAdmin(profile);

    if (isAdmin) {
      items.push(...adminNavItems);
    }

    if (isOrganisationAdmin) {
      items.push(...orgatisationNavItems);
    }

    return items;
  }, [profile, organisationId, hasThreadCreatePermission]);

  return (
    <>
      <DesktopSidebar navItems={filterForDevice(navItems, Devices.DESKTOP)}>
        <MobileNavigation navItems={filterForDevice(navItems, Devices.MOBILE)}>{children}</MobileNavigation>
      </DesktopSidebar>

      <ThreadAddModal open={openAddThreadModal} setOpen={setOpenAddThreadModal} type={GroupType.MDT} />
    </>
  );
}
