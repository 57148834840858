import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { Permissions } from '../../../types/Permissions';
import { getPermissions } from './permissionsThunks';

interface PermissionsState {
  permissions: Permissions | null;
  error: string | null | undefined;
  loading: boolean;
}

const initialState: PermissionsState = {
  permissions: null,
  loading: true,
  error: null,
};

const permissionsSlice = createSlice({
  name: SliceName.PERMISSIONS,
  initialState,
  reducers: {
    clearPermissions: (state) => {
      state.permissions = null;
      state.loading = true;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPermissions.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getPermissions.fulfilled, (state, action) => {
      state.loading = false;
      state.permissions = action.payload;
    });

    builder.addCase(getPermissions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clearPermissions } = permissionsSlice.actions;
export const selectPermissions = (state: RootState) => state.permissions;

export default permissionsSlice.reducer;
