import { useEffect } from 'react';
import { Slide, ToastContainer, toast } from 'react-toastify';

import styles from './FeedbackProvider.module.scss';
import { useAppSelector } from '../../../store/hooks';
import { selectFeedback } from '../../../store/slices/feedback/feedbackSlice';
import { Feedback } from '../../../types/Feedback';
import { FeedbackStatus } from '../../../types/enums/FeedbackStatus';
import FullscreenLoader from '../FullscreenLoader/FullscreenLoader';

const TOAST_DURATION = 4000;

const callFeedback = ({ status, message, options }: Feedback) => {
  const toastOptions = {
    autoClose: options?.duration ? Number(options.duration) : TOAST_DURATION,
  };

  switch (status) {
    case FeedbackStatus.SUCCESS:
      return toast.success(message, toastOptions);
    case FeedbackStatus.ERROR:
      return toast.error(message, toastOptions);
    case FeedbackStatus.WARNING:
      return toast.warning(message, toastOptions);
    case FeedbackStatus.INFO:
      return toast.info(message, toastOptions);
    default:
      return toast(message, toastOptions);
  }
};

const FeedbackProvider: React.FC = () => {
  const { feedback, loaderQueue } = useAppSelector(selectFeedback);

  useEffect(() => {
    if (feedback) {
      callFeedback(feedback);
    }
  }, [feedback]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={TOAST_DURATION}
        className={styles.toastContainer}
        newestOnTop={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={6}
        theme="light"
        transition={Slide}
      />

      {!!loaderQueue && <FullscreenLoader />}
    </>
  );
};

export default FeedbackProvider;
