import React from 'react';
import IconButton from '@mui/material/IconButton';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
  className?: string;
  disabled?: boolean;
}

const DownloadButton: React.FC<Props> = ({ onClick, className, disabled }) => {
  return (
    <IconButton onClick={onClick} className={className} disabled={disabled}>
      <DownloadRoundedIcon />
    </IconButton>
  );
};

export default DownloadButton;
