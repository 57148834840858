import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  disableMultifactorAuth,
  enableMultifactorAuth,
  getMultifactorAuthSettings,
  verifyMultifactorAuth,
} from '../../../../../store/slices/multifactorAuthSettings/multifactorAuthSettingsThunks';
import {
  cancelSettingMultifactorAuth,
  selectMultifactorAuthSettings,
} from '../../../../../store/slices/multifactorAuthSettings/multifactorAuthSettingsSlice';
import FullscreenLoader from '../../../../../components/feedback/FullscreenLoader/FullscreenLoader';
import classes from './MultifactorAuthManagement.module.scss';
import ErrorMessage from '../../../../../components/feedback/ErrorMessage/ErrorMessage';
import { Typography } from '@mui/material';
import Switch from '../../../../../components/inputs/Switch/Switch';
import VerifyMultifactorAuth from './VerifyMultifactorAuth/VerifyMultifactorAuth';
import { selectProfile } from '../../../../../store/slices/profile/profileSlice';

const MultifactorAuthManagement: React.FC = () => {
  const dispatch = useAppDispatch();

  const { profile } = useAppSelector(selectProfile);
  const { multifactorAuthSettings, error, loading, secret } = useAppSelector(selectMultifactorAuthSettings);

  useEffect(() => {
    dispatch(getMultifactorAuthSettings());
  }, []);

  const handleMultifactorAuthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) dispatch(enableMultifactorAuth());
    else dispatch(disableMultifactorAuth());
  };

  const handleVerifyMultifactorAuth = async (code: string) => await dispatch(verifyMultifactorAuth(code)).unwrap();

  const handleCancelSettingMultifactorAuth = () => dispatch(cancelSettingMultifactorAuth());

  if (loading || (!multifactorAuthSettings && !error)) {
    return (
      <div className={classes.loaderContainer}>
        <FullscreenLoader />
      </div>
    );
  }

  if (error || !multifactorAuthSettings) {
    return (
      <div className={classes.container}>
        <ErrorMessage>{error || 'An error occurred while fetching your MFA settings'}</ErrorMessage>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {!secret ? (
        <>
          <Typography className={classes.info}>
            Multi-Factor Authentication (MFA) is an essential security measure that adds an extra layer of protection to
            your account. Instead of just asking for your username and password, MFA requires additional credentials,
            such as a unique time-sensitive OTP (One-Time Password). This way, even if someone knows your password, they
            won&apos;t be able to access your account without the second verification.{' '}
          </Typography>

          <Switch
            value={multifactorAuthSettings.totp}
            label={
              multifactorAuthSettings.totp
                ? 'Two-Factor Authentication is enabled'
                : 'Two-Factor Authentication is disabled'
            }
            onChange={handleMultifactorAuthChange}
          />
        </>
      ) : (
        <VerifyMultifactorAuth
          secret={secret}
          onSubmit={handleVerifyMultifactorAuth}
          email={profile?.email}
          onCancel={handleCancelSettingMultifactorAuth}
        />
      )}
    </div>
  );
};

export default MultifactorAuthManagement;
