import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import styles from './PasswordResetForm.module.scss';
import TextInputField from '../../../../components/text-input-field/TextInputField';
import AuthService from '../../../../services/api/AuthService';

import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/Button';

const emailValidationSchema = yup.object({
  email: yup.string('Enter your email').email(`Invalid Email field`).required('Email field is required'),
});

const resetValidationSchema = yup.object({
  code: yup.string('Enter Verification Code').required('Verification Code field is required'),
  password: yup
    .string()
    .min(8, `Invalid Password field length`)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain upper case, lower case, number, special character',
    )
    .required('Password field is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirm Password doesn`t match')
    .required('Confirm Password field is required'),
});

export default function ResetPasswordForm() {
  const [isCodeSubmitted, setCodeSubmitted] = React.useState(false);
  const navigate = useNavigate();

  const emailFormik = useFormik({
    initialValues: { email: '' },
    validationSchema: emailValidationSchema,
    onSubmit: async (values) => {
      try {
        await AuthService.passwordReset(values);
        toast.success('Password reset code was successfully submitted');
        setCodeSubmitted(true);
      } catch (error) {
        console.error(error);
        toast.error('Password reset error');
      }
    },
  });

  const resetFormik = useFormik({
    initialValues: { code: '', password: '', confirmPassword: '' },
    validationSchema: resetValidationSchema,
    onSubmit: async (values) => {
      try {
        await AuthService.confirmPasswordReset({
          email: emailFormik.values.email,
          password: values.password,
          code: values.code,
        });
        toast.success('Password successfully restored');
        navigate('/sign-in');
      } catch (error) {
        console.error(error);
        toast.error('Password reset error');
      }
    },
  });

  const emailForm = (
    <form onSubmit={emailFormik.handleSubmit} className={styles.form} fullWidth>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInputField
            label="Email"
            name="email"
            value={emailFormik.values.email}
            onChange={emailFormik.handleChange}
            onBlur={emailFormik.handleBlur}
            error={emailFormik.touched.email && Boolean(emailFormik.errors.email)}
            errorHelperText={emailFormik.errors.email}
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button sx={{ mt: '4px' }} fullWidth loading={emailFormik.isSubmitting} type="submit">
            Continue
          </Button>
          Return to{' '}
          <Link to="/sign-in" component={RouterLink} underline="none" justifyContent="flex-end">
            {' '}
            Sign In
          </Link>
        </Grid>
      </Grid>
    </form>
  );

  const resetForm = (
    <form onSubmit={resetFormik.handleSubmit} className={styles.form} fullWidth>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInputField label="Email" name="email" disabled value={emailFormik.values.email} />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            label="Verification Code"
            name="code"
            helperText="Please check specified email and enter received code"
            onChange={resetFormik.handleChange}
            onBlur={resetFormik.handleBlur}
            value={resetFormik.values.code}
            error={resetFormik.touched.code && Boolean(resetFormik.errors.code)}
            errorHelperText={resetFormik.errors.code}
            inputProps={{ autoComplete: 'one-time-code' }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            type="password"
            label="Password"
            name="password"
            onChange={resetFormik.handleChange}
            onBlur={resetFormik.handleBlur}
            value={resetFormik.values.password}
            error={resetFormik.touched.password && Boolean(resetFormik.errors.password)}
            errorHelperText={resetFormik.errors.password}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            onChange={resetFormik.handleChange}
            onBlur={resetFormik.handleBlur}
            value={resetFormik.values.confirmPassword}
            error={resetFormik.touched.confirmPassword && Boolean(resetFormik.errors.confirmPassword)}
            errorHelperText={resetFormik.errors.confirmPassword}
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button sx={{ mt: '12px' }} fullWidth loading={resetFormik.isSubmitting} type="submit">
            Reset Password
          </Button>
          Return to{' '}
          <Link to="/sign-in" component={RouterLink} underline="none" justifyContent="flex-end">
            {' '}
            Sign In
          </Link>
        </Grid>
      </Grid>
    </form>
  );

  return isCodeSubmitted ? resetForm : emailForm;
}
