import ApiClient from './ApiClient';

class UserService {
  async getProfile() {
    const { status, data } = await ApiClient.get('/users/me');

    return { body: data, statusCode: status };
  }

  async getOnboardingProfile() {
    const { status, data } = await ApiClient.get(`/users/onboarding`);

    return { body: data, statusCode: status };
  }

  async updateProfile(id, payload) {
    const { status, data } = await ApiClient.patch(`/users/${id}`, { ...payload });

    return { body: data, statusCode: status };
  }

  async getOnboardingDocumentUploadUrl(params) {
    const { status, data } = await ApiClient.get(`/users/onboarding/upload-document-url`, { params });

    return { body: data, statusCode: status };
  }

  async getUsers(params) {
    const { status, data, headers } = await ApiClient.get(`/users`, { params });

    return { body: data, statusCode: status, headers };
  }

  async getOnboardingDocumentUrl(params) {
    const { status, data } = await ApiClient.get(`/users/onboarding/document-url`, { params });

    return { body: data, statusCode: status };
  }

  async getUser(id) {
    const { status, data } = await ApiClient.get(`/users/${id}`);

    return { body: data, statusCode: status };
  }

  setLocalUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getLocalUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  removeLocalUser() {
    localStorage.removeItem('user');
  }
}

export default new UserService();
