import React from 'react';
import { ListItem, Skeleton } from '@mui/material';

import styles from './ModalSkeletonMemberItem.module.scss';

const ModalSkeletonMemberItem = ({ className }) => {
  return (
    <ListItem className={className}>
      <div className={styles.content}>
        <Skeleton variant="circular" height={25} width={25} className={styles.avatarSkeleton} />

        <Skeleton variant="rounded" height={15} width={160} className={styles.name} />
        <Skeleton variant="rounded" height={15} width={60} />

        <Skeleton variant="rounded" height={15} width={140} className={styles.sceletonMobile} />

        <div className={styles.checkboxContainer}>
          <Skeleton variant="rounded" height={16} width={16} />
        </div>
      </div>
    </ListItem>
  );
};

export default ModalSkeletonMemberItem;
