import React, { useEffect } from 'react';
import styles from './PaymentFailurePage.module.scss';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearPlan, selectPlan } from '../../../store/slices/membershipPlan/membershipPlanSlice';
import { getPlan } from '../../../store/slices/membershipPlan/membershipPlanThunks';
import { useLocation } from 'react-router-dom';
import ChosenMembershipPlan from '../../../components/chosen-membership-plan/ChosenMembershipPlan';

const PaymentFailurePage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get('planId');

  const dispatch = useAppDispatch();
  const { plan } = useAppSelector(selectPlan);

  useEffect(() => {
    if (planId) {
      dispatch(getPlan(planId))
    }

    return () => { dispatch(clearPlan()); }
  }, [planId]);

  return (
    <div className={styles.container}>
      <div className={styles.containerItem}>
        <CancelOutlinedIcon className={styles.icon} />
        <h2>Failed to Purchase Subscription</h2>

        {plan && <ChosenMembershipPlan plan={plan} className={styles.planContainer} />}
      </div>
    </div>
  );
}

export default PaymentFailurePage;
