import React, { useEffect, useState } from 'react';
import styles from './PaymentSuccessPage.module.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearPlan, selectPlan } from '../../../store/slices/membershipPlan/membershipPlanSlice';
import { getPlan } from '../../../store/slices/membershipPlan/membershipPlanThunks';
import { useLocation, useNavigate } from 'react-router-dom';
import ChosenMembershipPlan from '../../../components/chosen-membership-plan/ChosenMembershipPlan';
import AuthService from '../../../services/api/AuthService';

const PaymentSuccessPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { plan } = useAppSelector(selectPlan);

  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get('planId');

  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    if (planId) {
      dispatch(getPlan(planId));
      AuthService.removeLocalTokens();
    }

    const timer = setTimeout(() => {
      navigate('/');
    }, 10000);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
    }, 1000);

    return () => {
      clearTimeout(timer);
      clearInterval(countdownInterval);
      dispatch(clearPlan());
    };
  }, [planId, dispatch, navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.containerItem}>
        <CheckCircleOutlineIcon className={styles.icon} />
        <h2>Thanks for subscribing</h2>

        <div className={styles.helperText}>
          You will be redirected in {countdown} seconds.
        </div>

        {plan && <ChosenMembershipPlan plan={plan} className={styles.planContainer} />}

        <div className={styles.containerItemHelperText}>
          Please note that it might take a few minutes for the changes to fully propagate. To expedite the process, you may re-login.
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
