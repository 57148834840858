import ApiClient from './ApiClient';
import { ApiRoute } from './ApiRoute';
import { getResponseData, getTotalCountHeader } from './adapterUtils';
import { Organisation } from '../../types/Organisation';
import { OrganisationsFilters } from '../../types/OrganisationsFilters';
import { UserExtended } from '../../types/User';
import { GetOrganisationUsersDTO } from '../../types/DTOs/GetOrganisationUsersDTO';
import { OnboardingReviewDTO } from '../../types/DTOs/OnboardingReviewDTO';

class OrganisationsService {
  async get(filters?: OrganisationsFilters) {
    const response = await ApiClient.get<Organisation[]>(ApiRoute.ORGANISATIONS, {
      params: filters,
    });

    return getResponseData(response);
  }

  async getOrganisationUsers({ organisationId, ...params }: GetOrganisationUsersDTO) {
    const response = await ApiClient.get<UserExtended[]>(
      `${ApiRoute.ORGANISATIONS}/${organisationId}/${ApiRoute.USERS}`,
      {
        params,
      },
    );

    return { items: getResponseData(response), totalCount: getTotalCountHeader(response) };
  }

  async updateOrganisationUser({
    userId,
    organisationId,
    data,
  }: {
    userId: string;
    organisationId: string;
    data: { mdtProfile?: OnboardingReviewDTO };
  }) {
    const response = await ApiClient.patch<{
      id: string;
    }>(`${ApiRoute.ORGANISATIONS}/${organisationId}/users/${userId}`, data);

    return getResponseData(response).id;
  }

  async getUserDocument({ s3Url, organisationId, userId }: { s3Url: string; organisationId: string; userId: string }) {
    const response = await ApiClient.get<{
      url: string;
    }>(`${ApiRoute.ORGANISATIONS}/${organisationId}/users/${userId}/document-url`, {
      params: { url: s3Url },
    });

    return getResponseData(response).url;
  }
}

export default new OrganisationsService();
