import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font, Link } from '@react-pdf/renderer';

import logo from '../../../../../assets/logo.png';
import { Thread, ThreadMessage } from '../../../../../types/Thread';
import { FormattedMessageStatus, MessageType } from '../../../../../constants/message.constants';
import { formatDateLong } from '../../../../../utils/date';
import { convertHtmlToPlainText } from '../../../../../utils/editor';
import { PdfBasicStyles, PdfInterFamilyFont } from '../../../../../constants/pdf-renderer';
import { FormattedThreadStatus } from '../../../../../constants/thread.constants';
import { User } from '../../../../../types/User';

Font.register(PdfInterFamilyFont);
const styles = StyleSheet.create(PdfBasicStyles);

const Section: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <View style={styles.section}>{children}</View>
);

const Row: React.FC<{ children: React.ReactNode }> = ({ children }) => <View style={styles.row}>{children}</View>;

const H2: React.FC<{ children: React.ReactNode }> = ({ children }) => <Text style={styles.h2}>{children}</Text>;

const Email: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <View style={[styles.row, styles.italic]}>
    <Text>{'<'}</Text>
    <Text style={{ textDecoration: 'underline' }}>{children}</Text>
    <Text>{'>'}</Text>
  </View>
);

interface Props {
  thread: Thread | null;
  withMessages?: boolean;
}

const ThreadToPDF: React.FC<Props> = ({ thread, withMessages }) => {
  if (!thread) {
    return;
  }

  const getMetadata = () => {
    const review = thread.messages?.findLast(({ type }) => type === MessageType.REVIEW);
    const reviewMetadata = review
      ? [
          <Row key={3}>
            <Text style={styles.bold}>Reviewed By: </Text>
            <Text>
              {review.user?.firstName} {review.user?.lastName}{' '}
            </Text>
            <Email>{review.user?.email}</Email>
          </Row>,
          <Row key={4}>
            <Text style={styles.bold}>Reviewed At: </Text>
            <Text>{formatDateLong(review.createdAt)}</Text>
          </Row>,
        ]
      : [];

    return [
      <Row key={0}>
        <Text style={styles.bold}>Status: </Text>
        <Text>{FormattedThreadStatus[thread?.status]}</Text>
      </Row>,
      <Row key={1}>
        <Text style={styles.bold}>Created By: </Text>
        <Text>
          {thread?.user?.firstName} {thread?.user?.lastName}{' '}
        </Text>
        <Email>{thread.user?.email}</Email>
      </Row>,
      <Row key={2}>
        <Text style={styles.bold}>Created At: </Text>
        <Text>{formatDateLong(thread?.createdAt)}</Text>
      </Row>,
      ...reviewMetadata,
    ];
  };

  const getMember = (member: User) => (
    <View style={styles.row} key={member.email}>
      <Text>
        • {member.firstName} {member.lastName}{' '}
      </Text>
      <Text>[{member.role?.toLowerCase()}] </Text>
      <Email>{member.email}</Email>
    </View>
  );

  const getMessage = (message: ThreadMessage) => {
    return (
      <View key={message.id} style={{ padding: '16px', borderTop: '1px solid #afb8cf' }}>
        <View style={styles.row}>
          <Text>
            {message.user.firstName} {message.user.lastName}{' '}
          </Text>
          <Email>{message.user.email}</Email>
          {message.type === MessageType.REVIEW && <Text> {FormattedMessageStatus[message.reviewStatus!]}</Text>}
        </View>

        <Text style={[styles.light, { fontSize: '10px' }]}>{formatDateLong(message.createdAt)}</Text>
        <Text style={{ marginTop: '4px' }}>{convertHtmlToPlainText(message.text)}</Text>
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Link src="https://medsym.io/">
            <Image style={styles.logo} src={logo} />
          </Link>
        </View>
        <Section>
          <H2>Metadata</H2>
          {getMetadata()}
        </Section>
        <Section>
          <H2>Members</H2>
          {thread.members.map((member) => getMember(member))}
        </Section>
        <Section>
          <H2>Title</H2>
          <Text>{thread.title}</Text>
        </Section>
        <Section>
          <H2>Description</H2>
          <Text>{convertHtmlToPlainText(thread.text)}</Text>
        </Section>
        {withMessages && (
          <Section>
            <H2>Messages</H2>
            {thread.messages.map((message) => getMessage(message))}
          </Section>
        )}
        <View
          style={[
            styles.light,
            {
              color: '#00000095',
              fontSize: '10px',
              position: 'absolute',
              bottom: '0px',
              textAlign: 'center',
              flexDirection: 'column',
              margin: '8px 16px',
              width: '100vw',
            },
          ]}
        >
          <Text>12-18 Hoxton Street, London, United Kingdom, N1 6NG</Text>
          <Text>info@medsym.io +44-20-7097-4918</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ThreadToPDF;
