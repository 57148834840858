import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalTitle from '../../../../../components/modal-title/ModalTitle';
import Modal from '../../../../../components/modal/Modal';
import Search from '../../../../../components/search/Search';
import { debounce } from '../../../../../utils/debounce';
import { List } from '@mui/material';
import styles from './GroupAddMemberModal/GroupAddMemberModal.module.scss';
import { getUsersSearchParams } from '../../../../../utils/userSearch';
import FilterSelect from '../../../../../components/filter-select/FilterSelect';
import { countries, specialities } from '../../../../public/sign-up/sign-up-form/SignUpForm.constants';
import { UserGroupRole } from '../../../../../constants/user.constants';
import { useAuthContext } from '../../../../../hooks/useAuthContext';
import { getOffset } from '../../../../../utils/url';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MdtService from '../../../../../services/api/MdtService';
import ModalMemberItem from './components/ModalMemberItem';
import ModalSkeletonMemberItem from './components/ModalSkeletonMemberItem';

export default function ThreadAddMemberModal({ open, setOpen, groupId, memberIds, setMemberIds }) {
  const [users, setUsers] = useState(null);
  const auth = useAuthContext();

  const [search, setSearch] = useState('');
  const [specialitySelect, setSpecialitySelect] = useState('');
  const [countrySelect, setCountrySelect] = useState('');

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchUsers = async (pageNum) => {
    const { body, headers } = await MdtService.getGroupUsers(groupId, {
      speciality: specialitySelect || undefined,
      country: countrySelect || undefined,
      offset: getOffset(pageNum),
      ...getUsersSearchParams(search),
    });

    const usersLength = users?.length || 0;
    if (usersLength + body.length >= headers['x-total-count']) {
      setHasMore(false);
    } else {
      setPage((prevPage) => prevPage + 1);
    }
    setUsers((prevUsers) => {
      if (prevUsers === null) return body;

      return prevUsers.concat(body);
    });
  };

  useEffect(() => {
    open && fetchUsers(1).catch((error) => console.error(error));

    return () => resetInfiniteScroll();
  }, [open, search, specialitySelect, countrySelect]);

  const resetInfiniteScroll = () => {
    setUsers(null);
    setPage(1);
    setHasMore(true);
  };

  const isThreadMember = (user) => memberIds.includes(user.id);

  const onAddUserToThread = (user) => {
    setMemberIds([...memberIds, user.id]);
  };

  const onRemoveUserFromThread = (user) => {
    setMemberIds(memberIds.filter((memberId) => memberId !== user.id));
  };

  const onSpecialitySelectChange = async (event) => {
    const selectValue = event.target.value ? event.target.value : undefined;

    setUsers([]);
    setPage(1);
    setSpecialitySelect(selectValue);
  };

  const onCountrySelectChange = async (event) => {
    const selectValue = event.target.value ? event.target.value : undefined;

    setUsers([]);
    setPage(1);
    setCountrySelect(selectValue);
  };

  const onSearchChange = async (event) => {
    const term = event.target.value;

    setUsers([]);
    setPage(1);
    setSearch(term);
  };

  const debouncedOnSearchChange = debounce(onSearchChange, 1000);
  const displayUsers = () => {
    if (!users) {
      return (
        <List className={styles.list}>
          {new Array(4).fill().map((_, index) => (
            <ModalSkeletonMemberItem key={index} className={styles.listItem} />
          ))}
        </List>
      );
    }

    return <List className={styles.list}>
      {users.map((user) => (
        <ModalMemberItem
          key={user.id}
          user={user}
          disabled={user.role === UserGroupRole.MANAGER || user.id === auth?.user?.id}
          className={styles.listItem}
          onAdd={() => onAddUserToThread(user)}
          onRemove={() => onRemoveUserFromThread(user)}
          checked={isThreadMember(user)}
        />
      ))}
      </List>;
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} className={styles.modal}>
        <form onSubmit={(e) => e.preventDefault()}>
          <ModalTitle>Add Thread Members</ModalTitle>
          <div className={styles.search}>
            <Search
              placeholder="Search for a user"
              value={search}
              onChange={(event) => debouncedOnSearchChange(event)}
            />
            <div className={styles.filters}>
              <FilterSelect
                onChange={onSpecialitySelectChange}
                label="Speciality"
                name="speciality"
                value={specialitySelect}
                options={specialities.map((speciality) => ({
                  name: speciality.toLocaleLowerCase(),
                  label: speciality,
                }))}
              />

              <FilterSelect
                onChange={onCountrySelectChange}
                label="Country"
                name="country"
                value={countrySelect}
                options={countries.map((speciality) => ({ name: speciality, label: speciality }))}
              />
            </div>
          </div>

          <InfiniteScroll
            className={styles.infiniteScrollWrapper}
            dataLength={users?.length || 0}
            height={300}
            next={() => fetchUsers(page)}
            hasMore={hasMore}
            loader={
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box>
            }
          >
            {displayUsers()}
          </InfiniteScroll>
        </form>
      </Modal>
    </div>
  );
}
