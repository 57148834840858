import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { OnboardingProfile } from '../../../types/OnboardingProfile';
import { getOnboardingProfile, updateOnboardingProfile } from './onboardingProfileThunks';

interface OnboardingProfileState {
  onboardingProfile: OnboardingProfile | null;
  error: string | null | undefined;
  loading: boolean;
}

const initialState: OnboardingProfileState = {
  onboardingProfile: null,
  loading: true,
  error: null,
};

const onboardingProfileSlice = createSlice({
  name: SliceName.ONBOARDING_PROFILE,
  initialState,
  reducers: {
    // TODO: Remove this after each onboarding step uses thunk to update the profile
    syncOnboardingProfile: (state, action: PayloadAction<OnboardingProfile>) => {
      if (state.onboardingProfile) {
        state.onboardingProfile = {
          ...state.onboardingProfile,
          ...action.payload,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOnboardingProfile.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getOnboardingProfile.fulfilled, (state, action) => {
      state.onboardingProfile = action.payload;
      state.loading = false;
    });

    builder.addCase(getOnboardingProfile.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(updateOnboardingProfile.fulfilled, (state, action) => {
      state.onboardingProfile = action.payload;
    });
  },
});

export const { syncOnboardingProfile } = onboardingProfileSlice.actions;

export const selectOnboardingProfile = (state: RootState) => state.onboardingProfile;

export default onboardingProfileSlice.reducer;
