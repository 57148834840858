export const config = {
  appName: 'MedSym-test',
  apiUrl: 'https://api.test.medsym.io',
  pagination: {
    threadsPageSize: 10,
    usersPageSize: 15,
    demosPageSize: 10,
    contactUsRequestsPageSize: 10,
  },
  httpPolling: {
    groups: {
      enabled: true,
      interval: 30000,
    },
    group: {
      enabled: true,
      interval: 20000,
    },
    thread: {
      enabled: true,
      interval: 10000,
    },
  },
  googleTagId: 'GTM-W9FT7GTJ',
  isNative: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBVoIe3wAnuLupCrOWglyYGTpwVx08TX-Q',
    authDomain: 'medsym-test.firebaseapp.com',
    projectId: 'medsym-test',
    storageBucket: 'medsym-test.appspot.com',
    messagingSenderId: '573898256218',
    appId: '1:573898256218:web:c1f430848016931aaacd63',
  },
  firebaseWebPushSertificateKey:
    'BEVjNlC194Mjp4FOCkpfReoeMRAAA3bR_bn6XJPI0tK3PwIibfxEjOCxN0WS0yzx2iOevOgx13dIZdEausQwqE0',
};
