import React from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './HomeWithTitleAndBackground.module.scss';

interface Props {
  id?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
}

const HomeWithTitleAndBackground: React.FC<Props> = ({ id = 'home', title, description, className }) => {
  return (
    <section id={id} className={classNames(styles.section, className)}>
      <div className={styles.info}>
        <Typography className={styles.infoTitle}>{title}</Typography>

        <div className={styles.descriptionContainer}>
          <Typography className={styles.description}>{description}</Typography>
        </div>

        <Button variant="contained" to="/book-demo" size="large" className={styles.joinButton} component={NavLink}>
          Join Us
        </Button>
      </div>
    </section>
  );
};

export default HomeWithTitleAndBackground;
