import React, { useState } from 'react';
import { CircularProgress, ListItemButton, ListItemText } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Chip from '../../../chips/Chip/Chip';
import classes from './FileListItem.module.scss';

const TYPE_LABELS_MAP = {
  address: 'Address',
  identity: 'Identity',
  criminalBackground: 'Criminals Background',
  qualityCredential: 'Quality',
};

interface Props {
  url: string;
  onClick: () => Promise<void>;
}

const FileListItem: React.FC<Props> = ({ url, onClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [type, name] = url.split('/').slice(-2);

  const handleClick = async () => {
    try {
      setIsLoading(true);

      await onClick();
    } catch {
      // ignore
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ListItemButton color="primary" className={classes.container} onClick={handleClick} disabled={isLoading}>
      {isLoading ? <CircularProgress size={24} /> : <FileDownloadIcon />}

      <ListItemText primary={name} />

      {type in TYPE_LABELS_MAP && (
        <Chip color="default" size="small" label={TYPE_LABELS_MAP[type as keyof typeof TYPE_LABELS_MAP]} />
      )}
    </ListItemButton>
  );
};

export default FileListItem;
