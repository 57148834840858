import React from 'react';
import classNames from 'classnames';

import styles from './ErrorMessage.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const ErrorMessage: React.FC<Props> = ({ children, className }) => {
  return <p className={classNames(styles.error, className)}>Error: {children}</p>;
};

export default ErrorMessage;
