import React, { useState, useMemo } from 'react';

import dayjs from 'dayjs';
import styles from './MessageAttachedFile.module.scss';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import CircularProgress from '@mui/material/CircularProgress';
import { downloadS3File } from '../../../../../../utils/file';
import MessagesService from '../../../../../../services/api/MessagesService';
import { isS3URL } from '../../../../../../utils/url';
import { ThreadAttachment } from '../../../../../../types/Thread';

const MessageAttachedFile: React.FC<{ messageId: string, attachment: ThreadAttachment }> = ({ messageId, attachment }) => {
  const [attachmentDownloading, setAttachmentDownloading] = useState(false);

  const downloadFile = async () => {
    setAttachmentDownloading(true);
    try {
      const { url } = await MessagesService.getMessageAttachmentSignature(messageId, { url: attachment.url });
      await downloadS3File(url, getFileName());
    } catch (error) {
      toast.error('Failed to download message attachment');
    } finally {
      setAttachmentDownloading(false);
    }
  };

  const onClick = () => {
    if (isS3URL(attachment.url)) {
      downloadFile();
    }
  };

  const attachedFileClasses = useMemo(() => classNames([styles.attachedFile, { [styles.attachedFileDisabled]: !isS3URL(attachment.url) }]), [attachment.url]);
  const getFileName = (): string => attachment.url.split('/')?.at(-1) ?? '';
  const formattedDate = dayjs(attachment.createdAt).format('MMM DD YYYY H:mm A');

  return (
    <div className={attachedFileClasses} onClick={onClick}>
      <div className={styles.logo}>
        {attachmentDownloading || !isS3URL(attachment.url) ? (
          <CircularProgress size={28} />
        ) : (
          <>
            <FilePresentRoundedIcon key="fileIcon" className={styles.attachedFileIcon} />
            <FileDownloadRoundedIcon key="downloadIcon" className={styles.attachedDownloadIcon} />
          </>
        )}
      </div>
      <div>
        <div className={styles.filename}>{getFileName()}</div>
        <div className={styles.attachedFileDate}>{formattedDate}</div>
      </div>
    </div>
  );
}

export default MessageAttachedFile;
