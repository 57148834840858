import React, { useState } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styles from './SidebarExpandableList.module.scss';
import CircularProgress from '@mui/material/CircularProgress';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';

const SidebarNavigationLink = React.forwardRef((props, ref) => {
  const match = useMatch({ path: props.to, exact: true, end: false });

  if (match?.pattern?.exact) {
    return (
      <div ref={ref} className={classNames({ [styles.activeLinkClass]: match?.pattern?.exact })}>
        <NavLink {...props} />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <NavLink {...props} />
    </div>
  );
});

export default function SidebarExpandableList(props) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const displayIcon = () => {
    if (props.loading) {
      return <CircularProgress className={styles.loading} />;
    }

    if (props.icon) {
      return <ListItemIcon className={styles.icon}>{props.icon}</ListItemIcon>;
    }
  };

  return (
    <ListItem disablePadding className={styles.wrapper} onClick={handleClick}>
      <ListItemButton
        to={props.to}
        component={props.to && SidebarNavigationLink}
        className={styles.button}
        onClick={props.onClick}
        sx={{ justifyContent: props.open ? 'initial' : 'center', px: 2.5 }}
      >
        {displayIcon()}
        {props.open && <ListItemText className={styles.text} primary={props.text} />}

        <ListItemIcon className={classNames(styles.icon, styles.iconExpand)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
      </ListItemButton>
      <Collapse in={open} className={styles.collapseWrapper} timeout="auto" unmountOnExit>
        {props.items}
      </Collapse>
    </ListItem>
  );
}
