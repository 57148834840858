import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import ThreadAddModal from '../../../pages/private/groups/components/modals/ThreadAddModal';
import UserService from '../../../services/api/UserService';
import styles from './Sidebar.module.scss';
import SidebarChevron from './components/SidebarChevron';
import SidebarItem from './components/SidebarItem';
import SidebarSignoutIcon from './components/icons/SidebarSignoutIcon';
import { GroupType } from '../../../constants/group.constants.js';
import { useAppDispatch } from '../../../store/hooks';
import { signOut } from '../../../store/slices/auth/authThunks';
import Logo from '../../ui/Logo/Logo';
import LogoIcon from '../../ui/LogoIcon/LogoIcon';
import Chip from '../../chips/Chip/Chip';

const openedMixin = (theme) => ({
  width: '16rem',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `6.125rem`,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Sidebar(props) {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(true);

  const [openAddThreadModal, setOpenAddThreadModal] = useState(false);
  const [signOutLoading, setSignOutLoading] = useState(false);

  const profile = UserService.getLocalUser() || {};
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      setSignOutLoading(true);
      await dispatch(signOut()).unwrap();
      navigate('/');
      setSignOutLoading(false);
    } catch (error) {
      // ignore error
    }
  };

  const navigation = (
    <div className={styles.navigation}>
      <List className={styles.list}>
        {props.navItems.map((navItem) => (
          <navItem.component open={open} {...navItem} key={navItem.to || navItem.text} />
        ))}
      </List>
      <SidebarChevron open={open} setOpen={setOpen} />
      <List className={styles.list}>
        <SidebarItem
          open={open}
          to="/internal/profile"
          text={
            <span className={styles.profile}>
              {profile?.firstName} {profile?.lastName} 
              <Chip
                className={styles.tierChip}
                label={profile?.tier?.toLowerCase()}
              />
            </span>
          }
          icon={
            <Avatar className={styles.avatar}>
              {profile?.firstName?.slice(0, 1)}
              {profile?.lastName?.slice(0, 1)}
            </Avatar>
          }
        />
        <div className={styles.signout}>
          <SidebarItem
            open={open}
            text='Logout'
            loading={signOutLoading}
            className={styles.signout}
            icon={<SidebarSignoutIcon />}
            onClick={handleSignOut}
          />
        </div>
      </List>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <Drawer
        variant="permanent"
        className={styles.sidebar}
        sx={{
          '& .MuiDrawer-paper': {
            transition:
              'top 400ms cubic-bezier(0.4, 0, 0.6, 1), max-height 400ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          },
        }}
        classes={{ paper: styles.MuiDrawerPaper }}
        open={open}
      >
        <div className={styles.companyLogo} onClick={() => navigate('/')}>
          {open ? <Logo className={styles.logo} /> : <LogoIcon className={styles.logoIcon} />}
        </div>

        {navigation}
      </Drawer>

      <ThreadAddModal open={openAddThreadModal} setOpen={setOpenAddThreadModal} type={GroupType.MDT} />

      {props.children}
    </div>
  );
}
