import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';

import styles from './Search.module.scss';

export default function Search({ placeholder = 'Search for a group', search, onChange, onClick, className }) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={classNames(styles.search, className)} onClick={onClick}>
      <Box className={classNames(styles.box, { [styles.focusedSearch]: isFocused })}>
        <SearchIcon />
        <InputBase
          placeholder={placeholder}
          value={search}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={onChange}
        />
      </Box>
    </div>
  );
}
