import Content from '../components/content/Content';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { MdtProvider } from '../context/MdtContext';
import { useEffect } from 'react';
import PrivateNavigation from '../components/private-navigation/PrivateNavigation';
import { containsSubPath } from '../utils/url';
import { getPermissions } from '../store/slices/permissions/permissionsThunks';
import { clearPermissions } from '../store/slices/permissions/permissionsSlice';
import { useAppDispatch } from '../store/hooks';

export default function ProtectedLayout() {
  const { user, isAuthenticated, isAccountOnboarding, isMdtOnboarding } = useAuthContext();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(getPermissions())

    return () => { dispatch(clearPermissions()); }
  }, [])

  if (!isAuthenticated()) {
    return <Navigate to="/" />;
  }

  const isNotProtectedIgnore = !['onboarding', 'profile', 'payment-success', 'payment-failure'].some((path) => pathname.includes(path));
  if (isAccountOnboarding && isNotProtectedIgnore) {
    return <Navigate to="/onboarding" replace />;
  }

  if (containsSubPath(pathname, '/mdt') && isMdtOnboarding && isNotProtectedIgnore) {
    return <Navigate to="/internal/onboarding/mdt-application" replace />;
  }

  const displayMdtContent = () => {
    return (
      <MdtProvider>
        <PrivateNavigation>
          <Outlet />
        </PrivateNavigation>
      </MdtProvider>
    );
  };

  return (
    <>
      <Content>{user && displayMdtContent()}</Content>
    </>
  );
}
