import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import TextInputField from '../../../../../components/text-input-field/TextInputField';
import { useMdtContext } from '../../../../../hooks/useMdtContext';
import ModalTitle from '../../../../../components/modal-title/ModalTitle';
import ModalButton from '../../../../../components/modal-button/ModalButton';
import Modal from '../../../../../components/modal/Modal';
import styles from './GroupCreateModal.module.scss';
import { GroupType } from '../../../../../constants/group.constants';
import Select from '../../../../../components/inputs/Select/Select';
import { ORGANISATION_CATEGORY_OPTIONS } from '../../../../../constants/organisation';

const getNewGroupValidationSchema = (type) =>
  yup.object({
    name: yup.string().min(2).max(50, 'Max group name 50 characters').required('Name field is required'),
    description: yup
      .string()
      .max(1000, 'Max group description 1000 characters')
      .required('Description field is required'),
    category: type === GroupType.MDT ? yup.string().required('Category is required') : undefined,
  });

export default function GroupCreateModal({ createGroupModal, setCreateGroupModal, type }) {
  const [loading, setLoading] = useState(false);
  const { addGroup } = useMdtContext();

  const groupFormik = useFormik({
    initialValues: { name: '', description: '', type: type.toUpperCase(), category: '' },
    validationSchema: getNewGroupValidationSchema(type),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (values.type !== GroupType.MDT) {
          delete values.category;
        }

        await addGroup(values);
        toast.success('New group was successfully submitted');
        onClose();
      } catch (error) {
        const message = error?.response?.data?.message || 'Group creation error';
        toast.error(message);
      } finally {
        setLoading(false);
      }
    },
  });

  const onClose = () => {
    setCreateGroupModal(false);
    groupFormik.resetForm();
  };

  const title = type === GroupType.MDT ? 'Create Organisation' : 'Create Group';
  const groupTypeLabel = {
    [GroupType.MDT]: 'MDT',
    [GroupType.PUBLIC]: 'Public',
    [GroupType.PRIVATE]: 'Private',
  };

  return (
    <div>
      <Modal open={createGroupModal} className={styles.createGroupModal} onClose={onClose}>
        <form component="form" onSubmit={groupFormik.handleSubmit} fullWidth>
          <ModalTitle>{title}</ModalTitle>
          <TextInputField
            label="Name"
            id="name"
            name="name"
            value={groupFormik.values.name}
            onChange={groupFormik.handleChange}
            onBlur={groupFormik.handleBlur}
            error={groupFormik.touched.name && Boolean(groupFormik.errors.name)}
            errorHelperText={groupFormik.errors.name}
          />

          <TextInputField
            label="Description"
            type="description"
            id="description"
            name="description"
            value={groupFormik.values.description}
            onChange={groupFormik.handleChange}
            onBlur={groupFormik.handleBlur}
            error={groupFormik.touched.description && Boolean(groupFormik.errors.description)}
            errorHelperText={groupFormik.touched.description && groupFormik.errors.description}
          />

          <TextInputField
            label="Type"
            type="type"
            id="type"
            name="type"
            value={groupTypeLabel[type.toUpperCase()]}
            onChange={groupFormik.handleChange}
            onBlur={groupFormik.handleBlur}
            disabled={true}
            error={groupFormik.touched.type && Boolean(groupFormik.errors.type)}
            errorHelperText={groupFormik.touched.type && groupFormik.errors.type}
          />

          {type === GroupType.MDT && (
            <Select
              name="category"
              label="Category"
              value={groupFormik.values.category}
              onChange={groupFormik.handleChange}
              onBlur={groupFormik.handleBlur}
              options={[
                {
                  label: 'Select category',
                  value: '',
                },
                ...ORGANISATION_CATEGORY_OPTIONS,
              ]}
              fullWidth
              error={groupFormik.touched.category && groupFormik.errors.category}
            />
          )}

          <ModalButton loading={loading} type="submit">
            Create
          </ModalButton>
        </form>
      </Modal>
    </div>
  );
}
