import React from 'react';

import ErrorMessage from '../../../components/feedback/ErrorMessage/ErrorMessage';

const NotFoundPage: React.FC = () => {
  return (
    <div>
      <ErrorMessage>Page not found</ErrorMessage>
    </div>
  );
};

export default NotFoundPage;
