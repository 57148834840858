import axios from 'axios';

class S3Service {
  async signedUrlDocumentUpload(url, formData) {
    const headers = { 'x-amz-server-side-encryption': 'AES256', 'Content-Type': formData.type };
    const { status, data } = await axios.put(url, formData, { headers });

    return { body: data, statusCode: status };
  }

  async downloadObject(url) {
    const { status, data } = await axios.get(url, { responseType: 'blob' });

    return { body: data, statusCode: status };
  }
}

export default new S3Service();
