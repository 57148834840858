import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import styles from './DasboardPersonalThreads.module.scss';
import Typography from '@mui/material/Typography';
import MdtService from '../../../../services/api/MdtService';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Skeleton from '@mui/material/Skeleton';
import { sanitize } from 'dompurify';

import { NavLink } from 'react-router-dom';
import { ThreadStatus, ThreadStatusMap } from '../../../../constants/thread.constants';
import { GroupType } from '../../../../constants/group.constants';
import Chip from '../../../../components/chips/Chip/Chip';

export default function DasboardPersonalThreads() {
  const [threads, setThreads] = useState(null);
  useEffect(() => {
    const fetchThreads = async () => {
      const { body } = await MdtService.getThreads();
      setThreads(body);
    };
    fetchThreads().catch((error) => console.error(error));
  }, []);

  const getFormattedGroupType = (type) => {
    const typeMap = {
      [GroupType.PUBLIC]: 'Public',
      [GroupType.PRIVATE]: 'Private',
      [GroupType.MDT]: 'MDT',
    };

    return typeMap[type];
  };

  const displayThread = (thread) => {
    return (
      <ListItem key={thread.id} className={styles.thread} disablePadding>
        <ListItemButton
          className={styles.itemButton}
          component={NavLink}
          to={`/internal/groups/${thread.group.type.toLowerCase()}/${thread.group.id}/threads/${thread.id}`}
        >
          <div className={styles.itemInfo}>
            <div className={styles.date}>
              <Typography>{dayjs(thread.createdAt).format('MMM DD, YYYY')}</Typography>
            </div>
            <div className={styles.title}>
              <Typography className={styles.textTitle}>{thread.title}</Typography>
              <div className={styles.threadLabels}>
                {thread.status !== ThreadStatus.NOT_APPLICABLE && (
                  <Chip label={ThreadStatusMap[thread.status].label} color={ThreadStatusMap[thread.status].color} />
                )}
                <Chip label={getFormattedGroupType(thread.group.type)} />
              </div>
            </div>

            <div className={styles.text}>
              <Typography className={styles.ellipsis} dangerouslySetInnerHTML={{ __html: sanitize(thread.text) }} />
            </div>
          </div>
        </ListItemButton>
      </ListItem>
    );
  };

  const displayThreads = () => {
    return <List className={styles.list}>{threads.map((thread) => displayThread(thread))}</List>;
  };

  const displayEmptyList = () => {
    return <Typography className={styles.emptyList}>No Personal Threads Available</Typography>;
  };

  if (threads === null) {
    return (
      <Grid className={styles.container} container direction="column" justify="center">
        <div className={styles.header}>
          <Typography>The Latest Personal Threads</Typography>
        </div>
        <List className={styles.list}>
          {new Array(4).fill(
            <ListItem className={styles.thread} disablePadding>
              <ListItemButton className={styles.itemButton}>
                <div className={styles.itemInfo}>
                  <div className={styles.date}>
                    <Typography>
                      <Skeleton variant="rounded" height="0.75rem" width="10vw" />
                    </Typography>
                  </div>
                  <div className={styles.title}>
                    <Typography className={styles.textTitle}>
                      <Skeleton variant="rounded" height="1.25rem" width="60vw" />
                    </Typography>
                    <div className={styles.threadLabels}>
                      <Typography>{<Skeleton variant="rounded" height="1.5rem" width="4rem" />}</Typography>
                    </div>
                  </div>

                  <div className={styles.text}>
                    <Skeleton variant="rounded" height="0.75rem" sx={{ mb: '2px' }} />
                    <Skeleton variant="rounded" height="0.75rem" sx={{ mb: '2px' }} />
                    <Skeleton variant="rounded" height="0.75rem" sx={{ mb: '2px' }} />
                  </div>
                </div>
              </ListItemButton>
            </ListItem>,
          )}
        </List>
      </Grid>
    );
  }

  return (
    <Grid className={styles.container} container direction="column" justify="center">
      <div className={styles.header}>
        <Typography>The Latest Personal Threads</Typography>
      </div>

      {threads.length ? displayThreads() : displayEmptyList()}
    </Grid>
  );
}
