import React, { useState, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import * as CookieConsent from 'vanilla-cookieconsent';

import styles from './ProfilePage.module.scss';
import Account from './components/Account';
import PasswordManagement from './components/PasswordManagement';
import MultifactorAuthManagement from './components/MultifactorAuthManagement/MultifactorAuthManagement';
import NotificationsManagement from './components/NotificationsManagement/NotificationsManagement';
import SubscriptionsManagement from './components/SubscriptionsManagement/SubscriptionsManagement';


export default function ProfilePage() {
  const [value, setValue] = useState('1');

  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  const handleChange = (_, newValue) => {
    setValue(newValue);    
  };

  useEffect(() => {
    if (tab === 'subscriptions') {
      setValue('3')
    }
  }, [])

  return (
    <div className={styles.container}>
      <TabContext value={value}>
        <TabList className={styles.tabList} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          <Tab className={styles.tabItem} label="Account" value="1" />
          <Tab className={styles.tabItem} label="Password Management" value="2" />
          <Tab className={styles.tabItem} label="Subscriptions" value="3" />
          <Tab className={styles.tabItem} label="Multi-Factor Authentication" value="4" />
          <Tab className={styles.tabItem} label="Notifications Management" value="5" />
          <Tab className={styles.tabItem} label="Consent Preferences" value="6" onClick={() => {
            setValue(value);
            return CookieConsent.showPreferences();
          }} />
        </TabList>

        <TabPanel className={styles.tabPanel} value="1">
          <Account />
        </TabPanel>
        <TabPanel className={styles.tabPanel} value="2">
          <PasswordManagement />
        </TabPanel>
        <TabPanel className={styles.tabPanel} value="3">
          <SubscriptionsManagement />
        </TabPanel>
        <TabPanel className={styles.tabPanel} value="4">
          <MultifactorAuthManagement />
        </TabPanel>
        <TabPanel className={styles.tabPanel} value="5">
          <NotificationsManagement />
        </TabPanel>
      </TabContext>
    </div>
  );
}
