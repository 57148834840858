import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';

import Typography from '@mui/material/Typography';
import TextInputField from '../../../../components/text-input-field/TextInputField';

import * as yup from 'yup';
import Button from '../../../../components/button/Button';

import styles from './SignUpForm.module.scss';

const validationSchema = yup.object({
  firstName: yup.string().min(2, `Invalid First Name field`).required('First Name field is required'),
  lastName: yup.string().min(2, `Invalid Last Name field`).required('Last Name field is required'),
  email: yup.string().email(`Invalid Email field`).required('Email field is required'),
  termAndPrivacy: yup.boolean().oneOf([true], `Terms and Privacy field should be checked`),
  password: yup
    .string()
    .min(8, `Invalid Password field length`)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain upper case, lower case, number, special character',
    )
    .required('Password field is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirm Password doesn`t match')
    .required('Confirm Password field is required'),
});

export default function SignUpForm({ onSubmit }) {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      termAndPrivacy: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        await onSubmit(values);
      } catch (error) {
        error.response?.data?.details?.forEach((detail) => setFieldError(detail.path, detail.message));
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <form component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInputField
            fullWidth
            label="First Name"
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            errorHelperText={formik.errors.firstName}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInputField
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            errorHelperText={formik.errors.lastName}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            errorHelperText={formik.errors.email}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            type="password"
            label="Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            errorHelperText={formik.errors.password}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            errorHelperText={formik.errors.confirmPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            className={{
              [styles.consentError]: formik.touched.termAndPrivacy && Boolean(formik.errors.termAndPrivacy),
            }}
            error={true}
            control={
              <Checkbox
                name="termAndPrivacy"
                value={formik.values.termAndPrivacy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                color="primary"
              />
            }
            label={
              <Typography>
                By checking the checkbox you agree to our{' '}
                <Link href="terms-of-use" target="_blank" underline="none">
                  {' '}
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link href="privacy-policy" target="_blank" underline="none">
                  Privacy Policy
                </Link>
                .
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <Button sx={{ mt: '24px' }} fullWidth loading={formik.isSubmitting}>
        Sign Up
      </Button>
      <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
        <Grid item>
          Already have an account?{' '}
          <Link to="/sign-in" component={RouterLink} underline="none">
            Sign In
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}
