import React from 'react';

import styles from './ThreadLayout.module.scss';

const ThreadLayout = ({ children, header }) => {
  return (
    <div className={styles.container}>
      <header>
        {header}

        <div className={styles.line} />
      </header>

      <main>{children}</main>
    </div>
  );
};

export default ThreadLayout;
