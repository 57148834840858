import React from 'react';
import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';

interface Props {
  value?: boolean;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode;
  className?: string;
  switchClassName?: string;
  disabled?: boolean;
}

const Switch: React.FC<Props> = ({ value, onChange, label, className, switchClassName, disabled }) => {
  return (
    <FormControlLabel
      control={<MuiSwitch checked={value} disabled={disabled} onChange={onChange} className={switchClassName} />}
      label={label}
      className={className}
    />
  );
};

export default Switch;
