import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceName } from '../../config';
import ProfileService from '../../../services/api/ProfileService';
import UserService from '../../../services/api/UserService';

export const getProfile = createAsyncThunk(`${SliceName.PROFILE}/getProfile`, async () => {
  const profile = await ProfileService.getProfile();

  // TODO: remove this after user is removed from local storage
  UserService.setLocalUser(profile);

  return profile;
});
