import { Typography } from '@mui/material';
import { GroupType } from './group.constants';

export const ThreadStatus = {
  OPEN: 'OPEN',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  CANCELED: 'CANCELED',
  NOT_APPLICABLE: 'N/A',
};

export const ThreadRole = {
  MEMBER: 'MEMBER',
  REVIEWER: 'REVIEWER',
  OWNER: 'OWNER',
};

export const FormattedThreadStatus = {
  [ThreadStatus.APPROVED]: 'Approved',
  [ThreadStatus.REJECTED]: 'Rejected',
  [ThreadStatus.OPEN]: 'Open',
  [ThreadStatus.CHANGES_REQUESTED]: 'Changes Requested',
  [ThreadStatus.CANCELED]: 'Canceled',
  [ThreadStatus.NOT_APPLICABLE]: 'N/A',
};

export const ThreadStatusMap = {
  [ThreadStatus.APPROVED]: { label: 'Approved', color: 'success' },
  [ThreadStatus.REJECTED]: { label: 'Rejected', color: 'error' },
  [ThreadStatus.OPEN]: { label: 'Open', color: 'primary' },
  [ThreadStatus.CHANGES_REQUESTED]: { label: 'Changes Requested', color: 'warning' },
  [ThreadStatus.CANCELED]: { label: 'Canceled', color: 'default' },
  [ThreadStatus.NOT_APPLICABLE]: { label: 'N/A', color: 'default' },
};

const MDTDisclaimer = (
  <Typography>
    All Forum Users agree to abide by the Forum Rules and acknowledge and accept the disclaimer and liability statement
    here. These rules and the associated disclaimer and liability statement are supplementary to the Terms and
    Conditions of use of the M3 Platform.
    <h2>FORUM RULES</h2>
    <ol>
      <li>
        Forum users must ensure they comply with the Forum Rules. By using the Forum, you agree to be subject to these
        Forum Rules, and that you will keep up to date with them. Please inform us through <u>info@medsym.io</u> if you
        see any content in breach of these rules.
      </li>
      <li>
        Forum moderators will remove posts and topics which are in breach of these rules. Users breaching the rules may
        be banned.
      </li>
      <li>
        Be polite and respectful to all forum users, including moderators. Bullying, aggression, nuisance behaviour and
        discrimination will not be tolerated. Language must be professional and polite.
      </li>
      <li>
        Be aware that our Forum users come from diverse backgrounds, with different cultures, languages and experiences
        with different legislation.
      </li>
      <li>
        Keep discussions on topic. Posts must be appropriate to the Forum and the discussion. The Forum for professional
        discussion of issues relevant to the prescribing of medicines (licensed and unlicensed) and patient care.
      </li>
      <li>
        Messages must not contain any content which could be considered defamatory, offensive, illegal, obscene,
        deliberately provocative, or inflammatory.
      </li>
      <li>
        Your posting must be your own content. You must be truthful and factual, and opinions and unsubstantiated
        statements must be acknowledged as such. You must not mislead or misquote or attempt to impersonate or pose as
        another person when posting.
      </li>
      <li>
        You must use the edit and delete features responsibly, giving a reason for these actions. Only prompt edits are
        permitted; you must not edit your post retroactively after the discussion has moved on by several other posts.
        You may only delete posts which are duplicates, in the wrong forum/discussion, or immediately after posting
        (before other replies). Moderators may bring back deleted posts if they do not follow these rules.
      </li>
      <li>
        You must not post complaints on the forums, particularly if identifying other users, the Nuclear-21 team, or any
        other person or organisation. If you see any breach of the rules, contact <u>info@medsym.io</u>
      </li>
      <li>
        You may not post commercial advertisements of any kind. Advertisements for non-commercial purposes may be
        permitted with the written consent of Medisonal Limited. You may provide brief details of a service or product
        supplier in response to a question or other post, but this should be limited to contact details only. Likewise,
        spam and references to surveys, contests and so on are not permitted.
      </li>
      <li>Forum users are permitted a single account only.</li>
      <li>
        Your Forum profile is your responsibility to maintain. Your Forum name must adhere to rules regarding posts with
        respect to professionalism and politeness.
      </li>
      <li>
        Posts must adhere to intellectual property law, including copyright law. You must not post, upload, download or
        otherwise exchange copyright protected material through the forums. Material extracted from copyright protected
        sources must be referenced. You may be held liable for breaches in copyright, or other, law broken as a result
        of your posts.
      </li>
    </ol>
    <h2>FORUM DISCLAIMER</h2>
    <div>
      User’s posts are their personal opinions and do not represent the views of Medisonal Limited. Medisonal Limited is
      not responsible for the content of forum posts, and does not certify any information on the Forum as being
      correct, worthy or accurate. You should treat any advice received under these considerations. All posts and other
      communications through the Forum, including private communications, are the property of Medisonal Limited. We
      reserve the right to remove your content, including all posts and topics created, at our discretion. Our public
      Forum can be crawled by search engines, and so Forum posts and topics may appear in search results for search
      engines such as Google, Bing, Yahoo and others. Medisonal Limited does not accept responsibility for search engine
      results that may include public forum posts. Private threads are not crawled, as so will not appear in search
      results. Your email address is not visible to other Forum users. If you disclose any contact details through the
      Forum you do so at your own risk. Medisonal Limited does not accept any responsibility for the consequences of
      such disclosure. Medisonal Limited team members can view your email address. This will be kept private, except if
      we are requested to provide this information by a duly authorised person or organisation with the legal right to
      make such a request in accordance with our Privacy Policy
    </div>
    <h2>LIABILITY</h2>
    <div>
      As the User, you accept sole liability for any content you post to the Forum. You agree to indemnify Medisonal
      Limited and its team members against and costs, claims, damages, liability, legal fees, loss and other expenses we
      may incur resulting from your post(s) or other communications using the Platform. We expressly exclude any
      liability for damages or loss resulting from a breach of the Forum Rules. We retain the right to remove or block
      access to any material which we believe is in breach of the Forum Rules, at our sole discretion.
    </div>
  </Typography>
);

export const ThreadPublishDisclaimer = {
  [GroupType.PUBLIC]: MDTDisclaimer,
  [GroupType.PRIVATE]: MDTDisclaimer,
  [GroupType.MDT]: MDTDisclaimer,
};

export const ThreadAddTitleNameMap = {
  [GroupType.PUBLIC]: 'Add Thread',
  [GroupType.PRIVATE]: 'Add Thread',
  [GroupType.MDT]: 'Add MDT Case',
};

export const ThreadEditTitleNameMap = {
  [GroupType.PUBLIC]: 'Edit Thread',
  [GroupType.PRIVATE]: 'Edit Thread',
  [GroupType.MDT]: 'Edit MDT Case',
};

export const ThreadAttachmentOperation = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};
