import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SelectChangeEvent, Skeleton, Typography } from '@mui/material';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectOrganisations } from '../../../store/slices/organisations/organisationsSlice';
import classes from './OrganisationManagementHeader.module.scss';
import Select from '../../inputs/Select/Select';
import { selectOrganisationUsers, setFilters } from '../../../store/slices/organisationUsers/organisationUsersSlice';
import Search from '../../inputs/Search/Search';
import { UserStatus } from '../../../types/enums/UserStatus';
import { SPECIALITY_OPTIONS, USER_STATUS_OPTIONS } from '../../../constants/user';

const OrganisationManagementHeader: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { organisationId } = useParams();

  const { organisations, isLoading } = useAppSelector(selectOrganisations);
  const { filters } = useAppSelector(selectOrganisationUsers);

  const isPendingApplicationsPage = location.pathname.includes('pending-applications');

  const organisationsOptions = organisations.map((organisation) => ({
    label: organisation.name,
    value: organisation.id,
  }));

  const handleOrganisationChange = (event: SelectChangeEvent<string>) => {
    if (organisationId) {
      const newLocation = location.pathname.replace(organisationId, event.target.value);

      navigate(newLocation);
    } else {
      navigate(`/organisations/${event.target.value}`);
    }
  };

  const handleSearchChange = (value: string) => dispatch(setFilters({ ...filters, search: value }));

  const handleStatusChange = (event: SelectChangeEvent<string>) =>
    dispatch(setFilters({ ...filters, status: event.target.value as UserStatus }));

  const handleSpecialityChange = (event: SelectChangeEvent<string>) =>
    dispatch(setFilters({ ...filters, speciality: event.target.value }));

  if (isLoading)
    return (
      <header className={classes.container}>
        <Skeleton variant="rectangular" width={180} height={36} />

        <div className={classes.filters}>
          <Skeleton variant="rectangular" width={200} className={classes.hiddenOnMobile} height={30} />

          {!isPendingApplicationsPage && <Skeleton variant="rectangular" width={100} height={36} />}

          <Skeleton variant="rectangular" width={100} height={36} />
        </div>
      </header>
    );

  return (
    <header className={classes.container}>
      {organisations.length === 1 ? (
        <Typography>{organisations[0].name}</Typography>
      ) : (
        <Select
          value={organisationId || ''}
          onChange={handleOrganisationChange}
          options={organisationsOptions}
          size="small"
          label="Organisation"
          className={classes.select}
          showHelperText={false}
        />
      )}

      <div className={classes.filters}>
        <Search value={filters.search} onChange={handleSearchChange} debounce className={classes.search} />

        {!isPendingApplicationsPage && (
          <Select
            value={filters.status || ''}
            onChange={handleStatusChange}
            options={[
              {
                label: 'Select status',
                value: '',
              },
              ...USER_STATUS_OPTIONS,
            ]}
            size="small"
            label="Status"
            className={classNames(classes.select, classes.filterSelect)}
            showHelperText={false}
          />
        )}

        <Select
          value={filters.speciality || ''}
          onChange={handleSpecialityChange}
          options={[
            {
              label: 'Select speciality',
              value: '',
            },
            ...SPECIALITY_OPTIONS,
          ]}
          size="small"
          label="Speciality"
          className={classNames(classes.select, classes.filterSelect)}
          showHelperText={false}
        />
      </div>
    </header>
  );
};

export default OrganisationManagementHeader;
