import React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import styles from './GroupsMenuHeader.module.scss';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import PaidIcon from '@mui/icons-material/Paid';

import { hasPermission } from '../../../utils/permissions';
import { MdtPermissions } from '../../../constants/user.constants';
import { useAppSelector } from '../../../store/hooks';
import { selectPermissions } from '../../../store/slices/permissions/permissionsSlice';

export default function GroupsMenuHeader({ permissions, title, setCreateGroupModal, groups, type }) {
  const { permissions: tierPermissions } = useAppSelector(selectPermissions);
  const navigate = useNavigate();
  const hasGroupCreationPermission = tierPermissions && tierPermissions[`groups:${type.toLowerCase()}:create`];

  const getTitle = () => {
    if (!groups) {
      return <Skeleton variant="rounded" height={20} width={120} />;
    }

    return <Typography>{title}</Typography>;
  };

  const getButton = () => {
    if (!groups || !hasPermission(permissions, MdtPermissions.CREATE_GROUP)) {
      return;
    }

    if (!hasGroupCreationPermission) {
      return (
        <IconButton onClick={() => navigate('intenal/settings?tab=subscriptions')} >
          <PaidIcon />
        </IconButton>
      );
    }

    return (
      <IconButton onClick={() => setCreateGroupModal(true)}>
        <AddCircleIcon />
      </IconButton>
    );
  };
  return (
    <div className={styles.header}>
      {getTitle()}
      {getButton()}
    </div>
  );
}
