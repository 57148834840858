import React from 'react';
import Typography from '@mui/material/Typography';
import styles from './GroupEmptyState.module.scss';

export default function GroupEmptyState() {
  return (
    <div className={styles.groupEmptyState}>
      <Typography>Select a group to start</Typography>
    </div>
  );
}
