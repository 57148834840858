import React from 'react';

import { Platform } from '../../../types/enums/Platform';

interface Props<P extends { children?: React.ReactNode }> {
  platform: Platform;
  WebComponent?: React.ComponentType<P>;
  NativeComponent?: React.ComponentType<P>;
}

export const PlatformSpecificComponent = <P extends { children?: React.ReactNode }>({
  platform,
  WebComponent = React.Fragment,
  NativeComponent = React.Fragment,
  ...rest
}: Props<P> & P) => {
  const Component = platform === Platform.WEB ? WebComponent : NativeComponent;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Component {...(rest as any as P)} />;
};
