import React from 'react';
import MuiPagination from '@mui/material/Pagination';
import classNames from 'classnames';

import { Pagination as PaginationType } from '../../../types/Pagination';
import { getOffset, getPage, getPageCount } from '../../../utils/pagination';
import classes from './Pagination.module.scss';

interface Props {
  pagination: PaginationType;
  onOffsetChange: (offset: number) => void;
  disabled?: boolean;
  centered?: boolean;
}

const Pagination: React.FC<Props> = ({ onOffsetChange, pagination, disabled, centered }) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onOffsetChange(getOffset(value, pagination.limit));
  };

  if (!pagination.totalCount) return null;

  return (
    <div className={classNames(classes.container, centered && classes.centered)}>
      <MuiPagination
        count={getPageCount(pagination)}
        page={getPage(pagination)}
        disabled={disabled}
        size="small"
        onChange={handleChange}
      />
    </div>
  );
};

export default Pagination;
