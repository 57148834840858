import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { Organisation } from '../../../types/Organisation';
import { getOrganisations } from './organisationsThunks';

interface OrganisationsState {
  organisations: Organisation[];
  error: string | null | undefined;
  isLoading: boolean;
}

const initialState: OrganisationsState = {
  organisations: [],
  isLoading: true,
  error: null,
};

const organisationsSlice = createSlice({
  name: SliceName.ORGANISATIONS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganisations.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(getOrganisations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.organisations = action.payload;
    });

    builder.addCase(getOrganisations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const selectOrganisations = (state: RootState) => state.organisations;

export default organisationsSlice.reducer;
