import React from 'react';

import HomeWithAppPreview from '../../../components/landings/HomeWithAppPreview/HomeWithAppPreview';
import Services from '../landing/components/Services';
import HowItWorks from '../regenerative-medicine/components/HowItWorks/HowItWorks';
import Partners from '../landing/components/Partners';
import ContactUs from '../landing/components/ContactUs';
import styles from './PharmacyPage.module.scss';
import Faq from '../landing/components/Faq';
import Pricing from '../landing/components/Pricing';

enum LandingSectionIds {
  services = 'services',
  howItWorks = 'how-it-works',
  pricing = 'pricing',
  contactUs = 'contact-us',
  faq = 'faq',
}

const PharmacyPage: React.FC = () => {
  return (
    <div>
      <HomeWithAppPreview
        className={styles.home}
        title={
          <>
            Streamlined Communication for <em> Pharmacists</em>
          </>
        }
        description={
          <>
            Pharmacies, healthcare institutions, pharmaceutical companies, research centers, and manufacturing
            facilities united through this innovative networking platform
            <p />
            Foster collaboration among pharmacists, researchers, and industry professionals to advance pharmaceutical
            knowledge and innovation
            <p />
            Accelerate the journey of novel drugs, medical technologies, and healthcare solutions from conception to
            widespread implementation!
          </>
        }
        image={<img src="/assets/pharmacy2.webp" alt="Pharmacy" className={styles.img} />}
      />

      <Services id={LandingSectionIds.services} className={styles.services} />

      <HowItWorks id={LandingSectionIds.howItWorks} />

      <Pricing id={LandingSectionIds.pricing} />

      <Partners />

      <ContactUs id={LandingSectionIds.contactUs} />

      <Faq id={LandingSectionIds.faq} />
    </div>
  );
};

export default PharmacyPage;
