import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import MuiButton from '@mui/material/Button';

import ConfirmMultifactorAuthForm from '../../../../../../components/forms/ConfirmMultifactorAuthForm/ConfirmMultifactorAuthForm';
import classes from './VerifyMultifactorAuth.module.scss';
import { Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import Button from '../../../../../../components/button/Button';
import { config } from '../../../../../../configs/config';

interface Props {
  secret: string;
  onSubmit: (code: string) => Promise<void>;
  onCancel: () => void;
  email?: string;
}

const VerifyMultifactorAuth: React.FC<Props> = ({ secret, onSubmit, email, onCancel }) => {
  const [activeStep, setActiveStep] = useState(0);

  const url = `otpauth://totp/${config.appName}:${email}?algorithm=SHA1&digits=6&period=30&issuer=${config.appName}&secret=${secret}`;

  const handleNextStep = () => setActiveStep(activeStep + 1);

  const handleBackStep = () => setActiveStep(activeStep - 1);

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Install authenticator application</StepLabel>

          <StepContent className={classes.step}>
            <Typography className={classes.info}>
              To enable Multi-Factor Authentication (MFA), you&apos;ll need to install an authenticator app on your
              smartphone. Authenticator apps, such as Google Authenticator, Microsoft Authenticator, Duo Security or
              Authy, generate unique codes for added security. You can download any of these apps from your
              device&apos;s app store.
              <br />
              If you are using an iPhone, you can utilize the built-in Apple&apos;s Authenticator app.
            </Typography>
            <div className={classes.buttons}>
              <MuiButton onClick={onCancel} variant="text">
                Back
              </MuiButton>

              <Button type="button" onClick={handleNextStep}>
                Next
              </Button>
            </div>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Scan QR code</StepLabel>

          <StepContent className={classes.step}>
            <Typography className={classes.info}>Scan the QR code below with your authenticator app.</Typography>

            <QRCodeSVG value={url} className={classes.qrCode} size={330} />

            <Typography className={classes.info}>
              Or use this{' '}
              <a href={url} target="_blank" rel="noreferrer">
                link
              </a>{' '}
              if you are currently logged in on your smartphone:
            </Typography>

            <div className={classes.buttons}>
              <MuiButton onClick={handleBackStep} variant="text">
                Back
              </MuiButton>

              <Button type="button" onClick={handleNextStep}>
                Next
              </Button>
            </div>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Enter verification code</StepLabel>

          <StepContent className={classes.step}>
            <Typography className={classes.info}>
              Enter verification code generated by the authenticator app.
            </Typography>

            <div className={classes.verificationFormContainer}>
              <ConfirmMultifactorAuthForm onSubmit={onSubmit} />
            </div>

            <MuiButton onClick={handleBackStep} variant="text">
              Back
            </MuiButton>
          </StepContent>
        </Step>
      </Stepper>
    </>
  );
};

export default VerifyMultifactorAuth;
