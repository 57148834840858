import React from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';

import styles from './ThreadMessageMetadata.module.scss';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';

export default function ThreadMessageMetadata({ user, createdAt, updatedAt, type = 'thread' }) {
  if (!user && !createdAt) {
    return (
      <div className={styles.metadataWrapper}>
        <Skeleton variant="circular" height={40} width={40} />
        <Typography className={styles.metadataName}>
          <Skeleton variant="rounded" height={20} width={120} />
        </Typography>
        <Typography className={styles.metadataDate}>
          <Skeleton variant="rounded" height={12} width={160} />
        </Typography>
      </div>
    );
  }

  const editedLabel =
    type === 'message' && new Date(createdAt).getTime() !== new Date(updatedAt).getTime() ? 'edited' : '';

  const userPageLink = `/internal/users/${user.id}`;
  return (
    <div className={styles.metadataWrapper}>
      <NavLink to={userPageLink} target="_blank" rel="noopener noreferrer">
        <Avatar className={styles.metadataAvatar}>
          {user?.firstName[0]}
          {user?.lastName[0]}
        </Avatar>
      </NavLink>
      <NavLink to={userPageLink} target="_blank" rel="noopener noreferrer">
        <Typography className={styles.metadataName}>
          {user?.firstName} {user?.lastName}
        </Typography>
      </NavLink>
      <Typography className={styles.metadataDate}>{dayjs(createdAt).format('ddd, MMM DD YYYY H:mm A')}</Typography>
      <Typography className={styles.metadataEditLabel}>{editedLabel}</Typography>
    </div>
  );
}
