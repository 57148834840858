import { useEffect, useRef } from 'react';
import { Typography } from '@mui/material';

import PCCALogo from './logos/pcca.svg';
import LiberumHealthLogo from './logos/liberumhealth.svg';
import CPDLogo from './logos/cpd.webp';
import CPSLogo from './logos/cps.webp';
import AMEELogo from './logos/amee.webp';
import CannabisIntustryLogo from './logos/cannabis-industry-council.webp';
import HarperJamesLogo from './logos/harper-james.webp';
import NanoRegMedLogo from './logos/nano-reg-med.webp';

import styles from './Partners.module.scss';
import { NavLink } from 'react-router-dom';

export default function Partners() {
  const logosRef = useRef();
  const logosSlideRef = useRef();

  useEffect(() => {
    const logosNode = logosRef.current;
    const logosSlideNode = logosSlideRef.current;

    if (logosNode && logosSlideNode) {
      var copy = logosSlideNode.cloneNode(true);
      logosNode.appendChild(copy);
    }
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.partners}>
        <Typography className={styles.partnersTitle}>Our Partners</Typography>
        <div className={styles.logos} ref={logosRef}>
          <div className={styles.logoSlide} ref={logosSlideRef}>
            <NavLink to="https://www.pccarx.com" target="_blank" rel="noopener noreferrer">
              <img src={PCCALogo} />
            </NavLink>
            <NavLink to="https://www.liberumhealth.com" target="_blank" rel="noopener noreferrer">
              <img src={LiberumHealthLogo} />
            </NavLink>
            <NavLink to="https://cpduk.co.uk" target="_blank" rel="noopener noreferrer">
              <img src={CPDLogo} />
            </NavLink>
            <NavLink to="https://www.centennialpharmaservices.co.uk" target="_blank" rel="noopener noreferrer">
              <img src={CPSLogo} />
            </NavLink>
            <NavLink to="https://amee.org/" target="_blank" rel="noopener noreferrer">
              <img src={AMEELogo} />
            </NavLink>
            <NavLink to="https://www.cicouncil.org.uk" target="_blank" rel="noopener noreferrer">
              <img src={CannabisIntustryLogo} />
            </NavLink>
            <NavLink to="https://harperjames.co.uk" target="_blank" rel="noopener noreferrer">
              <img src={HarperJamesLogo} />
            </NavLink>
            <NavLink to="https://www.nanoregmed.com" target="_blank" rel="noopener noreferrer">
              <img src={NanoRegMedLogo} />
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}
