import React from 'react';
import { Typography } from '@mui/material';
import { BlockRounded } from '@mui/icons-material';

import classes from './EmptyMessage.module.scss';

interface Props {
  children: React.ReactNode;
}

const EmptyMessage: React.FC<Props> = ({ children = 'No data found' }) => {
  return (
    <div className={classes.container}>
      <BlockRounded />

      {typeof children === 'string' ? <Typography variant="h6">{children}</Typography> : children}
    </div>
  );
};

export default EmptyMessage;
