import React from 'react';
import styles from './GroupEditMenu.module.scss';

import Menu from '@mui/material/Menu';

export default function GroupEditMenu({ children, openGroupMenu, setOpenGroupMenu }) {
  return (
    <Menu
      open={Boolean(openGroupMenu)}
      className={styles.menu}
      onClose={() => setOpenGroupMenu(null)}
      anchorEl={openGroupMenu}
      classes={{ paper: styles.MuiDrawerPaper }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      {children}
    </Menu>
  );
}
