import React from 'react';

import HomeWithAppPreview from '../../../components/landings/HomeWithAppPreview/HomeWithAppPreview';
import Services from '../landing/components/Services';
import HowItWorks from './components/HowItWorks/HowItWorks';
import Partners from '../landing/components/Partners';
import ContactUs from '../landing/components/ContactUs';
import styles from './RegenerativeMedicinePage.module.scss';
import Faq from '../landing/components/Faq';
import Pricing from '../landing/components/Pricing';

enum LandingSectionIds {
  services = 'services',
  howItWorks = 'how-it-works',
  pricing = 'pricing',
  contactUs = 'contact-us',
  faq = 'faq',
}

const RegenerativeMedicinePage: React.FC = () => {
  return (
    <div>
      <HomeWithAppPreview
        className={styles.home}
        title={
          <>
            Hub of <em>Regenerative Medicine</em>
          </>
        }
        description={
          <>
            Connect hubs, universities, pharmas, research institutes and pilot factories with our unique communication
            platform
            <p />
            Expand your collaborative cluster by engaging academics, patients, investors and entrepreneurs to broaden
            their knowledge
            <p />
            Together we can speed up the development of new biomaterials, cell therapies and innovations for
            regenerative medicine from lab to real world applications!
          </>
        }
      />

      <Services id={LandingSectionIds.services} className={styles.services} />

      <HowItWorks id={LandingSectionIds.howItWorks} />

      <Pricing id={LandingSectionIds.pricing} />

      <Partners />

      <ContactUs id={LandingSectionIds.contactUs} />

      <Faq id={LandingSectionIds.faq} />
    </div>
  );
};

export default RegenerativeMedicinePage;
