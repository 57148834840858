import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import styles from './GroupEditModal.module.scss';
import TextInputField from '../../../../../components/text-input-field/TextInputField';
import { useMdtContext } from '../../../../../hooks/useMdtContext';
import Modal from '../../../../../components/modal/Modal';
import ModalTitle from '../../../../../components/modal-title/ModalTitle';
import ModalButton from '../../../../../components/modal-button/ModalButton';
import { hasPermission } from '../../../../../utils/permissions';
import { MdtPermissions } from '../../../../../constants/user.constants';
import ConfirmDialog from '../../../../../components/confirm-dialog/ConfirmDialog';
import Select from '../../../../../components/inputs/Select/Select';
import { ORGANISATION_CATEGORY_OPTIONS } from '../../../../../constants/organisation';
import { GroupType } from '../../../../../constants/group.constants';

const getEditGroupValidationSchema = (type) =>
  yup.object({
    name: yup.string().min(2).max(50, 'Max group name 50 characters').required('Name field is required'),
    description: yup
      .string()
      .max(1000, 'Max group description 1000 characters')
      .required('Description field is required'),
    category: type === GroupType.MDT ? yup.string().required('Category is required') : undefined,
  });

export default function GroupEditModal({ permissions, open, setOpen, group }) {
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

  const { deleteGroup, editGroup } = useMdtContext();
  const navigate = useNavigate();

  const groupTypeMap = {
    PUBLIC: 'Public',
    PRIVATE: 'Private',
    MDT: 'MDT',
  };

  const onClose = () => {
    setOpen(false);
    groupFormik.resetForm();
  };
  const groupFormik = useFormik({
    initialValues: group,
    validationSchema: getEditGroupValidationSchema(group?.type),
    enableReinitialize: true,

    onSubmit: async (values) => {
      try {
        setLoading(true);

        if (values.type !== GroupType.MDT) {
          delete values.category;
        }

        editGroup(group?.id, values);
        onClose();
        toast.success('Group was successfully updated');
      } catch (error) {
        const message = error?.response?.data?.message || 'Group edit error';
        toast.error(message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const onDeleteGroup = async (event) => {
    event.preventDefault();
    setDeleteConfirmDialog(true);
  };

  const onDeleteConfirm = async () => {
    try {
      setDeleteConfirmDialog(false);
      setRemoveLoading(true);
      await deleteGroup(group?.id);
      onClose();

      navigate(`/internal/groups/${group.type.toLowerCase()}`);
      toast.success('Group was successfully deleted');
    } catch (error) {
      const message = error?.response?.data?.message || 'Group delete error';
      toast.error(message);
      console.error(error);
    } finally {
      setRemoveLoading(false);
    }
  };

  return (
    <Modal open={open} className={styles.editGroupModal} onClose={onClose}>
      <form onSubmit={groupFormik.handleSubmit} className={styles.form}>
        <ModalTitle>Setting</ModalTitle>
        <TextInputField
          label="Name"
          id="name"
          name="name"
          value={groupFormik.values.name}
          onChange={groupFormik.handleChange}
          onBlur={groupFormik.handleBlur}
          disabled={!hasPermission(permissions, MdtPermissions.EDIT_GROUP)}
          error={groupFormik.touched.name && Boolean(groupFormik.errors.name)}
          errorHelperText={groupFormik.errors.name}
        />

        <TextInputField
          label="Description"
          type="description"
          id="description"
          name="description"
          value={groupFormik.values.description}
          onChange={groupFormik.handleChange}
          onBlur={groupFormik.handleBlur}
          disabled={!hasPermission(permissions, MdtPermissions.EDIT_GROUP)}
          error={groupFormik.touched.description && Boolean(groupFormik.errors.description)}
          errorHelperText={groupFormik.touched.description && groupFormik.errors.description}
        />
        <TextInputField
          label="Type"
          type="type"
          id="type"
          name="type"
          value={groupTypeMap[group.type]}
          onChange={groupFormik.handleChange}
          onBlur={groupFormik.handleBlur}
          disabled={true}
          error={groupFormik.touched.type && Boolean(groupFormik.errors.type)}
          errorHelperText={groupFormik.touched.type && groupFormik.errors.type}
        />

        {groupFormik.values.type === GroupType.MDT && (
          <Select
            name="category"
            label="Category"
            value={groupFormik.values.category || ''}
            onChange={groupFormik.handleChange}
            onBlur={groupFormik.handleBlur}
            options={[
              {
                label: 'Select category',
                value: '',
              },
              ...ORGANISATION_CATEGORY_OPTIONS,
            ]}
            fullWidth
            error={groupFormik.touched.category && groupFormik.errors.category}
          />
        )}

        <div className={styles.buttons}>
          {hasPermission(permissions, MdtPermissions.DELETE_GROUP) && (
            <ModalButton className={styles.deleteButton} onClick={onDeleteGroup} loading={removeLoading}>
              Remove
            </ModalButton>
          )}

          {hasPermission(permissions, MdtPermissions.EDIT_GROUP) && (
            <ModalButton className={styles.editButton} loading={loading} type="submit">
              Edit
            </ModalButton>
          )}
        </div>
      </form>

      <ConfirmDialog
        open={deleteConfirmDialog}
        text="Are you sure you want to remove the group?"
        onClose={() => setDeleteConfirmDialog(false)}
        onConfirm={onDeleteConfirm}
      />
    </Modal>
  );
}
