import React from 'react';
import List from '@mui/material/List';

import styles from './MdtThreads.module.scss';
import ThreadsItem from '../components/ThreadsItem';

export default function MdtThreads({ threads, group }) {
  if (threads === null) {
    return <List className={styles.list}>{new Array(3).fill(<ThreadsItem displayStatus={true} thread={null} />)}</List>;
  }

  return (
    <List className={styles.list}>
      {threads.map((thread) => (
        <ThreadsItem displayStatus={true} key={thread.id} group={group} thread={thread} />
      ))}
    </List>
  );
}
