import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceName } from '../../config';
import OrganisationsService from '../../../services/api/OrganisationsService';
import { UserExtended } from '../../../types/User';
import { RootState } from '../../store';
import { getUsersSearchParams } from '../../../utils/userSearch';

export const getOrganisationUsers = createAsyncThunk<
  { items: UserExtended[]; totalCount: number | null },
  void,
  { state: RootState }
>(`${SliceName.ORGANISATION_USERS}/getOrganisationUsers`, async (_, { getState }) => {
  const { filters, pagination } = getState().organisationUsers;

  if (!filters.organisationId) throw new Error('Organisation ID is required');

  const data = await OrganisationsService.getOrganisationUsers({
    offset: pagination.offset,
    limit: pagination.limit,
    organisationId: filters.organisationId,
    ...getUsersSearchParams(filters.search),
    speciality: filters.speciality || undefined,
    status: filters.status || undefined,
  });

  return data;
});
