import React from 'react';

import styles from './SubscriptionPurchaseModal.module.scss';
import Modal from '../../../../../../components/modal/Modal';
import SubscriptionPurchase from './SubscriptionPurchase';


interface SubscriptionPurchaseModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubscriptionPurchaseModal: React.FC<SubscriptionPurchaseModalProps> = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} className={styles.container} onClose={handleClose}>
      <SubscriptionPurchase title="Choose Your Subscription Plan" />
    </Modal>
  );
};

export default SubscriptionPurchaseModal;
