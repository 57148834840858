import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';

export default function MdtOnboardingHelperText() {
  return (
    <Grid item xs={12} sm={12}>
      <FormHelperText>
        Medisonal adhere strictly to data protection laws and do not share your information with any third party without
        your explicit consent. Your documents are transmitted over a secure connection and are kept private.
      </FormHelperText>
    </Grid>
  );
}
