import React from 'react';

import { config } from '../../../configs/config';

interface Props {
  webContent?: React.ReactNode;
  nativeContent?: React.ReactNode;
}

const PlatformSpecificContent: React.FC<Props> = ({ webContent, nativeContent }) => {
  if (config.isNative) return nativeContent || null;

  return webContent || null;
};

export default PlatformSpecificContent;
