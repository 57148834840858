import React from 'react';
import { List } from '@mui/material';

import FileListItem from './FileListItem/FileListItem';

interface Props {
  files: string[];
  onFileClick: (url: string) => Promise<void>;
}

const FileList: React.FC<Props> = ({ files, onFileClick }) => {
  return (
    <List>
      {files.map((file, index) => (
        <FileListItem url={file} key={index} onClick={() => onFileClick(file)} />
      ))}
    </List>
  );
};

export default FileList;
