import classNames from 'classnames';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import styles from './SelectInputField.module.scss';

export default function SelectInputField(props) {
  const helperText = (
    <FormHelperText className={styles.helperText}>
      {props.error ? props.errorHelperText : props.helperText}
    </FormHelperText>
  );
  return (
    <FormControl fullWidth error={props.error}>
      <InputLabel id={props.name}>{props.label}</InputLabel>
      <Select
        labelId={props.id}
        fullWidth
        onChange={props.onChange}
        name={props.name}
        size={props.size || 'normal'}
        label={props.label}
        className={classNames(styles.selectField, props.className)}
        onBlur={props.onBlur}
        value={props.value}
        defaultValue={props.defaultValue || ''}
      >
        {props.children}
      </Select>
      {helperText}
    </FormControl>
  );
}
