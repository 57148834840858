import React, { useState } from 'react';
import SwipeableDrawerComponent from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import classNames from 'classnames';

import styles from './SwipableDrawer.module.scss';
import { useBackButtonOverride } from '../../hooks/useBackButtonOverride';

export default function SwipableDrawer({ children, mobileOnly, className, drawerClasses }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => setIsDrawerOpen(true);
  const handleDrawerClose = () => setIsDrawerOpen(false);

  useBackButtonOverride({
    onBack: handleDrawerClose,
    shouldOverride: isDrawerOpen,
    id: 'drawer',
  });

  return (
    <div className={classNames(styles.wrapper, className, { [styles.mobileOnly]: mobileOnly })}>
      <IconButton
        className={styles.drawerButton}
        edge="start"
        color="inherit"
        aria-label="Open Drawer"
        onClick={handleDrawerOpen}
      >
        <MenuIcon />
      </IconButton>

      <SwipeableDrawerComponent
        classes={drawerClasses}
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
      >
        {Array.isArray(children) ? (
          children.map((child, index) => (
            <div key={index} onClick={handleDrawerClose}>
              {child}
            </div>
          ))
        ) : (
          <div onClick={handleDrawerClose}>{children}</div>
        )}
      </SwipeableDrawerComponent>
    </div>
  );
}
