import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../components/pagination/Pagination';
import styles from './ThreadsPagination.module.scss';

export default function ThreadsPagination({ queryParams, totalCount, pageSize }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(queryParams.get('page') || 1);

  const onChange = (event, value) => {
    if (page === value) {
      return event.preventDefault();
    }

    queryParams.set('page', value);
    navigate({ search: queryParams.toString() });
    setPage(value);
  };

  return (
    <div className={styles.pagination}>
      <Pagination
        count={Math.ceil(totalCount / pageSize)}
        page={parseInt(page)}
        onChange={(event, value) => onChange(event, value)}
      />
    </div>
  );
}
