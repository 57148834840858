import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import classNames from 'classnames';

import styles from './Button.module.scss';

interface Props {
  loading?: boolean;
  children: React.ReactNode;
  component?: any;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  startIcon?: React.ReactNode;
  refAnchor?: React.Ref<any>;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  sx?: object;
  rounded?: boolean;
  containerClassName?: string;
}

const Button: React.FC<Props> = ({
  loading,
  children,
  component,
  onClick,
  className,
  disabled,
  startIcon,
  color,
  refAnchor,
  type = 'submit',
  fullWidth = false,
  variant = 'contained',
  sx,
  rounded,
  containerClassName,
}) => {
  return (
    <div className={classNames(styles.container, containerClassName)}>
      <LoadingButton
        disabled={disabled}
        className={classNames(styles.button, className, { [styles.rounded]: rounded })}
        type={type}
        component={component}
        loading={loading}
        color={color}
        onClick={onClick}
        variant={variant}
        fullWidth={fullWidth}
        sx={sx}
        ref={refAnchor}
        startIcon={startIcon}
      >
        {children}
      </LoadingButton>
    </div>
  );
};

export default Button;
