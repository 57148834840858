import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import styles from './SidebarChevron.module.scss';

export default function SidebarChevron(props) {
  const handleDrawerOpen = () => {
    props.setOpen(true);
  };

  const handleDrawerClose = () => {
    props.setOpen(false);
  };

  return (
    <IconButton
      className={styles.chevron}
      sx={{ '.MuiTouchRipple-ripple .MuiTouchRipple-child': { borderRadius: 0 } }}
      onClick={props.open ? handleDrawerClose : handleDrawerOpen}
    >
      {props.open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  );
}
