import styles from './TermsOfUsePage.module.scss';

export default function TermsOfUsePage() {
  return (
    <div className={styles.container}>
      <h2>Prescriber Disclaimer</h2>
      <p>
        Although this website provides information regarding cannabis and cannabis products, including but not limited
        to potency, pharmacologically active ingredients, source of cultivation, recommended uses and benefits, and all
        other descriptions or information, all such Information is provided for informational and educational purposes
        only and is not intended to be a substitute for your medical advice, diagnosis or treatment. The decision to
        prescribe medical cannabis is yours alone.
      </p>
      <p>
        All content, including text, graphics, images and information, contained on or available in this website is for
        general information purposes only. Medisonal Ltd. makes no representation and assumes no responsibility for the
        accuracy of information contained on or available in this website, and such information is subject to change
        without notice.
      </p>
      <p>
        Medisonal Ltd does not recommend, endorse or make any representation about the efficacy, appropriateness or
        suitability of any specific tests, products, procedures, treatments, services, opinions, health care providers
        or other information that may be contained herein. Medisonal Ltd. is not responsible nor liable for any advice,
        course of treatment, diagnosis or any other information, services or products that you discover having reviewed
        this website.
      </p>
      <p>
        All third-party research or content referred to herein is for informational purposes only. Neither Medisonal
        Ltd. nor any contributors to this website will be liable for any direct, indirect, consequential, special,
        exemplary or other damages arising from any reliance therefrom.
      </p>
      <sub>Last updated on 12 October 2023</sub>

      <hr />
      <h2>Terms of Use</h2>
      <p>
        These Terms of Use govern your use of the website located at{' '}
        <a href="https://www.medisonal.co.uk">https://www.medisonal.co.uk</a> and any related services provided by
        Medisonal.
      </p>
      <p>
        By accessing <a href="https://www.medisonal.co.uk">https://www.medisonal.co.uk</a>, you agree to abide by these
        Terms of Use and to comply with all applicable laws and regulations. If you do not agree with these Terms of
        Use, you are prohibited from using or accessing this website or using any other services provided by Medisonal.
      </p>
      <p>
        We, Medisonal, reserve the right to review and amend any of these Terms of Use at our sole discretion. Upon
        doing so, we will update this page. Any changes to these Terms of Use will take effect immediately from the date
        of publication.
      </p>

      <h4>Limitations of Use</h4>
      <p>
        By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you
        will not:
      </p>
      <ol>
        <li>
          modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained
          on this website;
        </li>
        <li>remove any copyright or other proprietary notations from any materials and software on this website;</li>
        <li>transfer the materials to another person or “mirror” the materials on any other server;</li>
        <li>
          knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts
          our networks or any other service Medisonal provides;
        </li>
        <li>
          use this website or its associated services to transmit or publish any harassing, indecent, obscene,
          fraudulent, or unlawful material;
        </li>
        <li>use this website or its associated services in violation of any applicable laws or regulations;</li>
        <li>use this website in conjunction with sending unauthorised advertising or spam;</li>
        <li>harvest, collect or gather user data without the user’s consent; or</li>
        <li>
          use this website or its associated services in such a way that may infringe the privacy, intellectual property
          rights, or other rights of third parties.
        </li>
      </ol>

      <h4>Intellectual Property</h4>
      <p>
        The intellectual property in the materials contained in this website are owned by or licensed to Medisonal and
        are protected by applicable copyright and trademark law. We grant our users permission to download one copy of
        the materials for personal, non-commercial transitory use.
      </p>
      <p>
        This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if
        you violate any of these restrictions or the Terms of Use, and may be terminated by Medisonal at any time.
      </p>

      <h4>User-Generated Content</h4>
      <p>
        You retain your intellectual property ownership rights over content you submit to us for publication on our
        website. We will never claim ownership of your content but we do require a license from you in order to use it.
      </p>
      <p>
        When you use our website or its associated services to post, upload, share or otherwise transmit content covered
        by intellectual property rights, you grant to us a non-exclusive, royalty-free, transferable, sub-licensable,
        worldwide license to use, distribute, modify, run, copy, publicly display, translate or otherwise create
        derivative works of your content in a manner that is consistent with your privacy preferences and our Privacy
        Policy.
      </p>
      <p>
        The license you grant us can be terminated at any time by deleting your content or account. However, to the
        extent that we (or our partners) have used your content in connection with commercial or sponsored content, the
        license will continue until the relevant commercial or post has been discontinued by us.
      </p>
      <p>
        You give us permission to use your username and other identifying information associated with your account in a
        manner that is consistent with your privacy preferences and our Privacy Policy.
      </p>

      <h4>Liability</h4>
      <p>
        Our website and the materials on our website are provided on an ‘as is’ basis. To the extent permitted by law,
        Medisonal makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties
        including, without limitation, implied warranties or conditions of merchantability, fitness for a particular
        purpose, or non-infringement of intellectual property, or other violation of rights.
      </p>
      <p>
        In no event shall Medisonal or its suppliers be liable for any consequential loss suffered or incurred by you or
        any third party arising from the use or inability to use this website or the materials on this website, even if
        Medisonal or an authorised representative has been notified, orally or in writing, of the possibility of such
        damage.
      </p>
      <p>
        In the context of this agreement, “consequential loss” includes any consequential loss, indirect loss, real or
        anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of
        opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under
        statute, contract, equity, tort (including negligence), indemnity or otherwise.
      </p>
      <p>
        Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for
        consequential or incidental damages, these limitations may not apply to you.
      </p>

      <h4>Accuracy of Materials</h4>
      <p>
        The materials appearing on our website are not comprehensive and are for general information purposes only.
        Medisonal does not warrant or make any representations concerning the accuracy, likely results, or reliability
        of the use of the materials on this website, or otherwise relating to such materials or on any resources linked
        to this website.
      </p>

      <h4>Links</h4>
      <p>
        Medisonal has not reviewed all of the sites linked to its website and is not responsible for the contents of any
        such linked site. The inclusion of any link does not imply endorsement, approval or control by Medisonal of the
        site. Use of any such linked site is at your own risk and we strongly advise you make your own investigations
        with respect to the suitability of those sites.
      </p>

      <h4>Right to Terminate</h4>
      <p>
        We may suspend or terminate your right to use our website and terminate these Terms of Use immediately upon
        written notice to you for any breach of these Terms of Use.
      </p>

      <h4>Severance</h4>
      <p>
        Any term of these Terms of Use which is wholly or partially void or unenforceable is severed to the extent that
        it is void or unenforceable. The validity of the remainder of these Terms of Use is not affected.
      </p>

      <h4>Governing Law</h4>
      <p>
        These Terms of Use are governed by and construed in accordance with the laws of United Kingdom. You irrevocably
        submit to the exclusive jurisdiction of the courts in that country or location.
      </p>

      <sub>Last updated on 12 October 2023</sub>
    </div>
  );
}
