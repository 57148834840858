import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './theme';
import Router from './routes/Router';
import FeedbackProvider from './components/feedback/FeedbackProvider/FeedbackProvider';
import FullscreenLoader from './components/feedback/FullscreenLoader/FullscreenLoader';
import { useLoader } from './hooks/useLoader';
import NativeProvider from './components/NativeProvider/NativeProvider';
import NotificationsProvider from './components/NotificationsProvider/NotificationsProvider';
import CookieConsentProvider from './components/cookie-consent-provider/CookieConsentProvider';


function App() {
  const loader = useLoader();

  return (
    <NativeProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <NotificationsProvider />
          <CookieConsentProvider />

          <CssBaseline />
          {loader.loading && <FullscreenLoader />}
          <FeedbackProvider />

          <Router />
        </ThemeProvider>
      </StyledEngineProvider>
    </NativeProvider>
  );
}

export default App;
