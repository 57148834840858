import React, { useCallback } from 'react';
import classNames from 'classnames';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import ButtonBase from '@mui/material/ButtonBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';

import { useMdtContext } from '../../../../hooks/useMdtContext';
import { getUsersSearchParams } from '../../../../utils/userSearch';
import styles from './MdtThreadSidebar.module.scss';
import Chip from '../../../../components/chips/Chip/Chip';
import { NavLink } from 'react-router-dom';
import { debounce } from '../../../../utils/debounce';
import { useAppDispatch } from '../../../../store/hooks';
import { addUserToThread, removeUserFromThread } from '../../../../store/slices/thread/threadThunks';

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  width: '100%!important',
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
    width: '100%',
  },
  [`.${autocompleteClasses.poper}`]: {
    width: '100%!important',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,

      borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const PopperComponent = (props) => {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
  borderRadius: 6,
  maxWidth: 400,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  padding: '14px',
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const SearchInput = (props) => {
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <div className={styles.search}>
      <Box className={classNames(styles.box, { [styles.focusedSearch]: isFocused })}>
        <SearchIcon />
        <InputBase
          ref={props.InputProps.ref}
          inputProps={props.inputProps}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          autoFocus
          placeholder="Type or choose a user"
        />
      </Box>
    </div>
  );
};

const excludeDuplicates = (users) =>
  users.filter((user, index, self) => index === self.findIndex((t) => t.id === user.id));

export default function MdtThreadSidebar({ thread }) {
  const dispatch = useAppDispatch();

  const { groupUsers, getGroupUsers } = useMdtContext();

  const handleAddUserToThread = (user) => dispatch(addUserToThread({ threadId: thread.id, user }));

  const handleRemoveUserFromThread = (user) => dispatch(removeUserFromThread({ threadId: thread.id, userId: user.id }));

  if (thread === null) {
    return (
      <div className={styles.wrapper}>
        {new Array(5).fill(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton variant="circular" height="25px" width="28px" sx={{ m: '4px' }} />
            <Skeleton variant="rounded" height="16px" width={'250px'} />
          </div>,
        )}
      </div>
    );
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const found = inputValue.length >= 3 && groupUsers ? groupUsers : [];
  const options = excludeDuplicates([...thread.members, ...found]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const id = open ? 'github-label' : undefined;

  const getDebouncedGroupUsers = useCallback(
    debounce(async (search) => await getGroupUsers({ groupId: thread.groupId, ...getUsersSearchParams(search) }), 1000),
    [],
  );

  const displayMember = (member) => {
    const userPageLink = `/internal/users/${member.id}`;

    return (
      <ListItem className={classNames(styles.listItem)} key={member.id}>
        <div>
          <NavLink to={userPageLink} target="_blank" rel="noopener noreferrer">
            <Avatar className={styles.avatar}>
              {member.firstName[0]}
              {member.lastName[0]}
            </Avatar>
          </NavLink>
          <div className={styles.info}>
            <NavLink to={userPageLink} target="_blank" rel="noopener noreferrer">
              <Typography className={styles.labelName}>
                {member.firstName} {member.lastName}
              </Typography>
            </NavLink>
            <div className={styles.labelWrapper}>
              <Chip className={styles.label} size="small" label={member.speciality} />
              <Chip className={styles.label} size="small" color="warning" label={member.role.toLowerCase()} />
            </div>
          </div>
        </div>
      </ListItem>
    );
  };
  return (
    <div className={styles.wrapper}>
      <ButtonBase className={styles.button} disableRipple aria-describedby={id} onClick={handleClick}>
        <Typography>Manage MDT Members</Typography>
        <SettingsIcon />
      </ButtonBase>
      <List className={styles.list}>{thread.members.map((member) => displayMember(member))}</List>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }

                setInputValue('');
              }}
              value={thread.members}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No members"
              onInputChange={async (_, search, reason) => {
                if (reason !== 'reset') {
                  setInputValue(search);
                }
                await getDebouncedGroupUsers(search);
              }}
              inputValue={inputValue}
              getOptionDisabled={(option) => ['REVIEWER', 'OWNER'].includes(option.role)}
              renderOption={(props, option, { selected }) => (
                <li {...props} className={classNames(styles.renderOptionItem, props.className)}>
                  <div>
                    <NavLink to={`/internal/users/${option.id}`} target="_blank" rel="noopener noreferrer">
                      <Avatar className={styles.avatar}>
                        {option.firstName[0]}
                        {option.lastName[0]}
                      </Avatar>
                    </NavLink>
                    <div className={styles.info}>
                      <div className={styles.infoRow}>
                        <NavLink to={`/internal/users/${option.id}`} target="_blank" rel="noopener noreferrer">
                          <Typography className={styles.labelAutocomplete}>
                            {option.firstName} {option.lastName}
                          </Typography>
                        </NavLink>
                        <div className={styles.labelWrapper}>
                          <Chip className={styles.label} label={option.speciality} />
                        </div>
                      </div>

                      <span className={styles.email}>{option.email}</span>
                    </div>
                  </div>
                  <IconButton
                    className={styles.autoCompleteButton}
                    disabled={['REVIEWER', 'OWNER'].includes(option.role)}
                    onClick={async () => await handleAddUserToThread({ ...option, role: 'MEMBER' })}
                    style={{ display: selected ? 'none' : 'flex' }}
                  >
                    <DoneIcon fontSize="small" />
                  </IconButton>

                  <IconButton
                    className={styles.autoCompleteButton}
                    disabled={['REVIEWER', 'OWNER'].includes(option.role)}
                    onClick={async () => await handleRemoveUserFromThread(option)}
                    style={{ display: selected ? 'flex' : 'none' }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </li>
              )}
              options={options}
              getOptionLabel={(option) => option.email}
              filterOptions={(options, state) =>
                options.filter((option) =>
                  `${option.firstName} ${option.lastName} ${option.email}`
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase()),
                )
              }
              renderInput={(params) => <SearchInput {...params} />}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </div>
  );
}
