import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { EditorState } from 'draft-js';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';

import ThreadHeader from '../components/thread/ThreadHeader';
import ThreadDetails from '../components/thread//ThreadDetails';
import ThreadMessages from '../components/thread/ThreadMessages';
import ThreadEditor from '../components/thread/ThreadEditor';
import { useMdtContext } from '../../../../hooks/useMdtContext';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { getUserRole } from '../../../../utils/permissions';
import { MdtPermissions, UserRole } from '../../../../constants/user.constants';
import ThreadLayout from '../components/thread/ThreadLayout/ThreadLayout';
import ErrorMessage from '../../../../components/feedback/ErrorMessage/ErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getThread } from '../../../../store/slices/thread/threadThunks';
import { clearThread, selectThread } from '../../../../store/slices/thread/threadSlice';
import { config } from '../../../../configs/config';
import { usePolling } from '../../../../hooks/usePolling';
import { useMessageSubmission } from '../../../../hooks/useMessageSubmission';
import { ThreadMessageAttachmentsValidation, ThreadMessageTextValidation } from '../components/validations/ThreadMessage';


const validationSchema = yup.object({ text: ThreadMessageTextValidation, attachments: ThreadMessageAttachmentsValidation });

export default function PrivateThread() {
  const params = useParams();
  const dispatch = useAppDispatch();

  const { thread, error: threadError, isLoading } = useAppSelector(selectThread);

  const messagesEndRef = useRef(null);

  const [editMessageId, setEditMessageId] = useState(null);

  useEffect(() => {
    dispatch(getThread({ id: params.threadId }));

    return () => dispatch(clearThread());
  }, [params.threadId]);

  usePolling({
    callback: () => dispatch(getThread({ id: params.threadId, isPolling: true })),
    interval: config.httpPolling.thread.interval,
    skip: isLoading,
  });

  const { group } = useMdtContext();
  const { user } = useAuthContext();

  const canEdit = user.id === thread?.user?.id && !thread.messages?.length;
  const rolePermissions = {
    [UserRole.ADMIN]: [
      MdtPermissions.CREATE_THREAD,
      MdtPermissions.EDIT_THREAD,
      MdtPermissions.DELETE_THREAD,
      MdtPermissions.READ_THREAD,
      MdtPermissions.DELETE_MESSAGE,
    ],
    [UserRole.DEFAULT]: [
      MdtPermissions.READ_GROUP,
      MdtPermissions.CREATE_THREAD,
      canEdit && MdtPermissions.EDIT_THREAD,
      canEdit && MdtPermissions.DELETE_THREAD,
    ],
  };
  const permissions = rolePermissions[getUserRole(user.scopes)] || [];

  const formik = useFormik({
    initialValues: { text: EditorState.createEmpty(), attachments: [] },
    validationSchema,
    onSubmit: (values) => onSubmit(values)
  });

  const { onSubmit } = useMessageSubmission({ formik, thread, messagesEndRef, editMessageId, setEditMessageId })

  return (
    <ThreadLayout
      header={<ThreadHeader group={group} thread={thread} permissions={permissions} isLoading={isLoading} />}
    >
      {threadError ? (
        <ErrorMessage>{threadError}</ErrorMessage>
      ) : (
        <>
          <ThreadDetails thread={thread} />

          <ThreadMessages
            editPeriod={3600}
            permissions={permissions}
            thread={thread}
            formik={formik}
            editMessageId={editMessageId}
            setEditMessageId={setEditMessageId}
            messagesEndRef={messagesEndRef}
          />

          <ThreadEditor
            isEdit={!!editMessageId}
            formik={formik}
            error={formik.touched.text && Boolean(formik.errors.text)}
            errorHelperText={formik.errors.text}
          />
        </>
      )}
    </ThreadLayout>
  );
}
