import React from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import classNames from 'classnames';

import styles from './FilterSelect.module.scss';

export default function FilterSelect({
  onChange,
  className,
  name,
  value,
  label,
  disabled,
  defaultOption = 'All',
  options = [],
}) {
  return (
    <FormControl className={styles.selectWrapper}>
      {!value && (
        <InputLabel className={styles.selectLabel} shrink={false} id="demo-simple-select-standard-label">
          {label}
        </InputLabel>
      )}
      <Select
        name={name}
        labelId="demo-simple-select-standard-label"
        className={classNames(className, styles.select, { [styles.activeSelect]: !!value })}
        value={value}
        onChange={onChange}
        disabled={disabled}
        input={<OutlinedInput />}
      >
        {defaultOption && (
          <MenuItem className={styles.selectItem} value="">
            {defaultOption}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.name} className={styles.selectItem} value={option.name}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
