import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import PasswordResetForm from './password-reset-form/PasswordResetForm';
import styles from './PasswordResetPage.module.scss';

export default function PasswordResetPage() {
  return (
    <Container className={styles.container}>
      <Typography className={styles.title} component="h1" variant="h4">
        Reset Password
      </Typography>
      <PasswordResetForm />
    </Container>
  );
}
