import axios from 'axios';
import { config } from '../../configs/config';
import UserService from './UserService';
import AuthService from './AuthService';
import { getErrorMessage } from '../../utils/error';
import { ApiRoute } from './ApiRoute';
// import { unsubscribeFromNotifications } from '../../store/slices/auth/authUtils';

const ApiClient = axios.create({
  baseURL: config.apiUrl,
  timeout: 15000,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

ApiClient.interceptors.request.use(
  (config) => {
    const token = AuthService.getLocalAccessToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

ApiClient.interceptors.response.use(
  (response) => response,
  async function (error) {
    try {
      const originalRequest = error.config;

      if (originalRequest.url === '/auth/token' || originalRequest.url === ApiRoute.MFA) {
        throw error;
      }

      if (originalRequest._retry) {
        throw error;
      }

      if (error?.response?.status !== 401) {
        throw error;
      }

      originalRequest._retry = true;
      const response = await ApiClient.post('/auth/token/refresh').catch(async () => {
        // await unsubscribeFromNotifications();
        UserService.removeLocalUser();
        AuthService.removeLocalTokens();
        location.reload();
      });

      if (response?.status !== 200 || !response?.data?.accessToken) {
        // await unsubscribeFromNotifications();
        UserService.removeLocalUser();
        AuthService.removeLocalTokens();
        location.reload();

        throw error;
      }

      ApiClient.defaults.headers.common['Authorization'] = `Bearer ${response?.data?.accessToken}`;
      AuthService.setLocalTokens(response?.data);

      return ApiClient(originalRequest);
    } catch (error) {
      const message = getErrorMessage(error);

      return Promise.reject(new Error(message));
    }
  },
);

export default ApiClient;
