import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import * as CookieConsent from 'vanilla-cookieconsent';

import { withPlatformSpecific } from '../HOCs/withPlatformSpecific/withPlatformSpecific';
import { config } from '../../configs/config';

const CookieConsentProvider: React.FC = () => {
  const consentConfig: CookieConsent.CookieConsentConfig = {
    guiOptions: {
      consentModal: {
        layout: 'bar',
        position: 'bottom left',
        equalWeightButtons: true,
        flipButtons: false
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: true
      }
    },
    categories: {
      necessary: {
        readOnly: true
      },
      functionality: {
        enabled: true
      },
      analytics: {
        enabled: true,
        services: {
          'Google Analytics': {
            onAccept: () => TagManager.initialize({ gtmId: config.googleTagId || '' }),
            cookies: [{ name: /^(_ga|_gid)/ }]
          }
        },
        autoClear: {
          cookies: [{ name: /^(_ga)/ }, { name: '_gid' }]
        }
      },
      performance: {
        enabled: true
      }
    },
    language: {
      default: 'en',
      translations: {
        en: {
          consentModal: {
            title: 'We value your privacy',
            description: 'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept all", you consent to our use of cookies.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage preferences',
            footer: '<a href=\"privacy-policy\" target="_blank" rel="noreferrer">Privacy Policy</a>\n<a href=\"terms-of-use\" target="_blank" rel="noreferrer">Terms and conditions</a>'
          },
          preferencesModal: {
            title: 'Consent Preferences',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Save preferences',
            closeIconLabel: 'Close modal',
            serviceCounterLabel: 'Service|Services',
            sections: [{
                title: 'Cookie Usage',
                description: `We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below.
                          The cookies that are categorized as "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of the site.
                          We also use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent.
                          You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.`
              },
              {
                title: 'Strictly Necessary <span class=\"pm__badge\">Always Enabled</span>',
                description: 'Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.',
                linkedCategory: 'necessary'
              },
              {
                title: 'Functionality',
                description: 'Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.',
                linkedCategory: 'functionality'
              },
              {
                title: 'Analytics',
                description: 'Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.',
                linkedCategory: 'analytics'
              },
              {
                title: 'Performance',
                description: 'Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.',
                linkedCategory: 'performance'
              },
            ]
          }
        }
      }
    }
  }


  useEffect(() => {
    CookieConsent.run(consentConfig);
}, []);


  return null;
};

export default withPlatformSpecific({
  WebComponent: CookieConsentProvider,
  NativeComponent: CookieConsentProvider
});
