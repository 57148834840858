import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { EditorState, ContentState, convertToRaw } from 'draft-js';

export const convertHtmlToEditorState = (text) => {
  const blocksFromHtml = htmlToDraft(text);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  return EditorState.createWithContent(contentState);
};

export const convertEditorStateToHtml = (editorState) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent());

  return draftToHtml(rawContentState);
};

export const convertHtmlToPlainText = (text) => {
  const blocksFromHtml = htmlToDraft(text);
  const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);

  return contentState.getPlainText();
}
