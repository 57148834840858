import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useFormik } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import IconButton from '../../../../components/buttons/IconButton/IconButton';
import { displayCents } from '../../../../utils/money';
import { deletePlan, getPlan, updatePlan } from '../../../../store/slices/membershipPlan/membershipPlanThunks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearPlan, selectPlan } from '../../../../store/slices/membershipPlan/membershipPlanSlice';
import SettingsItem from '../../../../components/settings-item/SettingsItem';
import SelectInputField from '../../../../components/select-input-field/SelectInputField';
import TextInputField from '../../../../components/text-input-field/TextInputField';
import styles from './AdminMembershipPlanPage.module.scss';
import { PlanRecurringInterval } from '../../../../types/enums/PlanRecurringInterval';

const validationSchema = yup.object({
  name: yup.string().min(2).max(100, 'Max plan name 10 characters').required('Name field is required'),
  description: yup.string().max(1000, 'Max plan description 1000 characters').required('Description field is required'),
  amount: yup.number().min(0, 'Amount must be greater than or equal to 0').max(1000, 'amount must be less than or equal to 1000').required('Amount field is required'),
});

const AdminMembershipPlanPage: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { plan, loading } = useAppSelector(selectPlan);
  const { planId } = useParams<{ planId: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialValues = {
    name: plan?.name || '',
    description: plan?.description || '',
    amount: plan?.amount ? plan.amount / 100 : 0,
    currency: plan?.currency || 'gbp',
    recurringInterval: plan?.recurringInterval || PlanRecurringInterval.MONTH,
  };

  useEffect(() => {
    if (planId) {
      dispatch(getPlan(planId))
    }

    return () => { dispatch(clearPlan()); }
  }, [planId]);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await dispatch(updatePlan({
        id: planId!,
        name: values.name,
        description: values.description,
        currency: values.currency,
        recurringInterval: values.recurringInterval,
        amount: values.amount * 100,
      }));

      setIsEditing(false);
    },
  });

  const renderActions = () => {
    if (loading) {
      return <CircularProgress size={24} />
    }

    if (!isEditing) {
      return <div>
        <IconButton icon="edit" onClick={() => setIsEditing(true)} />
        <IconButton icon="delete" onClick={async () => {
          planId && await dispatch(deletePlan(planId)).unwrap();

          navigate('/internal/admin/membership/plans');
        }} />
      </div>
    }

    return <div>
      <IconButton icon="close" onClick={() => setIsEditing(false)} />
      <IconButton icon="save" onClick={() => {
        formik.handleSubmit();
        setIsEditing(false);
      }} />
    </div>
  }

  const EditableField = ({ isEditing, title, children, editComponent, className }: { isEditing: boolean, title: string, children: React.ReactNode,  editComponent: React.ReactNode, className?: string}) => (
    <SettingsItem title={title} className={className}>
      {!isEditing ? children : editComponent}
    </SettingsItem>
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <IconButton icon={<ChevronLeftIcon />} onClick={() => navigate(`/internal/admin/membership/plans`)}></IconButton>
          Membership Plans
        </div>

        {renderActions()}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <SettingsItem title='ID'>{plan?.id}</SettingsItem>
        <SettingsItem title='Name'>
          {
            isEditing ? <TextInputField id="name" name="name" size="small" isEditing={isEditing} value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.name && Boolean(formik.errors.name)} errorHelperText={formik.errors.name} />
            : plan?.name
          }
        </SettingsItem>

        <SettingsItem title='Description' className={styles.description}>
          {
            isEditing ? <TextInputField type="description" id="description" name="description" size="small" multiline rows={4} value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.description && Boolean(formik.errors.description)} errorHelperText={formik.touched.description && formik.errors.description} helperText="Use a comma to create a new list item" />
            : <ul>{plan?.description.split(',').map((item, index) => <li key={index}>{item}</li>)}</ul>
          }
        </SettingsItem>

        <SettingsItem title='Amount'>
          {
            isEditing ? <TextInputField type="amount" id="amount" name="amount" size="small" value={formik.values.amount} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.amount && Boolean(formik.errors.amount)} errorHelperText={formik.touched.amount && formik.errors.amount} />
            : displayCents(plan?.amount || 0)
          }
        </SettingsItem>

        <EditableField
          isEditing={isEditing}
          title='Currency'
          editComponent={
            <SelectInputField
              name="currency"
              size="small"
              value={formik.values.currency}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              error={formik.touched.currency && formik.errors.currency}
            >
              <MenuItem value="gbp">GBP</MenuItem>
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="eur">EUR</MenuItem>
            </SelectInputField>
          }
        >
          {plan?.currency?.toLocaleUpperCase()}
        </EditableField>
        <EditableField
          isEditing={isEditing}
          title='Payment Interval'
          editComponent={
            <SelectInputField
              name="recurringInterval"
              size="small"
              value={formik.values.recurringInterval}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              error={formik.touched.recurringInterval && formik.errors.recurringInterval}
            >
              <MenuItem value={PlanRecurringInterval.MONTH}>Month</MenuItem>
              <MenuItem value={PlanRecurringInterval.YEAR}>Year</MenuItem>
            </SelectInputField>
          }
        >
          {plan?.recurringInterval}
        </EditableField>
        <SettingsItem title='Payment Gateway ID'>{plan?.gatewayPlanId}</SettingsItem>
        <SettingsItem title='Payment Gateway Price ID'>{plan?.gatewayPriceId}</SettingsItem>
      </form>
    </div>
  );
};

export default AdminMembershipPlanPage;
