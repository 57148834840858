import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
  className?: string;
  disabled?: boolean;
}

const CloseButton: React.FC<Props> = ({ onClick, className, disabled }) => {
  return (
    <IconButton onClick={onClick} className={className} disabled={disabled}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
