import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { selectUserDetails, setUserId } from '../../store/slices/userDetails/userDetailsSlice';
import { getUserDetails } from '../../store/slices/userDetails/userDetailsThunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const useUserDetailsEffects = () => {
  const dispatch = useAppDispatch();
  const { userId: userIdParam } = useParams();

  const { userId } = useAppSelector(selectUserDetails);

  useEffect(() => {
    if (userIdParam) {
      dispatch(setUserId(userIdParam));
    }
  }, [userIdParam]);

  useEffect(() => {
    if (userId) {
      dispatch(getUserDetails());
    }
  }, [userId]);
};
