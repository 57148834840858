import React, { useEffect, useState } from 'react';
import styles from './AccountOnboardingPage.module.scss';
import IdentityStep from './components/steps/IdentityStep';
import CredentialCertificatesStep from './components/steps/CredentialCertificatesStep';
import ApplicationReviewStep from './components/steps/ApplicationReviewStep';
import { UserProfileStatus } from '../../../constants/user.constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getOrganisations } from '../../../store/slices/organisations/organisationsThunks';
import { OrganisationView } from '../../../types/enums/OrganisationView';
import { getOnboardingProfile } from '../../../store/slices/onboardingProfile/onboardingProfileThunks';
import {
  selectOnboardingProfile,
  syncOnboardingProfile,
} from '../../../store/slices/onboardingProfile/onboardingProfileSlice';
import ErrorMessage from '../../../components/feedback/ErrorMessage/ErrorMessage';
import FullscreenLoader from '../../../components/feedback/FullscreenLoader/FullscreenLoader';
import { OrganisationCategory } from '../../../types/enums/OrganisationCategory';
import MdtOnboardingStepper from './components/MdtOnboardingStepper';
import PersonalInformationStep from './components/steps/PersonalInformationStep';
import { OnboardingApplicationStatus } from '../../../types/enums/OnboardingApplicationStatus';
import MembershipPlanStep from './components/steps/MembershipPlanStep';

export default function AccountOnboardingPage() {
  const dispatch = useAppDispatch();

  const { onboardingProfile, loading, error } = useAppSelector(selectOnboardingProfile);
  const [activeStep, setActiveStep] = useState(0);

  const isContactAndSpecialityCompleted = onboardingProfile?.address?.country && onboardingProfile.phoneNumber && onboardingProfile.speciality
  const isIdentityCompleted = onboardingProfile?.identity?.document
  const isCredentialCertificateCompleted = onboardingProfile?.qualityCredential?.medicalRegistrationNumber;
  const isApplicationReviewCompleted = UserProfileStatus.IN_REVIEW === onboardingProfile?.status;
  const isMembershipPlanCompleted = onboardingProfile?.subscriptions?.length;

  const steps = [
    { label: 'Contact & Speciality Information', completed: isContactAndSpecialityCompleted },
    { label: 'Identity', completed: isIdentityCompleted },
    { label: 'Credential Certificates', completed: isCredentialCertificateCompleted },
    { label: 'Membership Plan', completed: isMembershipPlanCompleted },
    { label: 'Application Review', completed: isApplicationReviewCompleted },
  ];

  const componentStepMap = {
    0: PersonalInformationStep,
    1: IdentityStep,
    2: CredentialCertificatesStep,
    3: MembershipPlanStep,
    4: ApplicationReviewStep,
  };
  const ActiveStepComponent = componentStepMap[activeStep];

  const isStepperDisabled = [
    UserProfileStatus.IN_REVIEW,
    UserProfileStatus.APPROVED,
    UserProfileStatus.REJECTED,
  ].includes(onboardingProfile?.status) || onboardingProfile?.onboardingApplicationStatus.includes(OnboardingApplicationStatus.RESTRICTED_STANDARD);


  useEffect(() => {
    dispatch(getOrganisations({ view: OrganisationView.ALL, category: OrganisationCategory.PRIVATE }));
    dispatch(getOnboardingProfile());
  }, []);

  useEffect(() => {
    if (onboardingProfile) updateActiveStep(onboardingProfile);
  }, [onboardingProfile]);

  const handleSyncOnboardingProfile = (profile) => dispatch(syncOnboardingProfile(profile));

  const handleBack = () => setActiveStep((activeStep) => activeStep - 1);
  const handleNext = () => {
    setActiveStep((activeStep) => {
      const nextStep = activeStep + 1;

      return nextStep > 4 ? 4 : nextStep;
    });
  };

  const renderStep = () => {
    return (
      <ActiveStepComponent
        profile={onboardingProfile}
        setProfile={handleSyncOnboardingProfile}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        onboardingApplicationStatus={OnboardingApplicationStatus.RESTRICTED_STANDARD_PENDING}
        nextDisabled={isStepperDisabled}
        backDisabled={isStepperDisabled}
      />
    );
  };

  const updateActiveStep = (profile) => {
    if (
      [
        UserProfileStatus.IN_REVIEW,
        UserProfileStatus.CHANGES_REQUESTED,
        UserProfileStatus.APPROVED,
        UserProfileStatus.REJECTED,
      ].includes(profile.status)
    ) {
      setActiveStep(4);
    } else if (!isContactAndSpecialityCompleted) {
      setActiveStep(0);
    } else if (!isIdentityCompleted) {
      setActiveStep(1);
    } else if (!isCredentialCertificateCompleted) {
      setActiveStep(2);
    } else if (!isMembershipPlanCompleted) {
      setActiveStep(3);
    } else setActiveStep(4);
  };

  const handleStepChange = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  if (loading)
    return (
      <div className={styles.container}>
        <FullscreenLoader />
      </div>
    );

  if (!onboardingProfile || error)
    return (
      <div className={styles.container}>
        <ErrorMessage>{error || 'Failed to load onboarding profile'}</ErrorMessage>
      </div>
    );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>Verification</h2>
      </div>
      <div className={styles.stepperContainer}>
        <MdtOnboardingStepper
          steps={steps}
          activeStep={activeStep}
          onStepChange={handleStepChange}
          disabled={isStepperDisabled}
        />

        <div className={styles.activeStepComponentContainer}>{renderStep()}</div>
      </div>
    </div>
  );
}
