import React, { useEffect, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import styles from './SubscriptionPurchase.module.scss';
import MembershipService from '../../../../../../services/api/MembershipService';
import { Plan } from '../../../../../../types/Plan';
import { displayCentsWithCurrency } from '../../../../../../utils/money';
import { PlanTier } from '../../../../../../types/enums/PlanTier';
import ModalTitle from '../../../../../../components/modal-title/ModalTitle';
import { Subscription } from '../../../../../../types/Subscription';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { AnalyticsEvent } from '../../../../../../constants/analytics.constants';
import { logAnalyticsEvent } from '../../../../../../utils/analytics';
import { PlanRecurringInterval } from '../../../../../../types/enums/PlanRecurringInterval';

const SubscriptionPurchase: React.FC<{ title?: string, isPublic?: boolean }> = ({ title, isPublic }) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [interval, setInterval] = useState<string>('year');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMembershipPlans = async () => {
      const plans = await MembershipService.getPlans();
      setPlans(plans);
    };

    const fetchMembershipSubscriptions = async () => {
      setLoading(true);
      const subscriptions = await MembershipService.getSubscriptions();
      setSubscriptions(subscriptions);
      setLoading(false);
    }

    fetchMembershipPlans();
    !isPublic && fetchMembershipSubscriptions();
  }, []);

  const handleSubscribe = async (plan: Plan) => {
    logAnalyticsEvent(AnalyticsEvent.PAYMENT_CREATE, { plan_type: plan.tier, plan_recurring_interval: plan.recurringInterval });
    if (isPublic) {
      return navigate('sign-up');
    }

    const { url } = await MembershipService.createUserCheckoutSession(plan.id);
    window.location.href = url;
  };

  const handleIntervalChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value) setInterval(value);
  };

  const isAnnual = (interval: PlanRecurringInterval) => PlanRecurringInterval.YEAR === interval;

  const renderToggleButtonGroup = () => (
    <ToggleButtonGroup
      color="primary"
      value={interval}
      exclusive
      onChange={handleIntervalChange}
      className={styles.buttonGroup}
    >
      <ToggleButton value="month">Month</ToggleButton>
      <ToggleButton value="year">Year</ToggleButton>
    </ToggleButtonGroup>
  );

  const renderPlan = (plan: Plan) => (
    <div key={plan.id} className={styles.planContainer}>
      <div className={styles.infoWrapper}>
        <div className={styles.name}>{plan.name}</div>
        <ul className={styles.description}>{plan?.description.split(',').map((item, index) => <li key={index}>{item}</li>)}</ul>
      </div>

      <div className={styles.purchaseWrapper}>
        <div className={styles.amountWrapper}>
          <div className={styles.amount}>{displayCentsWithCurrency(isAnnual(plan.recurringInterval) ? plan.amount / 12 : plan.amount, plan.currency)}</div>
          <div className={styles.interval}>per month {isAnnual(plan.recurringInterval) && <span>(paid yearly)</span>}</div>
        </div>
        <LoadingButton loading={loading} variant="contained" className={styles.button} onClick={() => handleSubscribe(plan)}>
          {subscriptions.length ? 'Subscribe' : 'Start Free'}
        </LoadingButton>
      </div>
    </div>
  );

  const tierToNumber = (tier: PlanTier) => {
    switch (tier) {
      case PlanTier.FREE:
        return 0;
      case PlanTier.STANDARD:
        return 1;
      default:
        return 2;
    }
  };

  const renderPlans = () => (
    <div className={styles.plansContainer}>
      {plans
        .filter(plan => plan.recurringInterval === interval)
        .sort((a, b) => tierToNumber(a.tier) - tierToNumber(b.tier))
        .map(renderPlan)}
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <ModalTitle className={styles.title}>{title}</ModalTitle>
      {renderToggleButtonGroup()}
      {renderPlans()}
    </div>
  );
};

export default SubscriptionPurchase;
