import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { withPlatformSpecific } from '../../../../../../../components/HOCs/withPlatformSpecific/withPlatformSpecific';
import { Thread } from '../../../../../../../types/Thread';
import DownloadButton from '../../../../../../../components/buttons/DownloadButton/DownloadButton';
import ThreadToPDF from '../../ThreadToPDF';
import { CircularProgress } from '@mui/material';
import { useDowloadPDFNative } from '../../../../../../../hooks/useDowloadPDFNative';

interface Props {
  thread: Thread;
}

const DownloadThreadButtonWeb: React.FC<Props> = ({ thread }) => {
  return (
    <PDFDownloadLink
      document={<ThreadToPDF thread={thread} withMessages />}
      fileName={`thread-${thread?.id}-${Date.now()}.pdf`}
    >
      <DownloadButton />
    </PDFDownloadLink>
  );
};

const DownloadThreadButtonNative: React.FC<Props> = ({ thread }) => {
  const { handleDownload, isLoading } = useDowloadPDFNative({
    content: <ThreadToPDF thread={thread} withMessages />,
    fileName: `thread-${thread?.id}-${Date.now()}`,
  });

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  return <DownloadButton onClick={handleDownload} />;
};

export default withPlatformSpecific({
  WebComponent: DownloadThreadButtonWeb,
  NativeComponent: DownloadThreadButtonNative,
});
