/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { withPlatformSpecific } from '../../../../../../../components/HOCs/withPlatformSpecific/withPlatformSpecific';
import PrintButton from '../../../../../../../components/buttons/PrintButton/PrintButton';
import { Thread } from '../../../../../../../types/Thread';
import { CircularProgress } from '@mui/material';
import { useDowloadPDFNative } from '../../../../../../../hooks/useDowloadPDFNative';
import ThreadToPDF from '../../ThreadToPDF';

interface Props {
  groupId: string;
  threadId: string;
  thread: Thread;
}

const PrintThreadButtonWeb: React.FC<Props> = ({ groupId, thread }) => {
  const [loading, setLoading] = useState(false);

  async function setPrint() {
    setLoading(true);

    const closePrint = () => {
      // @ts-ignore
      document.body.removeChild(this);
      setLoading(false);
    };
    // @ts-ignore
    this.contentWindow.onbeforeunload = closePrint;
    // @ts-ignore
    this.contentWindow.onafterprint = closePrint;
  }

  const handleClick = async () => {
    const iframe = document.createElement('iframe');
    iframe.onload = setPrint;
    iframe.style.display = 'none';
    (iframe.src = `/print/groups/mdt/${groupId}/threads/${thread.id}`), document.body.appendChild(iframe);
  };

  return <PrintButton onClick={handleClick} disabled={loading} />;
};

const PrintThreadButtonNative: React.FC<Props> = ({ thread }) => {
  const { handleDownload, isLoading } = useDowloadPDFNative({
    content: <ThreadToPDF thread={thread} />,
    fileName: `thread-${thread?.id}-${Date.now()}-print`,
  });

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  return <PrintButton onClick={handleDownload} />;
};

export default withPlatformSpecific({ WebComponent: PrintThreadButtonWeb, NativeComponent: PrintThreadButtonNative });
