import { useFeedback } from '../../../../../../hooks/useFeedback';
import { OnboardingProfile } from '../../../../../../types/OnboardingProfile';
import { OnboardingApplicationStatus } from '../../../../../../types/enums/OnboardingApplicationStatus';

export const useValidateOnboardingProfile = () => {
  const { showErrorFeedback } = useFeedback();

  const handleMdtOnboardingValidation = (profile: OnboardingProfile) => {
    const { address, identity, qualityCredential } = profile;

    if (!address.addressLine1 || !address.city || !address.zipCode) {
      throw new Error('Please complete Place of Registration/Medical Practise step');
    }

    if (!identity.document) {
      throw new Error('Please complete Identity Verification step');
    }

    if (!qualityCredential.document && !qualityCredential.medicalRegistrationNumber) {
      throw new Error('Please complete Credential Certificates step');
    }

    if (!profile.annualSalary.latestAppraisalDate) {
      throw new Error('Please complete Recent Annual Appraisal step');
    }

    if (!profile.criminalBackground.document) {
      throw new Error('Please complete Criminal Background Check step');
    }

  }

  const handleAccountOnboardingValidation = (profile: OnboardingProfile) => {
    const { address, identity, qualityCredential, subscriptions } = profile;

    if (!profile.speciality || !profile.phoneNumber || !address.country) {
      throw new Error('Contact & Speciality Information');
    }

    if (!identity.document) {
      throw new Error('Please complete Identity Verification step');
    }

    if (!qualityCredential.document && !qualityCredential.medicalRegistrationNumber) {
      throw new Error('Please complete Credential Certificates step');
    }

    if (!subscriptions.length) {
      throw new Error('Please complete Membership Plan step');
    }
  }

  const handleValidate = (profile: OnboardingProfile | null) => {
    try {
      if (!profile) throw new Error('No profile');

      if (profile.onboardingApplicationStatus.includes(OnboardingApplicationStatus.RESTRICTED_STANDARD)) {
        handleMdtOnboardingValidation(profile);
      } else {
        handleAccountOnboardingValidation(profile);
      }
  
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showErrorFeedback(error.message);

      return false;
    }
  };

  return handleValidate;
};
