import { createAsyncThunk } from '@reduxjs/toolkit';

import UsersService from '../../../services/api/UsersService';
import { SliceName } from '../../config';
import { UserNotificationSettings } from '../../../types/UserNotificationSettings';


export const getUserNotificationSettings = createAsyncThunk(
  `${SliceName.USER_NOTIFICATION_SETTINGS}/getUserNotificationSettings`,
  async () => {
    return await UsersService.getUserNotificationsSettings();
  },
);

export const updateUserNotificationSettings = createAsyncThunk(
  `${SliceName.USER_NOTIFICATION_SETTINGS}/updateUserNotificationSettings`,
  async (settings: UserNotificationSettings) => {
    await UsersService.updateUserNotificationsSettings(settings);

    return settings;
  },
);
