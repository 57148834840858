import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import ProfilePage from '../../pages/private/profile/ProfilePage';

export default function ProtectedAdminLayout() {
  const auth = useAuthContext();

  if (!auth.isAdmin()) return <ProfilePage />;

  return <Outlet />;
}
