import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceName } from '../../config';
import ProfileService from '../../../services/api/ProfileService';
import { UpdateProfileDTO } from '../../../types/DTOs/UpdateProfileDTO';
import { RootState } from '../../store';
import { OnboardingProfile } from '../../../types/OnboardingProfile';

export const getOnboardingProfile = createAsyncThunk(
  `${SliceName.ONBOARDING_PROFILE}/getOnboardingProfile`,
  async () => {
    const profile = await ProfileService.getOnboardingProfile();

    return profile;
  },
);

export const updateOnboardingProfile = createAsyncThunk<
  OnboardingProfile,
  UpdateProfileDTO,
  {
    state: RootState;
  }
>(`${SliceName.ONBOARDING_PROFILE}/updateOnboardingProfile`, async (updateProfileDTO, { getState }) => {
  const { profile } = getState().profile;

  if (!profile) throw new Error('Profile not found');

  await ProfileService.updateProfile({ id: profile.id, updateProfileDTO });

  const updatedProfile = await ProfileService.getOnboardingProfile();

  return updatedProfile;
});
