import React from 'react';
import MuiIconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void> | unknown;
  className?: string;
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'default' | 'inherit';
  icon: React.ReactNode | string
}

const iconMap: { [key: string]: React.ReactNode } = {
  save: <SaveIcon />,
  edit: <EditIcon />,
  close: <CloseIcon />,
  delete: <DeleteIcon />,
  add: <AddIcon />
};

const IconButton: React.FC<Props> = ({ onClick, className, disabled, icon, color }) => {
  const Icon = typeof icon === 'string' ? iconMap[icon] : icon;

  return (
    <MuiIconButton color={color} onClick={onClick} className={className} disabled={disabled}>
      {Icon}
    </MuiIconButton>
  );
};

export default IconButton;
