import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import SignInForm from './SignInForm/SignInForm';
import classes from './SignInPage.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { SignInDTO } from '../../../types/DTOs/SignInDTO';
import { confirmMultifactorAuth, signIn } from '../../../store/slices/auth/authThunks';
import { resetMultifactorVerificationData, selectAuth } from '../../../store/slices/auth/authSlice';
import ConfirmMultifactorAuthForm from '../../../components/forms/ConfirmMultifactorAuthForm/ConfirmMultifactorAuthForm';
import { useNavigate } from 'react-router-dom';
import { PlanTier } from '../../../types/enums/PlanTier';
import { Profile } from '../../../types/Profile';
import { UserScope } from '../../../constants/user.constants';
import { UserScope as UserScopeType } from '../../../types/enums/UserScope';

const SignInPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const { multifactorVerificationData } = useAppSelector(selectAuth);
  const navigate = useNavigate();

  const handleSignIn = async (credentials: SignInDTO) => {
    const profile = await dispatch(signIn(credentials)).unwrap() as Profile;

    const isProTier = profile?.tier === PlanTier.PRO;
    const isBasicRestricted =  profile.scopes.length === 1 && profile.scopes.includes(UserScope.BASIC_RESTRICTED as UserScopeType);

    if (isProTier && isBasicRestricted) {
      navigate('/internal/onboarding/mdt-application');
    }
  };

  const handleConfirmMultifactorAuth = async (code: string) => {
    await dispatch(confirmMultifactorAuth(code)).unwrap();
  };

  const handleCancelMultifactorAuth = () => dispatch(resetMultifactorVerificationData());

  return (
    <Container className={classes.container}>
      <Typography className={classes.title} component="h1" variant="h4">
        Sign In
      </Typography>

      {multifactorVerificationData ? (
        <>
          <Typography align="center" className={classes.mfaDescription}>
            Please open your authentication app for the second step of Multi-Factor Authentication. Enter the unique,
            time-sensitive code displayed in the provided field.
          </Typography>

          <ConfirmMultifactorAuthForm onSubmit={handleConfirmMultifactorAuth} onCancel={handleCancelMultifactorAuth} />
        </>
      ) : (
        <SignInForm onSubmit={handleSignIn} />
      )}
    </Container>
  );
};

export default SignInPage;
