import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { Thread } from '../../../types/Thread';
import {
  addThreadMessage,
  addThreadMessageAttachments,
  addThreadReview,
  addUserToThread,
  deleteThread,
  deleteThreadMessage,
  editThreadMessage,
  getThread,
  removeUserFromThread,
  updateThread,
  updateThreadMessageAttachments,
} from './threadThunks';

interface ThreadState {
  thread: Thread | null;
  error: string | null | undefined;
  isLoading: boolean;
  cache: {
    [id: string]: Thread;
  };
}

const initialState: ThreadState = {
  thread: null,
  isLoading: true,
  error: null,
  cache: {},
};

const threadSlice = createSlice({
  name: SliceName.THREAD,
  initialState,
  reducers: {
    clearThread: (state) => {
      state.thread = null;
      state.error = null;
      state.isLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getThread.pending, (state, action) => {
      if (!action.meta.arg.isPolling) {
        state.isLoading = true;
        state.error = null;

        if (state.cache[action.meta.arg.id]) {
          state.thread = state.cache[action.meta.arg.id];
          state.isLoading = false;
        } else {
          state.thread = null;
        }
      }
    });

    builder.addCase(getThread.fulfilled, (state, action) => {
      state.isLoading = false;
      state.thread = action.payload;
      state.cache[action.payload.id] = action.payload;
    });

    builder.addCase(getThread.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateThread.fulfilled, (state, action) => {
      if (action.payload.id === state.thread?.id) {
        state.thread = {
          ...state.thread,
          ...action.payload,
        };
      }

      if (state.cache[action.payload.id]) {
        state.cache[action.payload.id] = {
          ...state.cache[action.payload.id],
          ...action.payload,
        };
      }
    });

    builder.addCase(deleteThread.fulfilled, (state, action) => {
      if (state.thread?.id === action.meta.arg) {
        state.thread = null;
      }

      if (state.cache[action.meta.arg]) {
        delete state.cache[action.meta.arg];
      }
    });

    builder.addCase(addUserToThread.fulfilled, (state, action) => {
      if (state.thread?.id === action.meta.arg.threadId) {
        state.thread = {
          ...state.thread,
          members: [...state.thread.members, action.meta.arg.user],
        };
      }

      if (state.cache[action.meta.arg.threadId]) {
        state.cache[action.meta.arg.threadId] = {
          ...state.cache[action.meta.arg.threadId],
          members: [...state.cache[action.meta.arg.threadId].members, action.meta.arg.user],
        };
      }
    });

    builder.addCase(removeUserFromThread.fulfilled, (state, action) => {
      if (state.thread?.id === action.meta.arg.threadId) {
        state.thread = {
          ...state.thread,
          members: state.thread.members.filter((user) => user.id !== action.meta.arg.userId),
        };
      }

      if (state.cache[action.meta.arg.threadId]) {
        state.cache[action.meta.arg.threadId] = {
          ...state.cache[action.meta.arg.threadId],
          members: state.cache[action.meta.arg.threadId].members.filter((user) => user.id !== action.meta.arg.userId),
        };
      }
    });

    builder.addCase(addThreadReview.fulfilled, (state, { payload: { threadId, message } }) => {
      if (state.thread?.id === threadId) {
        state.thread = {
          ...state.thread,
          messages: [...state.thread.messages, message],
        };
      }

      if (state.cache[threadId]) {
        state.cache[threadId] = {
          ...state.cache[threadId],
          messages: [...state.cache[threadId].messages, message],
        };
      }
    });

    builder.addCase(addThreadMessage.fulfilled, (state, action) => {
      if (state.thread?.id === action.payload.threadId) {
        state.thread = {
          ...state.thread,
          messages: [...state.thread.messages, action.payload.message],
        };
      }

      if (state.cache[action.payload.threadId]) {
        state.cache[action.payload.threadId] = {
          ...state.cache[action.payload.threadId],
          messages: [...state.cache[action.payload.threadId].messages, action.payload.message],
        };
      }
    });

    builder.addCase(editThreadMessage.fulfilled, (state, { payload: { threadId, messageId, messageUpdates } }) => {
      if (state.thread) {
        state.thread = {
          ...state.thread,
          messages: state.thread.messages.map((message) =>
            message.id === messageId ? { ...message, ...messageUpdates } : message,
          ),
        };
      }

      if (state.cache[threadId]) {
        state.cache[threadId] = {
          ...state.cache[threadId],
          messages: state.cache[threadId].messages.map((message) =>
            message.id === messageId ? { ...message, ...messageUpdates } : message,
          ),
        };
      }
    });

    builder.addCase(deleteThreadMessage.fulfilled, (state, { payload: { threadId, messageId } }) => {
      if (state.thread) {
        state.thread = {
          ...state.thread,
          messages: state.thread.messages.filter((message) => message.id !== messageId),
        };
      }

      if (state.cache[threadId]) {
        state.cache[threadId] = {
          ...state.cache[threadId],
          messages: state.cache[threadId].messages.filter((message) => message.id !== messageId),
        };
      }
    });

    builder.addCase(addThreadMessageAttachments.fulfilled, (state, { payload: { threadId, messageId, attachments } }) => {
      if (state.thread) {
        state.thread = {
          ...state.thread,
          messages: state.thread.messages.map((message) =>
            message.id === messageId ? { ...message, attachments } : message,
          ),
        };
      }

      if (state.cache[threadId]) {
        state.cache[threadId] = {
          ...state.cache[threadId],
          messages: state.cache[threadId].messages.map((message) =>
            message.id === messageId ? { ...message, attachments } : message,
          ),
        };
      }
    });

    builder.addCase(updateThreadMessageAttachments.fulfilled, (state, { payload: { threadId, messageId, attachments } }) => {
      if (state.thread) {
        state.thread = {
          ...state.thread,
          messages: state.thread.messages.map((message) =>
            message.id === messageId ? { ...message, attachments } : message,
          ),
        };
      }

      if (state.cache[threadId]) {
        state.cache[threadId] = {
          ...state.cache[threadId],
          messages: state.cache[threadId].messages.map((message) =>
            message.id === messageId ? { ...message, attachments } : message,
          ),
        };
      }
    });
  },
});

export const { clearThread } = threadSlice.actions;

export const selectThread = (state: RootState) => state.thread;

export default threadSlice.reducer;
