import { config } from '../configs/config';

export const getQueryParamsOffset = (query) =>
  query.get('page') ? (query.get('page') - 1) * config.pagination.threadsPageSize : 0;

export const getOffset = (page) => (page ? (page - 1) * config.pagination.usersPageSize : 0);

export const isS3URL = (url) => url.includes('s3://');

export const containsSubPath = (path, subpath) => path.includes(subpath);
