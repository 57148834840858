import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

import styles from './SettingsItem.module.scss';
import classNames from 'classnames';
import { CircularProgress } from '@mui/material';
import { useFeedback } from '../../hooks/useFeedback';
import IconButton from '../buttons/IconButton/IconButton';

interface Props {
  title: string;
  onSave?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
  onEdit?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  children: React.ReactNode;
  showActions?: boolean;
}

const SettingsItem: React.FC<Props> = ({
  title, children, className, showActions, onEdit, onSave, onClose
}) => {
  const { showErrorFeedback } = useFeedback();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      if (onEdit) {
        onEdit(event);
      }
    } catch (error) {
      error instanceof Error && showErrorFeedback(error.message);
    } finally {
      setIsEditing(true);
    }
  };

  const handleSaveClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      if (onSave) {
        setIsLoading(true)
        await onSave(event);
      }
    } catch (error) {
      error instanceof Error && showErrorFeedback(error.message);
    } finally {
      setIsLoading(false);
      setIsEditing(false);
    }
  };

  const handleCloseClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      if (onClose) {
        onClose(event);
      }
    } catch (error) {
      error instanceof Error && showErrorFeedback(error.message);
    } finally {
      setIsEditing(false);
    }
  };

  const renderActions = () => {
    if (!isEditing) {
      return <IconButton icon="edit" onClick={handleEditClick} />
    }

    if (isLoading) {
      return <CircularProgress size={24} />
    }

    return <>
      <IconButton icon="close" onClick={handleCloseClick} />
      <IconButton icon="save" onClick={handleSaveClick} />
    </>
  }

  return (
    <div className={classNames(styles.itemWrapper, className)}>
      <Typography className={styles.itemTitle}>
        {title}
        <div className={styles.itemActions}>
          {showActions && renderActions()}
        </div>
      </Typography>
      <Typography className={styles.itemContent}>{children}</Typography>
    </div>
  );
}

export default SettingsItem;
