import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import styles from './TextInputField.module.scss';

export default function TextInputField(props) {
  const helperText = props.error ? props.errorHelperText : props.helperText;
  return (
    <FormControl fullWidth error={props.error} className={props.containerClassName}>
      <TextField
        fullWidth
        type={props.type}
        id={props.id}
        value={props.value}
        name={props.name}
        label={props.label}
        error={props.error}
        className={styles.textField}
        onChange={props.onChange}
        autoFocus={props.autoFocus}
        onBlur={props.onBlur}
        disabled={props.disabled}
        required={props.required}
        multiline={props.multiline}
        rows={props.rows}
        size={props.size || 'normal'}
        InputProps={props.inputProps}
      />
      <FormHelperText className={styles.helperText}>{helperText}</FormHelperText>
    </FormControl>
  );
}
