import ApiClient from './ApiClient';

class DemoService {
  async createDemo(payload) {
    const { status, data } = await ApiClient.post(`demos`, { ...payload });

    return { body: data, statusCode: status };
  }

  async deleteDemo(id) {
    const { status, data } = await ApiClient.delete(`demos/${id}`);

    return { body: data, statusCode: status };
  }

  async updateDemo(id, payload) {
    const { status, data } = await ApiClient.patch(`demos/${id}`, { ...payload });

    return { body: data, statusCode: status };
  }

  async getDemo(id) {
    const { status, data } = await ApiClient.get(`demos/${id}`);

    return { body: data, statusCode: status };
  }

  async getDemos(params) {
    const { status, data, headers } = await ApiClient.get(`demos`, { params });

    return { body: data, statusCode: status, headers };
  }
}

export default new DemoService();
