import { config } from '../../configs/config';
import ApiClient from './ApiClient';

class MdtService {
  async createGroup(payload) {
    const { status, data } = await ApiClient.post(`/mdt/groups`, { ...payload });

    return { body: data, statusCode: status };
  }

  async getGroups(params) {
    const { status, data } = await ApiClient.get(`/mdt/groups`, { params });

    return { body: data, statusCode: status };
  }

  async getGroup(id, params) {
    const { status, data } = await ApiClient.get(`/mdt/groups/${id}`, { params });

    return { body: data, statusCode: status };
  }

  async getGroupUser(groupId, userId) {
    const { status, data } = await ApiClient.get(`/mdt/groups/${groupId}/users/${userId}`);

    return { body: data, statusCode: status };
  }

  async getGroupThreads(id, queryParams = {}) {
    const params = {
      userId: queryParams.userId,
      status: queryParams.status,
      title: queryParams.title,
      limit: queryParams.limit || config.pagination.threadsPageSize,
      offset: queryParams.offset || 0,
    };
    const { status, data, headers } = await ApiClient.get(`/mdt/groups/${id}/threads`, { params });

    return { body: data, statusCode: status, headers };
  }

  async getGroupUsers(id, queryParams = {}) {
    const params = {
      limit: queryParams.limit || config.pagination.usersPageSize,
      offset: queryParams.offset || 0,
      firstName: queryParams.firstName,
      lastName: queryParams.lastName,
      email: queryParams.email,
      speciality: queryParams.speciality,
      country: queryParams.country,
    };
    const { status, data, headers } = await ApiClient.get(`/mdt/groups/${id}/users`, { params });

    return { body: data, statusCode: status, headers };
  }

  async updateGroup(id, payload) {
    const { status, data } = await ApiClient.patch(`/mdt/groups/${id}`, { ...payload });

    return { body: data, statusCode: status };
  }

  async deleteGroup(id) {
    const { status } = await ApiClient.delete(`/mdt/groups/${id}`);

    return { statusCode: status };
  }

  async addUserToGroup(groupId, userId, payload) {
    const { status, data } = await ApiClient.post(`/mdt/groups/${groupId}/users/${userId}`, { ...payload });

    return { body: data, statusCode: status };
  }

  async removeUserFromGroup(groupId, userId) {
    const { status, data } = await ApiClient.delete(`/mdt/groups/${groupId}/users/${userId}`);

    return { body: data, statusCode: status };
  }

  async updateUserGroup(groupId, userId, payload) {
    const { status, data } = await ApiClient.patch(`/mdt/groups/${groupId}/users/${userId}`, { ...payload });

    return { body: data, statusCode: status };
  }

  async createThread(groupId, payload) {
    const { status, data } = await ApiClient.post(`/mdt/groups/${groupId}/threads`, { ...payload });

    return { body: data, statusCode: status };
  }

  async getThreads(queryParams = {}) {
    const params = {
      status: queryParams.status,
      title: queryParams.title,
      limit: queryParams.limit || config.pagination.threadsPageSize,
      offset: queryParams.offset || 0,
    };

    const { status, data } = await ApiClient.get(`/mdt/threads`, { params });

    return { body: data, statusCode: status };
  }

  async getDashboard() {
    const { status, data } = await ApiClient.get(`/mdt/dashboard`);

    return { body: data, statusCode: status };
  }

  async getUsage(period) {
    const params = {
      period
    };

    const { status, data } = await ApiClient.get(`/mdt/usage`, { params});

    return { body: data, statusCode: status };
  }

  async validateThreadAuthenticity(payload) {
    const { status, data } = await ApiClient.post('/mdt/validate-thread-authenticity', { ...payload });

    return { body: data, statusCode: status };
  }

  async getThreadAttachmentUploadSignature(threadId, params) {
    const { status, data } = await ApiClient.get(`/mdt/threads/${threadId}/attachments/signature`, { params });

    return { body: data, statusCode: status };
  }

  async getThreadAttachmentSignature(threadId, params) {
    const { status, data } = await ApiClient.get(`/mdt/threads/${threadId}/attachments`, { params });

    return { body: data, statusCode: status };
  }

  async deleteThreadAttachment(threadId, params) {
    const { status } = await ApiClient.delete(`/mdt/threads/${threadId}/attachments`, { params });

    return { statusCode: status };
  }
}

export default new MdtService();
