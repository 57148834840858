import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useOrganisationUsersEffects } from './useOrganisationUsersEffects';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectOrganisationUsers, setOffset } from '../../../../store/slices/organisationUsers/organisationUsersSlice';
import classes from './OrganisationsUsersPage.module.scss';
import FullscreenLoader from '../../../../components/feedback/FullscreenLoader/FullscreenLoader';
import ErrorMessage from '../../../../components/feedback/ErrorMessage/ErrorMessage';
import UsersTable from '../../../../components/tables/UsersTable/UsersTable';
import { UserExtended } from '../../../../types/User';
import Pagination from '../../../../components/tables/Pagination/Pagination';

const OrganisationsUsersPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, pagination, users, error, filters } = useAppSelector(selectOrganisationUsers);

  useOrganisationUsersEffects();

  const handleUserClick = (user: UserExtended) =>
    navigate(`/internal/organisations/${filters.organisationId}/users/${user.id}`);

  const handleOffsetChange = (offset: number) => dispatch(setOffset(offset));

  if (isLoading)
    return (
      <div className={classes.loaderContainer}>
        <FullscreenLoader />
      </div>
    );

  if (error)
    return (
      <div className={classes.container}>
        <ErrorMessage>{error}</ErrorMessage>
      </div>
    );

  return (
    <div className={classes.container}>
      <UsersTable users={users} onRowClick={handleUserClick} />

      <Pagination pagination={pagination} onOffsetChange={handleOffsetChange} centered />
    </div>
  );
};

export default OrganisationsUsersPage;
