import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import styles from './AdminUsersPage.module.scss';
import { config } from '../../../configs/config';
import FilterSelect from '../../../components/filter-select/FilterSelect';
import Pagination from '../../../components/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import Chip from '../../../components/chips/Chip/Chip';
import { useLoader } from '../../../hooks/useLoader';
import DemoService from '../../../services/api/DemoService';
import { DemoStatus, DemoStatusMap } from '../../../constants/demo.constants';

export default function AdminDemosPage() {
  const [demos, setDemos] = useState([]);
  const [filterValues, setFilterValues] = useState({ status: '' });
  const [page, setPage] = useState(1);
  const [totalDemos, setTotalDemos] = useState(0);
  const navigate = useNavigate();
  const loader = useLoader();

  const getDemos = async (values = filterValues) => {
    const { body, headers } = await DemoService.getDemos({
      ...values,
      limit: config.pagination.demosPageSize,
      offset: (page - 1) * config.pagination.demosPageSize,
    });

    setDemos(body);
    setTotalDemos(headers['x-total-count']);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      loader.startLoading();
      await getDemos();

      loader.stopLoading();
    };

    fetchInitialData();
  }, [page, filterValues]);

  const handleFilterChange = async (event) => {
    const values = { ...filterValues, [event.target.name]: event.target.value };

    setPage(1);
    setFilterValues(values);

    await getDemos(values);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <Box component="main" className={styles.wrapper}>
      <div>
        <Box className={styles.actionsWrapper}>
          <div className={styles.actions}>
            <div className={styles.filters}>
              <FilterSelect
                onChange={handleFilterChange}
                label="Status"
                name="status"
                value={filterValues.status}
                options={[
                  { name: DemoStatus.REQUESTED, label: 'Requested' },
                  { name: DemoStatus.SCHEDULED, label: 'Scheduled' },
                  { name: DemoStatus.CANCELLED, label: 'Completed' },
                  { name: DemoStatus.CANCELLED, label: 'Cancelled' },
                ]}
              />
            </div>
          </div>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {demos.map((demo) => (
                <TableRow key={demo.id} onClick={() => navigate(`/internal/admin/demos/${demo.id}`)}>
                  <TableCell width={300} className={styles.tableCell}>
                    {demo.firstName}
                  </TableCell>
                  <TableCell width={300} className={styles.tableCell}>
                    {demo.lastName}
                  </TableCell>
                  <TableCell width={350} className={styles.tableCell}>
                    {demo.email}
                  </TableCell>
                  <TableCell align="left" className={styles.tableCell}>
                    {DemoStatusMap[demo.status] && (
                      <Typography sx={{ display: 'flex', justifyContent: 'left' }}>
                        <Chip
                          sx={{ textAlign: 'left', width: '120px' }}
                          color={DemoStatusMap[demo.status].color}
                          label={DemoStatusMap[demo.status].label}
                        />
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className={styles.pagination}>
        <Pagination
          count={Math.ceil(totalDemos / config.pagination.demosPageSize)}
          page={parseInt(page)}
          onChange={(event, value) => handlePageChange(event, value)}
        />
      </div>
    </Box>
  );
}
