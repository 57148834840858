export const DemoStatus = {
  REQUESTED: 'REQUESTED',
  SCHEDULED: 'SCHEDULED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const DemoStatusMap = {
  [DemoStatus.REQUESTED]: {
    label: 'Requested',
    color: 'info',
  },
  [DemoStatus.SCHEDULED]: {
    label: 'Scheduled',
    color: 'warning',
  },
  [DemoStatus.COMPLETED]: {
    label: 'Completed',
    color: 'success',
  },
  [DemoStatus.CANCELLED]: {
    label: 'Cancelled',
    color: 'error',
  },
};
