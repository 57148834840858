import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { Plan } from '../../../types/Plan';
import { createPlan, deletePlan, getPlan, getPlans, updatePlan } from './membershipPlanThunks';

interface MembershipPlanState {
  plan: Plan | null;
  plans: Plan[] | null;
  error: string | null | undefined;
  loading: boolean;
}

const initialState: MembershipPlanState = {
  plan: null,
  plans: null,
  loading: false,
  error: null,
};

const membershipPlanSlice = createSlice({
  name: SliceName.MEMBERSHIP_PLAN,
  initialState,
  reducers: {
    clearPlan: (state) => {
      state.plan = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlan.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getPlan.fulfilled, (state, action) => {
      state.plan = action.payload;
      state.loading = false;
    });

    builder.addCase(getPlan.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(getPlans.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getPlans.fulfilled, (state, action) => {
      state.plans = action.payload;
      state.loading = false;
    });

    builder.addCase(getPlans.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(deletePlan.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deletePlan.fulfilled, (state, action) => {
      state.plans = state.plans?.length ? state.plans.filter((plan) => plan.id !== action.payload) : state.plans;
      state.plan = state.plan?.id === action.payload ? null : state.plan;
      state.loading = false;
    });

    builder.addCase(deletePlan.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(updatePlan.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(updatePlan.fulfilled, (state, action) => {
      state.plan = state.plan?.id === action.payload.id ? { ...state.plan, ...action.payload } : state.plan;
      state.loading = false;
    });

    builder.addCase(updatePlan.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(createPlan.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(createPlan.fulfilled, (state, action) => {
      const plan = action.payload as Plan;

      state.plan = plan
      state.plans = state.plans ? [...state.plans, plan] : [plan];
      state.loading = false;
    });

    builder.addCase(createPlan.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { clearPlan } = membershipPlanSlice.actions;

export const selectPlan = (state: RootState) => state.plan;

export default membershipPlanSlice.reducer;
