import { Address } from '../types/Address';

export const getCurrentLanding = (pathname: string) => {
  if (!pathname) return '';

  if (pathname.includes('medical-c')) return 'medical-c';
  if (pathname.includes('hrt')) return 'hrt';
  if (pathname.includes('regenerative-medicine')) return 'regenerative-medicine';
  return '';
};

export const getDisplayAddress = (address: Address | null | undefined) => {
  if (!address) return '';

  return [address.addressLine1, address.addressLine2, address.city, address.zipCode, address.country]
    .filter(Boolean)
    .join(', ');
};
