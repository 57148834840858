import 'react-responsive-carousel/lib/styles/carousel.min.css';

import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import MedicalServicesRoundedIcon from '@mui/icons-material/MedicalServicesRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import classNames from 'classnames';

import styles from './Services.module.scss';

const CardItem = ({ icon, title, description, active }) => {
  return (
    <Box className={classNames(styles.cardItem, { [styles.cardItemActive]: active })}>
      <div>
        <div className={styles.cardItemIcon}>{icon}</div>
        <Typography className={styles.cardItemTitle}>{title}</Typography>
        <Typography className={styles.cardItemDescription}>{description}</Typography>
      </div>
    </Box>
  );
};

const MyArrowPrev = (onClickHandler, hasPrev) =>
  hasPrev && (
    <IconButton className={styles.backButton} onClick={onClickHandler}>
      <NavigateBeforeIcon fontSize="large" />
    </IconButton>
  );

const MyArrowNext = (onClickHandler, hasNext) =>
  hasNext && (
    <IconButton className={styles.nextButton} onClick={onClickHandler}>
      <NavigateNextIcon fontSize="large" />
    </IconButton>
  );

export default function Services({ id }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const onChange = (idx) => {
    setActiveIndex(idx);
  };
  return (
    <section id={id} className={styles.section}>
      <div className={styles.title}>
        <Typography component="h1" variant="h4">
          Our Services
        </Typography>
        <div>
          By joining you&apos;ll unlock a suite of features tailored for healthcare professionals. Our platform enables
          secure communication, promotes the exchange of knowledge and provides powerful collaboration tools, reshaping
          your approach to connectivity within the healthcare sphere
        </div>
      </div>

      <Carousel
        renderArrowPrev={MyArrowPrev}
        renderArrowNext={MyArrowNext}
        className={styles.carousel}
        showIndicators={false}
        showStatus={false}
        swipeable={false}
        showThumbs={false}
        centerSlidePercentage={40}
        transitionTime={1000}
        onChange={onChange}
        showArrows
        centerMode
        infiniteLoop
        autoPlay
      >
        <CardItem
          icon={<PublicRoundedIcon fontSize="large" />}
          title="Public Discussions"
          description="Engage in meaningful public discussions around relevant healthcare topics. Utilise our platform as a venue where you can interact and engage, expanding your understanding"
          active={activeIndex === 0}
        />

        <CardItem
          icon={<LockRoundedIcon fontSize="large" />}
          title="Private Discussions"
          description="Communicate with ease and complete confidentiality via our private groups. Craft a collaborative environment that focuses on privacy, allowing your team to share, discuss, and deliberate securely"
          active={activeIndex === 1}
        />

        <CardItem
          icon={<MedicalServicesRoundedIcon fontSize="large" />}
          title="MDT"
          description="Our Multi-Disciplinary Team is made up of world class doctors, experienced in prescribing medical cannabis, to give you the best advice and help possible. Benefit from the capability of creating private MDT cases"
          active={activeIndex === 2}
        />
      </Carousel>
    </section>
  );
}
