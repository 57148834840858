export const UserGroupRole = {
  OWNER: 'OWNER',
  MANAGER: 'MANAGER',
  DEFAULT: 'DEFAULT',
};

export const hasPermission = (permissions = [], permission) => {
  return permissions.includes(permission);
};

export const checkUserPermissions = (scopes, allowedRoles) => {
  return allowedRoles.some((role) => scopes.includes(role));
};

export const getUserRole = (scopes) => {
  const userRole = scopes.includes('admin') ? 'ADMIN' : 'DEFAULT';

  return userRole;
};
