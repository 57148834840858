import Home from './components/Home';
import Services from './components/Services';
import HowItWorks from './components/HowItWorks';
import ContactUs from './components/ContactUs';
import Partners from './components/Partners';
import Faq from './components/Faq';
import Pricing from './components/Pricing';

export const LandingSectionIds = {
  home: 'home',
  services: 'services',
  howItWorks: 'how-it-works',
  pricing: 'pricing',
  contactUs: 'contact-us',
  faq: 'faq',
};

export default function LandingPage() {
  return (
    <div>
      <Home id={LandingSectionIds.home} />
      <Services id={LandingSectionIds.services} />
      <HowItWorks id={LandingSectionIds.howItWorks} />
      <Pricing id={LandingSectionIds.pricing} />
      <Partners />
      <ContactUs id={LandingSectionIds.contactUs} />
      <Faq id={LandingSectionIds.faq} />
    </div>
  );
}
