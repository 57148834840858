import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { MultifactorAuthSettings } from '../../../types/MultifactorAuth';
import {
  disableMultifactorAuth,
  enableMultifactorAuth,
  getMultifactorAuthSettings,
  verifyMultifactorAuth,
} from './multifactorAuthSettingsThunks';

interface multifactorAuthSettingsState {
  multifactorAuthSettings: MultifactorAuthSettings | null;
  error: string | null | undefined;
  loading: boolean;
  secret: string | null;
}

const initialState: multifactorAuthSettingsState = {
  multifactorAuthSettings: null,
  loading: true,
  error: null,
  secret: null,
};

const multifactorAuthSettingsSlice = createSlice({
  name: SliceName.MULTIFACTOR_AUTH_SETTINGS,
  initialState,
  reducers: {
    cancelSettingMultifactorAuth: (state) => {
      state.error = null;
      state.secret = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMultifactorAuthSettings.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getMultifactorAuthSettings.fulfilled, (state, action) => {
      state.multifactorAuthSettings = action.payload;
      state.loading = false;
    });

    builder.addCase(getMultifactorAuthSettings.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(enableMultifactorAuth.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(enableMultifactorAuth.fulfilled, (state, action) => {
      state.secret = action.payload;
      state.loading = false;
    });

    builder.addCase(enableMultifactorAuth.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(verifyMultifactorAuth.fulfilled, (state) => {
      state.loading = false;
      if (state.multifactorAuthSettings) {
        state.multifactorAuthSettings.totp = true;
      }
      state.secret = null;
    });

    builder.addCase(disableMultifactorAuth.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(disableMultifactorAuth.fulfilled, (state) => {
      state.loading = false;
      if (state.multifactorAuthSettings) {
        state.multifactorAuthSettings.totp = false;
      }
    });

    builder.addCase(disableMultifactorAuth.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { cancelSettingMultifactorAuth } = multifactorAuthSettingsSlice.actions;

export const selectMultifactorAuthSettings = (state: RootState) => state.multifactorAuthSettings;

export default multifactorAuthSettingsSlice.reducer;
