import * as yup from 'yup';

export const ThreadMessageTextValidation = yup
  .string()
  .transform((value) => value.getCurrentContent().getPlainText())
  .max(3000, 'Max message size 3000 characters');

export const ThreadMessageAttachmentsValidation = yup.array()
  .max(25, `No more than 25 attachments are allowed`)
  .of(
    yup
      .mixed<{ file: File; }>()
      .nullable()
      .test('fileSize', 'Attachment size should be less than 3 MB', (value) => {
        return value?.file === null || !value?.file?.size || value?.file?.size <= 3_000_000;
      })
      .test('type', 'Unsupported Format', (value) => {
        return value?.file === null || !value?.file?.size || ['application/pdf', 'image/jpeg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value?.file.type);
      }),
  )
