import React, { useEffect, useState } from 'react';
import { Button, Link, Toolbar, debounce } from '@mui/material';
import { useLocation, useMatch, useNavigate, NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import classNames from 'classnames';

import styles from './Header.module.scss';
import { LandingSectionIds } from '../../pages/public/landing/LandingPage';
import SwipableDrawer from '../swipable-drawer/SwipableDrawer';
import { getCurrentLanding } from '../../utils/string';
import Logo from '../ui/Logo/Logo';
import PlatformSpecificContent from '../HOCs/PlatformSpecificContent/PlatformSpecificContent';

const NavigationLink = React.forwardRef((props, ref) => {
  const match = useMatch({ path: props.to, exact: true, end: false });

  if (match?.pattern?.exact) {
    return (
      <div ref={ref} className={classNames({ [styles.activeLinkClass]: match?.pattern?.exact })}>
        <NavLink {...props} />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <NavLink {...props} />
    </div>
  );
});

export default function Header() {
  const navigate = useNavigate();

  const { pathname, hash } = useLocation();
  const [activeSection, setActiveSection] = useState();

  const currentLanding = getCurrentLanding(pathname);

  const checkScroll = () => {
    if (!activeSection) {
      return;
    }

    const sections = [
      LandingSectionIds.home,
      LandingSectionIds.services,
      LandingSectionIds.howItWorks,
      LandingSectionIds.pricing,
      LandingSectionIds.contactUs,
      LandingSectionIds.faq
    ];
    let currentSection = false;

    for (const section of sections) {
      const element = document.getElementById(section);

      if (element && element.offsetTop - 100 < window.scrollY) {
        currentSection = section;
        setActiveSection(currentSection);
      }
    }
  };

  useEffect(() => {
    const debouncedCheckScroll = debounce(checkScroll, 250);

    window.addEventListener('scroll', debouncedCheckScroll);

    return () => {
      window.removeEventListener('scroll', debouncedCheckScroll);
    };
  }, [activeSection]);

  useEffect(() => {
    pathname === '/' ? setActiveSection(activeSection || LandingSectionIds.home) : setActiveSection(false);
  }, [pathname]);

  useEffect(() => {
    pathname === '/' ? setActiveSection(LandingSectionIds.home) : setActiveSection(false);

    if (hash && pathname === '/') {
      setActiveSection(hash.slice(1));
    }

    if (!hash) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [hash]);

  const scrollWithOffset = (el) => {
    const header = document.getElementById('header');
    const yOffset = -header.offsetHeight;
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;

    setActiveSection(el.id);
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const landingButtons = (
    <div className={styles.landingButtons}>
      <NavHashLink
        to={`/${currentLanding}#home`}
        scroll={(el) => scrollWithOffset(el)}
        className={classNames(styles.menuButton, {
          [styles.activeLinkClass]: activeSection === LandingSectionIds.home,
        })}
      >
        Home
      </NavHashLink>
      <NavHashLink
        to={`/${currentLanding}#services`}
        scroll={(el) => scrollWithOffset(el)}
        className={classNames(styles.menuButton, {
          [styles.activeLinkClass]: activeSection === LandingSectionIds.services,
        })}
      >
        Services
      </NavHashLink>
      <NavHashLink
        to={`/${currentLanding}#how-it-works`}
        scroll={(el) => scrollWithOffset(el)}
        className={classNames(styles.menuButton, {
          [styles.activeLinkClass]: activeSection === LandingSectionIds.howItWorks,
        })}
      >
        How It Works
      </NavHashLink>
      <NavHashLink
        to={`/${currentLanding}#pricing`}
        scroll={(el) => scrollWithOffset(el)}
        className={classNames(styles.menuButton, {
          [styles.activeLinkClass]: activeSection === LandingSectionIds.pricing,
        })}
      >
        Pricing
      </NavHashLink>
      <NavHashLink
        to={`/${currentLanding}#contact-us`}
        scroll={(el) => scrollWithOffset(el)}
        className={classNames(styles.menuButton, {
          [styles.activeLinkClass]: activeSection === LandingSectionIds.contactUs,
        })}
      >
        Contact Us
      </NavHashLink>
      <NavHashLink
        to={`/${currentLanding}#faq`}
        scroll={(el) => scrollWithOffset(el)}
        className={classNames(styles.menuButton, {
          [styles.activeLinkClass]: activeSection === LandingSectionIds.faq,
        })}
      >
        FAQ&apos;s
      </NavHashLink>
    </div>
  );

  const joinButtons = (
    <div className={styles.joinButtons}>
      <Link
        variant="text"
        to="sign-up"
        className={classNames(styles.menuButton, styles.signInButton)}
        component={NavigationLink}
      >
        Register Now
      </Link>
      <Link
        variant="text"
        to="sign-in"
        className={classNames(styles.menuButton, styles.signInButton)}
        component={NavigationLink}
      >
        Sign In
      </Link>

      <Button
        color="inherit"
        variant="outlined"
        to="book-demo"
        className={classNames(styles.menuButton, styles.bookDemoButton)}
        component={NavigationLink}
      >
        Book a Demo
      </Button>
    </div>
  );

  return (
    <Toolbar id="header" className={styles.header}>
      <PlatformSpecificContent
        webContent={
          <SwipableDrawer className={styles.drawerWrapper} drawerClasses={{ paper: styles.drawerPaper }} mobileOnly>
            {landingButtons}
            {joinButtons}
          </SwipableDrawer>
        }
      />

      <PlatformSpecificContent
        webContent={
          <>
            <div className={styles.companyLogo} onClick={() => navigate('/')}>
              <Logo className={styles.logo} />
            </div>
            {landingButtons}
            {joinButtons}
          </>
        }
        nativeContent={<Logo className={styles.logo} />}
      />
    </Toolbar>
  );
}
