import React, { useEffect } from 'react';
import { PushNotifications } from '@capacitor/push-notifications';

import { useAppSelector } from '../../store/hooks';
import { selectProfile } from '../../store/slices/profile/profileSlice';
import { useFeedback } from '../../hooks/useFeedback';
import { isString } from '../../utils/typeGuards';

const NativeNotificationsProvider: React.FC = () => {
  const { showInfoFeedback } = useFeedback();
  const { profile } = useAppSelector(selectProfile);

  const handleAddNotificationListeners = async () => {
    try {
      await PushNotifications.addListener('pushNotificationReceived', (notification) => {
        if (isString(notification.body)) {
          showInfoFeedback(notification.body);
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error subscribing to notifications:', error);
    }
  };

  useEffect(() => {
    if (profile) {
      handleAddNotificationListeners();
    }

    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [profile]);

  return null;
};

export default NativeNotificationsProvider;
