import React from 'react';

import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { sanitize } from 'dompurify';
import dayjs from 'dayjs';

import styles from './ThreadDetails.module.scss';
import ThreadMessageMetadata from './ThreadMessageMetadata';
import { ThreadStatusMap } from '../../../../../constants/thread.constants';
import Chip from '../../../../../components/chips/Chip/Chip';
import { MessageType } from '../../../../../constants/message.constants';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThreadAttachedFile from './components/ThreadAttachedFile';

export default function ThreadDetails({ thread, dislayStatus = false }) {
  const displayReviewComment = () => {
    const review = thread.messages.findLast((message) => message.type === MessageType.REVIEW);

    if (!review) return;

    return (
      <Accordion className={styles.reviewCommentWrapper}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={styles.summary}>
            <Chip
              size="small"
              variant="filled"
              label={ThreadStatusMap[thread.status].label}
              color={ThreadStatusMap[thread.status].color}
            />
            by
            <strong>
              {review.user.firstName} {review.user.lastName}
            </strong>
            at {dayjs(review.createdAt).format('MMM DD YYYY H:mm A')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography dangerouslySetInnerHTML={{ __html: sanitize(review.text) }} />
        </AccordionDetails>
      </Accordion>
    );
  };

  const displayAttachedFiles = () => {
    return (
      <div className={styles.attachedFiles}>
        {thread.attachments.map((attachment) => (
          <ThreadAttachedFile attachment={attachment} key={attachment.id} threadId={thread.id} />
        ))}
      </div>
    );
  };

  if (thread === null) {
    return (
      <div className={styles.threadDetailsWrapper}>
        <ThreadMessageMetadata />

        <div className={styles.threadDetailsContent}>
          <div className={styles.threadDetailsTitleWrapper}>
            <Typography className={styles.title}>
              <Skeleton variant="rounded" height={20} width={'90%'} />
            </Typography>
            {dislayStatus && <Skeleton variant="rounded" height={20} width={110} />}
          </div>

          <Typography className={styles.threadDetailsText}>
            {new Array(8).fill(<Skeleton variant="rounded" height={12} sx={{ mb: '4px' }} />)}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.threadDetailsWrapper}>
      <ThreadMessageMetadata user={thread?.user} createdAt={thread.createdAt} />

      <div className={styles.threadDetailsContent}>
        <div className={styles.threadDetailsTitleWrapper}>
          <Typography className={styles.title}>{thread.title}</Typography>
          {dislayStatus && (
            <Chip label={ThreadStatusMap[thread.status].label} color={ThreadStatusMap[thread.status].color} />
          )}
        </div>

        <Typography className={styles.threadDetailsText} dangerouslySetInnerHTML={{ __html: sanitize(thread.text) }} />
        {thread.attachments && displayAttachedFiles()}
        {dislayStatus && displayReviewComment()}
      </div>
    </div>
  );
}
