import React from 'react';
import { Typography } from '@mui/material';

import Button from '../../../../../../../components/button/Button';
import classes from './StepLayout.module.scss';

interface Props {
  title?: string;
  onNext?: () => void;
  onBack?: () => void;
  nextLabel?: string;
  isBackDisabled?: boolean;
  isNextDisabled?: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
}

const StepLayout: React.FC<Props> = ({
  children,
  title,
  onNext,
  onBack,
  nextLabel = 'Next',
  isLoading,
  isBackDisabled,
  isNextDisabled,
}) => {
  return (
    <div>
      <Typography component="h5" variant="h5" className={classes.title}>
        {title}
      </Typography>

      {children}

      <div className={classes.buttons}>
        {onBack && (
          <Button onClick={onBack} type="button" disabled={isBackDisabled}>
            Back
          </Button>
        )}

        <Button onClick={onNext} loading={isLoading} disabled={isNextDisabled} type="button">
          {nextLabel}
        </Button>
      </div>
    </div>
  );
};

export default StepLayout;
