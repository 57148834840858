/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { PlatformSpecificComponent } from '../PlatformSpecificComponent/PlatformSpecificComponent';
import { Platform } from '../../../types/enums/Platform';
import { config } from '../../../configs/config';

export const withPlatformSpecific =
  <P extends { [key: string]: any }>({
    WebComponent,
    NativeComponent,
  }: {
    WebComponent?: React.ComponentType<P>;
    NativeComponent?: React.ComponentType<P>;
  }) =>
  (props: P) => (
    <PlatformSpecificComponent
      platform={config.isNative ? Platform.NATIVE : Platform.WEB}
      WebComponent={WebComponent}
      NativeComponent={NativeComponent}
      {...props}
    />
  );
