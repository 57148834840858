import ApiClient from './ApiClient';
import { ApiRoute } from './ApiRoute';
import { getResponseData } from './adapterUtils';
import { Permissions } from '../../types/Permissions';

class PermissionsService {
  async getPermissions() {
    const response = await ApiClient.get<Permissions>(ApiRoute.PERMISSIONS);

    return getResponseData(response);
  }
}

export default new PermissionsService();
