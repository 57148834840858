import Grid from '@mui/material/Grid';
import * as yup from 'yup';

import styles from './PasswordManagement.module.scss';
import TextInputField from '../../../../components/text-input-field/TextInputField';
import { useFormik } from 'formik';
import AuthService from '../../../../services/api/AuthService';
import { toast } from 'react-toastify';
import Button from '../../../../components/button/Button';
import UserProfileWrapper from '../../../../components/user-profile/UserProfileWrapper';
import UserProfileItem from '../../../../components/user-profile/UserProfileItem';

const validationSchema = yup.object({
  oldPassword: yup
    .string('Enter your old password')
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
  newPassword: yup
    .string('Enter your new password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain upper case, lower case, number, special character',
    )
    .min(8, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Confirm New Password doesn`t match')
    .required('Confirm New Password field is required'),
});

export default function PasswordManagement() {
  const formik = useFormik({
    initialValues: { oldPassword: '', newPassword: '', confirmNewPassword: '' },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      AuthService.changePassword(values)
        .then(() => {
          toast.success('Password was successfully changed');
          resetForm();
        })
        .catch((error) => toast.error(` ${error.response?.data?.message || 'Failed to changed password.'}`))
        .finally(() => setSubmitting(false));
    },
  });
  return (
    <UserProfileWrapper title="Password Management" description="Manage your password">
      <UserProfileItem title="Change password">
        <form onSubmit={formik.handleSubmit}>
          <Grid container className={styles.wrapper} spacing={4}>
            <Grid item xs={12}>
              <Grid xs={12} className={styles.item} item>
                <TextInputField
                  label="Current Password"
                  type="password"
                  className={styles.item}
                  name="oldPassword"
                  size="small"
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                  errorHelperText={formik.errors.oldPassword}
                />
              </Grid>
              <Grid xs={12} className={styles.item} item>
                <TextInputField
                  label="New Password"
                  type="password"
                  className={styles.item}
                  name="newPassword"
                  size="small"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                  errorHelperText={formik.errors.newPassword}
                />
              </Grid>
              <Grid xs={12} className={styles.item} item>
                <TextInputField
                  label="Confirm New Password"
                  type="password"
                  className={styles.item}
                  name="confirmNewPassword"
                  size="small"
                  value={formik.values.confirmNewPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                  errorHelperText={formik.errors.confirmNewPassword}
                />
              </Grid>
              <Grid>
                <Button containerClassName={styles.buttonContainer} loading={formik.isSubmitting}>
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </UserProfileItem>
    </UserProfileWrapper>
  );
}
