import SvgIcon from '@mui/material/SvgIcon';

export default function SidebarSignoutIcon() {
  return (
    <SvgIcon>
      <svg viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.8813 0.574219C14.9093 0.574219 17.3789 3.00147 17.3789 5.9898V11.8323H9.93289C9.39926 11.8323 8.97732 12.247 8.97732 12.7715C8.97732 13.2838 9.39926 13.7107 9.93289 13.7107H17.3789V19.5409C17.3789 22.5293 14.9093 24.9687 11.8564 24.9687H5.81277C2.77232 24.9687 0.302734 22.5415 0.302734 19.5531V6.002C0.302734 3.00147 2.78473 0.574219 5.82518 0.574219H11.8813ZM20.4772 8.56366C20.8432 8.18555 21.4408 8.18555 21.8067 8.55147L25.3683 12.1009C25.5513 12.2838 25.6489 12.5156 25.6489 12.7717C25.6489 13.0157 25.5513 13.2596 25.3683 13.4304L21.8067 16.9798C21.6238 17.1627 21.3798 17.2603 21.1481 17.2603C20.9041 17.2603 20.6602 17.1627 20.4772 16.9798C20.1113 16.6139 20.1113 16.0162 20.4772 15.6503L22.4288 13.7109H17.3791V11.8325H22.4288L20.4772 9.89316C20.1113 9.52725 20.1113 8.92958 20.4772 8.56366Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
