import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceName } from '../../config';
import AuthService from '../../../services/api/AuthService';

export const getMultifactorAuthSettings = createAsyncThunk(
  `${SliceName.MULTIFACTOR_AUTH_SETTINGS}/getMultifactorAuthSettings`,
  async () => {
    const settings = await AuthService.getMultifactorAuthSettings();

    return settings;
  },
);

export const enableMultifactorAuth = createAsyncThunk(
  `${SliceName.MULTIFACTOR_AUTH_SETTINGS}/enableMultifactorAuth`,
  async () => {
    const secret = await AuthService.enableMultifactorAuth();

    return secret;
  },
);

export const verifyMultifactorAuth = createAsyncThunk(
  `${SliceName.MULTIFACTOR_AUTH_SETTINGS}/verifyMultifactorAuth`,
  async (code: string) => {
    await AuthService.verifyMultifactorAuth(code);
  },
);

export const disableMultifactorAuth = createAsyncThunk(
  `${SliceName.MULTIFACTOR_AUTH_SETTINGS}/disableMultifactorAuth`,
  async () => {
    await AuthService.disableMultifactorAuth();
  },
);
