import React from 'react';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';

import MdtOnboardingStepWrapper from './components/MdtOnboardingStepWrapper';
import MdtOnboardingDocumentUploadField, { uploadOnboardingDocument } from './components/MdtOnboardingDocumentUploadField';
import MdtOnboardingHelperText from './components/MdtOnboardingHelperText';

const validationSchema = yup.object().shape({
  document: yup.mixed().nullable()
    .test('fileSize', 'Files size is too large, it should be less than or equal to 3 MB', (value) => value === null || !value.size || value.size <= 3_000_000)
    .test('type', 'Unsupported Format', (value) => value === null || !value.size || ['application/pdf', 'image/jpeg', 'image/png'].includes(value.type))
});

export default function IndemnityInsuranceStep({ profile, setProfile, handleNext, handleBack }) {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...profile.indemnityInsurance },
    validationSchema,
    onSubmit: async (values) => {
      await uploadOnboardingDocument(formik.values.document, 'indemnityInsurance');

      setProfile({ ...profile, indemnityInsurance: values });
      handleNext();
    },
  });

  return (
    <MdtOnboardingStepWrapper
      name="Indemnity Insurance"
      formik={formik}
      handleBack={handleBack}
      nextLabel={profile.indemnityInsurance.document || formik.values.document ? 'Next' : 'Skip'}
      onSubmit={formik.handleSubmit}
    >
      <Grid item xs={12} sm={12}>
        <MdtOnboardingDocumentUploadField
          description="Please upload a copy of your personal indemnity insurance document"
          buttonLabel="Attach Document"
          name="document"
          onBlur={formik.handleBlur}
          uploaded={profile.indemnityInsurance.document}
          error={formik.touched.document && Boolean(formik.errors.document)}
          errorHelperText={formik.errors.document}
          setFieldValue={formik.setFieldValue}
          disabled={formik.isSubmitting}
        />
      </Grid>

      <MdtOnboardingHelperText />
    </MdtOnboardingStepWrapper>
  );
}
