import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';

import styles from './Search.module.scss';
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback';

interface Props {
  placeholder?: string;
  onChange: (value: string) => void;
  value: string;
  className?: string;
  debounce?: boolean;
  debounceTimeout?: number;
}

const Search: React.FC<Props> = ({
  placeholder = 'Search...',
  value,
  onChange,
  className,
  debounce,
  debounceTimeout = 1000,
}) => {
  const [innerValue, setInnerValue] = useState(value);

  const [isFocused, setIsFocused] = useState(false);

  const debouncedOnChange = useDebouncedCallback(onChange, debounceTimeout);

  useEffect(() => {
    if (value !== innerValue) {
      setInnerValue(value);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setInnerValue(newValue);

    if (debounce) {
      debouncedOnChange(newValue);
    } else {
      onChange(newValue);
    }
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className={classNames(styles.search, className)}>
      <Box className={classNames(styles.box, { [styles.focusedSearch]: isFocused })}>
        <SearchIcon />
        <InputBase
          placeholder={placeholder}
          value={innerValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Box>
    </div>
  );
};

export default Search;
