const ROUTER = {
  PUBLIC: {
    INDEX: {
      PATH: '/',
      ID: 'public',
    },
    HOME: {
      PATH: 'home',
    },
    SERVICES: {
      PATH: 'services',
    },
    SIGN_IN: {
      PATH: 'sign-in',
    },
    SIGN_UP: {
      PATH: 'sign-up',
    },
    PASSWORD_RESET: {
      PATH: 'password-reset',
    },
    TEAM: {
      PATH: 'team',
    },
    PRIVACY_POLICY: {
      PATH: 'privacy-policy',
    },
    TERMS_OF_USE: {
      PATH: 'terms-of-use',
    },
    CONTACT_US: {
      PATH: 'contact-us',
    },
  },
  PROTECTED: {
    INDEX: {
      PATH: 'internal',
      ID: 'protected',
    },
    PROFILE: {
      PATH: 'profile',
    },
    PRIVACY_POLICY: {
      PATH: 'privacy-policy',
    },
    TERMS_OF_USE: {
      PATH: 'terms-of-use',
    },
    CONTACT_US: {
      PATH: 'contact-us',
    },
    HOME: {
      PATH: 'home',
    },
    MDT: {
      PATH: 'mdt',
      ONBOARDING: {
        PATH: 'onboarding',
      },
    },
    MDT_ONBOARDING: {
      PATH: 'mdt/onboarding',
    },
  },
};

export default ROUTER;
